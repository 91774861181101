import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../constants/common";
import { getLocal, logoutUser } from "../../../helper/common";

const useSuperUser = () => {
  const navigate = useNavigate();

  const signout = () => {
    console.log("Logout");
    localStorage.removeItem("filter");
    logoutUser();
    navigate(`/`);
    axios
      .get(baseUrl + "api_auth/1/logout")
      .then(() => navigate(`/`))
      .catch(err => console.log(err));
  };

  const getUserName = () => {
    let localName = getLocal("name");
    return localName ? localName.trim() : "";
  };

  return {
    signout,
    getUserName,
  };
};

export default useSuperUser;
