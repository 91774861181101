import React, { useContext, createContext, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { isUserLoggedIn, getLocal } from "../helper/common";
import {
  customerAdminPaths,
  distributorAdminPaths,
  customerPaths,
  sellerAdminPaths,
  sellerPaths,
  distributorPaths,
  superUserPaths,
  enterprisePaths,
} from "../constants/allowedpath";

const Context = createContext(null);

// Helper to get the base paths for a role, considering enterprise status
const getBasePathsForRole = (role, isEnterpriseUser) => {
  
  let basePaths = [];
  
  switch (role) {
    case "customerIsAdmin":
      basePaths = customerAdminPaths;
      basePaths = isEnterpriseUser 
        ? [...superUserPaths, ...customerAdminPaths] 
        : customerAdminPaths;
      break;
    case "customer":
      basePaths = customerPaths;
      basePaths = isEnterpriseUser 
      ? [...superUserPaths, ...customerPaths] 
      : customerPaths;
      break;
    case "distributorIsAdmin":
      basePaths = distributorAdminPaths;
      break;
    case "distributor":
      basePaths = distributorPaths;
      break;
    case "sellerIsAdmin":
      basePaths = sellerAdminPaths;
      break;
    case "seller":
      basePaths = sellerPaths;
      break;
    case "superUser":
      // For superUsers, include both their paths and customer paths in enterprise mode
      basePaths = isEnterpriseUser 
        ? [...superUserPaths, ...customerAdminPaths, ...sellerAdminPaths] 
        : superUserPaths;
      break;
    default:
      basePaths = [];
  }
  // Add prefix for enterprise users
  // return isEnterpriseUser ? enterprisePaths : basePaths;

  // If enterprise user, add the prefix to each path
  if (isEnterpriseUser) {
    
    return basePaths.map(path => `/site-sentry${path}`);
  }

  return basePaths;
};

function PrivateRoutes({ Component }) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const route = location.pathname.split("/")[1];

  useEffect(() => {
    if (!isUserLoggedIn()) {
      navigate("/");
      return;
    }

    const role = getLocal("role");
    const isEnterpriseUser = getLocal("isEnterprise_user") === "true";
    const allowedPaths = getBasePathsForRole(role, isEnterpriseUser);

    // Replace dynamic parameters with their placeholder versions
    const currentPath = Object.keys(params).reduce((path, key) => {
      return path.replace(params[key], `:${key}`);
    }, location.pathname);

    const isAllowed = allowedPaths.some(allowedPath => {
      // Check if current path matches exactly or is a sub-path of an allowed path
      return currentPath === allowedPath || 
             currentPath.startsWith(allowedPath + '/') ||
             (allowedPath.includes(':') && new RegExp(allowedPath.replace(/:[\w]+/g, '[^/]+')).test(currentPath));
    });

    if (!isAllowed) {
      // If not allowed, redirect to the appropriate default path
      const defaultPath = allowedPaths[0];
      navigate(defaultPath);
    }
  }, [navigate, location.pathname, params]);

  return (
    <Context.Provider value={{ route }}>
      <Component route={route} />
    </Context.Provider>
  );
}

export default PrivateRoutes;

export const useRouteContext = () => useContext(Context);