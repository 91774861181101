import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import HeaderDashboard from "../../common/HeaderDashboard";
import GroupHeaders from "./GroupHeader";
import { getLocal } from "../../../../helper/common";
import { useUiContext } from "../../../../contexts/UiContext";
import DisplayControls from "./DisplayControl";
import CustomPagination from "./components/CustomPagination";
import CameraGrid from "./components/CameraGrid";
import SiteView from "../siteview/SiteView";
import { useAddressList } from "./services/useAddressList";
import { useToken } from "./services/useToken";
import { useDeviceList } from "./services/useDeviceList";
import { useGroupList } from "./services/useGroupList";
import { transformColumn } from "../../../../helper/utils";
import useDefaultViewService from "../../common/services/useDefaultViewService";
import CustomerSiteListSideBar from "../../../common/CustomerSiteListSideBar";
import { Grid, Box } from "@mui/material";

export default function CameraList({ route }) {
  const {
    defaultView,
    setDefaultView,
    searchFilter,
    setSearchFilter,
    sortBy,
    setSortBy,
    noOfColumns,
    setNoOfColumns,
    setNoOfColumnsGroupCameras,
  } = useUiContext();
  const [groupUuid, setGroupUuid] = useState("");
  const [activeGroupName, setActiveGroupName] = useState("All");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [addressesLatLog, setAddressesLatLog] = useState([]);
  const [addressesNames, setAddressesNames] = useState([]);
  const LIST_VIEW = "LIST_VIEW";
  const params = useLocation();
  const { fetchAddressList } = useAddressList();
  const { authToken, getTokens, setAuthToken } = useToken();
  const [defaultViewApiData, setDefaultViewApiData] = useState([]);
  const { deviceList, totalPageNo, currentPage, getDeviceList, getDeviceListManual, setCurrentPage } =
    useDeviceList();
  const { groups, fetchGroups } = useGroupList();
  const [role, setRole] = useState(null);

  const { 
    fetchDefaultViewData, 
    handleDefaultViewChange 
  } = useDefaultViewService();

  useEffect(() => {
    // Load default view data on component mount
    loadDefaultViewData();
  }, []);
  
  const loadDefaultViewData = async () => {
    try {
      const response = await fetchDefaultViewData();
      const results = response.data.results;
      
      if (results.length > 0) {
        setDefaultViewApiData(results);
        setDefaultView(results[0].json.defaultView);
      } else {
        setDefaultView(LIST_VIEW);
        await handleDefaultViewChange(LIST_VIEW);
      }
    } catch (error) {
      console.error("Failed to load default view:", error);
    }
  };

  useEffect(() => {
    setRole(getLocal("role"));
  }, []);

  const getAddressList = async () => {
    const { latlogArray, siteAddresses } = await fetchAddressList();
    setAddressesLatLog(latlogArray);
    setAddressesNames(siteAddresses);
  };

  useEffect(() => {
    if (role === "customer" || role === "customerIsAdmin") {
      fetchGroups();
      getAddressList();
    }
  }, [role]);

  useEffect(() => {
    const savedGroupUuid = localStorage.getItem("groupUuid");
    if (savedGroupUuid && savedGroupUuid !== groupUuid) {
      setGroupUuid(savedGroupUuid);
    }
  }, []);

  useEffect(() => {
    if (groupUuid) {
      localStorage.setItem("groupUuid", groupUuid);
    }
  }, [groupUuid]);

  useEffect(() => {
    const fetchToken = async () => {
      if (!authToken) {
        const token = await getTokens();
        if (token) {
          setAuthToken(token);
        }
      }
    };
  
    fetchToken();
  }, [authToken]);
  
  useEffect(() => {
    
    const fetchDevices = async () => {
      if (!authToken) return;
      
      try {
        const params = {
          page,
          ...(groupUuid && { group_uuid: groupUuid }),
          ...(searchFilter && { search: searchFilter }),
          ...(sortBy && { ordering: sortBy }),
          ...(limit && { limit: limit }),
        };
        
        if (role === "customer" || role === "customerIsAdmin" || role === "enterprise_user") {
          // TODO change to server side only after BE provide server side function
          if (params.page === 1) {
            await getDeviceList(params, groupUuid);
          } else {
            await getDeviceListManual(params);
          }
        }
      } catch (error) {
        console.error("Error fetching devices:", error);
      }
    };

    fetchDevices();
  }, [role, groupUuid, searchFilter, sortBy, page, limit, authToken]);

  const handleRefresh = () => {
    if (authToken) {
      getDeviceList({
        page,
        ...(groupUuid && { group_uuid: groupUuid }),
        ...(searchFilter && { search: searchFilter }),
        ...(sortBy && { ordering: sortBy }),
        ...(limit && { limit: limit }),
      }, groupUuid);
    }
  };

  const handlePageChange = (value) => {
    setCurrentPage(value);
    setPage(value);
  };

  const defaultViewApi = async (val) => {
    const newDefaultView = val
    setDefaultView(newDefaultView);
    try {
      await handleDefaultViewChange(newDefaultView);
      // Reload data to ensure we have the latest state
      loadDefaultViewData();
    } catch (error) {
      console.error("Failed to change default view:", error);
    }
  };

  const handleResponsiveColumns = (columns) => {
    const total = transformColumn(columns);
    setLimit(total);
    setDefaultView(LIST_VIEW);
    if (params.pathname.includes("/group/details")) {
      setNoOfColumnsGroupCameras(columns);
    }
    if (params.pathname.includes("/camera")) {
      setNoOfColumns(columns);
    }
  };

  const typingTimerRef = useRef(null);
  const handleSearch = (value) => {
    clearTimeout(typingTimerRef.current);
    typingTimerRef.current = setTimeout(() => {
      setSearchFilter(value);
      // Reset to first page when search changes
      setPage(1);
    }, 500); // Reduced delay for better responsiveness
  };

  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height devices-page d-flex flex-column">
          <GroupHeaders
            groups={groups}
            activeGroupName={activeGroupName}
            groupUuid={groupUuid}
            setGroupUuid={setGroupUuid}
            setActiveGroupName={setActiveGroupName}
            setSortBy={setSortBy}
            defaultView={defaultView}
            LIST_VIEW={LIST_VIEW}
          />

          <div className="flex-grow-1 camera-list-wrapper">
            <div className="row mb-4">
              <DisplayControls
                activeColumn={noOfColumns}
                handleResponsiveColumns={handleResponsiveColumns}
                defaultView={defaultView}
                setDefaultView={setDefaultView}
                defaultViewApi={defaultViewApi}
                LIST_VIEW={LIST_VIEW}
                SITE_VIEW="SITE_VIEW"
                onSearch={handleSearch}
                onRefresh={handleRefresh}
                route={route}
                addDeviceRoute={`/${route}/customer/cameras/add-new-device`}
              />
            </div>
            {/* Camera Row */}
            <Box className="camera-row">
              <Grid container spacing={2}>
                {defaultView === LIST_VIEW ? (
                  // LIST_VIEW: CameraGrid takes 10/12, Site component takes 2/12
                  <>
                    {/* Site component with 2/12 width */}
                    <Grid item xs={12} md={2}>
                      <Box>
                        {/* Your site component goes here */}
                        <Box
                          sx={{
                            borderRadius: 1,
                            height: "100%",
                          }}
                        >
                          <CustomerSiteListSideBar deviceList={deviceList} />
                        </Box>
                      </Box>
                    </Grid>
                    {/* CameraGrid with 10/12 width */}
                    <Grid item xs={12} md={10}>
                      <CameraGrid
                        route={route}
                        deviceList={deviceList}
                        columns={noOfColumns}
                        token={authToken}
                      />

                      {/* Pagination at the bottom of CameraGrid */}
                      <Box mt={3}>
                        <CustomPagination
                          page={currentPage}
                          totalPages={totalPageNo}
                          onPageChange={handlePageChange}
                        />
                      </Box>
                    </Grid>
                  </>
                ) : (
                  // SiteView: Takes full 12/12 width
                  <Grid item xs={12}>
                    <SiteView
                      latlogArray={addressesLatLog}
                      addressesNames={addressesNames}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
            {/* Camera Row */}
          </div>
        </div>
      </div>
    </div>
  );
}