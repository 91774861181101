import { useState } from "react";
import { useUiContext } from "../../../../contexts/UiContext";
import { getAuthHeaders } from "../../../../helper/utils";
import { baseUrl } from "../../../../constants/common";

export const useDeviceList = () => {
  const { apiRequest } = useUiContext();
  const [fullList, setFullList] = useState([]); // TODO Delete this in the future after BE provide server side
  const [deviceList, setDeviceList] = useState([]);
  const [totalPageNo, setTotalPageNo] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const headers = getAuthHeaders();

  const getDeviceList = async (params = {}, groupUuid = "") => {
    try {
      const response = await apiRequest(
        "get",
        "api/1/devices/",
        {},
        headers,
        true,
        { success: "", error: "" },
        params
      );
      let devices = response.data.results;
      setFullList(devices)
      let totalItems = response.data.results.length;
      let totalPage = Math.ceil(totalItems / params.limit)
      let start = (currentPage - 1) * params.limit;
      let end = start + params.limit;
      const paginatedDevices = devices.slice(start, end);
      setTotalPageNo(totalPage);
      setCurrentPage(response.data.page);
      if (groupUuid !== "" && paginatedDevices.length > 0) {
        const fetchLiveUrls = async (device) => {
          if (device.is_active) {
            // const res = await apiRequest(
            //   "get",
            //   `api/1/devices/${device.device_uuid}/mjpg_img_src`,
            //   {},
            //   headers,
            //   true
            // );
            // if (res) {
            //   device.live_url = res.response.data;
            // }
            device.live_url = `${baseUrl}api/public/mjpg_img_src/${device.device_uuid}/`;
          }
        };

        await Promise.all(paginatedDevices.map(fetchLiveUrls));
      }

      setDeviceList(paginatedDevices);
    } catch (error) {
      console.error("Error fetching device list:", error);
    }
  };

  const getDeviceListManual = (params) => {
    const totalItems = fullList.length;
    const totalPage = Math.ceil(totalItems / params.limit);
    setTotalPageNo(totalPage);
    // Handle pagination logic
    const page = params.page || 1; // Default to page 1 if params.page is undefined
    setCurrentPage(page);

    const start = (page - 1) * params.limit;
    const end = start + params.limit;
    const paginatedDevices = fullList.slice(start, end);
    setDeviceList(paginatedDevices);
  }

  return { deviceList, totalPageNo, currentPage, getDeviceList, getDeviceListManual, setCurrentPage };
};
