import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import ControlButton from "./ControlButton";
import LiveStatusButton from "./LiveStatusButton";
import NavigationIcon from "./NavigationIcon";
import { getIconsToShow } from "../../../../../helper/utils";

const LiveStream = ({
  liveUrl,
  isMobotix,
  openFullscreen,
  openMic,
  openRecord,
  openSound,
  deviceData, // Add deviceData prop
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const currentVideo = videoRef.current;

    return () => {
      if (currentVideo) {
        currentVideo.pause(); // Stop playback
        currentVideo.removeAttribute("src"); // Clear src
        currentVideo.load(); // Reset the video element
      }

      if (liveUrl.startsWith("blob:")) {
        setTimeout(() => URL.revokeObjectURL(liveUrl), 1000); // Delayed cleanup to prevent premature revocation
      }
    };
  }, [liveUrl]);

  const handleIconClick = (stateKey, newState, deviceData) => {
    console.log(`Icon ${stateKey} is now ${newState ? "on" : "off"}`);
    console.log(`Device data:`, deviceData);
    const handlerMap = {
      record: openRecord,
      sound: openSound,
      mic: openMic,
      shield: () => console.log("Shield toggled"),
      dim: () => console.log("Dim toggled"),
      horn: () => console.log("Horn toggled"),
      zoom: openFullscreen, // Special case for non-toggleable Zoom
    };

    // Call the appropriate handler, if defined
    if (handlerMap[stateKey]) {
      handlerMap[stateKey](newState);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#000",
        height: "100vh",
        width: "100%",
      }}
    >
      <LiveStatusButton variant="outlined" color="green" text="Live" />
      {deviceData && (
        <NavigationIcon
          position={{ top: 10, right: 10 }}
          onIconClick={handleIconClick}
          iconsToShow={(() => {
            const icons = getIconsToShow(isMobotix);
            return icons;
          })()}
          deviceData={deviceData}
          style={{
            position: "absolute", // Ensure it's absolutely positioned
            zIndex: 10, // Make sure it's above the video
          }}
        />
      )}
      {deviceData?.is_onvif ? (
        <video
          ref={videoRef}
          src={liveUrl}
          controls
          autoPlay
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      ) : (
        <video
          ref={videoRef}
          src={liveUrl}
          controls
          autoPlay
          style={{
            width: "100%",
            height: "auto",
            aspectRatio: "16/9",
            objectFit: "cover",
            zIndex: 1,
            position: "relative",
          }}
        />
      )}
      {/* TODO take out this code cz still out of scope PTZ button  */}
      {/* <Box
        sx={{
          position: "absolute",
          bottom: 10,
          right: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <ControlButton direction="up" onClick={() => console.log("Up")} />
        <Box sx={{ display: "flex" }}>
          <ControlButton direction="left" onClick={() => console.log("Left")} />
          <ControlButton direction="down" onClick={() => console.log("Down")} />
          <ControlButton
            direction="right"
            onClick={() => console.log("Right")}
          />
        </Box>
      </Box> */}
      {/* TODO take out this code cz still out of scope PTZ button  */}
    </Box>
  );
};

export default LiveStream;
