import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import { Grid, Button, Typography, IconButton } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import CustomTextField from "../Global/CustomTextField";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";
import CustomFileUpload from "./FileUpload";
import { toast } from "react-hot-toast";
import {
  getSiteAndAddressData,
  updateSiteMap,
  deleteSiteMap,
  getAddressDetail,
  createSiteMap,
} from "../../services/siteService/siteService";
import ImagePreview from "../Global/ImagePreview";
import ConfirmationModal from "../Global/ConfirmationModal";

export default function MapSiteForm() {
  const navigate = useNavigate();
  let params = useParams();
  let address_uuid = params.address_uuid;
  let sitemap_uuid = params.site_uuid;
  console.log("PARAMS", params);
  const { apiRequest } = useUiContext();
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    address: "",
    siteName: "",
    name: "",
    images: null,
    default_sitemap: false,
  });

  useEffect(() => {
    if (!address_uuid) return; // Exit if address_uuid is missing

    const fetchData = async () => {
      if (sitemap_uuid) {
        // Fetch full site + address data when both are available
        const combinedData = await getSiteAndAddressData(
          sitemap_uuid,
          address_uuid,
          apiRequest
        );
        if (combinedData) {
          setFormData((prev) => ({
            ...prev,
            ...combinedData, // Spread the combined data
          }));
        }
      } else {
        // Fetch only address details if no sitemap_uuid
        const addressData = await getAddressDetail(address_uuid, apiRequest);
        if (addressData) {
          setFormData((prev) => ({
            ...prev,
            siteName: addressData.data.name,
            address: addressData.data.address,
            address_uuid: address_uuid,
          }));
        }
      }
    };

    fetchData();
  }, [sitemap_uuid, address_uuid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdate = async (event) => {
    
    event.preventDefault();

    const updatedFormData = { ...formData };
    if (formData.images) {
      const imageData = new FormData();
      imageData.append("images", formData.images);
      updatedFormData.images = imageData;
    }

    const response = await updateSiteMap(sitemap_uuid, formData, apiRequest);
    if (response.success) {
      toast.success(response.message);
      navigate(`${siteAssetManagement}/sites/site-form/edit/${address_uuid}`);
    } else {
      toast.error(response.message);
    }
  };

  const handleCreate = async (event) => {
    
    event.preventDefault();
    const response = await createSiteMap(formData, apiRequest);
    
    if (response?.success) {
      toast.success(response.message);
      navigate(`${siteAssetManagement}/sites/site-form/edit/${address_uuid}`);
    } else {
      toast.error(response?.message);
    }
  };

  const handleDelete = async () => {
    const response = await deleteSiteMap(sitemap_uuid, apiRequest);
    if (response.success) {
      toast.success("Site map deleted successfully!");
      navigate(`${siteAssetManagement}/sites/site-form/edit/${address_uuid}`);
    } else {
      toast.error(response.message);
    }
  };

  const confirmDelete = () => {
    setOpenModal(true); // Open modal
  };

  return (
    <div style={{ padding: "20px", color: "#fff" }}>
      <IconButton
        onClick={() =>
          navigate(
            `${siteAssetManagement}/sites/site-form/edit/${address_uuid}`
          )
        }
      >
        <ArrowBackIcon style={{ color: "#fff" }} />
        <Typography color="white" variant="button">
          Back
        </Typography>
      </IconButton>
      <Typography variant="h4" gutterBottom>
        Form Map Site
      </Typography>

      <form
        onSubmit={sitemap_uuid && address_uuid ? handleUpdate : handleCreate}
      >
        <Grid container spacing={1}>
          {/* Left Side: Client and PIC Name */}
          <Grid item xs={12} sm={8}>
            <CustomTextField
              label="Site Name"
              name="siteName"
              value={formData.siteName}
              onChange={handleChange}
              disabled={true}
              type="text"
            />
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={1}>
          {/* Left Side: Client and PIC Name */}
          <Grid item xs={12} sm={8}>
            <CustomTextField
              label="Address"
              name="address"
              placeholder="Name Of Address"
              value={formData.address}
              onChange={handleChange}
              disabled={true}
              type="text"
            />
            <CustomTextField
              label="Name"
              name="name"
              placeholder="Map Site Name"
              value={formData.name}
              onChange={handleChange}
              type="text"
            />
            <CustomFileUpload
              label="Upload Image"
              name="images"
              file={formData.images}
              onFileChange={(file) =>
                setFormData((prev) => ({ ...prev, images: file }))
              }
              onRemove={() =>
                setFormData((prev) => ({ ...prev, images: null }))
              }
            />
            {formData.images && (
              <ImagePreview
                file={formData.images}
                onRemove={() =>
                  setFormData((prev) => ({ ...prev, images: null }))
                }
                onSetDefault={() =>
                  setFormData((prev) => ({
                    ...prev,
                    default_sitemap: !prev.default_sitemap,
                  }))
                }
                isDefault={formData.default_sitemap}
              />
            )}
          </Grid>
        </Grid>
        <hr />
        {/* Device Status */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", // Align buttons
            marginTop: "16px",
          }}
        >
          {sitemap_uuid && address_uuid && (
            <Button
              variant="contained"
              color="error"
              className="btn-rounded"
              sx={{ borderRadius: "15px" }}
              onClick={confirmDelete}
            >
              Delete
            </Button>
          )}
          {/* Submit Button */}
          <Button
            variant="contained"
            color="success"
            type="submit"
            className="btn-rounded"
            sx={{ borderRadius: "15px" }}
          >
            Save Map
          </Button>
          <ConfirmationModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            onConfirm={handleDelete}
            title="Are you sure you want to delete this Site?"
            note="NOTE - Once deleted, you can not find this Site in the list."
          />
        </div>
      </form>
    </div>
  );
}
