import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import HeaderAuthentication from '../../common/HeaderAuthentication';
import {useUiContext} from '../../../contexts/UiContext';
import {formValidation, setLocal} from "../../../helper/common";
import moment from "moment-timezone";

export default function VerifyCode() {
    const {apiRequest, setLoading, setHeadData} = useUiContext();
    const [isDisabled, setIsDisabled] = useState(true);
    const [formData, setFormData] = useState({
        code: '',
        trust: false,
    });
    const navigate = useNavigate();

    useEffect(() => {
        formValidation({code: formData.code}, setIsDisabled);
    }, [formData]);


    const verifyCode = async () => {
        console.log("Current Verification Code:", formData.code); // Debugging
    if (!formData.code) {
        console.error("Code is missing!");
        return;
    }
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("token")
        };
    
        setLoading(true);
        
        try {
            const verifyRes = await apiRequest("post", "api_auth/1/verify", formData, headers, {
                success: 'Login successfully',
                error: 'Invalid OTP'
            });
    
            if (!verifyRes || !verifyRes.data) {
                console.error("Verify response is empty:", verifyRes);
                setLoading(false);
                return;
            }
    
            if (!verifyRes.data.x_verified_jwt) {
                console.error("x_verified_jwt missing in response:", verifyRes.data);
                setLoading(false);
                return;
            }
    
            localStorage.setItem("x_verified_jwt", verifyRes.data.x_verified_jwt);
            const nowTs = moment().unix();
            localStorage.setItem("auth_metadata_ts", `${nowTs}`);
    
            const headersMetaData = {
                'Authorization': 'Bearer ' + localStorage.getItem("token"),
                'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
            };
    
            const metadataRes = await apiRequest("get", "api_auth/1/metadata", "", headersMetaData);
            if (!metadataRes || !metadataRes.data) {
                console.error("Metadata response is empty:", metadataRes);
                setLoading(false);
                return;
            }
    
            const user = metadataRes.data.user;
            
            if (!user) {
                console.error("User data is missing in metadata response:", metadataRes.data);
                setLoading(false);
                return;
            }
    
            // Store user info
            if (user.customer_uuid !== null) {
                localStorage.setItem("customeruuid", user.customer_uuid);
            }
            if (user.distributor_uuid !== null) {
                localStorage.setItem("customeruuid", user.distributor_uuid);
            }
            if (user.seller_uuid !== null) {
                localStorage.setItem("customeruuid", user.seller_uuid);
            }
            localStorage.setItem("tz", user.tz);
            localStorage.setItem("sn", metadataRes.data.site_name);
            setHeadData({ title: metadataRes.data.site_name, description: metadataRes.data.site_name });
            localStorage.setItem("username", user.username);
            setLocal("name", user.first_name + " " + user.last_name);
    
            let role = "";
            let isadmin = "";
    
            if (user.customer_uuid != null) role = "customer";
            if (user.seller_uuid != null) role = "seller";
            if (user.distributor_uuid != null) role = "distributor";
    
            let isEnterpriseUser = metadataRes.data.associated_seller_abn === "11167381482";
            if (isEnterpriseUser) {
                setLocal("isEnterprise_user", JSON.stringify(true));
            }
    
            switch (role) {
                case "customer":
                    isadmin = (user?.[role + "_profile"]?.[`is_${role}_admin`] || user?.[role + "_profile"]?.[`is_${role}_user_admin`]) ?? false;
                    setLocal('userRole', user?.customer_profile?.role ?? "");
                    break;
                case "seller":
                case "distributor":
                    isadmin = user?.[role + "_profile"]?.[`is_${role}_admin`] ?? false;
                    break;
            }
    
            setLocal("role", role + (isadmin ? 'IsAdmin' : ''));
            localStorage.setItem("isadmin", isadmin);
            setLoading(false);
    
            // Adjust navigation for enterprise users
            const basePath = isEnterpriseUser ? "site-sentry/" : "";
            navigate(`/${basePath}customer/cameras`);
        } catch (error) {
            console.error("Error in verifyCode:", error);
            setLoading(false);
        }
    };
    

    const reSendCode = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem("token")
        };
        await apiRequest("patch", "api_auth/1/verify?resend=1", true, headers, {
            success: 'Email re-send successfully',
            error: 'Failed to send code'
        });
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setTimeout(() => {
                verifyCode();
            }, 100); // Delay ensures state updates before calling API
        }
    };
    /* jshint ignore:start */

    return (
        <div className='d-flex flex-column site-auth-wrapper'>
            <HeaderAuthentication/>
            <div className="flex-grow-1 d-flex align-items-center justify-content-center auth-form-wrapper">
                <div className="container">
                    <div className="form-wrapper">
                        <div className="form-heading text-center">
                            <h3>Let us know it’s you</h3>
                            <p>To secure your account, enter the code we just send to your email</p>
                        </div>
                        <div className="field-wrapper">
                            <div
                                className="mb__20 position-relative"> {/* Add Class error to this div once error occurs and if its valid, add class success*/}
                                <input type="text" name="code" value={formData.code} className="form-control"
                                        onKeyUp={handleKeyPress} 
                                      placeholder="Enter Verification Code" onChange={(e) =>
                                    setFormData({...formData, code: e.target.value})
                                }
                                />
                                {/* <div className="validation-msg">This is not a valid email</div> */}
                            </div>
                            <div className={"additional-info mb__20 d-flex justify-content-between"}>
                                <div className="form-check">
                                    <input className="form-check-input"  type="checkbox" id="remember" name="terms_checkbox"                      
                                          onChange={(e) =>
                                               setFormData({...formData, trust: e.target.checked})} checked={formData.trust}/>
                                    <label className="form-check-label" htmlFor="remember">
                                        Trust this device for 60 days
                                    </label>
                                </div>
                            </div>

                            {/* <input className="form-check-input d-none" type="checkbox" id="remember" name="terms_checkbox" onChange={(e) =>
                                setIsChecked(e.target.checked)} checked={isChecked} /> */}

                            <div className="additional-info mb__20 d-flex justify-content-end" onClick={reSendCode}>
                                <p className='reset-password mb-0' style={{color: "#000", cursor: "pointer"}}>Didn’t get the
                                    code?<span className='btn-link reset-password'>Resend code</span></p>
                            </div>
                        </div>
                        <div className="button-wrap mb-0">
                            {/* <Link to="/reset-password"> */}
                            <button className='btn btn-primary' disabled={isDisabled} onClick={verifyCode}>Done</button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    /* jshint ignore:end */
}
