import React from "react";
import { Card, CardMedia, IconButton, Switch, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

export default function ImagePreview({ file, onRemove, onSetDefault, isDefault }) {
  if (!file) return null;

  // Check if the file is a string (URL) or a File object
  const imageUrl = typeof file === "string" ? file : URL.createObjectURL(file);

  return (
    <Card sx={{ maxWidth: 200, position: "relative", mt: 2 }}>
      {/* Remove Image Button */}
      <IconButton
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
          backgroundColor: "rgba(0,0,0,0.5)",
          color: "white",
          "&:hover": { backgroundColor: "rgba(0,0,0,0.7)" },
        }}
        onClick={onRemove}
      >
        <CloseIcon />
      </IconButton>

      {/* Image Preview */}
      <CardMedia component="img" height="140" image={imageUrl} alt="Uploaded Preview" />

      {/* Default Image Toggle */}
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "8px" }}>
        <Typography variant="body2">Make Default</Typography>
        <Switch color="success" checked={isDefault} onChange={onSetDefault} />
      </div>
    </Card>
  );
}
