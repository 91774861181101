import React, {useState, useMemo, useCallback, useRef} from "react";
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import { tileAttributionHtml, tileUrl } from "../../../constants/common";


export default function Leaflet({lat, lng, setFormData, formData, pinDrag}) {

  const [latitude, setLatitude] = useState(0);
  const [longtitude, setLongtitude] = useState(0);
  const [reloadMap, setReloadCount] = useState(0);

  function DraggableMarker({setFormData, formData}) {
    const center = {
      lat: lat,
      lng: lng,
    };
    const [draggable, setDraggable] = useState(true);
    const [position, setPosition] = useState(center);
    const markerRef = useRef(null);

    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            const latLng = marker.getLatLng();
            setFormData({...formData, lat: latLng.lat, lon: latLng.lng});
            setPosition(marker.getLatLng());
            pinDrag(latLng);
          }
        },
      }),
      [setFormData, formData],
    );
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d);
    }, []);

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup>
      </Marker>
    )
  }

  useMemo(() => {
    setReloadCount(reloadMap + 1)
  }, [lat, lng])
  if (lat !== "" && lng !== "") {
    return (
      <>
        <MapContainer center={[parseFloat(lat), parseFloat(lng)]} zoom={12} scrollWheelZoom={false}>

          <DraggableMarker setFormData={setFormData} formData={formData}/>
          <TileLayer url={tileUrl} attribution={tileAttributionHtml} />
          <Marker position={[51.505, -0.09]}>
            <Popup>
              A pretty CSS3 popup. <br/> Easily customizable.
            </Popup>
          </Marker>

        </MapContainer>
      </>
    );
  }
}
