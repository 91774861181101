import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import { formValidation } from "../../../../helper/common";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import {
  Grid,
  IconButton,
  Switch,
  Typography,
  Checkbox,
  Radio,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import Svg from "../../../common/Svg";
import { toast } from "react-hot-toast";
import { toUpperCaseText, MAC_DEFAULT } from "../../../../helper/utils";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";
import { cameraManufactureData } from "../../constant/CameraManufacture";
import CustomSelect from "../Global/CustomSelect";
import CustomTextField from "../Global/CustomTextField";
import { getDeviceTypeService } from "../Customer/services/customerService";
import {
  getSitesListService,
  getAddressList,
  getGroupsList,
  testDeviceConnectionService,
  newGroupService,
  getDeviceDetailsService,
  deviceUpdate,
  handleEventCheckboxChangeService,
} from "../../services/cameraServices/cameraService";
import { getAuthHeaders } from "../../../../helper/utils";
import Knob from "../../../../component/knob";
import { Layer, Rect, Stage, Image, Text } from "react-konva";

export default function CameraForm() {
  const getHeaders = () => getAuthHeaders();
  let navigate = useNavigate();
  const [image, setImage] = useState(new window.Image());
  const imageRef = useRef();
  const stageParentDiv = useRef();
  const { apiRequest } = useUiContext();
  const { device_uuid } = useParams();
  let uid = localStorage.getItem("customeruuid");
  const [isDisabled, setIsDisabled] = useState(true);
  const [groups, setGroups] = useState([]);
  const [createGroup, setCreateGroup] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    oak: "",
    mac: "",
  });
  const [formData, setFormData] = useState({
    cameraManufacture: "axis",
    customer_uuid: uid,
    is_active: true,
    mac: "",
    name: "",
    oak: "",
    devicePassword: "",
    deviceUsername: "",
    group: "",
    sync_device_recordings: false,
    address_uuid: "",
    sitemap_uuid: "",
    device_type: "",
    sitemap_bearing: 0,
    sitemap_x_fraction: 0.3,
    sitemap_y_fraction: 0.3,
    onvif_host: "", // Updated field name
    is_onvif: "",
  });
  const [addresses, setAddresses] = useState([]);
  const [isAddressselected, setIsAddressSelected] = useState(false);
  const [selectAddressText, setSelectAddressText] = useState("");
  const [siteMapList, setSiteMapList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState(null);
  const [oakIsInalidMessage, setIsOakInalidMessage] = useState(false);
  const oakRegexPattern = /^[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}$/;
  const [isProtocolOnvif, setIsProtocolOnvif] = useState(false);
  const [isMobotix, setIsMobotix] = useState(false);
  const [openVPNConfigUrl, setOpenVPNConfigUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfigUrlReady, setIsConfigUrlReady] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [cameraTypes, setCameraTypes] = useState([]);
  const [selectedSiteMap, setSelectedSiteMap] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedId, selectShape] = useState(null);
  const [isShowEventsForSync, setIsShowEventsForSync] = useState(false);
  const [cameraEventsOptions, setCameraEventsOptions] = useState([
    "All Events",
  ]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [isCameraConfigShow, setIsCameraConfigShow] = useState(true);
  const stageMobileParentDiv = useRef();
  const [attrs, setAttrs] = useState({
    id: 1,
    isDragging: false,
    x: 50,
    y: 50,
    rotation: rotation,
    selected: false,
    isActive: true,
  });
  const [dimensions, setDimensions] = useState({ width: "", height: "" });

  const getOpenVPNConfigUrl = async () => {
    return await apiRequest(
      "get",
      "api/1/devices/openvpn_config_url/",
      "",
      getHeaders(),
      false
    )
      .then((res) => {
        if (res.data?.url) {
          setOpenVPNConfigUrl(res.data.url);
          setIsConfigUrlReady(true);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const testDeviceConnection = async () => {
    setIsLoading(true);
    const result = await testDeviceConnectionService(
      apiRequest,
      formData,
      setIsLoading
    );
    if (result) {
      console.log("Device connection data:", result);
    }
  };

  const downloadOpenVPNConfig = () => {
    console.log("Downloading OpenVPN Config...");
    console.log("OpenVPN Config URL:", openVPNConfigUrl);
    const link = document.createElement("a");
    link.href = openVPNConfigUrl;
    const urlParts = openVPNConfigUrl.split("/");
    link.download = urlParts[urlParts.length - 1];
    console.log("Download link created:", link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log("Download link clicked and removed from document.");
  };

  useEffect(() => {
    const {
      mac,
      name,
      oak,
      password,
      username,
      address_uuid,
      sitemap_uuid,
      onvif_host, // Updated field name
    } = formData;

    formValidation(
      {
        name: name,
        password: password,
        username: username,
        ...(isProtocolOnvif && { onvif_host: onvif_host }), // Updated field name
        ...(isProtocolOnvif === false && { mac, oak }),
      },
      setIsDisabled
    );
  }, [formData]);

  const getDeviceAddressSitemap = async (address_uuid, deviceData) => {
    try {
      const res = await apiRequest(
        "get",
        `api/1/addresses/${address_uuid}/`,
        "",
        getHeaders(),
        true
      );

      if (res && res.data) {
        getSitesList(address_uuid);

        // If editing and there's a sitemap, set the form value
        if (deviceData && deviceData.sitemap) {
          setFormData((prev) => ({
            ...prev,
            sitemap_uuid: deviceData.sitemap.sitemap_uuid,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching address sitemap:", error);
    }
  };

  // Create a function to load device details
  const loadDeviceDetails = async () => {
    const setters = {
      setDeviceDetails,
      setIsMobotix,
      getDeviceAddressSitemap,
      setRotation,
      setCameraTypes,
      setFormData,
      setImage,
      formData,
    };

    await getDeviceDetailsService(apiRequest, device_uuid, setters);
  };

  useEffect(() => {
    if (device_uuid) {
      loadDeviceDetails();
    } else {
      document.title = "Add Camera";
    }
  }, [device_uuid]);

  useEffect(() => {
    if (selectedSite && selectedSiteMap) {
      console.log("selectedSiteMap", selectedSiteMap);
      console.log("selectedSite", selectedSite);
    }
  }, [selectedSite, selectedSiteMap]);

  const getGroups = async () => {
    const data = await getGroupsList(apiRequest);
    setGroups(data);
  };

  const getAddress = async () => {
    const data = await getAddressList(apiRequest);
    setAddresses(data);
  };

  const getSitesList = async (address_uuid) => {
    const sites = await getSitesListService(apiRequest, address_uuid);
    setSiteMapList(sites);
  };

  const getInputs = async () => {
    const cacheddeviceTypeList = localStorage.getItem("deviceTypeList");
    if (cacheddeviceTypeList && cacheddeviceTypeList !== "undefined") {
      try {
        setDeviceTypeList(JSON.parse(cacheddeviceTypeList));
        return;
      } catch (error) {
        console.error("Failed to parse cached deviceTypeList:", error);
      }
    }

    const data = await getDeviceTypeService(apiRequest);
    if (data) {
      localStorage.setItem("deviceTypeList", JSON.stringify(data));
      setDeviceTypeList(data);
    }
  };

  useEffect(() => {
    getGroups();
    getAddress();
    getInputs();
    getOpenVPNConfigUrl();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const oakInputhandleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "oak" && oakRegexPattern.test(e.target.value)) {
      setIsOakInalidMessage(false);
      handleChange(e);
    } else {
      setIsOakInalidMessage(true);
    }
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, group: e.target.value });
  };
  const addNewCamera = async () => {
    if (formData.sitemap_uuid === "") {
      formData.sitemap_uuid = null;
    }

    if (formData.address_uuid === "") {
      formData.address_uuid = null;
    }
    if (formData.device_type === "") {
      delete formData.device_type;
    }
    if (isProtocolOnvif) {
      formData.mac = MAC_DEFAULT;
    }
    let newFormData = formData;
    newFormData.username = formData.deviceUsername;
    newFormData.password = formData.devicePassword;
    newFormData.is_onvif = isProtocolOnvif;
    if (isMobotix) {
      newFormData.onvif_host = formData.onvif_host; // Updated field name
    }

    return await apiRequest(
      "post",
      "api/1/devices/",
      newFormData,
      getHeaders(),
      true,
      { success: "Device added successfully", error: "Failed to add device" }
    )
      .then((res) => {
        if (res?.response?.data) {
          const errorsData = res.response.data;
          let newErrorState = {
            name: "",
            mac: "",
            oak: "",
          };
          if (errorsData.non_field_errors) {
            toast.error(errorsData.non_field_errors[0], { duration: 10000 });
          }
          if (errorsData.name) newErrorState.name = errorsData.name[0];
          if (errorsData.mac) newErrorState.mac = errorsData.mac[0];
          if (errorsData.oak) newErrorState.oak = errorsData.oak[0];
          if (errorsData.code && errorsData.code.length > 0)
            navigate("/site-sentry/asset-management/customer/cameras");
          setErrors(newErrorState);
        } else {
          console.warn(res.data);
          navigate("/site-sentry/asset-management/customer/cameras");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create group API
  const createHandler = (e) => {
    e.preventDefault();
    newGroup(createGroup);
  };

  const newGroup = async (createGroup) => {
    setIsLoading(true);
    const result = await newGroupService(apiRequest, createGroup);
    if (result) {
      getGroups();
    }
    setIsLoading(false);
  };

  const cameraLink = () => {
    return (
      formData.mac !== "" && (
        <a
          className="btn btn-primary btn-rounded"
          href={`http://axis-${formData.mac}.local/`}
          target="_blank"
          rel="noreferrer"
        >
          Local Camera Config
        </a>
      )
    );
  };
  const isAdmin = localStorage.getItem("isadmin") === "true";

  const handleCameraManufacturerChange = (e) => {
    const selectedValue = e.target.value;

    setFormData((prev) => ({
      ...prev,
      cameraManufacture: selectedValue, // Update selected manufacturer
    }));

    if (selectedValue === "mobotix") {
      setIsMobotix(true);
      setIsProtocolOnvif(true);
    } else {
      setIsMobotix(false);
      setIsProtocolOnvif(false);
    }
  };

  const handleEventCheckboxChange = (checked, index) => {
    const setters = {
      setSelectedEvents,
      setFormData,
      formData,
    };

    handleEventCheckboxChangeService(
      checked,
      index,
      cameraEventsOptions,
      selectedEvents,
      setters
    );
  };

  const handleSubmit = async () => {
    if (device_uuid) {
      return await deviceUpdate(apiRequest, device_uuid, formData);
    } else {
      return await addNewCamera();
    }
  };

  const clearSelectedSiteData = () => {
    setSelectedSite(null);
    setSelectedSiteMap(null);
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleRangeChange = (value) => {
    setRotation(value);
  };

  useEffect(() => {
    setAttrs({ ...attrs, rotation: rotation });
  }, [rotation]);

  useEffect(() => {
    if (
      stageParentDiv.current?.offsetHeight &&
      stageParentDiv.current?.offsetWidth
    ) {
      setDimensions({
        width: stageParentDiv.current.offsetWidth,
        height: stageParentDiv.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (
      stageMobileParentDiv.current?.offsetHeight &&
      stageMobileParentDiv.current?.offsetWidth
    ) {
      setDimensions({
        width: stageMobileParentDiv.current.offsetWidth,
        height: 550,
      });
    }
  }, []);

  useEffect(() => {
    if (deviceDetails) {
      setFormData({
        ...formData,
        sitemap_bearing: parseInt(attrs.rotation),
        enable_screen_overlay: deviceDetails.enable_screen_overlay,
        enable_screen_overlay_date_and_time:
          deviceDetails.enable_screen_overlay_date_and_time,
        camera_default: deviceDetails.camera_default,
      });
    }
  }, [attrs]);

  const siteMapChange = (e) => {
    const img = new window.Image();
    if (e) {
      img.src = e.image;
      setImage(img);
      setFormData({ ...formData, sitemap_uuid: e.value });
    } else {
      setImage(new window.Image());
    }
  };

  const resetIconPosition = () => {
    if (deviceDetails) {
      // Use the original fraction-based calculation
      const newX = deviceDetails.sitemap_x_fraction * dimensions.width;
      const newY = deviceDetails.sitemap_y_fraction * (dimensions.height / 2);
  
      setAttrs(prevAttrs => ({
        ...prevAttrs,
        x: newX,
        y: newY,
        rotation: deviceDetails.sitemap_bearing || 0
      }));
    }
  };

  return (
    <div style={{ padding: "20px", color: "#fff" }}>
      <IconButton onClick={() => navigate(`${siteAssetManagement}/cameras`)}>
        <ArrowBackIcon style={{ color: "#3bb44a" }} />
        <Typography color="white" variant="button">
          Back
        </Typography>
      </IconButton>
      <div className="row add-dvc-row gx-4">
        <div className="col-lg-12">
          <div className="mb-4">
            <h1 className="text-white">
              {device_uuid ? "Edit" : "Create"} Form Camera
            </h1>
          </div>
          {/* Form Add Device */}
          <div className="theme-form-wrapper">
            <h4 className="text-white mb-3">Device Details</h4>
            <div className="d-flex mb__20">
              <label className="col-form-label text-end"></label>
              <div className="flex-grow-1">
                <div className="text-end new-tab-text">{cameraLink()}</div>
              </div>
            </div>
            <Grid container spacing={2} alignItems="center" className="mb-2">
              <Grid item xs={12} sm={12}>
                <CustomSelect
                  options={cameraManufactureData}
                  label="Camera Manufacturer"
                  name="cameraManufacture"
                  placeholder="Camera Manufacturer"
                  value={formData.cameraManufacture}
                  onChange={handleCameraManufacturerChange}
                />
              </Grid>
            </Grid>
            {isMobotix && (
              <Grid container spacing={2} alignItems="center" className="mb-2">
                <Grid item xs={12} sm={4} md={3}>
                  <label className="col-form-label text-end"></label>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadOpenVPNConfig}
                    disabled={!isConfigUrlReady}
                  >
                    {isConfigUrlReady
                      ? "Download OpenVPN Config"
                      : "Preparing the config file..."}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={testDeviceConnection}
                    disabled={isLoading}
                    sx={{ ml: 2 }}
                  >
                    {isLoading ? "Loading..." : "Test Connection"}
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={2}>
              {/* Left Side: Client and PIC Name */}
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  label="Device Name"
                  name="name"
                  placeholder="Device Name"
                  value={formData.name}
                  onChange={handleChange}
                  type="text"
                />
                {isMobotix && (
                  <CustomTextField
                    label="Mobotix IP/Host"
                    name="onvif_host"
                    placeholder="Mobotix IP/Host"
                    value={formData.onvif_host}
                    onChange={handleChange}
                    type="text"
                  />
                )}
                {!isMobotix && (
                  <CustomTextField
                    label="Serial Number"
                    name="mac"
                    placeholder="Serial Number"
                    value={formData.mac}
                    onChange={handleChange}
                    type="text"
                  />
                )}
                {!isMobotix && (
                  <CustomTextField
                    label="Owner Authentication Key"
                    name="oak"
                    placeholder="Owner Authentication Key"
                    value={formData.oak}
                    onChange={oakInputhandleChange}
                    type="text"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextField
                  label="Password"
                  name="devicePassword"
                  placeholder="Password"
                  value={formData.devicePassword}
                  onChange={handleChange}
                  type="password"
                />
                <CustomTextField
                  label="Username"
                  name="deviceUsername"
                  placeholder="Username"
                  value={formData.deviceUsername}
                  onChange={handleChange}
                  type="text"
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={2}>
              {/* Left Side: Client and PIC Name */}
              <Grid item xs={12} sm={6}>
                <CustomSelect
                  options={addresses}
                  label="Site"
                  name="address_uuid"
                  placeholder="Select Site"
                  value={formData.address_uuid} // Use formData instead of selectedSite
                  onChange={(e) => {
                    setIsAddressSelected(true);
                    setSelectAddressText("");
                    getSitesList(e.target.value);
                    siteMapChange();
                    handleChange(e);
                  }}
                  idKey="address_uuid"
                  labelKey="name"
                />

                <CustomSelect
                  options={siteMapList}
                  label="Site Map"
                  name="sitemap_uuid"
                  placeholder="Select sitemap"
                  value={formData.sitemap_uuid}
                  onClick={() => {
                    if (!isAddressselected) {
                      setSelectAddressText("Please Select Site");
                    }
                  }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  idKey="sitemap_uuid"
                  labelKey="name"
                />

                <CustomSelect
                  options={deviceTypeList}
                  label="Device Type"
                  name="device_type"
                  placeholder="Select sitemap"
                  onChange={handleChange}
                  value={formData.device_type}
                />
              </Grid>
            </Grid>

            {/* {!-- Sync Device --} */}
            <div className="sync-device-checkbox">
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="syncRecording"
                  name="sync_device_recordings"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      sync_device_recordings: e.target.checked,
                    })
                  }
                  checked={formData.sync_device_recordings}
                />
                <label className="form-check-label" htmlFor="syncRecording">
                  <p>Sync device recordings</p>
                  <span>Copy to Cloud if Storage Subscribed</span>
                </label>
                {device_uuid && (
                  <button
                    className="btn btn-primary btn-rounded"
                    onClick={() => {
                      setIsShowEventsForSync(true);
                    }}
                  >
                    Configure events for sync
                  </button>
                )}
              </div>
            </div>

            {/* {!-- Sync Device --} */}
            <hr />
            <div
              style={{
                display: "flex",
                alignItems: "left",
                gap: "16px",
                marginTop: "16px",
              }}
            >
              <Typography>Device Status</Typography>
              <Switch
                color="success"
                checked={formData.is_active}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    is_active: e.target.checked,
                  })
                }
              />
            </div>
            <hr />
            <fieldset className="d-flex mb__20 group-checkbox align-items-center">
              <label className="col-form-label left-align mr-2">
                Add this Device to a Group
              </label>
              <div className="flex-grow-1">
                <div className="d-flex flex-wrap group-checkbox-wrap align-items-center left-align">
                  {groups?.map((group, index) => (
                    <div className="form-check" key={index}>
                      <input
                        type="radio"
                        name="group"
                        id={group.group_uuid}
                        value={group.group_uuid}
                        onChange={handleRadioChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={group.group_uuid}
                      >
                        {group.name}
                      </label>
                    </div>
                  ))}
                  <div className="form-check left-align">
                    <button
                      className="add-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#addModal"
                    >
                      <Svg name="add-group" />
                    </button>
                  </div>
                </div>
              </div>
            </fieldset>
            <hr />
            {/* Map and Knob Part */}
            {/* Map and Components Side-by-Side Layout */}
            {device_uuid && selectedSite?.value !== null && (
              <Grid container spacing={3}>
                {/* Left Side: First 3/12 columns - Event Sync Section */}
                <Grid item xs={12} md={3}>
                  {isShowEventsForSync && (
                    <Box sx={{ mb: 4 }}>
                      <Typography variant="subtitle2" sx={{ mb: 2 }}>
                        {cameraEventsOptions.length > 0
                          ? "Select events to sync recordings to cloud"
                          : "No events"}
                      </Typography>

                      {cameraEventsOptions &&
                        cameraEventsOptions.map((event, eventIndex) => (
                          <FormControlLabel
                            key={eventIndex}
                            control={
                              <Checkbox
                                id={`event-${eventIndex}`}
                                checked={
                                  eventIndex === 0
                                    ? selectedEvents?.length ===
                                      cameraEventsOptions.length - 1
                                    : selectedEvents?.includes(event)
                                }
                                onChange={(e) =>
                                  handleEventCheckboxChange(
                                    e.target.checked,
                                    eventIndex
                                  )
                                }
                                sx={{
                                  color: "#3bb44a", // Default color
                                  "&.Mui-checked": {
                                    color: "#3bb44a", // Color when checked
                                  },
                                }}
                              />
                            }
                            label={event}
                            sx={{ display: "block", mt: 1 }}
                          />
                        ))}
                    </Box>
                  )}
                </Grid>
                {/* Middle: Second 3/12 columns - Camera Configuration Section */}
                <Grid item xs={12} md={3}>
                  {isCameraConfigShow && (
                    <Box>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <Typography variant="subtitle2">
                                Enter Stream name for overlay
                              </Typography>
                              <Typography variant="subtitle2">
                                Set Default Stream
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Camera Types */}
                        <Grid item xs={12}>
                          {cameraTypes.map((type) => (
                            <FormControlLabel
                              key={type.id}
                              control={
                                <Radio
                                  id={`stream-${type.id}`}
                                  checked={formData.camera_default === type.id}
                                  onChange={() => {
                                    setFormData({
                                      ...formData,
                                      camera_default: type.id,
                                    });
                                  }}
                                  sx={{
                                    color: "#3bb44a", // Default color
                                    "&.Mui-checked": {
                                      color: "#3bb44a", // Color when checked
                                    },
                                  }}
                                />
                              }
                              label={type.type}
                              className="camera-config"
                              sx={{ display: "block", mt: 1 }}
                            />
                          ))}
                        </Grid>
                        {/* Overlay Configuration */}
                        <Grid item xs={12} sx={{ mt: 4 }}>
                          <Typography variant="subtitle2" sx={{ mb: 2 }}>
                            Configure Overlay
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="enable-screen-overlay"
                                checked={formData.enable_screen_overlay}
                                onChange={(e) => {
                                  let checked = e.target.checked;
                                  let dateTimeOverlay = false;
                                  if (!checked) {
                                    dateTimeOverlay = checked;
                                  }
                                  setFormData({
                                    ...formData,
                                    enable_screen_overlay: checked,
                                    enable_screen_overlay_date_and_time:
                                      dateTimeOverlay,
                                  });
                                }}
                                sx={{
                                  color: "#3bb44a", // Default color
                                  "&.Mui-checked": {
                                    color: "#3bb44a", // Color when checked
                                  },
                                }}
                              />
                            }
                            label="Enable Screen Overlay"
                            sx={{ display: "block", mt: 1 }}
                          />
                          {formData.enable_screen_overlay && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="add-date-time"
                                  checked={
                                    formData.enable_screen_overlay_date_and_time
                                  }
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      enable_screen_overlay_date_and_time:
                                        e.target.checked,
                                    });
                                  }}
                                  sx={{
                                    color: "#3bb44a", // Default color
                                    "&.Mui-checked": {
                                      color: "#3bb44a", // Color when checked
                                    },
                                  }}
                                />
                              }
                              label="Add Date and Time"
                              sx={{
                                display: "block",
                                mt: 1,
                                ml: 4,
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>
                {/* Right Side: Last 6/12 columns - Map View and Knob */}
                <Grid item xs={12} md={6} ref={stageMobileParentDiv}>
                  <Stage
                    width={dimensions.width / 2}
                    height={dimensions.height / 2}
                    onMouseDown={checkDeselect}
                    onTouchStart={checkDeselect}
                  >
                    <Layer>
                      <Image
                        width={dimensions.width / 2}
                        height={dimensions.height / 2}
                        x={0}
                        y={0}
                        image={image}
                        ref={imageRef}
                      />
                      <Draw {...{ attrs, setAttrs }} />
                    </Layer>
                  </Stage>
                  {/* Dial Control */}
                  <Grid container spacing={2} sx={{ mt: 2, mb: 2, px: 1 }}>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Rotate the dial to change the camera orientation on the
                        map
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center", my: 2 }}>
                      <Knob
                        numTicks={125}
                        degrees={360}
                        min={1}
                        max={360}
                        value={rotation}
                        onChange={handleRangeChange}
                        onTouchStart={handleRangeChange}
                      />
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center", my: 2 }}>
                      <Button
                        className="btn btn-primary text-white"
                        onClick={resetIconPosition}
                        variant="outlined"
                        startIcon={<CenterFocusStrongIcon />}
                      >
                        Reset Position
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* Map and Knob Part */}
            <hr />
            <div className="d-flex pt-3">
              <legend className="col-form-label text-end d-none d-lg-block"></legend>
              <div className="flex-grow-1 d-flex justify-content-end">
                {device_uuid && (
                  <button
                    className="btn btn-primary btn-rounded mr-2"
                    onClick={() => {
                      setIsCameraConfigShow(!isCameraConfigShow);
                    }}
                  >
                    Configure Overlay Options
                  </button>
                )}
                <button
                  className="btn btn-primary btn-rounded bg-primary"
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  {toUpperCaseText("Save Device")}
                </button>
              </div>
            </div>
          </div>
          {/* Form Add Device */}
        </div>
        <div
          className="modal fade add-modal"
          id="addModal"
          tabIndex={-1}
          aria-labelledby="addModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              {isAdmin ? (
                <div className="modal-header">
                  <h5 className="modal-title text-center">Create new group</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <Svg name="close-rounded" />
                  </button>
                </div>
              ) : null}
              <div className="modal-body">
                <div className="theme-form-wrapper">
                  <form>
                    <div className="d-flex mb__20 align-items-center">
                      <label
                        htmlFor="groupName"
                        className="col-form-label text-end"
                      >
                        Group name
                      </label>
                      <div className="flex-grow-1">
                        <input
                          type="text"
                          className="form-control"
                          id="groupName"
                          onChange={(e) => setCreateGroup(e.target.value)}
                          placeholder="Group name"
                        />
                      </div>
                    </div>
                    <div className="button-wrap text-center">
                      <button
                        className="btn btn-primary btn-rounded"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={createHandler}
                      >
                        {toUpperCaseText(
                          device_uuid ? "Update Device" : "Save Device"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Draw({ attrs, setAttrs }) {
  const [cameraImg, setCameraImg] = useState(new window.Image());
  const cameraImgRef = useRef();

  useEffect(() => {
    // Find the appropriate SVG element
    const svgElement = document.getElementById(
      attrs.isActive ? "svgActive" : "svgDeactive"
    );

    if (svgElement) {
      try {
        // Serialize the SVG to a data URL - use the element, not the ID string
        const svgData = encodeURIComponent(
          new XMLSerializer().serializeToString(svgElement)
        );

        // Create a new image
        const img = new window.Image();

        // Handle load success and errors
        img.onload = () => {
          setCameraImg(img);
        };

        img.onerror = (err) => {
          console.error("Error loading camera icon:", err);
        };

        // Set the source
        img.src = "data:image/svg+xml;charset=utf-8," + svgData;
      } catch (error) {
        console.error("Error serializing SVG:", error);
      }
    } else {
      console.error(
        `SVG element with id ${
          attrs.isActive ? "svgActive" : "svgDeactive"
        } not found`
      );
    }
  }, [attrs.isActive]);

  const onDragEnd = ({ target }) => {
    setAttrs({
      ...attrs,
      x: target.x(),
      y: target.y(),
      width: target.width() || 32,
      height: target.height() || 30,
    });
  };

  return (
    <Image
      image={cameraImg}
      ref={cameraImgRef}
      {...attrs}
      draggable={true}
      onDragEnd={onDragEnd}
      height={30}
      width={32}
    />
  );
}
