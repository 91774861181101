import { useCallback } from 'react';
import { useUiContext } from "../../../../contexts/UiContext";

// Custom hook for default view operations
const useDefaultViewService = () => {
  const { apiRequest } = useUiContext();
  
  // Constants
  const API_KEY = 'reactapp';
  const API_BASE_URL = 'api/1/my-api-data/';

  // Helper function to get auth headers
  const getAuthHeaders = useCallback(() => {
    return {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
  }, []);

  /**
   * Fetches the current default view data from API
   * @returns {Promise} Promise resolving to default view data
   */
  const fetchDefaultViewData = useCallback(async () => {
    const headers = getAuthHeaders();
    const params = { key: API_KEY, timeStamp: new Date().getTime() };
    
    return apiRequest(
      "get",
      API_BASE_URL,
      "",
      headers,
      false,
      { showToast: false },
      params
    );
  }, [apiRequest, getAuthHeaders]);

  /**
   * Creates a new default view record via POST
   * @param {string} defaultView - The default view value to set
   * @returns {Promise} Promise resolving to the API response
   */
  const createDefaultView = useCallback(async (defaultView) => {
    const headers = getAuthHeaders();
    const body = {
      json: {
        defaultView: defaultView,
      },
      key: API_KEY,
    };
    
    return apiRequest(
      "post", 
      API_BASE_URL, 
      body, 
      headers, 
      false, 
      {
        success: "Default view Changed.",
        error: "Default view not changed",
        showToast: true,
      }
    );
  }, [apiRequest, getAuthHeaders]);

  /**
   * Updates an existing default view record via PATCH
   * @param {string} defaultView - The default view value to set
   * @param {string} apiDataUuid - UUID of the record to update
   * @returns {Promise} Promise resolving to the API response
   */
  const updateDefaultView = useCallback(async (defaultView, apiDataUuid) => {
    const headers = getAuthHeaders();
    const body = {
      json: {
        defaultView: defaultView,
      },
      key: API_KEY,
    };
    
    return apiRequest(
      "patch",
      `${API_BASE_URL}${apiDataUuid}/`,
      body,
      headers,
      false,
      {
        success: "Default view Changed.",
        error: "Default view not changed",
        showToast: true,
      }
    );
  }, [apiRequest, getAuthHeaders]);

  /**
   * Main function to handle default view changes
   * First checks if data exists, then either updates or creates based on result
   * @param {string} newDefaultView - The new default view value to set
   * @returns {Promise} Promise resolving when operation is complete
   */
  const handleDefaultViewChange = useCallback(async (newDefaultView) => {
    try {
      const response = await fetchDefaultViewData();
      const results = response.data.results;
      
      if (results.length > 0) {
        const apiDataUuid = results[0].api_data_uuid;
        await updateDefaultView(newDefaultView, apiDataUuid);
      } else {
        await createDefaultView(newDefaultView);
      }
      
      return response.data.results;
    } catch (error) {
      console.error("Error handling default view change:", error);
      throw error;
    }
  }, [fetchDefaultViewData, updateDefaultView, createDefaultView]);

  // Return all the functions to be used by components
  return {
    fetchDefaultViewData,
    createDefaultView,
    updateDefaultView,
    handleDefaultViewChange
  };
};

export default useDefaultViewService;