// services/userActiveService.js
import { useState, useEffect } from "react";
import { useUiContext } from "../../../../contexts/UiContext";
import { getAuthHeaders } from "../../../../helper/utils";

const useUserData = (endpoint, uid) => {
    const { apiRequest } = useUiContext();
    const [data, setData] = useState(null);
    const headers = getAuthHeaders();
  
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const res = await apiRequest(
            "get",
            `api/1/${endpoint}/${uid}/get_summary_users_for/`,
            "",
            headers,
            true
          );
          if (res?.data?.rows) {
            setData(res.data.rows);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };
  
      fetchUserData();
    }, [endpoint, uid]);
  
    return data;
  };
  
  export default useUserData;