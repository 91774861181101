import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useUiContext} from '../../../../contexts/UiContext';
import HeaderDashboard from '../../common/HeaderDashboard';
import SettingNavigation from '../../common/SettingNavigation';
import Svg from '../../common/Svg';
import moment from "moment-timezone";
import {baseUrl} from "../../../../constants/common";

export default function ReportsAnalytics({route}) {

    const {apiRequest} = useUiContext();
    const location = useLocation();
    const [authToken, setAuthToken] = useState({authtoken: {exp: null}});
    const [data, setData] = useState([]);
    const [routeRole, setRouteRole] = useState("");
    let uuid = localStorage.getItem("customeruuid");
    const endpoint = (routeRole === 'customer') ? 'customers' : (routeRole === 'reseller') ? 'sellers' : 'distributors';

     useEffect(() => {
            const routeSiteSentry = location.pathname
              .split("/site-sentry/")[1]
              ?.split("/")[0];
            console.log("Extracted Role:", routeSiteSentry); // ✅ Outputs "customer"
            setRouteRole(routeSiteSentry);
          }, [location.pathname]);

    const getReportData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        };
        return await apiRequest(
            'get', `api/1/${endpoint}/${uuid}/available_subscriptions_reports/`, "", headers, true
        ).then(res => {
            if (res.data?.available_reports) setData(res.data.available_reports);
            else console.warn(res.data);
        }).catch((error) => {
            console.error(error);
        });
    };

    const getTokens = async () => {
        const headers = {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
        };
        return await apiRequest(
            "get",
            `api_auth/1/auth_token`,
            {},
            headers,
            false
        ).then(res => {
            if (res.data?.authtoken) {
                // noinspection JSUnresolvedReference
                setAuthToken(res.data);
            } else {
                console.warn(res.data);
            }
            return authToken;
        }).catch((error) => {
            console.error(error);
            return authToken;
        });
    };

    const isAuthTokenExpiring = (expMinutes = 2) => {
        const tokenExpiry = authToken?.authtoken?.exp;
        const tokenExpMinutes = tokenExpiry ? moment.unix(tokenExpiry).diff(moment(), "minutes") : 0;
        return tokenExpMinutes < expMinutes;
    };

    const getFile = async (year, month, filename) => {
        const getPath = `${baseUrl}api/1/${endpoint}/${uuid}/get_subscriptions_report/?year=${year}&month=${month}`;
        if (isAuthTokenExpiring()) {
            return getTokens().then(gotToken => {
                const link = `${getPath}&authtoken=${gotToken.authtoken.token}`;
                return window.open(link, "_blank");
            });
        }
        const link = `${getPath}&authtoken=${authToken.authtoken.token}`;
        return window.open(link, "_blank");
    };

    useEffect(() => {
        if (!routeRole) return;
        getTokens().then(_ => {
        });
        getReportData().then(_ => {
        });
    }, [routeRole]);

    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard/>
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation/>
                    <div className="flex-grow-1 setting-page-wrapper">
                        {data.map((res, index) =>
                            <div className="container" key={index}>
                                <div className="report-analytics">
                                    <Link className="d-flex report-analytics-wrap" to>
                                        <div className="title flex-grow-1"
                                             onClick={() => getFile(res.year, res.month, res.filename)}>
                                            {res.filename}
                                        </div>
                                        <div className="icon"
                                             onClick={() => getFile(res.year, res.month, res.filename)}>
                                            <Svg name="download-icon"/>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
