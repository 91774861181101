import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDeviceList } from "../customer/cameras/services/useDeviceList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Svg from "./Svg";

const RecordingDetailsSidebar = () => {
  const navigate = useNavigate();
  const { device_uuid } = useParams();
  const { deviceList, currentPage, getDeviceList, getDeviceListManual } =
    useDeviceList();

  const [expandedCustomers, setExpandedCustomers] = useState([]);
  const [expandedSites, setExpandedSites] = useState([]);
  const [routeRole, setRouteRole] = useState("");
   
    const location = useLocation();
  
    useEffect(() => {
      const routeSiteSentry = location.pathname
        .split("/site-sentry/")[1]
        ?.split("/")[0];
      console.log("Extracted Role:", routeSiteSentry); // ✅ Outputs "customer"
      setRouteRole(routeSiteSentry);
    }, [location.pathname]);

  useEffect(() => {
    const params = { limit: 10, page: currentPage };

    if (currentPage === 1) {
      getDeviceList(params);
    } else {
      getDeviceListManual(params);
    }
  }, [currentPage]);

  const customerData = Object.values(
    deviceList.reduce((acc, device) => {
      if (!acc[device.customer_name]) {
        acc[device.customer_name] = {
          name: device.customer_name,
          sites: {},
        };
      }
      if (!acc[device.customer_name].sites[device.address_name]) {
        acc[device.customer_name].sites[device.address_name] = {
          name: device.address_name,
          devices: [],
        };
      }
      acc[device.customer_name].sites[device.address_name].devices.push({
        name: device.name,
        device_uuid: device.device_uuid,
        status: device.status,
      });
      return acc;
    }, {})
  ).map((customer) => ({
    ...customer,
    sites: Object.values(customer.sites),
  }));

  useEffect(() => {
    if (device_uuid) {
      customerData.forEach((customer) => {
        const site = customer.sites.find((site) =>
          site.devices.some((device) => device.device_uuid === device_uuid)
        );
        if (site) {
          setExpandedCustomers((prev) =>
            prev.includes(customer.name) ? prev : [...prev, customer.name]
          );
          setExpandedSites((prev) =>
            prev.includes(site.name) ? prev : [...prev, site.name]
          );
        }
      });
    }
  }, [device_uuid, customerData]);

  return (
    <div className="sidebar-theme sidebar-details" style={{ backgroundColor: "#000", color: "#fff" }}>
      <div className="camera-header d-flex align-items-center">
        <div className="flex-grow-1 available-heading d-flex justify-content-between">
          <button className="btn btn-link p-0" onClick={() => navigate(`/site-sentry/customer/cameras`)}>
            <Svg name="back-arrow-big" />
          </button>
        </div>
      </div>
      <div className="sidebar-wrapper" style={{ overflowY: "scroll", height: "100vh" }}>
        {customerData.map((customer) => (
          <Accordion key={customer.name} className="accordion" style={{ backgroundColor: "#000", color: "#fff" }}
            expanded={expandedCustomers.includes(customer.name)}
            onChange={() =>
              setExpandedCustomers((prev) =>
                prev.includes(customer.name)
                  ? prev.filter((name) => name !== customer.name)
                  : [...prev, customer.name]
              )
            }
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />} className="accordion-header">
              {customer.name}
            </AccordionSummary>
            <AccordionDetails className="accordion-body">
              {customer.sites.map((site) => (
                <Accordion key={site.name} className="accordion" style={{ backgroundColor: "#000", color: "#fff" }}
                  expanded={expandedSites.includes(site.name)}
                  onChange={() =>
                    setExpandedSites((prev) =>
                      prev.includes(site.name)
                        ? prev.filter((name) => name !== site.name)
                        : [...prev, site.name]
                    )
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />} className="accordion-header">
                    {site.name}
                  </AccordionSummary>
                  <AccordionDetails className="accordion-body">
                    <ul className="device-list">
                      {site.devices.map((device) => (
                        <li key={device.device_uuid} className={device.device_uuid === device_uuid ? "active" : ""}>
                          <Link to={`/site-sentry/${routeRole}/cameras/live-camera/${device.device_uuid}`} style={{ color: device.device_uuid === device_uuid ? "#3bb44a" : "#fff", textDecoration: "none" }}>
                            <span className={`status-dot ${device.status === "ok" ? "online" : "offline"}`}></span>
                            {device.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default RecordingDetailsSidebar;