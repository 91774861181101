import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUiContext } from '../../../../contexts/UiContext'
import { formValidation } from '../../../../helper/common'
import HeaderDashboard from '../../common/HeaderDashboard'
import SettingNavigation from '../../common/SettingNavigation'
import Svg from '../../common/Svg'

export default function CreateResellerCustomer() {
    let navigate = useNavigate();
    let uid = localStorage.getItem("customeruuid");
    const { apiRequest } = useUiContext();
    const [disabled, setDisabled] = useState(true);
    const [distributors, setDistributors] = useState([]);
    const [emailError, setEmailError] = useState(true);
    const [form_choices, setForm_choices] = useState();
    const [errors, setErrors] = useState({
        abn: "",
        timezone: "",
        contact_email: ""
    });
    const [formData, setFormData] = useState({
        name: "",
        abn: "",
        timezone: "",
        sellers : [{
            seller_uuid: uid,
        }],
        contact_name: "",
        contact_email: "",
        // recording_fetch_start_time: null,
        // recording_fetch_stop_time: null,
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        getFormChoices();
    }, [])

    useEffect(() => {
        const { name, abn, timezone, contact_name, contact_email } = formData
        formValidation({ name, abn, timezone, contact_name, contact_email }, setDisabled, setEmailError)
    }, [formData])

    const getFormChoices = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api_auth/1/form_choices`, {}, headers, true, {})
        if(res.status === 200){
            setForm_choices(res.data);
        }
    }

    const createCustomer = async (e) => {
        e.preventDefault();
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('post', `api/1/sellers/${uid}/create_customer/`, formData, headers, true, { success: 'Created Successfully', error: 'Customer not created' })
        if (res) {
            if (res.response && res.response.data) {
                const errorsData = res.response.data.errors;
                let newErrorState = {
                    abn: "", timezone: "", contact_email: ""
                }
                if(errorsData){
                    if (errorsData.abn) {
                        newErrorState.abn = errorsData.abn[0]
                    }
                    if (errorsData.timezone) {
                        newErrorState.timezone = errorsData.timezone[0];
                    }
                    if (errorsData.contact_email) {
                        newErrorState.contact_email = errorsData.contact_email[0];
                    }
                    if (errorsData.length > 0) {
                        setFormData({
                            name: "",
                            abn: "",
                            timezone: "",
                            sellers : [{
                                seller_uuid: uid,
                            }],
                            contact_name: "",
                            contact_email: "",
                            // resend: false,
                            // recording_fetch_start_time: null,
                            // recording_fetch_stop_time: null,
                            // code: '',
                        })
                    }
                }
                setErrors(newErrorState)
            } else {
                setFormData({
                    name: "",
                    abn: "",
                    timezone: "",
                    sellers : [{
                        seller_uuid: uid,
                    }],
                    contact_name: "",
                    contact_email: "",
                    // resend: false,
                    // recording_fetch_start_time: null,
                    // recording_fetch_stop_time: null,
                    // code: '',
                })
            }
            if(res.status === 201){
                navigate('/site-sentry/reseller/resellers-system-integrators');
            }
        }
    }

    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            <div className="user-permissions">
                                <Link to="/distributor/resellers-system-integrators" className="back-btn d-inline-flex align-items-center">
                                    <Svg name="left-arrow" /> Back
                                </Link>
                                <div className="create-user">
                                    <div className="page-heading">
                                        <h3>Create new user</h3>
                                    </div>
                                    <div className="create-user-wrapper">
                                        <div className="form-wrapper theme-form-wrapper">
                                            <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3 position-relative">
                                                                <label>Name</label>
                                                                <input type="text" placeholder="Your Name" value={formData.name} name="name" className="form-control" onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3 position-relative">
                                                                <label>ABN</label>
                                                                <input type="text" placeholder="Abn" name="abn" className="form-control" value={formData.abn} onChange={handleChange} />
                                                                {errors.abn !== '' && <div className="validation-msg" onClick={() => setErrors({ ...errors, abn: '' })}>{errors.abn}</div>}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 position-relative">
                                                        <label>Timezone</label>
                                                        <select className="form-control" placeholder='Timezone' name="timezone" value={formData.timezone} onChange={handleChange}>
                                                            <option value='Select timezone'>Select Timezone</option>
                                                            {form_choices !== undefined &&  Object.entries(form_choices.timezones).map((timezone, i) => {
                                                            return <option key={i} value={timezone[0]}>{timezone[1]}</option> 
                                                            })}

                                                        </select>
                                                        {errors.timezone !== '' && <div className="validation-msg" onClick={() => setErrors({ ...errors, timezone: '' })}>{errors.timezone}</div>}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="mb-3 position-relative">
                                                                <label>Billing Contact Name</label>
                                                                <input type="text" placeholder="Billing Contact Name" className="form-control" name="contact_name" value={formData.contact_name} onChange={handleChange} />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="mb-3 position-relative">
                                                                <label>Billing Contact Email</label>
                                                                <input type="email" placeholder="Billing Contact Email" className="form-control" name="contact_email" value={formData.contact_email} onChange={handleChange} />
                                                                {errors.contact_email !== '' && <div className="validation-msg" onClick={() => setErrors({ ...errors, contact_email: '' })}>{errors.contact_email}</div>}
                                                            </div>
                                                        </div>
                                                    </div>                                                
                                                <div className="button-wrap text-center text-lg-start">
                                                    <button className="btn btn-primary btn-rounded" disabled={disabled} onClick={createCustomer}>Create</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
