import forge from "node-forge";
import ls from "localstorage-slim";
import {
  customerAdminPaths,
  distributorAdminPaths,
  customerPaths,
  sellerAdminPaths,
  sellerPaths,
  distributorPaths,
  enterprisePaths,
} from "../constants/allowedpath";

// export const checkUserRoutePermission = (
//   role,
//   currentPath,
//   allowedRoles = []
// ) => {
//   const isEnterprise_user = JSON.parse(
//     getLocal("isEnterprise_user") || "false"
//   );

//   let userRole = getLocal("userRole");

//   const normalizePath = (path) => {
//     return path.replace(/:[a-zA-Z0-9_-]+/g, "*");
//   };
//   const normalizedCurrentPath = normalizePath(currentPath);

//   switch (role) {
//     case "customerIsAdmin":
//       const allowedRoute = isEnterprise_user
//         ? enterprisePaths
//         : customerAdminPaths;
//       return (
//         allowedRoute.some(
//           (route) => normalizePath(route) === normalizedCurrentPath
//         ) && (allowedRoles.length > 0 ? allowedRoles.includes(userRole) : true)
//       );
//     // return customerAdminPaths.indexOf(currentPath) !== -1;
//     case "customer":
//       const allowedPaths = isEnterprise_user ? enterprisePaths : customerPaths;
//       return allowedPaths.some(
//         (route) => normalizePath(route) === normalizedCurrentPath
//       );
//     // return customerPaths.indexOf(currentPath) !== -1;
//     case "sellerIsAdmin":
//       const allowedSellerRoutes = isEnterprise_user
//         ? enterprisePaths
//         : sellerAdminPaths;
//       return allowedSellerRoutes.some(
//         (route) => normalizePath(route) === normalizedCurrentPath
//       );
//     case "seller":
//       return sellerPaths.some(
//         (route) => normalizePath(route) === normalizedCurrentPath
//       );
//     case "distributorIsAdmin":
//       return distributorAdminPaths.some(
//         (route) => normalizePath(route) === normalizedCurrentPath
//       );
//     case "distributor":
//       return distributorPaths.some(
//         (route) => normalizePath(route) === normalizedCurrentPath
//       );
//     default:
//       return false;
//   }
// };

export const checkUserRoutePermission = (
  role,
  currentPath,
  allowedRoles = []
) => {
  const isEnterprise_user = JSON.parse(
    getLocal("isEnterprise_user") || "false"
  );

  let userRole = getLocal("userRole");

  const normalizePath = (path) => {
    return path.replace(/:[a-zA-Z0-9_-]+/g, "*");
  };
  
  // For enterprise users, we need to check if the path starts with "/site-sentry"
  let pathToCheck = currentPath;
  if (isEnterprise_user && !currentPath.startsWith("/site-sentry")) {
    pathToCheck = `/site-sentry${currentPath}`;
  } else if (!isEnterprise_user && currentPath.startsWith("/site-sentry")) {
    // Strip "/site-sentry" prefix for non-enterprise users
    pathToCheck = currentPath.replace("/site-sentry", "");
  }
  
  const normalizedCurrentPath = normalizePath(pathToCheck);

  let basePaths = [];
  switch (role) {
    case "customerIsAdmin":
      basePaths = customerAdminPaths;
      break;
    case "customer":
      basePaths = customerPaths;
      break;
    case "sellerIsAdmin":
      basePaths = sellerAdminPaths;
      break;
    case "seller":
      basePaths = sellerPaths;
      break;
    case "distributorIsAdmin":
      basePaths = distributorAdminPaths;
      break;
    case "distributor":
      basePaths = distributorPaths;
      break;
    default:
      return false;
  }
  
  // Apply the "/site-sentry" prefix to paths if user is enterprise
  if (isEnterprise_user) {
    basePaths = basePaths.map(path => `/site-sentry${path}`);
  }
  
  const hasPermission = basePaths.some(
    (route) => normalizePath(route) === normalizedCurrentPath
  );

  // Check additional role permissions if needed
  if (allowedRoles.length > 0) {
    return hasPermission && allowedRoles.includes(userRole);
  }
  
  return hasPermission;
};

export const logoutUser = () => {
  localStorage.removeItem("x_verified_jwt");
  localStorage.removeItem("token");
  localStorage.removeItem("customeruuid");
  localStorage.removeItem("startDate");
  localStorage.removeItem("endDate");
  localStorage.removeItem("show");
  localStorage.removeItem("userRole");
  localStorage.clear();
  return true;
};

export const isUserLoggedIn = (ignoreVerified = false) => {
  return (
    localStorage.getItem("token") !== null &&
    (ignoreVerified || localStorage.getItem("x_verified_jwt") !== null)
  );
};

export const setNavbarDropdown = (pathname, setActiveRouteName) => {
  const pathnameArray = pathname.split("/");
  if (pathnameArray.includes("cameras")) {
    setActiveRouteName("Camera");
  } else if (pathnameArray.includes("recordings")) {
    setActiveRouteName("Recordings");
  } else if (
    pathnameArray.includes("groups") ||
    pathnameArray.includes("group")
  ) {
    setActiveRouteName("Groups");
  } else {
    const role = getLocal("role");
    if (role === "distributor" || role === "distributorIsAdmin") {
      setActiveRouteName("Distributor Administration");
    } else if (role === "seller" || role === "sellerIsAdmin") {
      setActiveRouteName("Reseller Administration");
    } else {
      setActiveRouteName("Settings");
    }
  }
};

export const formValidation = (fields, setIsDisabled, setEmailError) => {
  let hasInvalidField = false;
  let hasInvalidEmail = false;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  for (const key in fields) {
    const value = fields[key];

    if (setEmailError && (key === "email" || key === "contact_email") && value) {
      if (!emailRegex.test(value)) {
        hasInvalidEmail = true;
      }
    } else if (value === "" || /^\s*$/.test(value)) {
      hasInvalidField = true;
    }
  }

  if (setEmailError) setEmailError(hasInvalidEmail);
  setIsDisabled(hasInvalidField || hasInvalidEmail);
};

//Used for encrypting the string using RSA Encryption
export const encryptString = (password) => {
  const pubKey = localStorage.getItem("publicKey");
  if (pubKey !== "" && pubKey !== undefined && pubKey !== null) {
    // noinspection JSUnresolvedVariable
    const publicKey = forge.pki.publicKeyFromPem(pubKey);
    // noinspection JSUnresolvedVariable
    const encodedPassword = forge.util.encodeUtf8(password);
    // noinspection JSUnresolvedVariable
    const encData = publicKey.encrypt(encodedPassword, "RSA-OAEP", {
      md: forge.md.sha512.create(),
      mgf1: {
        md: forge.md.sha1.create(),
      },
    });
    // noinspection JSUnresolvedVariable
    return forge.util.encode64(encData);
  }
};

export const setLocal = (key, value) => {
  ls.config.encrypt = true;
  return ls.set(key, value);
};

export const getLocal = (key) => {
  ls.config.encrypt = true;
  return ls.get(key);
};
