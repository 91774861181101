import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { siteAssetManagement } from "../site-sentry/common/Utility";
import TableCameraList from "./components/Camera/TableCameraList";
import AddButton from "../customer/cameras/components/AddBtn";
import { useUiContext } from "../../contexts/UiContext";
import ConfirmationModal from "./components/Global/ConfirmationModal";
import { useDeviceList } from "../site-sentry/customer/cameras/services/useDeviceList";

export default function Cameras() {
  const { deviceList, currentPage, getDeviceList, getDeviceListManual } =
  useDeviceList();

  useEffect(() => {
    const params = { limit: 10, page: currentPage };

    if (currentPage === 1) {
      getDeviceList(params);
    } else {
      getDeviceListManual(params);
    }
  }, [currentPage]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Cameras</Typography>
        <AddButton textLabel="Add Camera" to={`${siteAssetManagement}/cameras/camera-form`} />
      </Box>
      <div>
        <TableCameraList key={deviceList.length} data={deviceList} />
      </div>
    </div>
  );
}
