import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

export default function ConfirmationModal({ open, onClose, onConfirm, title, note }) {
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ style: { backgroundColor: "#000", color: "#fff", border: "1px solid white",  } }}>
      <DialogTitle style={{ color: "#fff" }}>Confirmation</DialogTitle>
      <DialogContent>
        <Typography variant="h6" style={{ color: "#fff" }}>{title}</Typography>
        {note && (
          <Typography variant="body2" style={{ color: "#ccc", marginTop: "8px" }}>
            {note}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ color: "#fff", borderColor: "#fff" }} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onConfirm} style={{ backgroundColor: "green", color: "#fff" }} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
