import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  // Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import WebIcon from "@mui/icons-material/Web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import HomeIcon from "@mui/icons-material/Home";
import VideocamIcon from "@mui/icons-material/Videocam";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { ReactComponent as EyefiLogo } from "../../../assets/images/eyefi-logo.svg";
import UserIcon from "../../../assets/images/user-profile.jpg";
import Svg from "./Svg";
import ToggleDark from "../common/toggleDark";
import { ThemeContext, themes } from "../../../contexts/ThemeContext";
import { getLocal } from "../../../helper/common";
import { useRouteContext } from "../../../component/PrivateRoutes";
import useSuperUser from "../../super-user/services/useSuperUser";
import { baseSiteSentry } from "./Utility";
import SiteSentry from "../../../assets/images/site-sentry";

const drawerWidth = 240;

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  color: "#fff",
  backgroundColor: active ? "rgba(59, 180, 74, 0.5)" : "transparent",
  borderRight: active ? "2px solid #3bb44a" : "2px solid transparent",
  "&:hover": {
    backgroundColor: "rgba(59, 180, 74, 0.5)",
    borderRight: "2px solid #3bb44a",
    cursor: "pointer",
    color: "#fff",
  },
}));

export default function HeaderSuperUser() {
  const { signout } = useSuperUser();
  const location = useLocation();
  let localdarkMode = localStorage.getItem("darkmode");
  localdarkMode = !(localdarkMode && localdarkMode === "false");
  const [isLeftOpen, setIsLeftOpen] = useState(false);
  const [isRightOpen, setIsRightOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(localdarkMode);
  const { route } = useRouteContext();
  const localName = getLocal("name"); // FIXME
  let name = localName ? localName.trim() : ""; // FIXME
  let role = getLocal("role");
  let terms = localStorage.getItem("terms");
  let privacy = localStorage.getItem("privacy");
  let help = localStorage.getItem("help");
  const logOut = () => {
    signout();
  };

  const selectDarkMode = (mode, theme) => {
    setDarkMode(mode);
    localStorage.setItem("darkmode", mode);
    theme(mode ? themes.dark : themes.light);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* Header */}
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className="bg-black">
          {/* Left Sidebar Toggle */}
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => setIsLeftOpen(!isLeftOpen)}
            sx={{ mr: 2 }}
          >
            {isLeftOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          {/* Logo */}
          <EyefiLogo style={{ height: "40px", marginRight: "10px" }} />
          {/* Title */}
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Asset Management
          </Typography>
          <Link
            to="https://www.sitesentry.com.au/"
            target="_blank"
            className="logo logo-axis site-sentry-logo"
            style={{ marginRight: "16px" }}
          >
            <SiteSentry />
          </Link>
          {/* Right Sidebar Trigger */}
          <IconButton
            color="inherit"
            onClick={() => setIsRightOpen(!isRightOpen)}
          >
            <AccountCircleIcon />
            <KeyboardArrowDownIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {/* Left Sidebar */}
      <Drawer
        variant="permanent"
        open={isLeftOpen}
        sx={{
          width: isLeftOpen ? drawerWidth : 58,
          flexShrink: 0,
          whiteSpace: "nowrap",
          "& .MuiDrawer-paper": {
            backgroundColor: "#000", // Set drawer background color
            color: "#fff", // Ensure text is visible
            width: isLeftOpen ? drawerWidth : 58,
            overflowX: "hidden",
            transition: (theme) =>
              theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
          },
        }}
      >
        <Toolbar />
        <List className="list-super-user">
          {[
            {
              path: `${baseSiteSentry}/customer/cameras`,
              label: "Home",
              icon: <HomeIcon />,
            },
            {
              path: `${baseSiteSentry}/asset-management`,
              label: "Dashboard",
              icon: <WebIcon />,
            },
            {
              path: `${baseSiteSentry}/asset-management/customer`,
              label: "Customer",
              icon: <PersonAddIcon />,
            },
            {
              path: `${baseSiteSentry}/asset-management/sites`,
              label: "Sites",
              icon: <PlaceIcon />,
            },
            {
              path: `${baseSiteSentry}/asset-management/cameras`,
              label: "Cameras",
              icon: <VideocamIcon />,
            },
            {
              path: `${baseSiteSentry}/asset-management/users`,
              label: "Users",
              icon: <PeopleIcon />,
            },
          ].map(({ path, label, icon }) => {
            const isActive = location.pathname === path;

            return (
              <StyledListItem
                key={path}
                button
                component={Link}
                to={path}
                active={isActive}
              >
                <ListItemIcon sx={{ color: "#fff" }}>{icon}</ListItemIcon>
                {isLeftOpen && <ListItemText primary={label} />}
              </StyledListItem>
            );
          })}
        </List>
      </Drawer>
      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: isLeftOpen ? `calc(100% - 240px)` : `calc(100% - 58px)`,
          transition: "all 0.3s ease-in-out",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      {/* Right Sidebar */}
      <Drawer
        anchor="right"
        open={isRightOpen}
        onClose={() => setIsRightOpen(!isRightOpen)}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "none !important",
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Right Sidebar</Typography>
          <Typography>
            {/* Right Bar First */}
            <div
              className={`right-navigation-menu transition ease-out transform ${
                isRightOpen
                  ? "ease-out translate-right-0"
                  : "ease-in translate-right-full"
              }`}
            >
              <div className="account-top-wrap flex-grow-1">
                <div className="vps-logo d-flex align-items-center mx-2  d-lg-none">
                  <Link
                    to="https://www.sitesentry.com.au/"
                    target="_blank"
                    className="logo logo-axis site-sentry-logo"
                    style={{ marginRight: "16px" }}
                  >
                    <SiteSentry />
                  </Link>
                </div>
                {/* Right Bar */}
                <div className="account-top-scroll">
                  <div className="account-wrap">
                    <p>Select account</p>
                    <div className="account-wrapper">
                      <ul>
                        <li className="d-flex align-items-center">
                          <div className="profile-wrap">
                            <img src={UserIcon} alt="User Name" />
                          </div>
                          <div className="profile-name flex-grow-1">
                            {name && name !== "" ? name : "Personal account"}
                          </div>
                          <div className="status-wrap">
                            <Svg name="profile-check" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="account-wrap d-flex align-items-center">
                    <div className="title flex-grow-1">Dark theme</div>
                    <ThemeContext.Consumer>
                      {({ changeTheme }) => (
                        <ToggleDark
                          darkMode={darkMode}
                          toggleDark={() => {
                            selectDarkMode(!darkMode, changeTheme);
                          }}
                        />
                      )}
                    </ThemeContext.Consumer>
                  </div>
                  <div className="account-wrap d-flex">
                    <div className="title flex-grow-1">
                      <a href={help} rel="noreferrer" target="_blank">
                        Help center
                      </a>
                    </div>
                    <span className="help-icon"></span>
                  </div>
                  <div className="signout text-center">
                    <button
                      className="signout-btn d-inline-flex align-items-center "
                      onClick={logOut}
                    >
                      <Svg name="signout-btn" /> Sign Out
                    </button>
                  </div>
                </div>
              </div>
              <div className="menu-bottom">
                <ul className="menu-link d-flex flex-wrap">
                  <li>
                    <a href={terms} rel="noreferrer" target="_blank">
                      T&Cs
                    </a>
                  </li>
                  <li>
                    <a href={privacy} rel="noreferrer" target="_blank">
                      Privacy Policy
                    </a>
                  </li>
                </ul>
                <div className="text-wrap">
                  © 2022 EYEfi Pty Ltd. All rights reserved.
                </div>
              </div>
              {/* Right Bar */}
            </div>
          </Typography>
        </Box>
      </Drawer>
    </Box>
  );
}
