import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import { Grid, Button, Switch, Typography, IconButton } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import CustomSelect from "../../../super-user/components/Global/CustomSelect";
import CustomTextField from "../../../super-user/components/Global/CustomTextField";
import { oakRegexPattern } from "../../../site-sentry/common/Utility";
import { deviceTypeList } from "./services/constan/deviceTypeList";
import { addDeviceService } from "./services/deviceService";
import HeaderDashboard from "../../../site-sentry/common/HeaderDashboard";
import RecordingDetailsSidebar from "../../../site-sentry/common/RecordingDetailsSidebar";

export default function StrobeHornForm() {
  const { apiRequest } = useUiContext();
  const navigate = useNavigate();
  const { device_uuid } = useParams();
  const location = useLocation();
  const deviceDetails = location.state?.deviceDetails || {};
  const [formData, setFormData] = useState({
    is_active: deviceDetails.is_active || true,
    mac: "",
    oak: "",
    cameraLocation: deviceDetails.name || "",
    sync_device_recordings: deviceDetails.sync_device_recordings || false,
    group_uuid: deviceDetails.groups?.map((group) => group.group_uuid) || [],
    group_detail: deviceDetails.groups || [],
    name: deviceDetails.name || "",
    password: "",
    username: "",
    device_model: deviceDetails.device_type || "",
    sitemap_uuid: deviceDetails.sitemap_uuid || "",
    address_uuid: deviceDetails.address_uuid || "",
    status: deviceDetails.status || "",
    sitemap_bearing: deviceDetails.sitemap_bearing || 0,
    sitemap_x_fraction: deviceDetails.sitemap_x_fraction || 0,
    sitemap_y_fraction: deviceDetails.sitemap_y_fraction || 0,
    camera_default: deviceDetails.camera_default || null,
    enable_screen_overlay: deviceDetails.enable_screen_overlay || false,
    enable_screen_overlay_date_and_time: deviceDetails.enable_screen_overlay_date_and_time || false,
    sync_all_events: deviceDetails.sync_all_events || false,
    sync_event_list: deviceDetails.sync_event_list || null,
    onvif_host: deviceDetails.onvif_host || "",
    onvif_port: 0,
    is_onvif: deviceDetails.is_onvif || false,
    ip_address: deviceDetails.ip_address || "",
    parent_device: device_uuid,
    customer_uuid: localStorage.getItem("customeruuid") || "",
  });
  console.log(formData);
  const [oakError, setOakError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeviceModelChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      name: `${prevData.name.split(' - ')[0]} - ${value}`,
    }));
  };

  const handleStatusChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      is_active: !prevData.is_active,
    }));
  };

  const formatOak = (value) => {
    return value
      .replace(/[^A-Fa-f0-9]/g, "")
      .replace(/(.{4})/g, "$1-")
      .replace(/-$/, "");
  };

  const oakInputhandleChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = formatOak(value);
    setFormData({ ...formData, [name]: formattedValue });

    if (name === "oak") {
      setOakError(!oakRegexPattern.test(formattedValue));
    }
  };

  const isFormValid = () => {
    return (
      !oakError &&
      formData.mac.trim() !== "" &&
      formData.name.trim() !== "" &&
      formData.username.trim() !== "" &&
      formData.password.trim() !== "" &&
      formData.device_model.trim() !== "" &&
      formData.oak.trim() !== "" &&
      formData.ip_address.trim() !== "" &&
      formData.customer_uuid.trim() !== ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!isFormValid()) return;

    await addDeviceService(formData, apiRequest, navigate);
  };

  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height devices-page d-flex">
          <RecordingDetailsSidebar />
          <div className="flex-grow-1 p-4">
            <div className="d-flex flex-column">
              <div className="camera-header d-flex align-items-center details-header">
                <IconButton
                  onClick={() =>
                    navigate(`/site-sentry/customer/cameras/details/${device_uuid}`)
                  }
                >
                  <ArrowBackIcon style={{ color: "#3bb44a" }} />
                  <Typography color="white" variant="button">
                    Back
                  </Typography>
                </IconButton>
                <div className="flex-grow-1 available-heading">
                  <h2 style={{ color: "#fff" }}>Add Axis D4100/C1310</h2>
                </div>
              </div>
              <div className="flex-grow-1 details-view">
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CustomTextField
                        label="MAC Address"
                        name="mac"
                        value={formData.mac}
                        onChange={handleChange}
                        type="text"
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Enter MAC Address"
                      />
                      <CustomTextField
                        label="Device Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        type="text"
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Enter Device Name"
                      />
                      <CustomTextField
                        label="Username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        type="text"
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Enter Username"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextField
                        label="IP Address"
                        name="ip_address"
                        value={formData.ip_address}
                        onChange={handleChange}
                        type="text"
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Enter IP Address"
                      />
                      <CustomTextField
                        label="Owner Authentication Key"
                        name="oak"
                        value={formData.oak}
                        onChange={oakInputhandleChange}
                        type="text"
                        error={oakError}
                        helperText={oakError ? "Invalid OAK format" : ""}
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Enter Owner Authentication Key (OAK)"
                      />
                      <CustomTextField
                        label="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        type="password"
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Enter Password"
                      />
                    </Grid>
                  </Grid>
                  <hr />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CustomSelect
                        options={deviceTypeList}
                        label="Device Model"
                        name="device_model"
                        value={formData.device_model}
                        onChange={handleDeviceModelChange}
                        required
                        isSubmitted={isSubmitted}
                        placeholder="Select Device Model"
                      />
                      <Typography variant="body2" color="textSecondary" style={{ marginTop: "8px", color: "#fff" }}>
                        Please select the device model and fill in the required details:
                        <br />
                        <strong>Axis D4100 – Network Strobe Siren</strong>
                        <br />
                        Purpose: This device integrates a high-decibel siren with multicolor strobe lighting, offering both audible and visual alerts to deter intruders and enhance perimeter security during emergencies.
                        <br />
                        <strong>Axis C1310 – Network Horn Speaker</strong>
                        <br />
                        Purpose: Engineered for clear, long-range voice communication, this device supports both pre-recorded messages and live announcements, making it ideal for outdoor public address and emergency instructions.
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          marginTop: "16px",
                        }}
                      >
                        <Typography style={{ color: "#fff" }}>Device Status</Typography>
                        <Switch
                          color="success"
                          checked={formData.is_active}
                          onChange={handleStatusChange}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "16px",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      className="btn-rounded"
                      sx={{ borderRadius: "15px" }}
                      disabled={isSubmitted && !isFormValid()}
                    >
                      Save Changes
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
