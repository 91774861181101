import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import { formValidation } from "../../../../helper/common";
import HeaderDashboard from "../../../site-sentry/common/HeaderDashboard";
import Svg from "../../../common/Svg";
import { toast } from "react-hot-toast";
import { toUpperCaseText, MAC_DEFAULT } from "../../../../helper/utils";

export default function AddNewDevice() {
  let navigate = useNavigate();
  const { apiRequest } = useUiContext();
  let uid = localStorage.getItem("customeruuid");
  const [isDisabled, setIsDisabled] = useState(true);
  const [groups, setGroups] = useState([]);
  const [createGroup, setCreateGroup] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    oak: "",
    mac: "",
  });
  const [formData, setFormData] = useState({
    customer_uuid: uid,
    is_active: true,
    mac: "",
    name: "",
    oak: "",
    devicePassword: "",
    deviceUsername: "",
    group: "",
    sync_device_recordings: false,
    address_uuid: "",
    sitemap_uuid: "",
    device_type: "",
    sitemap_bearing: 0,
    sitemap_x_fraction: 0.3,
    sitemap_y_fraction: 0.3,
    onvif_host: "", // Updated field name
    is_onvif: ""
  });
  const [addresses, setAddresses] = useState([]);
  const [isAddressselected, setIsAddressSelected] = useState(false);
  const [selectAddressText, setSelectAddressText] = useState("");
  const [siteMapList, setSiteMapList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState(null);
  const [oakIsInalidMessage, setIsOakInalidMessage] = useState(false);
  const oakRegexPattern = /^[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}$/;
  const [isProtocolOnvif, setIsProtocolOnvif] = useState(false);
  const [isMobotix, setIsMobotix] = useState(false);
  const [openVPNConfigUrl, setOpenVPNConfigUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConfigUrlReady, setIsConfigUrlReady] = useState(false);

  const getOpenVPNConfigUrl = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/devices/openvpn_config_url/", "", headers, false)
      .then((res) => {
        if (res.data?.url) {
          setOpenVPNConfigUrl(res.data.url);
          setIsConfigUrlReady(true);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const testDeviceConnection = async () => {
    setIsLoading(true);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const requestData = {
      ip: formData.onvif_host, // Updated field name
      username: formData.deviceUsername,
      password: formData.devicePassword,
    };
    console.log("Headers:", headers);
    console.log("Request Data:", requestData);
    return await apiRequest("post", "api/1/devices/test_device_connection/", requestData, headers, true)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Connection failed");
        }
        console.log("Response:", res);
        setIsLoading(false);
        toast.success("Connection successful");
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        toast.error("Connection failed");
      });
  };

  useEffect(() => {
    getGroups();
    getAddressList();
    getInputs();
    getOpenVPNConfigUrl();
  }, []);

  const downloadOpenVPNConfig = () => {
    console.log("Downloading OpenVPN Config...");
    console.log("OpenVPN Config URL:", openVPNConfigUrl);
    const link = document.createElement("a");
    link.href = openVPNConfigUrl;
    const urlParts = openVPNConfigUrl.split('/');
    link.download = urlParts[urlParts.length - 1];
    console.log("Download link created:", link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log("Download link clicked and removed from document.");
  };

  useEffect(() => {
    const {
      mac,
      name,
      oak,
      password,
      username,
      address_uuid,
      sitemap_uuid,
      onvif_host, // Updated field name
    } = formData;

    console.log("Form Data:", formData); // Add this log
    console.log("isProtocolOnvif:", isProtocolOnvif); // Add this log

    formValidation(
      {
        name: name,
        password: password,
        username: username,
        ...(isProtocolOnvif && { onvif_host: onvif_host }), // Updated field name
        ...(isProtocolOnvif === false && { mac, oak }),
      },
      setIsDisabled
    );

    console.log("isDisabled:", isDisabled);
  }, [formData]);

  const getGroups = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    return await apiRequest("get", "api/1/groups/", "", headers, false)
      .then((res) => {
        if (res.data?.results !== null) {
          setGroups(res.data.results);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAddressList = async () => {
    try {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
      };
  
      const res = await apiRequest("get", "api/1/addresses/", "", headers, true);
  
      if (res?.data?.results) {
        setAddresses(res.data?.results);
      } else {
        setAddresses([]); // Fallback to an empty array if no data is returned
      }
    } catch (error) {
      console.error("Error fetching address list:", error);
      setAddresses([]); // Ensure state is reset even if API fails
    }
  };
  

  const getSitesList = async (address_uuid) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest(
      "get",
      `api/1/sitemaps/?address_uuid=${address_uuid}`,
      "",
      headers
    )
      .then((res) => {
        let response = res.data.results;
        setSiteMapList(response);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const getInputs = () => {
    apiRequest("get", "api_auth/1/form_choices").then((res) => {
      setDeviceTypeList(res.data?.device_types);
    });
  };

  useEffect(() => {
    getGroups();
    getAddressList();
    getInputs();
    getOpenVPNConfigUrl();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const oakInputhandleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "oak" && oakRegexPattern.test(e.target.value)) {
      setIsOakInalidMessage(false);
      handleChange(e);
    } else {
      setIsOakInalidMessage(true);
    }
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, group: e.target.value });
  };
  const addNewCamera = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    if (formData.sitemap_uuid === "") {
      formData.sitemap_uuid = null;
    }

    if (formData.address_uuid === "") {
      formData.address_uuid = null;
    }
    if (formData.device_type === "") {
      delete formData.device_type;
    }
    if (isProtocolOnvif) {
      formData.mac = MAC_DEFAULT;
    }
    let newFormData = formData;
    newFormData.username = formData.deviceUsername;
    newFormData.password = formData.devicePassword;
    newFormData.is_onvif = isProtocolOnvif;
    if (isMobotix) {
      newFormData.onvif_host = formData.onvif_host; // Updated field name
    }

    console.log("Form Data:", newFormData);
    console.log("Headers:", headers);

    return await apiRequest(
      "post",
      "api/1/devices/",
      newFormData,
      headers,
      true,
      { success: "Device added successfully", error: "Failed to add device" }
    )
      .then((res) => {
        if (res?.response?.data) {
          const errorsData = res.response.data;
          let newErrorState = {
            name: "",
            mac: "",
            oak: "",
          };
          if (errorsData.non_field_errors) {
            toast.error(errorsData.non_field_errors[0], { duration: 10000 });
          }
          if (errorsData.name) newErrorState.name = errorsData.name[0];
          if (errorsData.mac) newErrorState.mac = errorsData.mac[0];
          if (errorsData.oak) newErrorState.oak = errorsData.oak[0];
          if (errorsData.code && errorsData.code.length > 0)
            navigate("/site-sentry/customer/cameras");
          setErrors(newErrorState);
        } else {
          console.warn(res.data);
          navigate("/site-sentry/customer/cameras");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create group API
  const createHandler = (e) => {
    e.preventDefault();
    newGroup(createGroup);
  };

  const newGroup = async (createGroup) => {
    let header = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    let uid = localStorage.getItem("customeruuid");
    await apiRequest(
      "post",
      "api/1/groups/",
      { customer_uuid: uid, name: createGroup },
      header,
      true,
      { success: "Device Added Successfully", error: "Device not Added" }
    ).then(() => {
      getGroups();
    });
  };

  const cameraLink = () => {
    return (
      formData.mac !== "" && (
        <a
          className="btn btn-primary btn-rounded"
          href={`http://axis-${formData.mac}.local/`}
          target="_blank"
          rel="noreferrer"
        >
          Local Camera Config
        </a>
      )
    );
  };
  const isAdmin = localStorage.getItem("isadmin") === "true";

  const handleCameraManufacturerChange = (e) => {
    if (e.target.value === "mobotix") {
      setIsMobotix(true);
      setIsProtocolOnvif(true);
    } else {
      setIsMobotix(false);
      setIsProtocolOnvif(false);
    }
  };

  return (
    <div className="d-flex flex-column site-main-wrapper">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="add-new-device-page">
          {/* HeaderText */}
          <div className="d-flex align-items-center mb-3">
            <button
              className="btn btn-link text-white"
              onClick={() => {
                navigate(`/site-sentry/customer/cameras`);
              }}
            >
              <Svg name="back-arrow-big" /> Back
            </button>
          </div>
          <div className="row add-dvc-row gx-4">
            <div className="col-lg-7">
              <div className="mb-4">
                <h1 className="text-white">Add Device</h1>
              </div>
              {/* Form Add Device */}
              <div className="theme-form-wrapper">
                <h4 className="text-white mb-3">Device Details</h4>
                <div className="d-flex mb__20">
                  <label className="col-form-label text-end"></label>
                  <div className="flex-grow-1">
                    <div className="text-end new-tab-text">{cameraLink()}</div>
                  </div>
                </div>
                <div className="d-flex mb__20">
                  <label htmlFor="protocol" className="col-form-label text-end">
                    Camera Manufacturer
                  </label>
                  <div className="flex-grow-1 position-relative">
                    <select
                      className="form-control"
                      name="address_uuid"
                      onChange={handleCameraManufacturerChange}
                    >
                      <option value="axis">Axis</option>
                      <option value="mobotix">Mobotix</option>
                    </select>
                  </div>
                </div>
                {isMobotix && (
                  <div className="d-flex mb__20">
                    <label className="col-form-label text-end"></label>
                    <div className="flex-grow-1">
                      <button
                        className="btn btn-primary btn-rounded"
                        onClick={downloadOpenVPNConfig}
                        disabled={!isConfigUrlReady}
                      >
                        {isConfigUrlReady ? "Download OpenVPN Config" : "Preparing the config file..."}
                      </button>
                      <button
                        className="btn btn-secondary btn-rounded ms-2"
                        onClick={testDeviceConnection}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Test Connection"}
                      </button>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceName"
                        className="col-form-label text-end"
                      >
                        Device Name
                      </label>
                      <div className="flex-grow-1 position-relative">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.name}
                          className="form-control"
                          id="deviceName"
                          name="name"
                          placeholder="Device Name"
                        />
                        {errors.name !== "" && (
                          <div
                            className="validation-msg"
                            onClick={() => setErrors({ ...errors, name: "" })}
                          >
                            {errors.name}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceUsername"
                        className="col-form-label text-end"
                      >
                        Username
                      </label>
                      <div className="flex-grow-1">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.deviceUsername}
                          className="form-control"
                          id="deviceUsername"
                          placeholder="Username"
                          name="deviceUsername"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="devicePassword"
                        className="col-form-label text-end"
                        autoComplete="off"
                      >
                        Password
                      </label>
                      <div className="flex-grow-1">
                        <input
                          type={
                            formData.devicePassword === "" ? "text" : "password"
                          }
                          onChange={handleChange}
                          value={formData.devicePassword}
                          className="form-control"
                          id="devicePassword"
                          placeholder="Password"
                          name="devicePassword"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!isMobotix && (
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="d-flex mb__20">
                        <label
                          htmlFor="dynamicField1"
                          className="col-form-label text-end"
                        >
                          Serial Number
                        </label>
                        <div className="flex-grow-1 position-relative">
                          <input
                            type="text"
                            onChange={handleChange}
                            value={formData.mac}
                            className="form-control"
                            id="dynamicField1"
                            name="mac"
                            placeholder="Serial Number"
                          />
                          <div className="form-text">MAC Address</div>
                          {errors.mac && (
                            <div
                              className="validation-msg"
                              onClick={() => setErrors({ ...errors, mac: "" })}
                            >
                              {errors.mac}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6">
                      <div className="d-flex mb__20">
                        <label
                          htmlFor="dynamicField2"
                          className="col-form-label text-end"
                        >
                          Owner Authentication Key
                        </label>
                        <div className="flex-grow-1 position-relative">
                          <input
                            type="text"
                            onChange={oakInputhandleChange}
                            value={formData.oak}
                            className="form-control"
                            id="dynamicField2"
                            name="oak"
                            placeholder="OAK"
                          />
                          <div className="form-text">
                            Owner Authentication Key - Format: XXXX-XXXX-XXXX
                          </div>
                          {oakIsInalidMessage && (
                            <div className="validation-msg">
                              Please add valid oak
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isMobotix && (
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="onvifHost"
                        className="col-form-label text-end"
                      >
                        Mobotix IP/Host
                      </label>
                      <div className="flex-grow-1 position-relative">
                        <input
                          type="text"
                          onChange={handleChange}
                          value={formData.onvif_host} // Updated field name
                          className="form-control"
                          id="onvifHost"
                          name="onvif_host" // Updated field name
                          placeholder="Mobotix IP/Host"
                        />
                        <div className="form-text">IP/Host Address</div>
                        {errors.onvif_host && ( // Updated field name
                          <div
                            className="validation-msg"
                            onClick={() => setErrors({ ...errors, onvif_host: "" })} // Updated field name
                          >
                            {errors.onvif_host}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <hr />
                <div className="row">
                  <h4 className="text-white mb-3">Settings</h4>
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="address_uuid"
                        className="col-form-label text-end"
                      >
                        Site
                      </label>
                      <div className="flex-grow-1">
                        <select
                          className="form-control"
                          name="address_uuid"
                          onChange={(e) => {
                            setIsAddressSelected(true);
                            setSelectAddressText("");
                            getSitesList(e.target.value);
                            handleChange(e);
                          }}
                        >
                          <option value="">Select site</option>
                          {addresses.map((site) => (
                            <option
                              value={site.address_uuid}
                              key={site.sitemap_uuid}
                            >
                              {site.name}
                            </option>
                          ))}
                        </select>
                        {selectAddressText.length > 0 && (
                          <p>{selectAddressText}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="sitemap"
                        className="col-form-label text-end"
                      >
                        Sitemap
                      </label>
                      <div className="flex-grow-1">
                        <select
                          className="form-control"
                          name="sitemap_uuid"
                          onClick={() => {
                            if (!isAddressselected) {
                              setSelectAddressText("Please Select Site");
                            }
                          }}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                        >
                          <option value="">Select sitemap</option>
                          {siteMapList.map((siteMap) => (
                            <option
                              value={siteMap.sitemap_uuid}
                              key={siteMap.sitemap_uuid}
                            >
                              {siteMap.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="d-flex mb__20">
                      <label
                        htmlFor="deviceType"
                        className="col-form-label text-end"
                      >
                        Device Type
                      </label>
                      <div className="flex-grow-1">
                        <select
                          className="form-control"
                          name="device_type"
                          onChange={handleChange}
                          value={formData.device_type}
                        >
                          <option value="">Select device type</option>
                          {deviceTypeList &&
                            Object.entries(deviceTypeList).map((device, i) => {
                              return (
                                <option value={device[0]} key={i}>
                                  {device[1]}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sync-device-checkbox">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="syncRecording"
                      name="sync_device_recordings"
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          sync_device_recordings: e.target.checked,
                        })
                      }
                      checked={formData.sync_device_recordings}
                    />
                    <label className="form-check-label" htmlFor="syncRecording">
                      <p>Sync device recordings</p>
                      <span>Copy to Cloud if Storage Subscribed</span>
                    </label>
                  </div>
                </div>
                <hr />
                <div className="d-flex mb__20 align-items-center">
                  <label className="col-form-label text-end">
                    Device Status
                  </label>
                  <div className="flex-grow-1">
                    <label className="switch" id="isActive">
                      <input
                        type="checkbox"
                        id="isActive"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            is_active: e.target.checked,
                          })
                        }
                        checked={formData.is_active}
                        defaultChecked
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
                <hr />
                <fieldset className="d-flex mb__20 group-checkbox align-items-center">
                  <legend className="col-form-label text-end mt-0">
                    Add this Device to a Group
                  </legend>
                  <div className="flex-grow-1">
                    <div className="d-flex flex-wrap group-checkbox-wrap align-items-center">
                      {groups?.map((group, index) => (
                        <div className="form-check" key={index}>
                          <input
                            type="radio"
                            name="group"
                            id={group.group_uuid}
                            value={group.group_uuid}
                            onChange={handleRadioChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={group.group_uuid}
                          >
                            {group.name}
                          </label>
                        </div>
                      ))}
                      <div className="form-check">
                        <button
                          className="add-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#addModal"
                        >
                          <Svg name="add-group" />
                        </button>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <hr />
                <div className="d-flex pt-3">
                  <legend className="col-form-label text-end d-none d-lg-block"></legend>
                  <div className="flex-grow-1 d-flex justify-content-end">
                    <button
                      className="btn btn-primary btn-rounded bg-primary"
                      onClick={addNewCamera}
                      disabled={isDisabled}
                    >
                      {toUpperCaseText("Save Device")}
                    </button>
                  </div>
                </div>
              </div>
              {/* Form Add Device */}
            </div>
            <div
              className="modal fade add-modal"
              id="addModal"
              tabIndex={-1}
              aria-labelledby="addModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  {isAdmin ? (
                    <div className="modal-header">
                      <h5 className="modal-title text-center">
                        Create new group
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <Svg name="close-rounded" />
                      </button>
                    </div>
                  ) : null}
                  <div className="modal-body">
                    <div className="theme-form-wrapper">
                      <form>
                        <div className="d-flex mb__20 align-items-center">
                          <label
                            htmlFor="groupName"
                            className="col-form-label text-end"
                          >
                            Group name
                          </label>
                          <div className="flex-grow-1">
                            <input
                              type="text"
                              className="form-control"
                              id="groupName"
                              onChange={(e) => setCreateGroup(e.target.value)}
                              placeholder="Group name"
                            />
                          </div>
                        </div>
                        <div className="button-wrap text-center">
                          <button
                            className="btn btn-primary btn-rounded"
                            type="button"
                            data-bs-dismiss="modal"
                            onClick={createHandler}
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
