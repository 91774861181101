import { getAuthHeaders } from "../../../../helper/utils";
import { toast } from "react-hot-toast";

const getHeaders = () => getAuthHeaders();
/**
 * Deregisters a camera device.
 * @param {Function} apiRequest - The API request function.
 * @param {String} device_uuid - The UUID of the device.
 * @param {Object} deviceDetails - The details of the device.
 * @returns {Promise<void>}
 */
export const deRegisterDeviceService = async (apiRequest, device_uuid, deviceDetails) => {
   // Ensure fresh headers
  const body = {
    name: deviceDetails.name,
    sitemap_bearing: deviceDetails.sitemap_bearing,
    sitemap_uuid: deviceDetails.sitemap_uuid,
    sitemap_x_fraction: deviceDetails.sitemap_x_fraction,
    sitemap_y_fraction: deviceDetails.sitemap_y_fraction,
  };

  try {
    await apiRequest(
      "post",
      `api/1/devices/${device_uuid}/deregister_device_oak/`,
      body,
      getHeaders(),
      true,
      {
        success: "Camera Device has been deregistered",
        error: "Failed to deregister Camera Device",
      }
    );
    toast.success("Camera Device has been deregistered");
  } catch (error) {
    console.error("Failed to deregister Camera Device:", error);
    toast.error("Failed to deregister Camera Device");
  }
};

/**
 * Downloads the OpenVPN configuration file.
 * @param {String} openVPNConfigUrl - The URL of the OpenVPN configuration file.
 */
export const downloadOpenVPNConfigService = (openVPNConfigUrl) => {
  if (!openVPNConfigUrl) {
    console.error("OpenVPN Config URL is missing");
    toast.error("OpenVPN Config URL is missing");
    return;
  }

  const link = document.createElement("a");
  link.href = openVPNConfigUrl;
  link.download = openVPNConfigUrl.split("/").pop();
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Tests the connection to a device.
 * @param {Function} apiRequest - The API request function.
 * @param {Object} formData - The form data containing connection details.
 * @param {Function} setIsLoading - Function to toggle loading state.
 * @returns {Promise<void>}
 */
export const testDeviceConnectionService = async (apiRequest, formData, setIsLoading) => {
  setIsLoading(true);

  try {
    const requestData = {
      ip: formData.onvif_host, // Updated field name
      username: formData.deviceUsername,
      password: formData.devicePassword,
    };

    const res = await apiRequest(
      "post",
      "api/1/devices/test_device_connection/",
      requestData,
      getHeaders(),
      true
    );

    if (res.status !== 200) {
      throw new Error("Connection failed");
    }

    toast.success("Connection successful");
  } catch (error) {
    console.error("Error:", error);
    toast.error("Connection failed");
  } finally {
    setIsLoading(false);
  }
};

/**
 * Updates device details.
 * @param {Function} apiRequest - The API request function.
 * @param {String} device_uuid - The UUID of the device.
 * @param {Object} formData - The updated form data.
 * @param {Function} getDeviceDetails - Function to fetch updated device details.
 * @returns {Promise<void>}
 */
export const deviceUpdateService = async (apiRequest, device_uuid, formData, getDeviceDetails, setLoading) => {
    let updatedFormData = { ...formData };
    
    if (formData.sync_event_list == null) {
      delete updatedFormData.sync_event_list;
    }
  
    try {
      setLoading(true);
      await apiRequest(
        "patch",
        `api/1/devices/${device_uuid}/`,
        updatedFormData,
        getHeaders(),
        true,
        {
          success: "Camera detail has been updated",
          error: "Failed to update camera detail",
          showToast: true,
        }
      );
  
      // Refresh device details after successful update
      getDeviceDetails(device_uuid);
    } catch (error) {
      console.error("Error updating device details:", error);
    } finally {
      // Stop loading after update is done
      setLoading(false);
    }
  };

  export const getOpenVPNConfigUrlService = async (apiRequest, setOpenVPNConfigUrl, setIsConfigUrlReady) => {
    try {
      const res = await apiRequest(
        "get",
        "api/1/devices/openvpn_config_url/",
        "",
        getHeaders(),
        false
      );
  
      if (res.data?.url) {
        setOpenVPNConfigUrl(res.data.url);
        setIsConfigUrlReady(true);
      } else {
        console.warn("Unexpected response data:", res.data);
      }
    } catch (error) {
      console.error("Error fetching OpenVPN config URL:", error);
    }
  };

  export const getGroupsService = async (apiRequest, setGroups) => {
    try {
      const res = await apiRequest("get", "api/1/groups/", "", getHeaders(), false);
  
      if (res.data?.results) {
        setGroups(res.data.results);
      } else {
        console.warn("Unexpected response data:", res.data);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  export const getDeviceDetailsService = async (
    apiRequest,
    id,
    setDeviceDetails,
    setIsMobotix,
    getDeviceAddressSitemap,
    setRotation,
    setCameraTypes,
    setFormData,
    formData
  ) => {
    try {
      const res = await apiRequest("get", `api/1/devices/${id}`, "", getHeaders(), true);
  
      if (!res.data) {
        console.warn("No device details received:", res);
        return;
      }
  
      setDeviceDetails(res.data);
      setIsMobotix(res.data.is_onvif);
  
      if (res.data.address_uuid) {
        getDeviceAddressSitemap(res.data.address_uuid, res.data);
      }
  
      setRotation(res.data.sitemap_bearing);
  
      // Map camera types based on device type
      const type = ["South", "West", "North", "East", "Combined"];
      const camera_types = res.data.camera_choices.map((choice, choiceIndex) => {
        const id = choice[0];
        switch (res.data.device_type) {
          case "quad":
            return { id, type: `Stream ${id} Quad1-${type[choiceIndex]}` };
          case "dual":
            return { id, type: `Stream ${id} Dual1` };
          case "single":
            return { id, type: `Single Stream` };
          case "max":
            return { id, type: `Stream Max${id}` };
          default:
            return { id, type: `Stream ${id}` };
        }
      });
  
      setCameraTypes(camera_types);
  
      if (res.data.sitemap_uuid) {
        setFormData({
          ...formData,
          sitemap_uuid: res.data.sitemap_uuid,
          address_uuid: res.data.address_uuid,
        });
      }
    } catch (error) {
      console.error("Error fetching device details:", error);
    }
  };
  
  
  