import { useState } from "react";
import { useUiContext } from "../../../../../contexts/UiContext";

const TOKEN_CACHE_KEY = "authTokenCache";
const CACHE_EXPIRATION_TIME = 60000; // 1 minute in milliseconds

export const useToken = () => {
  const { apiRequest } = useUiContext();
  const [authToken, setAuthToken] = useState(() => {
    const cachedToken = localStorage.getItem(TOKEN_CACHE_KEY);
    if (cachedToken) {
      const { token, timestamp } = JSON.parse(cachedToken);
      if (Date.now() - timestamp < CACHE_EXPIRATION_TIME) {
        return token;
      }
    }
    return "";
  });

  const getTokens = async () => {
    if (authToken) {
      return authToken;
    }

    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    try {
      const response = await apiRequest("get", `api_auth/1/auth_token`, {}, headers, false);
      if (response?.data?.authtoken) {
        const tokenData = {
          token: response.data,
          timestamp: Date.now(),
        };
        setAuthToken(response.data);
        localStorage.setItem(TOKEN_CACHE_KEY, JSON.stringify(tokenData));
        return response.data;
      } else {
        console.warn("Token not found in response:", response.data);
        return authToken;
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      return authToken;
    }
  };

  return { authToken, setAuthToken, getTokens };
};
