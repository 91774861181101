import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import TableSites from "./components/Site/TableSites";
import AddButton from "../customer/cameras/components/AddBtn";
import { siteAssetManagement } from "../site-sentry/common/Utility";
import { useUiContext } from "../../contexts/UiContext";
import { getSiteList, deleteAddress } from "./services/siteService/siteService";
import ConfirmationModal from "./components/Global/ConfirmationModal";

export default function SitesLocation() {
  const { apiRequest } = useUiContext();
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const fetchSites = useCallback(async () => {
    const dataSite = await getSiteList(apiRequest);
    setData(dataSite);
  }, []); // Dependency to avoid stale closure issues

  useEffect(() => {
    fetchSites();
  }, [fetchSites]);

  const handleDelete = async () => {
    if (selectedId) {
      const success = await deleteAddress(selectedId, apiRequest);
      if (success) {
        fetchSites();
      }
      setOpenModal(false); // Close modal after delete
    }
  };

  const confirmDelete = (address_uuid) => {
    setSelectedId(address_uuid);
    setOpenModal(true); // Open modal
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Sites</Typography>
        <AddButton
          textLabel="Add Sites"
          to={`${siteAssetManagement}/sites/site-form`}
        />
      </Box>
      <div>
        <TableSites key={data.length} data={data} handleDelete={confirmDelete} />
        <ConfirmationModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onConfirm={handleDelete}
          title="Are you sure you want to delete this address?"
          note="NOTE - Once deleted, you can not find this address in the list."
        />
      </div>
    </div>
  );
}
