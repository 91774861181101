import React, { useEffect, useState, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useUiContext } from "../../contexts/UiContext";
import TableCustomerList from "./components/Customer/TableCustomerList";
import AddButton from "../customer/cameras/components/AddBtn";
import { siteAssetManagement } from "../site-sentry/common/Utility";
import { getCustomerDataService } from "./components/Customer/services/customerService";

export default function SuperCustomer() {
  let uid = localStorage.getItem("customeruuid")
  const { apiRequest } = useUiContext();
  const [data, setData] = useState([]);

  const fetchCustomer = useCallback(async () => {
    let dataSite = await getCustomerDataService(apiRequest, "customer_uuid", uid);
    setData(dataSite);
  }, []);
  
  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer, uid]);

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Customer</Typography>
        <AddButton textLabel="Add Customer" to={`${siteAssetManagement}/customer/customer-form`} />
      </Box>
      <div>
        <TableCustomerList key={data.length} data={data} />
      </div>
    </div>
  );
}
