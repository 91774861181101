import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import { Grid, Button, Typography, IconButton } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
// import MultiSelect from "./MultiSelect";
import CustomTextField from "../Global/CustomTextField";
import CustomSwitch from "../Global/CustomSwitch";
import CustomTextFieldWithTwoButtons from "../Global/CustomInputBtn";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";
import Leaflet from "../../../common/Leaflet";
import { toast } from "react-hot-toast";
import {
  createAddress,
  getAddressDetail,
  updateSiteData,
  getAddress
} from "../../services/siteService/siteService";
import MapBox from "./MapBox";

const initialDataSite = {
  sitemaps: [], // Default empty array
  sitemaps_url: "",
  sitemaps_count: 0,
};

export default function SiteForm() {
  const navigate = useNavigate();
  let params = useParams();
  let editId = params.address_uuid;
  const { apiRequest } = useUiContext();
  const [addresses, setAddresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [siteMapData, setSiteMapData] = useState(initialDataSite);
  const isSingleSelect = true;
  const uid = localStorage.getItem("customeruuid");
  const [errors, setErrors] = useState({
    name: "",
    lat: "",
    lon: "",
  });
  const [formData, setFormData] = useState({
    customer: isSingleSelect ? null : [],
    customer_uuid: uid,
    default_location: false,
    address: "",
    lat: "",
    lon: "",
    name: "",
    sitemaps: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const pinDrag = async (latLng) => {
    let customer_uuid = uid;
    let params = { search_location: { lat: latLng.lat, lon: latLng.lng } };
    setFormData({ ...formData, lat: latLng.lat, lon: latLng.lng });
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "post",
      `api/1/customers/${customer_uuid}/geocode_location_addresses/`,
      params,
      headers,
      true,
      { success: "pin dragged", error: "error" }
    );
    if (res.status === 200) {
      let response = res.data;
      setAddresses(response.addresses);
      // setSelectedAddress()
      setFormData({
        ...formData,
        address: "",
        lat: "",
        lon: "",
      });
      if (response.addresses.length > 0) {
        openAddressModal();
      } else {
        toast.error("No address found, please try again");
      }
    }
  };

  const openAddressModal = () => {
    let addressModal = document.getElementById("addressModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(addressModal);
    if (modal && !modal._isShown) {
      setIsModalOpen(true);
      modal.show();
    }
  };

  const closeLeaveModal = () => {
    let addressModal = document.getElementById("addressModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(addressModal);
    if (modal && modal._isShown) {
      modal.hide();
      setSelectedAddress(null);
      setIsModalOpen(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await createAddress(formData, apiRequest);

    if (result.success) {
      navigate(`${siteAssetManagement}/sites`);
    } else {
      setErrors(result.errors);
    }
  };

  const handleUpdateSite = async (e) => {
    e.preventDefault();
    const result = await updateSiteData(editId, formData, apiRequest);

    if (result.success) {
      navigate(`${siteAssetManagement}/sites`);
    } else {
      setErrors(result.errors);
    }
  };

  const getDetail = async () => {
    const res = await getAddressDetail(editId, apiRequest);
    console.log("baru", res.data);
    setFormData(res.data);
    setSiteMapData((prev) => ({
      ...prev,
      sitemaps: res.data.sitemaps || [],
      sitemaps_url: res.data.sitemaps_url || "",
      sitemaps_count: res.data.sitemaps_count || 0,
    }));
  };

  const handleDocumentLoad = ({ numPages }) => {
    console.log(`PDF Loaded with ${numPages} pages.`);
  };

  const handleSearch = async () => {
    const response = await getAddress(formData.address, uid, apiRequest);
  
    if (response.success) {
      setFormData({
        ...formData,
        address: response.data.address,
        lat: response.data.lat,
        lon: response.data.lon,
      });
    } else {
      console.error("Error fetching address:", response.message || response.errors);
    }
  };

  const handleGetLocation = () => {
    if (formData.lat === "" && formData.lon === "")
      navigator.geolocation.getCurrentPosition(function (position) {
        setFormData({
          ...formData,
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      });
  };

  useEffect(() => {
    if (!editId) return;
    getDetail();
  }, [editId]);

  return (
    <div style={{ padding: "20px", color: "#fff" }}>
      <IconButton onClick={() => navigate(`${siteAssetManagement}/sites`)}>
        <ArrowBackIcon style={{ color: "#3bb44a" }} />
        <Typography color="white" variant="button">
          Back
        </Typography>
      </IconButton>
      <Typography variant="h4" gutterBottom>
        {editId ? "Edit Form Site" : "Create Form Site"}
      </Typography>

      <form onSubmit={editId ? handleUpdateSite : handleSubmit}>
        <Grid container spacing={1}>
          {/* Left Side: Client and PIC Name */}
          <Grid item xs={12} sm={8}>
            {/* <CustomTextField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              type="text"
            /> */}
            <CustomTextFieldWithTwoButtons
              label="Address"
              value={formData.address}
              onChange={handleChange}
              name="address"
              placeholder="Find your Address"
              required={true}
              buttonAriaLabel="search"
              onPrimaryButtonClick={handleSearch}
              primaryButtonTooltip="Search location"
              onSecondaryButtonClick={handleGetLocation}
              secondaryButtonTooltip="Get current location"
            />
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={1}>
          {/* Left Side: Client and PIC Name */}
          <Grid item xs={12} sm={8}>
            <CustomTextField
              label="Name"
              name="name"
              placeholder="Name Of Address"
              value={formData.name}
              onChange={handleChange}
              type="text"
            />
            <CustomTextField
              label="Latitude"
              name="lat"
              value={formData.lat}
              onChange={handleChange}
              type="lat"
            />
            <CustomTextField
              label="Longitude"
              name="lon"
              value={formData.lon}
              onChange={handleChange}
              type="lon"
            />
            <CustomSwitch
              label="Make this as Default Address"
              name="default_location"
              checked={formData.default_location}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  default_location: e.target.checked,
                })
              }
            />
          </Grid>
        </Grid>
        <hr />
        {/* Map */}
        <div className="map-wrap">
          <Leaflet
            lat={formData.lat}
            lng={formData.lon}
            setFormData={setFormData}
            formData={formData}
            pinDrag={pinDrag}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          {/* Submit Button */}
          {editId && (
            <Link
              to={`${siteAssetManagement}/sites/site-map-form/${editId}/create-map-site`}
            >
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: "50px", marginRight: "1rem" }}
              >
                Add Site Map
              </Button>
            </Link>
          )}
          <Button
            variant="contained"
            color="success"
            type="submit"
            className="btn-rounded"
            sx={{ borderRadius: "15px" }}
          >
            {editId ? "Update Site" : "Create Site"}
          </Button>
        </div>
        {editId && siteMapData.sitemaps.length > 0 && (
          <div className="mt-4">
            <MapBox
              site={siteMapData.sitemaps}
              addressUUID={editId}
              onDocumentLoadSuccess={handleDocumentLoad}
            />
          </div>
        )}
      </form>
      {/* Modal */}
      <div
        className={
          isModalOpen ? "show modal fade add-modal" : "modal fade add-modal"
        }
        data-bs-backdrop="static"
        id="addressModal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header p-3 d-flex justify-content-between">
              <h5 className="modal-title text-start">
                Address Suggestions by dragged pin
              </h5>
            </div>
            <div className="modal-body p-4">
              <div className="theme-form-wrapper">
                <form>
                  <div className="d-flex mb__20 align-items-center">
                    <div className="row w-100">
                      {addresses.length > 0 ? (
                        addresses.map((data, i) => {
                          return (
                            <div
                              key={i}
                              className="col-12 my-3 d-flex align-items-center"
                            >
                              <input
                                type="radio"
                                id={i}
                                name="address"
                                className="mx-3 select-address"
                                checked={
                                  selectedAddress !== null
                                    ? selectedAddress.address === data.address
                                      ? true
                                      : false
                                    : false
                                }
                                value={data.address}
                                onChange={() => {
                                  setSelectedAddress(data);
                                  setFormData({
                                    ...formData,
                                    address: data.address,
                                    lat: data.location.lat,
                                    lon: data.location.lon,
                                  });
                                }}
                              />
                              <label htmlFor={i}>{data.address}</label>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12 w-100 my-3 d-flex justify-content-center align-items-center">
                          <span>No address found, Please try again</span>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer text-center w-100">
              <div className="button-wrap text-center">
                <button
                  className="btn btn-primary"
                  style={{ width: "250px", borderRadius: "50px" }}
                  type="button"
                  disabled={selectedAddress === null}
                  onClick={() => closeLeaveModal()}
                >
                  Set Selected Address
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
    </div>
  );
}
