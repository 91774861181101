import { useLocation, useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../../../constants/common";
import HeaderDashboard from "../../common/HeaderDashboard";
import { createRef, useState } from "react";
import { useEffect } from "react";
import { useUiContext } from "../../../../contexts/UiContext";
import Svg from "../../common/Svg";
import { Image, Layer, Rect, Stage, Text } from "react-konva";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useRef } from "react";

function getPercentage(value, heightOrWidth) {
  return ((value / heightOrWidth) * 100) / 100;
}

export default function MapView({ route }) {
  const location = useLocation();
  const { address_uuid } = useParams();
  const { apiRequest } = useUiContext();
  
  const [siteMaps, setSiteMaps] = useState([]);
  const [siteMap, setSiteMap] = useState();
  const [siteMapUuid, setSiteMapUuid] = useState("");
  const [siteMapAddress, setSiteAddress] = useState("");
  const [activeSiteName, setActiveSiteName] = useState("All");
  const [hoverDevice, setHoverDevice] = useState(null);
  const [cPosx, setCPosx] = useState(0);
  const [cPosy, setCPosy] = useState(0);
  const [siteMapDimension, setSiteMapDimension] = useState({
    width: 500,
    height: 500,
  });
  const siteMapsRef = useRef([]);
  const singlesiteMapRef = useRef(null);

  const getAddressData = async (addressID) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    const res = await apiRequest(
      "get",
      `api/1/addresses/${addressID}/`,
      "",
      headers,
      true
    );
    if (res) {
     
      setSiteAddress(res.data.name);
      if (res.data.sitemaps) {
        
        siteMapsRef.current = res.data.sitemaps.map(
          (ref, index) => (siteMapsRef.current[index] = createRef())
        );

        let siteMaps = res.data.sitemaps.map((siteMap, i) => {
          let img = new window.Image();
          img.src = siteMap.image_map;
          return { ...siteMap, imgTag: img };
        });
        setSiteMaps(siteMaps);
      }
    }
  };

  const getSiteMap = async (id) => {
    if (id !== "All") {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
      };
      await apiRequest("get", `api/1/sitemaps/${id}/`, "", headers, true)
        .then((res) => {
          let sitemap = res.data;
          let img = new window.Image();
          img.src = sitemap.image_map;
          sitemap["imgTag"] = img;
          setSiteMap(sitemap);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    } else {
      getAddressData(address_uuid);
    }
  };

  useEffect(() => {
    if(activeSiteName !=="All" && singlesiteMapRef.current === null) {
      singlesiteMapRef.current = createRef();
    }
  }, [activeSiteName])
  

  useEffect(() => {

    if (singlesiteMapRef.current) {
     setSiteMapDimension({...siteMapDimension, width: singlesiteMapRef.current.offsetWidth - 30})
    }
  }, [singlesiteMapRef.current]);

  useEffect(() => {
    if (siteMapsRef.current.length) {
      setSiteMapDimension({...siteMapDimension, width: siteMapsRef.current[0].offsetWidth - 30})
    }
  }, [siteMapsRef.current]);
  


  useEffect(() => {
    if (location && address_uuid) {
      getAddressData(address_uuid);
    }
  }, [location, address_uuid]);

  return (
    <div className="d-flex flex-column site-main-wrapper grid-page site-map-view">
      <HeaderDashboard route={route} />
      <div className="camera-header d-flex align-items-center justify-content-end">
        <div className="d-block">
          <div className="dropdown group-dropdown">
            <button
              className="btn dropdown-toggle"
              type="button"
              id="dropdownSorting"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {activeSiteName}
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownSorting">
              <li
                key="all"
                onClick={() => {
                  setActiveSiteName("All");
                  getSiteMap("All");
                  setSiteMap();
                }}
              >
                <button
                  className={
                    activeSiteName === "All"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                >
                  All
                </button>
              </li>
              {siteMaps?.map((site, key) => (
                <li
                  key={key}
                  onClick={() => {
                    setActiveSiteName(site.name);
                    setSiteMapUuid(site.sitemap_uuid);
                    setSiteMapDimension({...siteMapDimension})
                    getSiteMap(site.sitemap_uuid);
                  }}
                >
                  <button
                    className={
                      activeSiteName === site.name
                        ? "dropdown-item active"
                        : "dropdown-item"
                    }
                  >
                    {site.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="d-block d-lg-none text-center">
          <RefreshButton />
        </div> */}
      </div>
      <div className="flex-grow-1 main-wrapper p-3 container mb-md-2 mb-5">
        <div className="row justify-content-center mt-5">
            <h3>Address: {siteMapAddress}</h3>
            <div className="camera-row row d-flex mt-5">
              <div
                id="my-tooltip"
                role="tooltip"
                className="react-tooltip core-styles-module_tooltip__3vRRp styles-module_tooltip__mnnfp styles-module_success__A2AKt react-tooltip__place-top react-tooltip__show core-styles-module_show__Nt9eE"
                style={{
                  display: cPosx == 0 ? "none" : "block",
                  position: "fixed",
                  top: `${cPosy}px`,
                  left: `${cPosx}px`,
                  zIndex: 999,
                  width: "auto",
                  backgroundColor: "#79a564",
                  color: "#000000",
                  fontWeight: "bold",
                }}
              >
                {hoverDevice?.name}
              </div>
              {siteMaps.length > 0 ? (
                <>
                  {siteMaps.length > 0 &&
                    siteMap === undefined &&
                    siteMaps.map((map, mapIndex) => {
                      return (
                        <div
                          className="col-md-6 mb-4"
                          ref={el => siteMapsRef.current[mapIndex] = el } 
                          key={mapIndex}
                          style={{
                            overflowX: "auto",
                            position: "relative",
                          }}
                        >
                          <h4>{map.name}</h4>
                          <Stage
                            className="stage-class"
                            width={siteMapDimension.width}
                            height={siteMapDimension.height}
                            style={{
                              overflowX: "auto",
                              position: "relative",
                              width: "100%",
                            }}
                          >
                            <Layer>
                              <Image
                                width={siteMapDimension.width}
                                height={siteMapDimension.height}
                                image={map.imgTag}
                                preventDefault={false}
                              />
                              {map.devices.map((device, i) => {
                                return (
                                  <Device
                                    device={device}
                                    siteMapDimension={siteMapDimension}
                                    id={`device_${device.device_uuid}`}
                                    siteMapRef={siteMapsRef.current[mapIndex]}
                                    setHoverDevice={setHoverDevice}
                                    setCPosx={setCPosx}
                                    setCPosy={setCPosy}
                                  />
                                );
                              })}
                              <Image />
                            </Layer>
                          </Stage>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div className="d-flex justify-content-center">
                  <h1>No Site Map Loaded</h1>
                </div>
              )}

              {siteMap !== undefined && (
                <div className="col-xl-6 mb-4" style={{
                  overflowX: "auto",
                  position: "relative",
                }}
                ref={singlesiteMapRef}
                >
                  <h4>{siteMap.name}</h4> 
                  <Stage
                    className="stage-class"
                    width={siteMapDimension.width}
                    height={siteMapDimension.height}
                    style={{
                      overflowX: "auto",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <Layer>
                      <Image
                        width={siteMapDimension.width}
                        height={siteMapDimension.height}
                        image={siteMap.imgTag}
                        preventDefault={false}
                      />
                      {siteMap.devices.map((device, i) => {
                        return (
                          <Device
                            device={device}
                            siteMapDimension={siteMapDimension}
                            setHoverDevice={setHoverDevice}
                            setCPosx={setCPosx}
                            setCPosy={setCPosy}
                          />
                        );
                      })}
                      <Image />
                    </Layer>
                  </Stage>
                </div>
              )}
          </div>
        </div>
      </div>
      <svg
        style={{ display: "none" }}
        id="svgDeactive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="#e82929"
      >
        <path d="M80-160v-640h640v275l160-160v410L720-435v275H80Z" />
      </svg>
      <svg
        style={{ display: "none" }}
        id="svgActive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="#3bb44a"
      >
        <path d="M80-160v-640h640v275l160-160v410L720-435v275H80Z" />
      </svg>
    </div>
  );
}

function Device({
  device,
  siteMapDimension,
  setHoverDevice,
  setCPosx,
  setCPosy,
}) {
  const navigate = useNavigate();
  const [cameraImg, setCameraImg] = useState(new window.Image());
  const cameraImgRef = useRef();
  // const TransformRef = useRef();

  const elementClicked = (deviceId) => {
    navigate(`/site-sentry/customer/cameras/live-camera/${deviceId}`);
  };

  const handleMouseEnter = (e) => {
    let xPosition;
    let yPosition;
    if (e.evt.clientX) {
      xPosition = e.evt.clientX;
      yPosition = e.evt.clientY;
    } else if (e.evt?.touches[0]) {
      xPosition = e.evt.touches[0]?.clientX;
      yPosition = e.evt.touches[0]?.clientY;
    }
    setCPosx(xPosition);
    setCPosy(yPosition);

    setHoverDevice({
      deviceId: device.device_uuid,
      siteId: device.sitemap_uuid,
      isActive: device.is_active,
      name: device.name,
      x: e.evt.offsetX,
      y: e.evt.offsetY,
    });
  };

  const handleMouseLeave = () => {
    setHoverDevice(null);
    setCPosx(0);
    setCPosy(0);
  };

  useEffect(() => {
    let svgId = device.is_active
      ? document.getElementById("svgActive")
      : document.getElementById("svgDeactive");
    const svgData = encodeURIComponent(
      new XMLSerializer().serializeToString(svgId)
    );
    cameraImg.src = "data:image/svg+xml;charset=utf-8," + svgData;
    setCameraImg(cameraImg);
  }, []);

  return (
    <Image
      data-tooltip-id={device.device_uuid}
      data-for={device.device_uuid}
      width={32}
      height={30}
      x={device.sitemap_x_fraction * siteMapDimension.width}
      y={device.sitemap_y_fraction * siteMapDimension.height}
      rotation={device.sitemap_bearing}
      draggable={false}
      image={cameraImg}
      ref={cameraImgRef}
      onClick={() => elementClicked(device.device_uuid)}
      onTap={() => elementClicked(device.device_uuid)}
      onMouseEnter={handleMouseEnter}
      onTouchStart={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchMove={handleMouseLeave}
      preventDefault={false}
    />
  );
}
