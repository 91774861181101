import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUiContext } from "../../../../../contexts/UiContext";
import HeaderDashboard from "../../../common/HeaderDashboard";
import SettingNavigation from "../../../common/SettingNavigation";
import Svg from "../../../common/Svg";
import {
  formValidation,
  getLocal,
  checkUserRoutePermission,
} from "../../../../../helper/common";
import Leaflet from "../../../common/Leaflet";
import { toast } from "react-hot-toast";

export default function AddAddress({ route }) {
  let role = getLocal("role");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [emailError, setEmailError] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const { apiRequest } = useUiContext();
  const uid = localStorage.getItem("customeruuid");
  const [addresses, setAddresses] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [errors, setErrors] = useState({
    name: "",
    lat: "",
    lon: "",
  });
  const [formData, setFormData] = useState({
    customer_uuid: uid,
    default_location: false,
    address: "",
    lat: "",
    lon: "",
    name: "",
  });

  let loggedinUserId = localStorage.getItem("customeruuid");

  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const { address, lat, lon, name } = formData;
    formValidation(
      { address: address, lat: lat, lon: lon, name: name },
      setIsDisabled,
      setEmailError
    );
  }, [formData]);

  const cameraLocationHandler = () => {
    if (formData.lat === "" && formData.lon === "")
      navigator.geolocation.getCurrentPosition(function (position) {
        setFormData({
          ...formData,
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      });
  };
  const createAddress = async (e) => {
    e.preventDefault();
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "post",
      "api/1/addresses/",
      formData,
      headers,
      true,
      { success: "Address created succesfully", error: "Address not created" }
    );
    if (res) {
      if (res.response && res.response.data) {
        const errorsData = res.response.data;
        let newErrorState = {
          name: "",
          lat: "",
          lon: "",
        };
        if (errorsData.name) {
          newErrorState.name = errorsData.name[0];
        }
        if (errorsData.lat) {
          newErrorState.lat = errorsData.lat[0];
        }
        if (errorsData.lon) {
          newErrorState.lon = errorsData.lon[0];
        }
        if (errorsData.length > 0) {
          navigate("/site-sentry/customer/sites");
        }
        setErrors(newErrorState);
      } else {
        navigate("/site-sentry/customer/sites");
      }
    }
  };

  const pinDrag = async (latLng) => {
    let customer_uuid = localStorage.getItem("customeruuid");
    let params = { search_location: { lat: latLng.lat, lon: latLng.lng } };
    setFormData({ ...formData, lat: latLng.lat, lon: latLng.lng });
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "post",
      `api/1/customers/${customer_uuid}/geocode_location_addresses/`,
      params,
      headers,
      true,
      { success: "pin dragged", error: "error" }
    );
    if (res.status === 200) {
      let response = res.data;
      setAddresses(response.addresses);
      // setSelectedAddress()
      setFormData({
        ...formData,
        address: "",
        lat: "",
        lon: "",
      });
      if (response.addresses.length > 0) {
        openAddressModal();
      } else {
        toast.error("No address found, please try again");
      }
    }
  };

  const openAddressModal = () => {
    let addressModal = document.getElementById("addressModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(addressModal);
    if (modal && !modal._isShown) {
      setIsModalOpen(true);
      modal.show();
    }
  };

  const closeLeaveModal = () => {
    let addressModal = document.getElementById("addressModal");
    let modal = window.bootstrap.Modal.getOrCreateInstance(addressModal);
    if (modal && modal._isShown) {
      modal.hide();
      setSelectedAddress(null);
      setIsModalOpen(false);
    }
  };
  
  const getAddress = async (address) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "post",
      `api/1/customers/${loggedinUserId}/geocode_address_text/`,
      { address: address },
      headers,
      true
    );
    if (res.response && res.response.data.errors) {
      toast.error(res.response.data.errors.address);
    }
    if (res.status === 422) {
      setFormData({
        ...formData,
        address: "",
        lat: "",
        lon: "",
      });
    }
    if (res.data) {
      setData(res.data.results);
      let locationData = res.data.location;

      setFormData({
        ...formData,
        address: res.data.address,
        lat: locationData.lat,
        lon: locationData.lon,
      });
    }
  };  

  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height settings-page d-flex flex-column">
          <SettingNavigation />
          <div className="flex-grow-1 setting-page-wrapper">
            <div className="container">
              <div className="user-permissions">
                {checkUserRoutePermission(role, `/${route}/customer/addresses`) && (
                  <Link
                    to={`/${route}/customer/addresses`}
                    className="back-btn d-inline-flex align-items-center"
                  >
                    <Svg name="left-arrow" /> Back
                  </Link>
                )}
                <div className="create-user">
                  <div className="page-heading">
                    <h3>Create new Site</h3>
                  </div>
                  <div className="create-user-wrapper">
                    <div className="form-wrapper theme-form-wrapper">
                      <form>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className=" d-flex position-relative">
                              <input
                                type="text"
                                placeholder="Enter Address"
                                className="form-control"
                                value={formData.address}
                                onChange={handleChange}
                                name="address"
                              />
                              <div className="find-button-wrap d-flex justify-content-center ms-4">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => getAddress(formData.address)}
                                >
                                  Find
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex mb__20">
                            <a
                              className="get-location form-text text-primary"
                              onClick={() => cameraLocationHandler()}
                            >
                              Use Browser Location
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <div className="mb__20 position-relative">
                              <input
                                type="text"
                                placeholder="Latitude"
                                className="form-control"
                                value={formData.lat}
                                onChange={handleChange}
                                name="lat"
                              />
                              {errors.lat !== "" && (
                                <div
                                  className="validation-msg"
                                  onClick={() =>
                                    setErrors({ ...errors, lat: "" })
                                  }
                                >
                                  {errors.lat}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="mb__20 position-relative">
                              <input
                                type="text"
                                placeholder="Longitude"
                                className="form-control"
                                value={formData.lon}
                                onChange={handleChange}
                                name="lon"
                              />
                              {errors.lon !== "" && (
                                <div
                                  className="validation-msg"
                                  onClick={() =>
                                    setErrors({ ...errors, lon: "" })
                                  }
                                >
                                  {errors.lon}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="mb__20 position-relative">
                              <input
                                type="text"
                                placeholder="Name of the Address"
                                className="form-control"
                                value={formData.name}
                                onChange={handleChange}
                                name="name"
                              />
                              {errors.name !== "" && (
                                <div
                                  className="validation-msg"
                                  onClick={() =>
                                    setErrors({ ...errors, name: "" })
                                  }
                                >
                                  {errors.name}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="makeDefault"
                            checked={formData.default_location}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                default_location: e.target.checked,
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="makeDefault"
                          >
                            Make this as Default Address
                          </label>
                        </div>
                        <div className="map-wrap">
                          <Leaflet
                            lat={formData.lat}
                            lng={formData.lon}
                            setFormData={setFormData}
                            formData={formData}
                            pinDrag={pinDrag}
                          />
                        </div>
                        <div className="button-wrap text-center text-lg-start">
                          <button
                            className="btn btn-primary btn-rounded"
                            onClick={createAddress}
                            disabled={isDisabled}
                          >
                            Create
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              isModalOpen ? "show modal fade add-modal" : "modal fade add-modal"
            }
            data-bs-backdrop="static"
            id="addressModal"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header p-3 d-flex justify-content-between">
                  <h5 className="modal-title text-start">
                    Address Suggestions by dragged pin
                  </h5>
                </div>
                <div className="modal-body p-4">
                  <div className="theme-form-wrapper">
                    <form>
                      <div className="d-flex mb__20 align-items-center">
                        <div className="row w-100">
                          {addresses.length > 0 ? (
                            addresses.map((data, i) => {
                              return (
                                <div
                                  key={i}
                                  className="col-12 my-3 d-flex align-items-center"
                                >
                                  <input
                                    type="radio"
                                    id={i}
                                    name="address"
                                    className="mx-3 select-address"
                                    checked={(selectedAddress !== null) ? (selectedAddress.address === data.address) ? true : false : false}
                                    value={data.address}
                                    onChange={() => {
                                      setSelectedAddress(data)
                                      setFormData({
                                        ...formData,
                                        address: data.address,
                                        lat: data.location.lat,
                                        lon: data.location.lon,
                                      });
                                    }}
                                  />
                                  <label htmlFor={i}>{data.address}</label>
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-12 w-100 my-3 d-flex justify-content-center align-items-center">
                              <span>No address found, Please try again</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="modal-footer text-center w-100">
                  <div className="button-wrap text-center">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px", borderRadius: "50px" }}
                      type="button"
                      disabled={selectedAddress === null}
                      onClick={() => closeLeaveModal()}
                    >
                      Set Selected Address
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}
