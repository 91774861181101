import React, { useEffect, useState } from 'react'
import { useUiContext } from '../../../../contexts/UiContext';
import HeaderDashboard from '../../common/HeaderDashboard';
import SettingNavigation from '../../common/SettingNavigation';
import { encryptString, formValidation } from "../../../../helper/common"
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
    const { apiRequest, setLoading } = useUiContext();
    const [isDisabled, setisDisabled] = useState(true);
    const [emailError, setEmailError] = useState(true);
    let name = localStorage.getItem("username")
    const [formData, setFormData] = useState({
        oldPassword:'',
        newPassword:'',
        old_enc_password: "",
        new_enc_password: "",
        confirmPassword:""
    })
    const [errors, setErrors] = useState({
        old_enc: "",
        isMatch:0,
        passError:0,
    });
    

    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    useEffect(()=>{
        getpublicKeyData();
    },[])

    const getpublicKeyData = async() =>{
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest("get", "api_auth/1/login","", headers, true);
        if(res){
            localStorage.setItem("publicKey", res.data.public_rsa_key);
        }
    }

   
    useEffect(() => {
        const {oldPassword,newPassword,confirmPassword} = formData
        formValidation({oldPassword,newPassword,confirmPassword}, setisDisabled, setEmailError);
    }, [formData])

    const validate = (e) => {
        e.preventDefault();
        let newpassword = formData.newPassword
        let confirmPassword = formData.confirmPassword
      // let Passwordregex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
        let Passwordregex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        let newErrors = {isMatch:0,passError:0}
        if(!newpassword.match(Passwordregex)){
            newErrors.passError = 2
        }else{
            newErrors.passError = 1
        }
        if (newpassword != confirmPassword) {
            newErrors.isMatch = 2
        }else{
            newErrors.isMatch = 1
        }
        setErrors({...errors,isMatch:newErrors.isMatch,passError:newErrors.passError})
    }

    useEffect(()=>{
        if( errors.passError === 1  && errors.isMatch === 1 ){     
            changePassword();
        }
    },[errors])

    const changePassword = async () => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        let formDataValues = formData;
        formDataValues.old_enc_password = encryptString(formDataValues.oldPassword);
        formDataValues.new_enc_password = encryptString(formDataValues.newPassword);
        const res = await apiRequest("patch", `api/1/my-user/${name}/change_password/`, {old_enc_password:formDataValues.old_enc_password,new_enc_password:formDataValues.new_enc_password}, headers, true, {success:'password changed Successfully',error:'Password not changed'});
        if (res) {
           if(res.response && res.response.data && res.response.data.errors){
            const errorsData = res.response.data.errors;
            let newErrorState = {
                old_enc: "", 
            }
            if (errorsData.old_enc_password) {
                newErrorState.old_enc = errorsData.old_enc_password;
            }
            setErrors(newErrorState)
           }else{
            setFormData({
                oldPassword:'',
                newPassword:'',
                confirmPassword:''
            })
            setErrors({
                old_enc:"",
                isMatch:0,
                passError:0
            })
        }
        }
    }
    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">

                        <div className="container">
                            <form className='profile-wrapper reset-password-form'>
                                <div className="row theme-form-wrapper">
                                    <div className="col-lg-6">
                                        <div className="account-form timezone-form p-0">
                                            <div className="d-flex mb__20">
                                                <label htmlFor="name" className="col-form-label text-end">old password</label>
                                                <div className="flex-grow-1">
                                                    <input type="password" className="form-control" id="name" value={formData.oldPassword} name="oldPassword" onChange={handleOnChange} />
                                                    {errors.old_enc !== '' && <label onClick={() => setErrors({ ...errors, old_enc:'' })}>Old password is not matched</label>}
                                                </div>
                                            </div>
                                            <div className="d-flex mb__20  ">
                                                <label htmlFor="name" className="col-form-label text-end">Enter New Password</label>
                                                <div className="flex-grow-1 ">
                                                    <input type="password" className="form-control" id="newpassword" name="newPassword" value={formData.newPassword}onChange={handleOnChange} />
                                                {errors.passError === 2  && <label >Password must be atleast 8 character ,include Aplhabet and numeric value</label> }
                                                </div>
                                            </div>
                                            <div className="d-flex mb__20 "  >
                                                <label htmlFor="abn" className="col-form-label text-end">Re-Enter Password</label>
                                                <div className="flex-grow-1 " >
                                                    <input type="password" className="form-control" value={formData.confirmPassword} name="confirmPassword" id="confirmPassword" onChange={handleOnChange} />
                                                {errors.isMatch === 2 && <label >Passwords not match</label> }
                                                </div>
                                            </div>
                                            <div className="d-lg-flex pt-lg-3 text-center">
                                                <label htmlFor="name" className="col-form-label text-end"></label>
                                                <div className="flex-grow-1">
                                                    <div className="button-wrap">
                                                        <button className='btn btn-primary btn-rounded' disabled={isDisabled} onClick={validate}>Change Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
