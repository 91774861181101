import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../component/PrivateRoutes'; // Ensure you have your ProtectedRoute component

// Import components (both regular and enterprise versions)
import CameraList from './customer/cameras/CameraList';
import CameraListSS from './site-sentry/customer/cameras/CameraList'; // Enterprise version
import AddNewDevice from './customer/cameras/AddNewDevice';
import LiveView from './customer/recordings/LiveView';
import Details from './customer/recordings/Details';
import Notifications from './customer/recordings/Notifications';
import Groups from "./customer/groups/Index";
import GroupDetails from "./customer/groups/Details";
import HelpCenter from './content-pages/HelpCenter';
import ReportProblem from './content-pages/ReportProblem';
import ProfileSettings from './customer/settings/Index';
import ChangePassword from './customer/settings/ChangePassword';
import AccountDetails from './customer/settings/AccountDetails';
import AddressList from './customer/settings/user-address/AddressList';
import AddAddress from './customer/settings/user-address/AddAddress';
import EditAddress from './customer/settings/user-address/EditAddress';
import SitesList from './customer/settings/user-address/SitesList';
import EditSite from './customer/settings/user-address/EditSite';
import AddSiteMap from './customer/settings/user-address/AddSiteMap';
import MapView from './customer/siteview/MapView';
import BillableSubscriptions from './customer/settings/BillableSubscriptions';
import EndSubscriptions from './customer/settings/EndSubscriptions';
import DevicesStorage from './customer/settings/DevicesStorage';
import UsersPermissions from './customer/settings/users-permissions/UsersPermissions';
import Adduser from './customer/settings/users-permissions/AddUser';
import EditUser from './customer/settings/users-permissions/EditUser';
import ResellersSystemIntegrators from './customer/settings/ResellersSystemIntegrators';
import ReportsAnalytics from './customer/settings/ReportsAnalytics';
import Recordings from './customer/recordings/Index';
import CameraView from './customer/recordings/CameraView';
import UnassignedSubscriptionsSummary from './reseller/unassigned-subscriptions-summary/UnassignedSubscriptionsSummary';
import AssignSubscription from './reseller/unassigned-subscriptions-summary/AssignSubscription';
import CreateSubscription from './reseller/unassigned-subscriptions-summary/CreateSubscription';
import ResellerDetails from './reseller/Details';
import BillableSubscriptionsSummary from './reseller/BillableSubscriptionsSummary';
import DevicesStorageSummary from './reseller/DevicesStorageSummary';
import UsersSummary from './reseller/users-summary/UsersSummary';
import AddSummaryUser from './reseller/users-summary/AddUser';
import DistributorsCustomers from './reseller/DistributorsCustomers';
import SubscriptionReports from './reseller/SubscriptionReports';
import CreateResellerCustomer from './customer/settings/CreateResellerCustomer';
import UserListTable from './customer/settings/UserListTable';

import AddNewDeviceSS from './site-sentry/customer/cameras/AddNewDevice';
import LiveViewSS from './site-sentry/customer/recordings/LiveView';
import DetailsSS from './site-sentry/customer/recordings/Details';
import NotificationsSS from './site-sentry/customer/recordings/Notifications';
import GroupsSS from './site-sentry/customer/groups/Index';
import GroupDetailsSS from './site-sentry/customer/groups/Details';
import HelpCenterSS from './site-sentry/content-pages/HelpCenter';
import ReportProblemSS from './site-sentry/content-pages/ReportProblem';
import ProfileSettingsSS from './site-sentry/customer/settings/Index';
import ChangePasswordSS from './site-sentry/customer/settings/ChangePassword';
import AccountDetailsSS from './site-sentry/customer/settings/AccountDetails';
import AddressListSS from './site-sentry/customer/settings/user-address/AddressList';
import AddAddressSS from './site-sentry/customer/settings/user-address/AddAddress';
import EditAddressSS from './site-sentry/customer/settings/user-address/EditAddress';
import SitesListSS from './site-sentry/customer/settings/user-address/SitesList';
import EditSiteSS from './site-sentry/customer/settings/user-address/EditSite';
import AddSiteMapSS from './site-sentry/customer/settings/user-address/AddSiteMap';
import MapviewSS from './site-sentry/customer/siteview/MapView';
import BillableSubscriptionsSS from './site-sentry/customer/settings/BillableSubscriptions';
import EndSubscriptionsSS from './site-sentry/customer/settings/EndSubscriptions';
import DevicesStorageSS from './site-sentry/customer/settings/DevicesStorage';
import UsersPermissionsSS from './site-sentry/customer/settings/users-permissions/UsersPermissions';
import AdduserSS from './site-sentry/customer/settings/users-permissions/AddUser';
import EditUserSS from './site-sentry/customer/settings/users-permissions/EditUser';
import ResellersSystemIntegratorsSS from './site-sentry/customer/settings/ResellersSystemIntegrators';
import ReportsAnalyticsSS from './site-sentry/customer/settings/ReportsAnalytics';
import RecordingsSS from './site-sentry/customer/recordings/Index';
import CameraViewSS from './site-sentry/customer/recordings/CameraView';
import UnassignedSubscriptionsSummarySS from './site-sentry/reseller/unassigned-subscriptions-summary/UnassignedSubscriptionsSummary';
import AssignSubscriptionSS from './site-sentry/reseller/unassigned-subscriptions-summary/AssignSubscription';
import CreateSubscriptionSS from './site-sentry/reseller/unassigned-subscriptions-summary/CreateSubscription';
import ResellerDetailsSS from './site-sentry/reseller/Details';
import BillableSubscriptionsSummarySS from './site-sentry/reseller/BillableSubscriptionsSummary';
import DevicesStorageSummarySS from './site-sentry/reseller/DevicesStorageSummary';
import UsersSummarySS from './site-sentry/reseller/users-summary/UsersSummary';
import AddSummaryUserSS from './site-sentry/reseller/users-summary/AddUser';
import DistributorsCustomersSS from './site-sentry/reseller/DistributorsCustomers';
import SubscriptionReportsSS from './site-sentry/reseller/SubscriptionReports';
import CreateResellerCustomerSS from './site-sentry/customer/settings/CreateResellerCustomer';
import UserListTableSS from './site-sentry/customer/settings/UserListTable';

import StrobeHornForm from './site-sentry/customer/cameras/StrobeHornForm';
import CreateReseller from './customer/settings/CreateReseller';
import CreateResellerSS from './site-sentry/customer/settings/CreateReseller';

// Test Import 

// Test Import

// Route configuration
// Route configuration
export const dynamicRoutesPath = [
    {
      path: "/customer/cameras",
      component: CameraList,
      enterprisePath: "/site-sentry/customer/cameras",
      enterpriseComponent: CameraListSS
    },
    {
      path: "/customer/cameras/add-new-device",
      component: AddNewDevice,
      enterprisePath: "/site-sentry/customer/cameras/add-new-device",
      enterpriseComponent: AddNewDeviceSS
    },
    {
      path: "/customer/cameras/live-camera/:device_uuid",
      component: LiveView,
      enterprisePath: "/site-sentry/customer/cameras/live-camera/:device_uuid",
      enterpriseComponent: LiveViewSS
    },
    {
      path: "/customer/cameras/details/:device_uuid",
      component: Details,
      enterprisePath: "/site-sentry/customer/cameras/details/:device_uuid",
      enterpriseComponent: DetailsSS
    },
    {
      path: "/customer/cameras/details/:device_uuid/strobe-horn",
      component: StrobeHornForm,
      enterprisePath: "/site-sentry/customer/cameras/details/:device_uuid/strobe-horn",
      enterpriseComponent: StrobeHornForm
    },
    {
      path: "/customer/cameras/notifications/:device_uuid",
      component: Notifications,
      enterprisePath: "/site-sentry/customer/cameras/notifications/:device_uuid",
      enterpriseComponent: NotificationsSS
    },
    {
      path: "/customer/groups",
      component: Groups,
      enterprisePath: "/site-sentry/customer/groups",
      enterpriseComponent: GroupsSS
    },
    {
      path: "/customer/group/details/:group_uuid",
      component: GroupDetails,
      enterprisePath: "/site-sentry/customer/group/details/:group_uuid",
      enterpriseComponent: GroupDetailsSS
    },
    {
      path: "/customer/help-center",
      component: HelpCenter,
      enterprisePath: "/site-sentry/customer/help-center",
      enterpriseComponent: HelpCenterSS
    },
    {
      path: "/customer/report-problem",
      component: ReportProblem,
      enterprisePath: "/site-sentry/customer/report-problem",
      enterpriseComponent: ReportProblemSS
    },
    {
      path: "/customer/profile-settings",
      component: ProfileSettings,
      enterprisePath: "/site-sentry/customer/profile-settings",
      enterpriseComponent: ProfileSettingsSS
    },
    {
      path: "/customer/change-password",
      component: ChangePassword,
      enterprisePath: "/site-sentry/customer/change-password",
      enterpriseComponent: ChangePasswordSS
    },
    {
      path: "/customer/account-details",
      component: AccountDetails,
      enterprisePath: "/site-sentry/customer/account-details",
      enterpriseComponent: AccountDetailsSS
    },
    {
      path: "/customer/sites",
      component: AddressList,
      enterprisePath: "/site-sentry/customer/sites",
      enterpriseComponent: AddressListSS
    },
    {
      path: "/customer/sites/create",
      component: AddAddress,
      enterprisePath: "/site-sentry/customer/sites/create",
      enterpriseComponent: AddAddressSS
    },
    {
      path: "/customer/sites/edit",
      component: EditAddress,
      enterprisePath: "/site-sentry/customer/sites/edit",
      enterpriseComponent: EditAddressSS
    },
    {
      path: "/customer/sites/:address_uuid/site",
      component: SitesList,
      enterprisePath: "/site-sentry/customer/sites/:address_uuid/site",
      enterpriseComponent: SitesListSS
    },
    {
      path: "/customer/sites/:address_uuid/site/:site_uuid",
      component: EditSite,
      enterprisePath: "/site-sentry/customer/sites/:address_uuid/site/:site_uuid",
      enterpriseComponent: EditSiteSS
    },
    {
      path: "/customer/cameras/sites/map-view/:address_uuid",
      component: MapView,
      enterprisePath: "/site-sentry/customer/cameras/sites/map-view/:address_uuid",
      enterpriseComponent: MapviewSS
    },
    {
      path: "/customer/billable-subscriptions",
      component: BillableSubscriptions,
      enterprisePath: "/site-sentry/customer/billable-subscriptions",
      enterpriseComponent: BillableSubscriptionsSS
    },
    {
      path: "/customer/end-subscriptions",
      component: EndSubscriptions,
      enterprisePath: "/site-sentry/customer/end-subscriptions",
      enterpriseComponent: EndSubscriptionsSS
    },
    {
      path: "/customer/devices-and-storage",
      component: DevicesStorage,
      enterprisePath: "/site-sentry/customer/devices-and-storage",
      enterpriseComponent: DevicesStorageSS
    },
    {
      path: "/customer/users-and-permissions",
      component: UsersPermissions,
      enterprisePath: "/site-sentry/customer/users-and-permissions",
      enterpriseComponent: UsersPermissionsSS
    },
    {
      path: "/customer/users-and-permissions/create",
      component: Adduser,
      enterprisePath: "/site-sentry/customer/users-and-permissions/create",
      enterpriseComponent: AdduserSS
    },
    {
      path: "/customer/users-and-permissions/edit/:username",
      component: EditUser,
      enterprisePath: "/site-sentry/customer/users-and-permissions/edit/:username",
      enterpriseComponent: EditUserSS
    },
    {
      path: "/customer/sites/add-site-map",
      component: AddSiteMap,
      enterprisePath: "/site-sentry/customer/sites/add-site-map",
      enterpriseComponent: AddSiteMapSS
    },
    {
        path: "/customer/sites/:address_uuid/site/add-map",
        component: AddSiteMap,
        enterprisePath: "/site-sentry/customer/sites/:address_uuid/site/add-map",
        enterpriseComponent: AddSiteMapSS
      },
      {
        path: "/customer/resellers-system-integrators",
        component: ResellersSystemIntegrators,
        enterprisePath: "/site-sentry/customer/resellers-system-integrators",
        enterpriseComponent: ResellersSystemIntegratorsSS
      },
      {
        path: "/customer/reports-and-analytics",
        component: ReportsAnalytics,
        enterprisePath: "/site-sentry/customer/reports-and-analytics",
        enterpriseComponent: ReportsAnalyticsSS
      },
      {
        path: "/customer/recordings",
        component: Recordings,
        enterprisePath: "/site-sentry/customer/recordings",
        enterpriseComponent: RecordingsSS
      },
      {
        path: "/customer/recordings/camera-view/:recording_uuid",
        component: CameraView,
        enterprisePath: "/site-sentry/customer/recordings/camera-view/:recording_uuid",
        enterpriseComponent: CameraViewSS
      },
      {
        path: "/reseller/unassigned-subscriptions-summary",
        component: UnassignedSubscriptionsSummary,
        enterprisePath: "/site-sentry/reseller/unassigned-subscriptions-summary",
        enterpriseComponent: UnassignedSubscriptionsSummarySS
      },
      {
        path: "/reseller/unassigned-subscriptions-summary/assign",
        component: AssignSubscription,
        enterprisePath: "/site-sentry/reseller/unassigned-subscriptions-summary/assign",
        enterpriseComponent: AssignSubscriptionSS
      },
      {
        path: "/reseller/unassigned-subscriptions-summary/create",
        component: CreateSubscription,
        enterprisePath: "/site-sentry/reseller/unassigned-subscriptions-summary/create",
        enterpriseComponent: CreateSubscriptionSS
      },
      {
        path: "/reseller/users-and-permissions",
        component: UsersPermissions,
        enterprisePath: "/site-sentry/reseller/users-and-permissions",
        enterpriseComponent: UsersPermissionsSS
      },
      {
        path: "/reseller/users-and-permissions/create",
        component: Adduser,
        enterprisePath: "/site-sentry/reseller/users-and-permissions/create",
        enterpriseComponent: AdduserSS
      },
      {
        path: "/reseller/users-and-permissions/edit/:username",
        component: EditUser,
        enterprisePath: "/site-sentry/reseller/users-and-permissions/edit/:username",
        enterpriseComponent: EditUserSS
      },
      {
        path: "/reseller/devices-and-storage",
        component: DevicesStorage,
        enterprisePath: "/site-sentry/reseller/devices-and-storage",
        enterpriseComponent: DevicesStorageSS
      },
      {
        path: "/reseller/resellers-system-integrators",
        component: ResellersSystemIntegrators,
        enterprisePath: "/site-sentry/reseller/resellers-system-integrators",
        enterpriseComponent: ResellersSystemIntegratorsSS
      },
      {
        path: "/reseller/reports-and-analytics",
        component: ReportsAnalytics,
        enterprisePath: "/site-sentry/reseller/reports-and-analytics",
        enterpriseComponent: ReportsAnalyticsSS
      },
      {
        path: "/reseller/account-details",
        component: AccountDetails,
        enterprisePath: "/site-sentry/reseller/account-details",
        enterpriseComponent: AccountDetailsSS
      },
      {
        path: "/reseller/details",
        component: ResellerDetails,
        enterprisePath: "/site-sentry/reseller/details",
        enterpriseComponent: ResellerDetailsSS
      },
      {
        path: "/reseller/billable-subscriptions",
        component: BillableSubscriptions,
        enterprisePath: "/site-sentry/reseller/billable-subscriptions",
        enterpriseComponent: BillableSubscriptionsSS
      },
      {
        path: "/reseller/end-subscriptions",
        component: EndSubscriptions,
        enterprisePath: "/site-sentry/reseller/end-subscriptions",
        enterpriseComponent: EndSubscriptionsSS
      },
      {
        path: "/reseller/billable-subscriptions-summary",
        component: BillableSubscriptionsSummary,
        enterprisePath: "/site-sentry/reseller/billable-subscriptions-summary",
        enterpriseComponent: BillableSubscriptionsSummarySS
      },
      {
        path: "/reseller/devices-storage-summary",
        component: DevicesStorageSummary,
        enterprisePath: "/site-sentry/reseller/devices-storage-summary",
        enterpriseComponent: DevicesStorageSummarySS
      },
      {
        path: "/reseller/user-summary",
        component: UsersSummary,
        enterprisePath: "/site-sentry/reseller/user-summary",
        enterpriseComponent: UsersSummarySS
      },
      {
        path: "/reseller/user-summary/create-user",
        component: AddSummaryUser,
        enterprisePath: "/site-sentry/reseller/user-summary/create-user",
        enterpriseComponent: AddSummaryUserSS
      },
      {
        path: "/reseller/distributor",
        component: DistributorsCustomers,
        enterprisePath: "/site-sentry/reseller/distributor",
        enterpriseComponent: DistributorsCustomersSS
      },
      {
        path: "/reseller/subscription-reports",
        component: SubscriptionReports,
        enterprisePath: "/site-sentry/reseller/subscription-reports",
        enterpriseComponent: SubscriptionReportsSS
      },
      {
        path: "/reseller/create-reseller",
        component: CreateResellerCustomer,
        enterprisePath: "/site-sentry/reseller/create-reseller",
        enterpriseComponent: CreateResellerCustomerSS
      },
      {
        path: "/reseller/user-list-table",
        component: UserListTable,
        enterprisePath: "/site-sentry/reseller/user-list-table",
        enterpriseComponent: UserListTableSS
      },
      {
        path: "/distributor/create-reseller",
        component: CreateReseller,
        enterprisePath: "/site-sentry/distributor/create-reseller",
        enterpriseComponent: CreateResellerSS
      },
      {
        path: "/distributor/account-details",
        component: AccountDetails,
        enterprisePath: "/site-sentry/distributor/account-details",
        enterpriseComponent: AccountDetails
      },
      {
        path: "/distributor/billable-subscriptions",
        component: BillableSubscriptions,
        enterprisePath: "/site-sentry/distributor/billable-subscriptions",
        enterpriseComponent: BillableSubscriptions
      },
      {
        path: "/distributor/end-subscriptions",
        component: EndSubscriptions,
        enterprisePath: "/site-sentry/distributor/end-subscriptions",
        enterpriseComponent: EndSubscriptions
      },
      {
        path: "/distributor/unassigned-subscriptions-summary",
        component: UnassignedSubscriptionsSummary,
        enterprisePath: "/site-sentry/distributor/unassigned-subscriptions-summary",
        enterpriseComponent: UnassignedSubscriptionsSummarySS
      },
      {
        path: "/distributor/unassigned-subscriptions-summary/assign",
        component: AssignSubscription,
        enterprisePath: "/site-sentry/distributor/unassigned-subscriptions-summary/assign",
        enterpriseComponent: AssignSubscriptionSS
      },
      {
        path: "/distributor/unassigned-subscriptions-summary/create",
        component: CreateSubscription,
        enterprisePath: "/site-sentry/distributor/unassigned-subscriptions-summary/create",
        enterpriseComponent: CreateSubscriptionSS
      },
      {
        path: "/distributor/users-and-permissions",
        component: UsersPermissions,
        enterprisePath: "/site-sentry/distributor/users-and-permissions",
        enterpriseComponent: UsersPermissions
      },
      {
        path: "/distributor/users-and-permissions/create",
        component: Adduser,
        enterprisePath: "/site-sentry/distributor/users-and-permissions/create",
        enterpriseComponent: Adduser
      },
      {
        path: "/distributor/users-and-permissions/edit/:username",
        component: EditUser,
        enterprisePath: "/site-sentry/distributor/users-and-permissions/edit/:username",
        enterpriseComponent: EditUser
      },
      {
        path: "/distributor/devices-and-storage",
        component: DevicesStorage,
        enterprisePath: "/site-sentry/distributor/devices-and-storage",
        enterpriseComponent: DevicesStorage
      },
      {
        path: "/distributor/resellers-system-integrators",
        component: ResellersSystemIntegrators,
        enterprisePath: "/site-sentry/distributor/resellers-system-integrators",
        enterpriseComponent: ResellersSystemIntegratorsSS
      },
      {
        path: "/distributor/reports-and-analytics",
        component: ReportsAnalytics,
        enterprisePath: "/site-sentry/distributor/reports-and-analytics",
        enterpriseComponent: ReportsAnalyticsSS
      }
  ];

// Map through the routes and render dynamically
const DynamicRoutes = () => {
  return (
    <Routes>
      {dynamicRoutesPath.map(({ path, component, enterprisePath, enterpriseComponent }) => (
        <>
          <Route
            key={path}
            exact
            path={path}
            element={<ProtectedRoute Component={component} />}
          />
          {enterprisePath && (
            <Route
              key={enterprisePath}
              exact
              path={enterprisePath}
              element={<ProtectedRoute Component={enterpriseComponent} />}
            />
          )}
        </>
      ))}
    </Routes>
  );
};

export default DynamicRoutes;
