import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableFooter,
  Pagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";

export default function TableCameraList({data}) {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <Typography variant="h6" style={{ padding: "8px 0", color: "white" }}>
        Camera List
      </Typography>
      <GridToolbarQuickFilter
        quickFilterParser={(value) => value.split(",")}
        style={{ width: "300px" }}
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          "& .MuiInputBase-root": { color: "#fff" },
          "& .MuiSvgIcon-root": { color: "#fff" },
          "& .MuiOutlinedInput-root": { borderColor: "#6200c0" },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#6200c0" },
          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#6200c0" },
        }}
      />
    </GridToolbarContainer>
  );
  
  const handleEdit = (id) => {
    console.log(`Edit clicked for row with ID: ${id}`);
    navigate(`${siteAssetManagement}/cameras/camera-form/edit/${id}`);
  };
  
  const handleDelete = (id) => {
    console.log(`Delete clicked for row with ID: ${id}`);
    // Your delete logic here
  };
  
  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 }, // Small width for the ID column
    { field: "name", headerName: "Camera Name", flex: 0.15 }, // Slightly larger space for "Asset"
    { field: "address_name", headerName: "Address Name", flex: 0.2 }, // Larger space for camera names
    { field: "device_type", headerName: "Device Type", flex: 0.1 }, // "Client" will also share space
    { field: "arm_disarm_status", headerName: "Arm Disarm Status", flex: 0.1 }, // "Location" can take up more space
    // {
    //   field: "firmware",
    //   headerName: "Firmware",
    //   flex: 0.1,
    //   renderCell: (params) => (
    //     <Typography
    //     style={{
    //       color: params.value === "Updated" ? "#4CAF50" : "#D32F2F",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       height: "100%", // Ensure it takes full height of the row
    //     }}
    //   >
    //     {params.value}
    //   </Typography>
    //   ),
    // },
    { field: "oak", headerName: "OAK", type: "number", flex: 0.25 },
    {
      field: "status",
      headerName: "Status",
      flex: 0.15,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: params.value === true ? "#4CAF50" : "#D32F2F",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Ensure it takes full height of the row
            }}
          >
            {params.value ? "Active" : "Inactive"}
          </Typography>
        );
      },
    },    
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.2, // Use flex to allocate space for the action column
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <GridActionsCellItem
            icon={<Edit style={{ color: "white" }} />} // Edit icon in white
            onClick={() => handleEdit(params.row.device_uuid)}
            showInMenu
          />
          <GridActionsCellItem
            icon={<Delete style={{ color: "red" }} />} // Trash icon in red
            onClick={() => handleDelete(params.row.device_uuid)}
            showInMenu
          />
        </div>
      ),
    },
  ];

  const rows = data.map((camera, index) => ({
    id: index + 1,
    name: camera.name,
    address_name: camera.address_name,
    device_type: camera.device_type,
    arm_disarm_status: camera.arm_disarm_status,
    firmware: index % 2 === 0 ? "Outdated" : "Updated",
    oak: camera.oak,
    status: camera.is_active,
    device_uuid: camera.device_uuid,
  }));
  
  const rowsPerPage = 5;

  const paginatedRows = rows.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <div>
      <TableContainer component={Paper} className="custom-table">
        <Table>
          {/* DataGrid with Checkbox Selection */}
          <DataGrid
            rows={paginatedRows}
            columns={columns}
            pageSize={rowsPerPage}
            className="custom-datagrid"
            slots={{ toolbar: CustomToolbar }}
            sx={{
              "& .MuiDataGrid-root": {
                borderColor: "none",
              },
              "& .MuiDataGrid-container--top": {
                backgroundColor: "#000",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#000",
                color: "#fff",
              },
              "& .MuiDataGrid-row--borderBottom": {
                background: "#000 !important",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#fff",
              },
              "& .MuiCheckbox-root": {
                color: "white !important",
              },
            }}
            hideFooter // Hide the default footer
          />
          {/* Custom Pagination */}
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(rows.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    className="custom-pagination"
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
