import React from "react";
import HeaderDashboard from "../../../common/HeaderDashboard";
import SettingNavigation from "../../../common/SettingNavigation";
import Svg from "../../../common/Svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUiContext } from "../../../../../contexts/UiContext";
import { useState } from "react";
import { useRef } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { formValidation } from "../../../../../helper/common";

const EditSite = () => {
  const params = useParams();
  let address_uuid = params.address_uuid;
  let sitemap_uuid = params.site_uuid;
  const fileInput = useRef();
  let navigate = useNavigate();
  const { apiRequest } = useUiContext();
  const [addressName, setAddressName] = useState("");
  const [siteName, setSiteName] = useState('')
  const [displayImage, setDisplayImage] = useState("");
  const [fileInputValue, setFileInputValue] = useState('')
  const [formData, setFormData] = useState({
    address: "",
    siteName: "",
    name: '',
    images: null,
    default_sitemap: false,
  });
  const [errors, setErrors] = useState({
    name: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const headers = {
    Authorization: "Bearer " + localStorage.getItem("token"),
    "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
  };

  const handleFileInput = (e) => {
    if(e.target.files.length > 0 && e.target.files[0].type !== "application/pdf") {
      setFileInputValue(e.target.files[0].name);
      setFormData({
        ...formData,
        images: e.target.files[0],
      });
    } else{
      setFileInputValue('');
      setFormData({
        ...formData,
        images: null,
      });
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  }

  function onDocumentLoadSuccess(e) {
    setNumPages(e.numPages);
  }

  const updateSiteMap = async () => {
    // here api call
    await apiRequest("patch", `api/1/sitemaps/${sitemap_uuid}/`, formData, headers, true, {
      success: "Sitemap updated successfully",
      error: "Sitemap not updated",
    })
      .then(async (res) => {
        let statusCode = res.status;
        headers["Content-Type"] = "multipart/form-data";
        if (statusCode === 200) {
          let sitemap_uuid = res.data.sitemap_uuid;
          if (formData.images) {
            let dataToSend = {
              image_map: formData.images,
            };
            await apiRequest(
              "post",
              `api/1/sitemaps/${sitemap_uuid}/upload_img/`,
              dataToSend,
              headers,
              true
            ).then((imageUploadRes) => {
              setFormData({
                ...formData,
                siteName: "",
                name: "",
                images: null,
                default_sitemap: false,
              });
              setErrors({ name: "" });
              fileInput.current.value = "";
            });
          }
          navigate(`/site-sentry/customer/sites/${address_uuid}/site`);
        } else if (res.response.status === 400) {
          let response = res.response.data;
          if (response.name) {
            setErrors({ ...errors, name: res.response.data.name[0] });
          }
          if (response.default_sitemap) {
            toast.error(response.default_sitemap[0]);
          }
        }
      })
      .catch((err) => {
        console.log("Create Sitemap Error: ", err);
      });
  };

  const deleteSiteMap = async () => {
    await apiRequest("delete", `api/1/sitemaps/${sitemap_uuid}/`, "", headers, true, {
      success: "Sitemap deleted successfully",
      error: "Sitemap not deleted",
    }).then((res) => {
      toast.success("Sitemap deleted successfully")
      navigate(`/site-sentry/customer/sites/${address_uuid}/site`)
    }).catch((error) => {
      toast.success("Sitemap not deleted")
    });
  }

  const getSiteData = async (address,siteName) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest("get", `api/1/sitemaps/${sitemap_uuid}/`, {}, headers)
      .then((res) => {
        let response = res.data;
        setFormData({
          ...formData,
          address: address,
          siteName: siteName,
          name: response.name,
          images: response.image_map,
          default_sitemap: response.default_sitemap,
        });
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getAddress = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    await apiRequest("get", `api/1/addresses/${address_uuid}/`, {}, headers)
      .then((res) => {
        let response = res.data;
        setAddressName(response.address);
        setSiteName(response.name);
        getSiteData(response.address, response.name);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    setFormData(formData);
  }, [formData]);

  useEffect(() => {
    const { address, siteName, name, images } = formData;
    formValidation(
      { address, siteName, name, images },
      setDisabled
    );
  }, [formData]);

  useEffect(() => {
    getAddress();
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
    ).toString()
  }, []);

  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height settings-page d-flex flex-column">
          <SettingNavigation />
          <div className="flex-grow-1 setting-page-wrapper">
            <div className="container">
              <div className="user-permissions">
                <Link
                  to={`/site-sentry/customer/sites/${address_uuid}/site`}
                  className="back-btn d-inline-flex align-items-center"
                >
                  <Svg name="left-arrow" /> Back
                </Link>
                <div className="create-user">
                  <div className="page-heading">
                    <h3>Edit site map</h3>
                  </div>
                  <div className="create-user-wrapper">
                    <div className="form-wrapper theme-form-wrapper">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb__20 position-relative">
                            <input
                              type="text"
                              placeholder="Enter Address"
                              className="form-control"
                              readOnly
                              name="address"
                              value={addressName}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb__20 position-relative">
                            <input
                              type="text"
                              placeholder="Site Name"
                              className="form-control"
                              name="siteName"
                              readOnly
                              value={siteName}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="mb__20 position-relative">
                            <input
                              type="text"
                              placeholder="Map Name"
                              className="form-control"
                              name="name"
                            //   defaultValue={formData.name}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                });
                              }}
                              value={formData.name}
                            />
                            {errors.name !== "" && (
                              <div
                                className="validation-msg"
                                onClick={() =>
                                  setErrors({ ...errors, name: "" })
                                }
                              >
                                {errors.name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="upload-wrapper mb-3">
                        <div className="input-group mb-1">
                          <input
                            style={{display: 'none'}}
                            type="file"
                            ref={fileInput}
                            // multiple
                            accept="image/*"
                            className="form-control"
                            id="inputGroupFile02"
                            onChange={handleFileInput}
                          />
                          <input 
                            id="uploadFile"
                            type="text"
                            className="form-control file-input"
                            value={fileInputValue}
                            onClick={handleClick}
                            placeholder="No choosen file"
                            readOnly
                            />
                          <label
                            className="input-group-text"
                            htmlFor="inputGroupFile02"
                          >
                            Upload
                          </label>
                        </div>
                        <p className="upload-info">
                          You can upload map in in JPG, GIF, PNG format
                        </p>
                      </div>
                      <div className="uploaded-list">
                        <div className="row">
                          {formData.images && (
                            <div className="col-lg-4 mb-3">
                              <div className="uploaded-img">
                                {typeof formData.images === 'string' ? 
                                    <>
                                      <button
                                        className="remove-icon"
                                        onClick={() => {
                                          fileInput.current.value = "";
                                          setDisplayImage('');
                                          setFileInputValue('')
                                          setFormData({ ...formData, images: null });
                                        }}
                                      >
                                        <Svg name="times-icon-new" />
                                      </button>
                                      <div className="img-wrap">
                                          {/* This code commented for pdf preview */}
                                          {/* {formData.images.includes('pdf') ? 
                                              <Document
                                                  file={formData.images}
                                                  onLoadSuccess={(e) =>
                                                      onDocumentLoadSuccess(e)
                                                  }
                                              >
                                                  <Page pageNumber={pageNumber}></Page>
                                              </Document> :
                                          } */}
                                          <img src={formData.images} />
                                      </div>
                                    </> :
                                    <div className="img-wrap">
                                        {formData.images.type !== "application/pdf" ? (
                                            <>
                                              <button
                                                className="remove-icon"
                                                onClick={() => {
                                                  fileInput.current.value = "";
                                                  setDisplayImage('');
                                                  setFileInputValue('')
                                                  setFormData({ ...formData, images: null });
                                                }}
                                              >
                                                <Svg name="times-icon-new" />
                                              </button>
                                              <img
                                                  src={URL.createObjectURL(formData.images)}
                                                  alt=""
                                              />
                                            </>
                                            ) : null
                                            // This code commented for upload pdf preview
                                            // <Document
                                            //     file={formData.images}
                                            //     onLoadSuccess={(e) =>  
                                            //         onDocumentLoadSuccess(e)
                                            //     }
                                            // >
                                            //     <Page pageNumber={pageNumber}></Page>
                                            // </Document>
                                        }      
                                    </div>
                                }
                                <div className="form-check">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`default_image`}
                                    name="defaultImage"
                                    checked={formData.default_sitemap}
                                    // defaultChecked={imageIndex == 0}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setFormData({
                                                ...formData,
                                                default_sitemap: true,
                                            });
                                        } else {
                                            setFormData({
                                                ...formData,
                                                default_sitemap: false,
                                            });
                                        }
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor={`default_image`}
                                >
                                    Make this as Default
                                </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="button-wrap text-center text-lg-start">
                        <button
                          type="submit"
                          className="btn btn-primary btn-rounded"
                          style={{marginRight: "20px"}}
                          onClick={() => {
                            updateSiteMap();
                          }}
                        >
                          {" "}
                          Update{" "}
                        </button>
                        <button
                          type="button"
                          className=" btn btn-delete-new btn-rounded"
                          onClick={() => {
                            deleteSiteMap();
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSite;
