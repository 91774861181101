// useCaseDeviceList.js

import { useState, useEffect } from "react";
import { useUiContext } from "../../../contexts/UiContext";
import { getAuthHeaders } from "../../../helper/utils";

const useCaseDeviceList = () => {
  const { apiRequest } = useUiContext();
  const [sortBy, setSortBy] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [groupedDeviceList, setGroupedDeviceList] = useState({});
  const [groupedSiteMapDeviceList, setGroupedSiteMapDeviceList] = useState({});
  const [groupedSiteDeviceList, setGroupedSiteDeviceList] = useState({});

  const getDeviceList = async (currentPage = page) => {
    const headers = getAuthHeaders();
    try {
      const res = await apiRequest(
        "get",
        `api/1/devices/?ordering=${sortBy}&page=${currentPage}`,
        "",
        headers,
        false
      );

      // Early return with empty data if response is invalid
      if (!res || !res.data || !Array.isArray(res.data?.results)) {
        console.warn("Invalid response from device API:", res);
        return;
      }

      const data = res.data.results;
      
      // Group data by group_uuid and use group name
      const groupedData = data.reduce((groups, device) => {
        // Handle devices with no groups
        if (!device || !Array.isArray(device.groups) || device.groups.length === 0) {
          const groupKey = "Ungrouped";
          if (!groups[groupKey]) {
            groups[groupKey] = { name: "Ungrouped", devices: [] };
          }
          groups[groupKey].devices.push(device);
          return groups;
        }
        
        device.groups.forEach((group) => {
          if (!group) return;
          
          const groupKey = group.group_uuid || "Ungrouped";
          const groupName = group.name || "Ungrouped";
          
          if (!groups[groupKey]) {
            groups[groupKey] = { name: groupName, devices: [] };
          }
          groups[groupKey].devices.push(device);
        });
        return groups;
      }, {});

      const groupDevicesBySiteMap = (devices) => {
        if (!Array.isArray(devices)) return {};
        return devices.reduce((sites, device) => {
          if (!device) return sites;
          
          if (device.sitemap) {
            const siteKey = device.sitemap.sitemap_uuid || "Unassigned";
            const siteName = device.sitemap.name || "Unassigned";
      
            if (!sites[siteKey]) {
              sites[siteKey] = { name: siteName, devices: [] };
            }
            sites[siteKey].devices.push(device);
          } else {
            // Handle devices with no sitemap
            const siteKey = "Unassigned";
            if (!sites[siteKey]) {
              sites[siteKey] = { name: "Unassigned", devices: [] };
            }
            sites[siteKey].devices.push(device);
          }
          return sites;
        }, {});
      };

      const groupDevicesBySite = (devices) => {
        if (!Array.isArray(devices)) return {};
        
        return devices.reduce((sites, device) => {
          if (!device) return sites;
          
          const siteKey = device.address_uuid || "Unassigned";
          const siteName = device.address_name || "Unassigned";
      
          if (!sites[siteKey]) {
            sites[siteKey] = { name: siteName, devices: [] };
          }
          sites[siteKey].devices.push(device);
          
          return sites;
        }, {});
      };
      
      // Group devices by site Map
      const groupedDevicesBySiteMap = groupDevicesBySiteMap(data);
      setGroupedSiteMapDeviceList(groupedDevicesBySiteMap);    

      // Group devices by siteName
      const groupedDevicesBySiteName = groupDevicesBySite(data);
      setGroupedSiteDeviceList(groupedDevicesBySiteName);    

      // Merge with existing grouped data if not the first page
      setGroupedDeviceList((prevState) => {
        if (currentPage === 1) return groupedData;

        // Merge new data with existing groups
        const updatedGroups = { ...prevState };
        Object.entries(groupedData).forEach(([groupKey, groupData]) => {
          if (!updatedGroups[groupKey]) {
            updatedGroups[groupKey] = { name: groupData.name, devices: [] };
          }
          updatedGroups[groupKey].devices = [
            ...updatedGroups[groupKey].devices,
            ...groupData.devices,
          ];
        });
        return updatedGroups;
      });

      setTotalPage(res.data.page_count || 1);
    } catch (error) {
      console.error("Error fetching device list:", error);
      // Keep existing state on error
    }
  };

  return {
    groupedSiteDeviceList,
    groupedSiteMapDeviceList,
    groupedDeviceList,
    sortBy,
    setSortBy,
    page,
    setPage,
    totalPage,
    getDeviceList,
  };
};

export default useCaseDeviceList;