import React, { useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useUiContext } from "../../../../../contexts/UiContext";

const CustomDropdown = () => {
  const [selected, setSelected] = useState("Default");
  const { setSortBy } = useUiContext();

  const options = [
    { label: "A to Z", value: "name" },
    { label: "Z to A", value: "-name" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (value) => {
    if (value) {
      setSelected(options.find((option) => option.value === value)?.label || "Default");
      setSortBy(value); // Call context function to set sort value
    }
    setAnchorEl(null);
  };

  return (
    <Box display="flex" alignItems="center" gap={1} bgcolor="#000" className="custom-dropdown">
      {/* Dropdown Button */}
      <Button
        onClick={handleMenuClick}
        startIcon={<SwapVertIcon />}
        endIcon={<ExpandMoreIcon />}
        sx={{
          color: "#ffffff",
          textTransform: "none",
          borderRadius: "20px",
          backgroundColor: "#000",
          "&:hover": { backgroundColor: "#222" },
        }}
      >
        {selected}
      </Button>
      {/* Menu for Dropdown Options */}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={() => handleMenuClose(null)}
        MenuListProps={{ sx: { bgcolor: "#000", color: "#ffffff" } }}
        PaperProps={{
          sx: {
            bgcolor: "#000",
            color: "#ffffff",
            borderRadius: "10px",
          },
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuClose(option.value)}
            sx={{
              "&:hover": {
                backgroundColor: "#222",
              },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CustomDropdown;