import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { checkUserRoutePermission, getLocal } from "../../../../helper/common";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";
import moment from "moment-timezone";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { CUSTOMER_ADMIN, DISTRIBUTOR_ADMIN, ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, SELLER_ADMIN, SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER } from "../../../../constants/common";

const TableUser = ({ users, deleteUser, resetPassword, sendWelcomeMail, userInactive, userServerParams, setUserServerParams  }) => {
  let role = getLocal("role");
  let userRole = getLocal('userRole');
  const [pageValue, setPageValue] = useState(1);

  const totalPageNo = Math.ceil(users?.length / 10); // Example pagination logic
  const timeZone = "UTC"; // Define the correct timezone
  const route = "admin"; // Example route, replace with dynamic logic if needed

  // Handle input value change
  const handlePageValueChange = (e) => {
    setPageValue(Number(e.target.value) || 1);
  };

  // Handle page navigation input keypress
  const handlePageValueKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      setUserServerParams({ ...userServerParams, page: pageValue });
    }
  };

  // Handle input blur
  const handlePageValueFocusout = () => {
    setUserServerParams({ ...userServerParams, page: pageValue || 1 });
  };

  return (
    <div className="devices-storage-list">
      {/* Search Input */}
    
      <input
        type="text"
        className="form-control mb-5"
        placeholder="Search"
        defaultValue={userServerParams.search}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setUserServerParams((prev) => ({ ...prev, search: e.target.value }));
          }
        }}
      />
      
      {/* User Table */}
      <div className="theme-table">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Access Level</th>
                <th>Last Login</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users?.length > 0 ? (
                users.map((user, userIndex) => (
                  <tr key={userIndex}>
                    <td className={user.is_active ? "" : "inactive"} onClick={() => setPageValue(userIndex + 1)}>
                      {user.first_name} {user.last_name}
                    </td>
                    <td className={user.is_active ? "text-capitalize" : "inactive text-capitalize"}>
                      {user.customer_profile?.role?.replaceAll("_", " ") || "-"}
                    </td>
                    <td className={user.is_active ? "" : "inactive"}>
                      {user.last_login ? moment.tz(user.last_login, timeZone).format("DD/MM/YYYY") : "-"}
                    </td>
                    <td>
                      <div className="button-wrap">
                        {/* Edit Button */}
                        {([SELLER_ADMIN, DISTRIBUTOR_ADMIN].includes(role) ||
                          [ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER].includes(userRole)) && (
                          <>
                            <Link
                              data-tooltip-id={`edit-${user.username}`}
                              to={`${siteAssetManagement}/users/user-form/edit/${user.username}`}
                              className="btn btn-primary btn-rounded edit"
                            >
                              Edit
                            </Link>
                            <ReactTooltip id={`edit-${user.username}`} place="top" content="Edit" />
                          </>
                        )}

                        {/* Disable / Enable Button */}
                        {[SELLER_ADMIN, DISTRIBUTOR_ADMIN, CUSTOMER_ADMIN].includes(role) && (
                          <button
                            data-tooltip-id={`disable-${user.username}`}
                            className={`btn btn-delete-new btn-rounded ${user.is_active ? "disable" : "enable"}`}
                            onClick={() => userInactive(userIndex, user.is_active)}
                          >
                            {user.is_active ? "Disable" : "Re-activate"}
                          </button>
                        )}
                        <ReactTooltip id={`disable-${user.username}`} place="top" content={user.is_active ? "Disable" : "Re-activate"} />

                        {/* Delete Button */}
                        {([ENTERPRISE_ADMIN, ENTERPRISE_SUPER_USER, MULTI_SITE_ADMIN, MULTI_SITE_SUPER_USER, SINGLE_SITE_ADMIN, SINGLE_SITE_SUPER_USER, SELLER_ADMIN, DISTRIBUTOR_ADMIN].includes(userRole)) && (
                          <button
                            data-tooltip-id={`delete-${user.username}`}
                            className="btn btn-danger btn-rounded delete"
                            onClick={() => deleteUser(user.username)}
                          >
                            Delete
                          </button>
                        )}
                        <ReactTooltip id={`delete-${user.username}`} place="top" content="Delete" />

                        {/* Reset Password Button */}
                        <button
                          data-tooltip-id={`reset-${user.username}`}
                          className="btn btn-secondary orange-color btn-rounded reset"
                          onClick={() => resetPassword(user)}
                        >
                          Reset Password
                        </button>
                        <ReactTooltip id={`reset-${user.username}`} place="top" content="Reset Password" />

                        {/* Resend Welcome Email */}
                          <>
                            <button
                              data-tooltip-id={`resend-${user.username}`}
                              className="btn btn-success btn-rounded resend"
                              onClick={() => sendWelcomeMail(user)}
                            >
                              Resend Welcome Email
                            </button>
                            <ReactTooltip id={`resend-${user.username}`} place="top" content="Resend Welcome Email" />
                          </>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">No data found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {users.length > 0 && (
          <div className="d-md-flex flex-wrap justify-content-center page-nav">
            {userServerParams.page > 1 && (
              <Link
                to=""
                className="btn btn-primary btn-rounded mx-2"
                onClick={() => setUserServerParams({ ...userServerParams, page: userServerParams.page - 1 })}
              >
                Previous Page
              </Link>
            )}

            {userServerParams.page < totalPageNo && (
              <Link
                to=""
                className="btn btn-primary btn-rounded mx-2"
                onClick={() => setUserServerParams({ ...userServerParams, page: userServerParams.page + 1 })}
              >
                Next Page
              </Link>
            )}

            <p className="no-underline heading mx-2">Page {userServerParams.page} of {totalPageNo}</p>

            {/* Go to Page Input */}
            <div className="d-md-flex text-md-start text-center flex-wrap pt-2 mx-2">
              <p className="pt-3 mx-3 no-underline heading">Go to Page#</p>
              <input
                className="page-search form-control mx-auto"
                type="number"
                value={pageValue}
                onChange={handlePageValueChange}
                onKeyDown={handlePageValueKeyDown}
                onBlur={handlePageValueFocusout}
              />
              <button
                className="search-btn btn btn-primary btn-rounded"
                onClick={() => setUserServerParams({ ...userServerParams, page: pageValue })}
              >
                Go
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableUser;
