import React from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export function CustomFileUpload({
  label,
  name,
  file,
  onFileChange,
  onRemove,
  multiple = false,
}) {
  const fileInputRef = React.useRef(null);

  const handleFileInput = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      onFileChange(multiple ? Array.from(selectedFiles) : selectedFiles[0]);
    }
  };

  const triggerFileSelect = () => {
    fileInputRef.current.click(); // Opens file selector
  };

  const handleRemove = () => {
    onRemove();
    fileInputRef.current.value = ""; // Reset input field
  };

  return (
    <div
    style={{ display: "flex", alignItems: "center", width: "100%" }}
    >
      {/* Label on the left */}
      <InputLabel
        htmlFor={name}
        sx={{
          marginRight: "8px",
          color: "#fff",
          width: "124px", // Ensure all labels have the same width
          textAlign: "left", // Align text for consistency
        }}
      >
        {label}
      </InputLabel>

      {/* File Upload Input & Display */}
      <FormControl sx={{ flex: 1 }}>
        {/* Hidden file input */}
        <input
          type="file"
          accept="image/*"
          onChange={handleFileInput}
          ref={fileInputRef}
          style={{ display: "none" }}
          id={name}
          multiple={multiple}
        />

        {/* TextField to display file name(s) */}
        <TextField
          variant="outlined"
          size="small"
          value={
            file
              ? multiple
                ? file.map((f) => f.name).join(", ")
                : file.name
              : ""
          }
          placeholder="Upload an image"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: file && (
              <IconButton onClick={handleRemove} size="small">
                <DeleteIcon />
              </IconButton>
            ),
          }}
          sx={{
            backgroundColor: "#333", // Same as CustomTextField
            color: "#fff",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#333", // Ensures same background
              borderRadius: "4px", // Ensure it matches other input fields
              "& fieldset": {
                borderColor: "#666", // Border color
              },
              "&:hover fieldset": {
                borderColor: "green",
              },
              "&.Mui-focused fieldset": {
                borderColor: "green",
              },
            },
            "& input": {
              color: "#fff",
              padding: "10px", // Adjust padding to match other inputs
            },
            "& input::placeholder": {
              color: "#fff",
              opacity: 0.7,
            },
          }}
        />
      </FormControl>

      {/* Upload Button */}
      <Button
        variant="contained"
        component="span"
        sx={{
          marginLeft: "8px",
          backgroundColor: "green", // Set background color to green
          "&:hover": {
            backgroundColor: "darkgreen", // Darker green on hover for better UI
          },
        }}
        onClick={triggerFileSelect}
      >
        Choose File
      </Button>
    </div>
  );
}

export default CustomFileUpload;
