import React from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf";
import Svg from "../../../common/Svg";
import { getLocal } from "../../../../helper/common";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";

const ALLOWED_ROLES = [
  "ENTERPRISE_ADMIN",
  "MULTI_SITE_ADMIN",
  "SINGLE_SITE_ADMIN",
];

export default function MapBox({ site, addressUUID, onDocumentLoadSuccess }) {
  const userRole = getLocal("userRole");
  const canEdit = ALLOWED_ROLES.includes(userRole);

  if (!Array.isArray(site)) {
    console.error("Expected site to be an array, received:", site);
    return null; // Prevent errors if site is not an array
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "16px",
        justifyContent: "center",
        alignItems: "stretch", // Ensures all cards stretch evenly
      }}
    >
      {site.map((item) => (
        <Card
          key={item.sitemap_uuid}
          sx={{
            background: "#1E1E1E",
            color: "white",
            border: "1px solid white",
            p: 2,
            borderRadius: 2,
            height: "100%", // Ensures all cards are the same height
            display: "flex",
            flexDirection: "column", // Stacks content properly
            justifyContent: "space-between", // Keeps button at the bottom
          }}
        >
          <CardContent>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">
                {item.name} {item.default_sitemap && <small>(Default)</small>}
              </Typography>
              {canEdit && (
                <Link
                  to={`/customer/sites/${addressUUID}/site/${item.sitemap_uuid}`}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ ml: 2 }}
                  >
                    <Svg name="edit-details" />
                  </Button>
                </Link>
              )}
            </div>

            {item.image_map ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                }}
              >
                {item.image_map.includes("pdf") ? (
                  <Document
                    file={item.image_map}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page pageNumber={1} />
                  </Document>
                ) : (
                  <img
                    src={item.image_map}
                    alt="Site Map"
                    style={{
                      width: "100%",
                      height: "300px", // Ensures a fixed height
                      objectFit: "cover", // Crops excess height while filling the box
                      borderRadius: 8,
                    }}
                  />
                )}
              </div>
            ) : (
              <Typography
                variant="body2"
                sx={{ mt: 2, textAlign: "center", color: "gray" }}
              >
                Site map not uploaded.
              </Typography>
            )}

            <div style={{ textAlign: "center", marginTop: 16 }}>
              <Link
                to={`${siteAssetManagement}/sites/site-map-form/${addressUUID}/site/${item.sitemap_uuid}`}
              >
                <Button
                  variant="contained"
                  color="success"
                  sx={{ borderRadius: "50px" }}
                >
                  Edit Site
                </Button>
              </Link>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
