import React from "react";
import { FormControl, InputLabel, TextField, IconButton, Divider, Tooltip } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

export function CustomTextFieldWithTwoButtons({
  label,
  value,
  onChange,
  name,
  placeholder,
  type = "text",
  disabled = false,
  required = false,
  error = false,
  helperText = "",
  isSubmitted,
  primaryButtonIcon = <SearchIcon />,
  onPrimaryButtonClick,
  primaryButtonAriaLabel = "search",
  primaryButtonTooltip = "Search",
  secondaryButtonIcon = <DirectionsIcon />,
  onSecondaryButtonClick,
  secondaryButtonAriaLabel = "directions",
  secondaryButtonTooltip = "Get directions"
}) {
  const isError = isSubmitted && required && !value.trim();

  const handlePrimaryButtonClick = (e) => {
    e.preventDefault();
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    }
  };

  const handleSecondaryButtonClick = (e) => {
    e.preventDefault();
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick();
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
      <InputLabel
        htmlFor={name}
        style={{ marginRight: "8px", color: "#fff", width: "150px" }}
      >
        {label}
      </InputLabel>
      <FormControl fullWidth>
        <TextField
          id={name}
          name={name}
          placeholder={placeholder ? placeholder : name}
          value={value}
          onChange={onChange}
          type={type}
          variant="outlined"
          disabled={disabled}
          error={isError || error}
          helperText={isError ? `${label} is required` : helperText}
          sx={{
            backgroundColor: disabled ? "#444" : "#333",
            color: "#fff",
            "& .MuiOutlinedInput-root": {
              backgroundColor: disabled ? "#444" : "#333",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: disabled ? "#666" : "green",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "green",
              },
              "&.Mui-disabled": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#666",
                },
              },
            },
          }}
          InputProps={{
            style: { color: "#fff" },
            endAdornment: (
              <React.Fragment>
                <Tooltip title={primaryButtonTooltip}>
                  <IconButton 
                    onClick={handlePrimaryButtonClick}
                    disabled={disabled}
                    aria-label={primaryButtonAriaLabel}
                    sx={{ 
                      color: "#fff",
                      "&:hover": {
                        color: "green"
                      },
                      "&.Mui-disabled": {
                        color: "#666"
                      }
                    }}
                  >
                    {primaryButtonIcon}
                  </IconButton>
                </Tooltip>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Tooltip title={secondaryButtonTooltip}>
                  <IconButton 
                    onClick={handleSecondaryButtonClick}
                    disabled={disabled}
                    aria-label={secondaryButtonAriaLabel}
                    sx={{ 
                      color: "#fff",
                      "&:hover": {
                        color: "green"
                      },
                      "&.Mui-disabled": {
                        color: "#666"
                      }
                    }}
                  >
                    {secondaryButtonIcon}
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            )
          }}
        />
      </FormControl>
    </div>
  );
}

export default CustomTextFieldWithTwoButtons;