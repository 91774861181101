import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Svg from "./Svg";
import { checkUserRoutePermission, getLocal } from "../../../helper/common";
import {
  ENTERPRISE_ADMIN,
  ENTERPRISE_SUPER_USER,
  MULTI_SITE_ADMIN,
  MULTI_SITE_SUPER_USER,
  SINGLE_SITE_ADMIN,
  SINGLE_SITE_SUPER_USER,
} from "../../../constants/common";

export default function SettingNavigation() {
  const [selectedItem, setSelectedItem] = useState(null);
  const role = getLocal("role");
  const userRole = getLocal("userRole");
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    const selectedItem = document.querySelector(
      `.settings-header li a[href="${pathname}"]`
    );
    if (selectedItem) {
      setSelectedItem(selectedItem);
      selectedItem.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const isAdmin = localStorage.getItem("isadmin") === "true";
  const isEnterpriseUser = JSON.parse(getLocal("isEnterprise_user") || "false");
  const isEnterpriseAdmin =
    isAdmin && isEnterpriseUser && userRole === "enterprise_admin";
  const canSeeResellerLinks = isEnterpriseAdmin && role === "customerIsAdmin";
  
  return (
    <div className="position-relative">
      <div className="settings-header">
        <ul>
          {/* <li className={(!checkUserRoutePermission(role,"abc"))?"d-none":""}> */}
          {checkUserRoutePermission(
            role,
            "/site-sentry/customer/profile-settings"
          ) &&
            !canSeeResellerLinks && (
              <li>
                <NavLink to="/site-sentry/customer/profile-settings">
                  <Svg name="general-setting" /> General
                </NavLink>
              </li>
            )}
          {checkUserRoutePermission(
            role,
            "/site-sentry/reseller/profile-settings"
          ) &&
            !canSeeResellerLinks && (
              <li>
                <NavLink to="/site-sentry/reseller/profile-settings">
                  <Svg name="general-setting" /> General
                </NavLink>
              </li>
            )}
          {checkUserRoutePermission(
            role,
            "/site-sentry/distributor/profile-settings"
          ) &&
            !canSeeResellerLinks && (
              <li>
                <NavLink to="/site-sentry/distributor/profile-settings">
                  <Svg name="general-setting" /> General
                </NavLink>
              </li>
            )}
          {checkUserRoutePermission(
            role,
            "/site-sentry/customer/account-details"
          ) && (
            <li>
              <NavLink to="/site-sentry/customer/account-details">
                <Svg name="account-setting" /> Account details
              </NavLink>
            </li>
          )}
          {checkUserRoutePermission(
            role,
            "/site-sentry/reseller/account-details"
          ) && (
            <li>
              <NavLink to="/site-sentry/reseller/account-details">
                <Svg name="account-setting" /> Account details
              </NavLink>
            </li>
          )}
          {checkUserRoutePermission(
            role,
            "/site-sentry/distributor/account-details"
          ) && (
            <li>
              <NavLink to="/site-sentry/distributor/account-details">
                <Svg name="account-setting" /> Account details
              </NavLink>
            </li>
          )}

          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/customer/billable-subscriptions"
              ) && (
                <li>
                  <NavLink to="/site-sentry/customer/billable-subscriptions">
                    <Svg name="billable-setting" /> Billable Subscriptions{" "}
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/reseller/billable-subscriptions"
              ) && (
                <li>
                  <NavLink to="/site-sentry/reseller/billable-subscriptions">
                    <Svg name="billable-setting" /> Billable Subscriptions{" "}
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/distributor/billable-subscriptions"
              ) && (
                <li>
                  <NavLink to="/site-sentry/distributor/billable-subscriptions">
                    <Svg name="billable-setting" /> Billable Subscriptions{" "}
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/reseller/unassigned-subscriptions-summary"
              ) && (
                <li>
                  <NavLink to="/site-sentry/reseller/unassigned-subscriptions-summary">
                    <Svg name="billable-setting" /> Assign Subscriptions
                  </NavLink>
                </li>
              )
            : null}
          {checkUserRoutePermission(
            role,
            "/site-sentry/distributor/unassigned-subscriptions-summary"
          ) && (
            <li>
              <NavLink to="/site-sentry/distributor/unassigned-subscriptions-summary">
                <Svg name="billable-setting" /> Assign Subscriptions
              </NavLink>
            </li>
          )}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/customer/devices-and-storage"
              ) && (
                <li>
                  <NavLink to="/site-sentry/customer/devices-and-storage">
                    <Svg name="device-setting" /> Devices and Storage{" "}
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/reseller/devices-and-storage"
              ) && (
                <li>
                  <NavLink to="/site-sentry/reseller/devices-and-storage">
                    <Svg name="device-setting" /> Devices and Storage{" "}
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/distributor/devices-and-storage"
              ) && (
                <li>
                  <NavLink to="/site-sentry/distributor/devices-and-storage">
                    <Svg name="device-setting" /> Devices and Storage{" "}
                  </NavLink>
                </li>
              )
            : null}
          {[
            ENTERPRISE_ADMIN,
            ENTERPRISE_SUPER_USER,
            MULTI_SITE_ADMIN,
            MULTI_SITE_SUPER_USER,
            SINGLE_SITE_ADMIN,
            SINGLE_SITE_SUPER_USER,
          ].includes(userRole) && (
            <li>
              <NavLink to="/site-sentry/customer/users-and-permissions">
                <Svg name="users-setting" /> Users and Permissions
              </NavLink>
            </li>
          )}
          {checkUserRoutePermission(role, "/site-sentry/customer/sites") && (
            <li>
              <NavLink to="/site-sentry/customer/sites">
                <Svg name="addresses" /> Sites
              </NavLink>
            </li>
          )}
          {isAdmin
            ? checkUserRoutePermission(role, "/site-sentry/reseller/sites") && (
                <li>
                  <NavLink to="/site-sentry/reseller/sites">
                    <Svg name="addresses" /> Sites
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/distributor/sites"
              ) && (
                <li>
                  <NavLink to="/site-sentry/distributor/sites">
                    <Svg name="addresses" /> Sites
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/distributor/users-and-permissions"
              ) && (
                <li>
                  <NavLink to="/site-sentry/distributor/users-and-permissions">
                    <Svg name="users-setting" /> Users and Permissions
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/reseller/users-and-permissions"
              ) && (
                <li>
                  <NavLink to="/site-sentry/reseller/users-and-permissions">
                    <Svg name="users-setting" /> Users and Permissions
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/customer/resellers-system-integrators"
              ) && (
                <li>
                  <NavLink to="/site-sentry/customer/resellers-system-integrators">
                    <Svg name="system-setting" /> Resellers / System Integrators
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/reseller/resellers-system-integrators"
              ) && (
                <li>
                  <NavLink to="/site-sentry/reseller/resellers-system-integrators">
                    <Svg name="system-setting" /> Distributors / Customers
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/distributor/resellers-system-integrators"
              ) && (
                <li>
                  <NavLink to="/site-sentry/distributor/resellers-system-integrators">
                    <Svg name="system-setting" /> Resellers / System Integrators
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/customer/reports-and-analytics"
              ) && (
                <li>
                  <NavLink to="/site-sentry/customer/reports-and-analytics">
                    <Svg name="reports-setting" /> Reports and Analytics
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/reseller/reports-and-analytics"
              ) && (
                <li>
                  <NavLink to="/site-sentry/reseller/reports-and-analytics">
                    <Svg name="reports-setting" /> Reports and Analytics
                  </NavLink>
                </li>
              )
            : null}
          {isAdmin
            ? checkUserRoutePermission(
                role,
                "/site-sentry/distributor/reports-and-analytics"
              ) && (
                <li>
                  <NavLink to="/site-sentry/distributor/reports-and-analytics">
                    <Svg name="reports-setting" /> Reports and Analytics
                  </NavLink>
                </li>
              )
            : null}
        </ul>
      </div>
    </div>
  );
}
