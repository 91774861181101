import React from "react";
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";

export function CustomSelect({
  label = "Select",
  options = [],
  value,
  onChange,
  name,
  idKey = "id", // Default key for ID
  labelKey = "name", // Default key for label
  required = false,
  isSubmitted = false, // New prop to track submission state
}) {
  const isError = isSubmitted && required && !value; // Only show error after submission

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginBottom: "16px",
      }}
    >
      <InputLabel
        style={{
          color: "#fff",
          width: "150px",
        }}
      >
        {label}
      </InputLabel>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ backgroundColor: "#333", borderRadius: "4px" }}
        error={isError} // Apply error state
      >
        <Select
          name={name}
          value={value}
          onChange={onChange}
          displayEmpty
          sx={{
            color: "#fff",
            backgroundColor: "#333",
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "green",
            },
          }}
        >
          <MenuItem value="" disabled>
            {label} {/* Placeholder */}
          </MenuItem>
          {Array.isArray(options)
            ? options.map((option) => (
                <MenuItem key={option?.[idKey]} value={option?.[idKey]}>
                  {option?.[labelKey] || "Unknown"}
                </MenuItem>
              ))
            : null}
        </Select>
        {isError && <FormHelperText>{label} is required</FormHelperText>}
      </FormControl>
    </div>
  );
}

export default CustomSelect;
