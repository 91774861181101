import React, { useEffect, useRef } from "react";
import {
  Grid,
  Badge,
  Card,
  CardMedia,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { getLocal } from "../../../../helper/common";
import { useLocation } from "react-router-dom";
import {
  baseUrl,
  ENTERPRISE_ADMIN,
  MULTI_SITE_ADMIN,
  SINGLE_SITE_ADMIN,
} from "../../../../constants/common";
import NavigationIcon from "../../recordings/components/NavigationIcon";
import {
  truncateString,
  getIconsToShow,
  formatBadgeContent,
} from "../../../../helper/utils";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";

const CameraGrid = ({
  route,
  deviceList,
  columns = 4,
  token,
  groupUuid = "",
  showDeleteButtons = false,
  onDeleteClick,
}) => {
  const columnSpan = 12 / columns;
  const userRole = getLocal("userRole");
  const videoRefs = useRef({});
  const cleanupTimers = useRef({});
  const location = useLocation();

  // Proper video cleanup function
  const cleanupVideos = () => {
    Object.entries(videoRefs.current).forEach(([key, video]) => {
      if (video) {
        try {
          // Stop all media tracks if using MediaStream
          if (video.srcObject) {
            video.srcObject.getTracks().forEach(track => track.stop());
            video.srcObject = null;
          }
          
          // Clear all sources and attributes
          video.pause();
          video.removeAttribute('src');
          video.load();
          
          // Remove all child elements (like <source> tags)
          while (video.firstChild) {
            video.removeChild(video.firstChild);
          }
          
          // Clean up IntersectionObserver if exists
          if (video._observer) {
            video._observer.disconnect();
            delete video._observer;
          }
          
          // Clear any pending timeouts
          if (cleanupTimers.current[key]) {
            clearTimeout(cleanupTimers.current[key]);
            delete cleanupTimers.current[key];
          }
        } catch (error) {
          console.error('Error cleaning up video:', error);
        }
      }
    });
    videoRefs.current = {};
  };

  useEffect(() => {
    return () => {
      cleanupVideos();
    };
  }, [location.pathname]);

  // Function to set video ref with proper cleanup
  const setVideoRef = (deviceKey, element) => {
    if (!element) {
      // When element unmounts, schedule cleanup
      cleanupTimers.current[deviceKey] = setTimeout(() => {
        if (videoRefs.current[deviceKey]) {
          try {
            const video = videoRefs.current[deviceKey];
            video.pause();
            if (video.srcObject) {
              video.srcObject.getTracks().forEach(track => track.stop());
              video.srcObject = null;
            }
            delete videoRefs.current[deviceKey];
          } catch (error) {
            console.error('Cleanup error:', error);
          }
        }
      }, 100);
      return;
    }

    // Get the actual video element
    const videoElement = element.tagName === 'VIDEO' ? element : element.querySelector('video');
    
    if (videoElement) {
      // Clear any pending cleanup for this device
      if (cleanupTimers.current[deviceKey]) {
        clearTimeout(cleanupTimers.current[deviceKey]);
        delete cleanupTimers.current[deviceKey];
      }

      videoRefs.current[deviceKey] = videoElement;
      
      // Set up IntersectionObserver for lazy loading
      if (!videoElement._observer) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              videoElement.play().catch(e => console.log('Autoplay prevented:', e));
            } else {
              videoElement.pause();
              videoElement.currentTime = 0;
            }
          });
        }, { threshold: 0.1 });
        
        observer.observe(videoElement);
        videoElement._observer = observer;
      }
    }
  };

  const handleIconClick = (stateKey, newState, deviceData) => {
    console.log(`Icon ${stateKey} is now ${newState ? "on" : "off"}`);
    console.log(`Device data:`, deviceData);
    const handlerMap = {
      sound: () => console.log("Sound toggled"),
      mic: () => console.log("Mic toggled"),
      arm: () => console.log("Arm/Disarm toggled"),
      dim: () => console.log("Dim toggled"),
      horn: () => console.log("Horn toggled"),
    };
    if (handlerMap[stateKey]) {
      handlerMap[stateKey](newState);
    }
  };

  if (!deviceList) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          backgroundColor: "#1e1e1e",
          padding: "2rem",
          borderRadius: "8px",
        }}
      >
        <VideocamOffIcon sx={{ fontSize: 48, color: "#888888", mb: 2 }} />
        <Typography variant="h6" sx={{ color: "#ffffff", mb: 1 }}>
          No Camera Data Available
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#888888", textAlign: "center" }}
        >
          There might be an issue loading the camera data. Please try again later.
        </Typography>
      </Box>
    );
  }

  if (deviceList.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          backgroundColor: "#1e1e1e",
          padding: "2rem",
          borderRadius: "8px",
        }}
      >
        <VideocamOffIcon sx={{ fontSize: 48, color: "#888888", mb: 2 }} />
        <Typography variant="h6" sx={{ color: "#ffffff", mb: 1 }}>
          No Cameras Found
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#888888", textAlign: "center" }}
        >
          There are currently no cameras in the system.
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container spacing={1}>
      {deviceList.map((device, index) => {
        const deviceKey = device.device_uuid;
        
        return (
          <Grid
            item
            xs={12}
            sm={12 / (columns > 2 ? 2 : 1)}
            md={columnSpan}
            lg={columnSpan}
            key={index}
          >
            <Card
              className="card-camera"
              sx={{
                backgroundColor: "#1e1e1e",
                color: "#ffffff",
                position: "relative",
              }}
            >
              {device.is_subscribed && device.is_active && device.status === "ok" ? (
                <>
                  <Link
                    to={`/${route}/cameras/live-camera/${device.device_uuid}`}
                    style={{ textDecoration: "none" }}
                  >
                   <CardMedia
                      ref={el => setVideoRef(deviceKey, el)}
                      component={groupUuid === "" ? "img" : "video"}
                      src={
                        groupUuid === ""
                          ? `${baseUrl}api/1/devices/${device.device_uuid}/snapshot_img/?as_attachment=true&authtoken=${token?.authtoken?.token}`
                          : device.live_url
                      }
                      controls={groupUuid !== ""}
                      autoPlay={groupUuid !== ""}
                      muted={groupUuid !== ""}
                      alt={device.name}
                      sx={{
                        height: "auto",
                        width: "100%",
                        aspectRatio: "16 / 9",
                        objectFit: "cover",
                        transition: "visibility 0s, opacity 0.3s ease",
                        "&:hover": { border: "2px solid rgba(59, 180, 74, 0.5)" },
                      }}
                    />
                  </Link>

                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0.5rem",
                      zIndex: 5,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: device.status === "ok" ? "green" : "red",
                          marginRight: 0.5,
                        }}
                      />
                      <Typography variant="caption" sx={{ color: "#fff" }}>
                        {truncateString(device.name, 15)}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", gap: "4px" }}>
                      {showDeleteButtons && (
                        <IconButton
                          size="small"
                          sx={{
                            color: "#ffffff",
                            backgroundColor: "red",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            marginTop: "4px",
                            "&:hover": { backgroundColor: "#d32f2f" },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onDeleteClick) {
                              onDeleteClick(device.device_uuid);
                            }
                          }}
                        >
                          <CloseIcon fontSize="small" sx={{ fontSize: "0.9rem" }} />
                        </IconButton>
                      )}
                      {[
                        ENTERPRISE_ADMIN,
                        MULTI_SITE_ADMIN,
                        SINGLE_SITE_ADMIN,
                      ].includes(userRole) && (
                        <Link
                          to={`/${route}/cameras/details/${device.device_uuid}`}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton
                            size="small"
                            sx={{
                              color: "#ffffff",
                              "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Link>
                      )}
                      <Link
                        to={`/${route}/cameras/notifications/${device.device_uuid}`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          sx={{
                            color:
                              device.recordings_unacknowledged_count > 0
                                ? "red"
                                : "#ffffff",
                            "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                          }}
                        >
                          <Badge
                            color="error"
                            badgeContent={formatBadgeContent(11)}
                            sx={{
                              "& .MuiBadge-badge": {
                                minWidth: ({ badgeContent }) =>
                                  String(badgeContent).length > 1 ? "22px" : "18px",
                                height: ({ badgeContent }) =>
                                  String(badgeContent).length > 1 ? "22px" : "18px",
                                borderRadius: "50%",
                                padding: ({ badgeContent }) =>
                                  String(badgeContent).length > 1 ? "0 4px" : "0",
                                fontSize: ({ badgeContent }) =>
                                  String(badgeContent).length > 2 ? "0.65rem" : "0.7rem",
                                lineHeight: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              },
                            }}
                          >
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      zIndex: 10,
                      visibility: "hidden",
                      opacity: 0,
                      transition: "visibility 0s, opacity 0.3s ease",
                    }}
                    className="navigation-container"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <NavigationIcon
                      position={{ bottom: 10 }}
                      onIconClick={(stateKey, newState) =>
                        handleIconClick(stateKey, newState, device)
                      }
                      iconsToShow={getIconsToShow(device.is_onvif)}
                      deviceData={device}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 0,
                    paddingTop: "56.25%",
                    backgroundColor: "#333333",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "0.5rem",
                      zIndex: 5,
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: "50%",
                          backgroundColor: device.status === "ok" ? "green" : "red",
                          marginRight: 1,
                        }}
                      />
                      <Typography variant="caption" sx={{ color: "#fff" }}>
                        {truncateString(device.name, 15)}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", gap: "1px" }}>
                      {showDeleteButtons && (
                        <IconButton
                          size="small"
                          sx={{
                            color: "#ffffff",
                            backgroundColor: "red",
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            marginTop: "4px",
                            "&:hover": { backgroundColor: "#d32f2f" },
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (onDeleteClick) {
                              onDeleteClick(device.device_uuid);
                            }
                          }}
                        >
                          <CloseIcon fontSize="small" sx={{ fontSize: "0.9rem" }} />
                        </IconButton>
                      )}
                      {[
                        ENTERPRISE_ADMIN,
                        MULTI_SITE_ADMIN,
                        SINGLE_SITE_ADMIN,
                      ].includes(userRole) && (
                        <Link
                          to={`/${route}/cameras/details/${device.device_uuid}`}
                          style={{ textDecoration: "none" }}
                        >
                          <IconButton
                            size="small"
                            sx={{
                              color: "#ffffff",
                              "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                            }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Link>
                      )}
                      <Link
                        to={`/${route}/cameras/notifications/${device.device_uuid}`}
                        style={{ textDecoration: "none" }}
                      >
                        <IconButton
                          size="small"
                          sx={{
                            color:
                              device.recordings_unacknowledged_count > 0
                                ? "red"
                                : "#ffffff",
                            "&:hover": { color: "rgba(59, 180, 74, 0.7)" },
                          }}
                        >
                          <Badge
                            color="error"
                            badgeContent={formatBadgeContent(7)}
                            sx={{
                              "& .MuiBadge-badge": {
                                minWidth: ({ badgeContent }) =>
                                  String(badgeContent).length > 1 ? "22px" : "18px",
                                height: ({ badgeContent }) =>
                                  String(badgeContent).length > 1 ? "22px" : "18px",
                                borderRadius: "50%",
                                padding: ({ badgeContent }) =>
                                  String(badgeContent).length > 1 ? "0 4px" : "0",
                                fontSize: ({ badgeContent }) =>
                                  String(badgeContent).length > 2 ? "0.65rem" : "0.7rem",
                                lineHeight: "18px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              },
                            }}
                          >
                            <NotificationsIcon />
                          </Badge>
                        </IconButton>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VisibilityOffIcon
                      sx={{ color: "#888888", fontSize: 40, marginRight: 1 }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: 12,
                        color: "#888888",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Camera Offline
                    </Typography>
                  </Box>
                </Box>
              )}
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CameraGrid;