import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import DetailsSubmenu from "../../../site-sentry/common/DetailsSubmenu";
import HeaderDashboard from "../../../site-sentry/common/HeaderDashboard";
import RecordingDetailsSidebar from "../../../site-sentry/common/RecordingDetailsSidebar";
import DeleteIcon from "@mui/icons-material/Delete";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import CustomButton from "./components/CustomButton";
import PasswordInput from "./components/PasswordInput";
import Svg from "../../common/Svg";
import { toast } from "react-hot-toast";
import Select from "react-select";
import { getAuthHeaders } from "../../../../helper/utils";
import { Layer, Rect, Stage, Transformer, Image, Text } from "react-konva";
import {
  Grid,
  Typography,
  Checkbox,
  Radio,
  FormControlLabel,
  Button,
  Box,
} from "@mui/material";
import Knob from "../../../../component/knob";

export default function Details({ route }) {
  const getHeaders = () => getAuthHeaders();
  const [rotation, setRotation] = useState(0);
  const [isCanvasRefresh, setIsCanvasRefresh] = useState(false);

  let navigate = useNavigate();
  const { apiRequest } = useUiContext();
  const location = useLocation();
  const { device_uuid } = useParams();
  const [deviceID, setDeviceID] = useState(null);
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [deviceTypeList, setDeviceTypeList] = useState(null);
  const [groups, setGroups] = useState([]);
  const [createGroup, setCreateGroup] = useState("");
  const [isMobotix, setIsMobotix] = useState(false);
  const [openVPNConfigUrl, setOpenVPNConfigUrl] = useState("");
  const [isConfigUrlReady, setIsConfigUrlReady] = useState(false);
  const [oakIsValidMessage, setIsOakValidMessage] = useState(false);
  const oakRegexPattern = /^[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}$/;
  const [formData, setFormData] = useState({
    is_active: "",
    mac: "",
    oak: "",
    cameraLocation: "",
    sync_device_recordings: false,
    group_uuid: [],
    group_detail: [],
    name: "",
    devicePassword: "",
    deviceUsername: "",
    device_type: "",
    sitemap_uuid: "",
    address_uuid: "",
    status: "",
    sitemap_bearing: 0,
    sitemap_x_fraction: 0,
    sitemap_y_fraction: 0,
    camera_default: null,
    enable_screen_overlay: false,
    enable_screen_overlay_date_and_time: false,
    sync_all_events: false,
    sync_event_list: null,
    onvif_host: "",
    onvif_port: "",
    is_onvif: "",
  });
  const [image, setImage] = useState(new window.Image());
  const imageRef = useRef();
  const stageParentDiv = useRef();
  const stageMobileParentDiv = useRef();
  const [dimensions, setDimensions] = useState({ width: "", height: "" });
  const [addresses, setAddresses] = useState([]);
  const [siteMapList, setSiteMapList] = useState([]);
  const [selectedSiteMap, setSelectedSiteMap] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, selectShape] = useState(null);

  const [attrs, setAttrs] = useState({
    id: 1,
    isDragging: false,
    x: 50,
    y: 50,
    rotation: rotation,
    selected: false,
    isActive: true,
  });

  const [isCameraConfigShow, setIsCameraConfigShow] = useState(true);
  const [isShowEventsForSync, setIsShowEventsForSync] = useState(false);

  const [cameraTypes, setCameraTypes] = useState([]);
  const [cameraEventsOptions, setCameraEventsOptions] = useState([
    "All Events",
  ]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const getInputs = () => {
    apiRequest("get", "api_auth/1/form_choices").then((res) => {
      setDeviceTypeList(res.data?.device_types);
    });
  };

  const getOpenVPNConfigUrl = async () => {
    return await apiRequest(
      "get",
      "api/1/devices/openvpn_config_url/",
      "",
      getHeaders(),
      false
    )
      .then((res) => {
        if (res.data?.url) {
          setOpenVPNConfigUrl(res.data.url);
          setIsConfigUrlReady(true);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const oakInputhandleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "oak" && oakRegexPattern.test(e.target.value)) {
      setIsOakValidMessage(false);
      handleChange(e);
    } else {
      setIsOakValidMessage(true);
    }
  };

  useEffect(() => {
    setAttrs({ ...attrs, rotation: rotation });
  }, [rotation]);

  useEffect(() => {
    if (
      stageParentDiv.current?.offsetHeight &&
      stageParentDiv.current?.offsetWidth
    ) {
      setDimensions({
        width: stageParentDiv.current.offsetWidth,
        height: stageParentDiv.current.offsetHeight,
      });
    }
  }, []);

  useEffect(() => {
    if (
      stageMobileParentDiv.current?.offsetHeight &&
      stageMobileParentDiv.current?.offsetWidth
    ) {
      setDimensions({
        width: stageMobileParentDiv.current.offsetWidth,
        height: 550,
      });
    }
  }, []);

  useEffect(() => {
    if (deviceDetails) {
      setFormData({
        ...formData,
        sitemap_bearing: parseInt(attrs.rotation),
        enable_screen_overlay: deviceDetails.enable_screen_overlay,
        enable_screen_overlay_date_and_time:
          deviceDetails.enable_screen_overlay_date_and_time,
        camera_default: deviceDetails.camera_default,
      });
    }
  }, [attrs]);

  useEffect(() => {
    if (location) {
      setDeviceID(location.search.split("=")[1]);
    }
    getGroups();
    getInputs();
    getOpenVPNConfigUrl();
  }, [location]);

  useEffect(() => {
    if (device_uuid) {
      getDeviceDetails(device_uuid);
      eventList(device_uuid);
      setSelectedEvents([]);
    }
  }, [device_uuid]);

  const getGroups = async () => {
    return await apiRequest("get", "api/1/groups/", "", getHeaders(), false)
      .then((res) => {
        if (res.data?.results !== null) {
          setGroups(res.data.results);
        } else {
          console.warn(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Create group API
  const createHandler = (e) => {
    e.preventDefault();
    newGroup(createGroup);
  };

  const newGroup = async (createGroup) => {
    let uid = localStorage.getItem("customeruuid");
    let res = await apiRequest(
      "post",
      "api/1/groups/",
      { customer_uuid: uid, name: createGroup },
      getHeaders(),
      true
    );

    return res;
  };

  useEffect(() => {
    if (deviceDetails && groups) {
      getAddressList();
      setAttrs({
        ...attrs,
        x: deviceDetails.sitemap_x_fraction * dimensions.width,
        y: deviceDetails.sitemap_y_fraction * (dimensions.height / 2),
        rotation: deviceDetails.sitemap_bearing,
        isActive: deviceDetails.is_active ? true : false,
      });

      setFormData({
        ...formData,
        is_active: deviceDetails.is_active,
        name: deviceDetails.name,
        mac: deviceDetails.mac,
        oak: deviceDetails.oak,
        cameraLocation: deviceDetails.name,
        deviceUsername: deviceDetails.username,
        devicePassword: deviceDetails.password,
        device_type: deviceDetails.device_type,
        status: deviceDetails.status,
        sync_device_recordings: deviceDetails.sync_device_recordings,
        group_uuid: deviceDetails.groups.map((group) => group.group_uuid),
        group_detail: groups,
        address_uuid: deviceDetails.address_uuid,
        sitemap_uuid: deviceDetails.sitemap_uuid,
        onvif_host: deviceDetails.onvif_host,
        onvif_port: deviceDetails.onvif_port,
        is_onvif: deviceDetails.is_onvif,
        sitemap_x_fraction: deviceDetails.sitemap_x_fraction,
        sitemap_y_fraction: deviceDetails.sitemap_y_fraction,
      });
      setSelectedSite({
        value: deviceDetails.address_uuid,
        label: deviceDetails.address_name,
      });
      setSelectedSiteMap({
        value: deviceDetails.sitemap_uuid,
        label: deviceDetails.sitemap?.name,
      });
      const img = new window.Image();
      img.src = deviceDetails.sitemap ? deviceDetails.sitemap.image_map : "";
      setImage(img);
    }
  }, [groups, deviceDetails]);

  const eventList = async (id) => {
    await apiRequest(
      "get",
      `api/1/devices/${id}/get_events_list`,
      "",
      getHeaders()
    ).then((res) => {
      if (res.response?.status === 500) {
        setCameraEventsOptions([]);
      } else {
        let data = res.data.event_list.root.eventids;
        let eventList = [];
        if (data && typeof data.eventid !== "string") {
          eventList = res.data.event_list.root.eventids.eventid;
          setCameraEventsOptions((prevState) => {
            return [...prevState, ...eventList];
          });
        }
        if (data && typeof data.eventid === "string") {
          eventList = data.eventid;
          setCameraEventsOptions((prevState) => {
            return [...prevState, eventList];
          });
        }
        if (!data) {
          setCameraEventsOptions([]);
        }
      }
    });
  };

  useEffect(() => {
    if (deviceDetails?.sync_device_recordings) {
      let eventList = [];
      if (deviceDetails?.sync_event_list) {
        eventList = deviceDetails?.sync_event_list;
      } else {
        eventList = cameraEventsOptions.slice(1);
      }
      setSelectedEvents(eventList);
      setFormData((prevState) => {
        return {
          ...prevState,
          sync_event_list: eventList,
          sync_all_events: true,
        };
      });
    }
  }, [cameraEventsOptions]);

  const getDeviceDetails = async (id) => {
    await apiRequest("get", `api/1/devices/${id}`, "", getHeaders(), true).then(
      (res) => {
        setDeviceDetails(res.data);
        setIsMobotix(res.data.is_onvif);

        if (res.data.address_uuid) {
          getDeviceAddressSitemap(res.data.address_uuid, res.data);
        }
        setRotation(res.data.sitemap_bearing);
        let camera_types = [];
        let type = ["South", "West", "North", "East", "Combined"];
        res.data.camera_choices.map((choice, choiceIndex) => {
          if (res.data.device_type === "quad") {
            camera_types.push({
              id: choice[0],
              type: `Stream ${choice[0]} Quad1-${type[choiceIndex]}`,
            });
          } else if (res.data.device_type === "dual") {
            camera_types.push({
              id: choice[0],
              type: `Stream ${choice[0]} Dual1`,
            });
          } else if (res.data.device_type === "single") {
            camera_types.push({
              id: choice[0],
              type: `Single Stream`,
            });
          } else if (res.data.device_type === "max") {
            camera_types.push({
              id: choice[0],
              type: `Stream Max${choice[0]}`,
            });
          }
        });
        setCameraTypes(camera_types);
        if (res.data.sitemap_uuid) {
          setFormData({
            ...formData,
            sitemap_uuid: res.data.sitemap_uuid,
            address_uuid: res.data.address_uuid,
          });
        }
      }
    );
  };

  const getAddressList = async () => {
    const res = await apiRequest(
      "get",
      "api/1/addresses/",
      "",
      getHeaders(),
      true
    );
    if (res) {
      let options = [];
      options.push({
        value: null,
        label: "Select Site",
      });
      res.data?.results.map((ele) => {
        options.push({
          value: ele.address_uuid,
          label: ele.name,
        });
      });
      setAddresses(options);
      if (deviceDetails.address_uuid) {
        let site = options.filter(
          (data) => data.value === deviceDetails.address_uuid
        );
        setSelectedSite(site);
      }
    }
  };

  const getDeviceAddressSitemap = async (
    address_uuid,
    deviceDetail = deviceDetails
  ) => {
    const res = await apiRequest(
      "get",
      `api/1/addresses/${address_uuid}/`,
      "",
      getHeaders(),
      true
    );
    if (res) {
      let options = [];
      options.push({
        value: null,
        label: "Select Site map",
        image: null,
      });
      if (address_uuid) {
        res.data.sitemaps.map((ele) => {
          options.push({
            value: ele.sitemap_uuid,
            label: ele.name,
            image: ele.image_map,
          });
        });
      }
      setSiteMapList(options);
      if (deviceDetail.sitemap) {
        let siteMap = options.filter(
          (data) => data.value === deviceDetail.sitemap.sitemap_uuid
        );
        setSelectedSiteMap(siteMap);
      }
    }
  };

  const deleteHandler = async () => {
    deleteDevice(device_uuid);
  };
  const deleteDevice = async (device_uuid) => {
    const res = await apiRequest(
      "delete",
      `api/1/devices/${device_uuid}/`,
      "",
      getHeaders(),
      true,
      { success: "Camera has been removed", error: "Failed to removed camera" }
    );
    if (res) {
      navigate("/site-sentry/customer/cameras");
    }
  };

  const deviceUpdate = async () => {
    let updatedFormdata = { ...formData };
    if (formData.sync_event_list == null) {
      delete updatedFormdata.sync_event_list;
    }
    await apiRequest(
      "patch",
      `api/1/devices/${device_uuid}/`,
      updatedFormdata,
      getHeaders(),
      true,
      {
        success: "Camera detail has been update",
        error: "Failed to update camera detail",
        showToast: true,
      }
    ).then((res) => {
      getDeviceDetails(device_uuid);
    });
  };

  const deRegisterDevice = async () => {
    const body = {
      name: `${deviceDetails.name}`,
      sitemap_bearing: deviceDetails.sitemap_bearing,
      sitemap_uuid: `${deviceDetails.sitemap_uuid}`,
      sitemap_x_fraction: deviceDetails.sitemap_x_fraction,
      sitemap_y_fraction: deviceDetails.sitemap_y_fraction,
    };
    const res = await apiRequest(
      "post",
      `api/1/devices/${device_uuid}/deregister_device_oak/`,
      body,
      getHeaders(),
      true,
      {
        success: "Camera Device has been deregistered",
        error: "Failed to deregister Camera Device",
      }
    )
      .then(() => {
        toast.success("Camera Device has been deregistered");
      })
      .catch(() => {
        toast.error("Failed to deregister Camera Device");
      });

    return res;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const downloadOpenVPNConfig = () => {
    console.log("Downloading OpenVPN Config...");
    console.log("OpenVPN Config URL:", openVPNConfigUrl);
    const link = document.createElement("a");
    link.href = openVPNConfigUrl;
    const urlParts = openVPNConfigUrl.split("/");
    link.download = urlParts[urlParts.length - 1];
    console.log("Download link created:", link);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log("Download link clicked and removed from document.");
  };

  const testDeviceConnection = async () => {
    setIsLoading(true);

    const requestData = {
      ip: formData.onvif_host, // Updated field name
      username: formData.deviceUsername,
      password: formData.devicePassword,
    };

    console.log("Request Data:", requestData);
    return await apiRequest(
      "post",
      "api/1/devices/test_device_connection/",
      requestData,
      getHeaders(),
      true
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Connection failed");
        }
        console.log("Response:", res);
        setIsLoading(false);
        toast.success("Connection successful");
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
        toast.error("Connection failed");
      });
  };

  const handleRadioChange = (e) => {
    setFormData({ ...formData, group: e.target.value });
  };

  const cameraLink = () => {
    return (
      deviceDetails && (
        <a
          className="btn btn-primary btn-rounded"
          href={`${deviceDetails.axis_config_url}camera/index.html`}
          target="_blank"
          rel="noreferrer"
        >
          Camera Configuration
        </a>
      )
    );
  };

  const addStrobeHornBtn = () => {
    if (!deviceDetails || !device_uuid) return null;

    return (
      <button
        className="btn btn-primary btn-rounded"
        onClick={() =>
          navigate(
            `/site-sentry/customer/cameras/details/${device_uuid}/strobe-horn`,
            {
              state: { deviceDetails },
            }
          )
        }
      >
        Register New Axis D4100/C1310 Device
      </button>
    );
  };

  const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;

  const clearSelectedSiteData = () => {
    setSelectedSite(null);
    setSelectedSiteMap(null);
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
    }
  };

  const handleRangeChange = (value) => {
    setRotation(value);
  };

  const handleEventCheckboxChange = (checked, index) => {
    if (index === 0 && checked) {
      let list = cameraEventsOptions.slice(1);
      setSelectedEvents(list);
      setFormData({
        ...formData,
        sync_device_recordings: true,
        sync_all_events: true,
        sync_event_list: list,
      });
    } else if (index === 0 && !checked) {
      setSelectedEvents([]);
      setFormData({
        ...formData,
        sync_device_recordings: false,
        sync_all_events: false,
        sync_event_list: null,
      });
    } else {
      if (checked) {
        console.log(
          "list 1",
          typeof cameraEventsOptions[index],
          cameraEventsOptions[index]
        );
        setSelectedEvents((prevSelectedEvents) => [
          ...prevSelectedEvents,
          cameraEventsOptions[index],
        ]);
        let syncEventList = formData.sync_event_list
          ? formData.sync_event_list
          : [];
        let array = [...syncEventList, cameraEventsOptions[index]];
        setFormData({
          ...formData,
          sync_device_recordings: true,
          sync_all_events: array.length === cameraEventsOptions.length - 1,
          sync_event_list: array,
        });
      } else {
        const filteredSelectedEvents = selectedEvents.filter(
          (event) => event !== cameraEventsOptions[index]
        );
        setSelectedEvents(filteredSelectedEvents);
        console.log("here filteredSelectedEvents", filteredSelectedEvents);

        let syncEventList = formData.sync_event_list
          ? formData.sync_event_list
          : [];
        const filteredSyncEventList = syncEventList.filter(
          (event) => event !== cameraEventsOptions[index]
        );

        setFormData({
          ...formData,
          sync_device_recordings:
            filteredSelectedEvents.length > 0 ? true : false,
          sync_all_events:
            filteredSelectedEvents.length === cameraEventsOptions.length - 1,
          sync_event_list:
            filteredSyncEventList.length > 0 ? filteredSyncEventList : null,
        });
      }
    }
  };

  const siteMapChange = (e) => {
    const img = new window.Image();
    if (e) {
      img.src = e.image;
      setImage(img);
      setFormData({ ...formData, sitemap_uuid: e.value });
    } else {
      setImage(new window.Image());
    }
  };

  const resetIconPosition = () => {
    if (deviceDetails) {
      // Use the original fraction-based calculation
      const newX = deviceDetails.sitemap_x_fraction * dimensions.width;
      const newY = deviceDetails.sitemap_y_fraction * (dimensions.height / 2);

      setAttrs((prevAttrs) => ({
        ...prevAttrs,
        x: newX,
        y: newY,
        rotation: deviceDetails.sitemap_bearing || 0,
      }));
    }
  };

  return (
    <>
      <div className="d-flex flex-column site-main-wrapper grid-page">
        <HeaderDashboard />
        <div className="flex-grow-1 main-wrapper">
          {/* listing page */}
          <div className="main-page-height devices-page d-flex">
            <RecordingDetailsSidebar
              route={route}
              canvasRefresh={isCanvasRefresh}
              clearSelectedSiteData={clearSelectedSiteData}
              cameraEventsOptions={cameraEventsOptions}
              setCameraEventsOptions={setCameraEventsOptions}
              setSelectedEvents={setSelectedEvents}
            />
            <div className="flex-grow-1">
              <div className="d-flex flex-column">
                {/* Top Header */}
                <div className="camera-header d-flex align-items-center details-header">
                  <div className="dropdown">
                    <button
                      className="btnn"
                      type="button"
                      id="dropdownSorting"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Svg name="camera-active" />
                    </button>
                  </div>
                  <div className="flex-grow-1 available-heading">
                    <h2>{deviceDetails?.name}</h2>
                  </div>
                </div>
                {/* Top Header */}
                <div className="flex-grow-1 details-view">
                  <DetailsSubmenu route={route} />
                  {isAdmin ? (
                    <div className="add-new-device-page">
                      <div className="row add-dvc-row gx-0">
                        <div className="col-xl-12">
                          {/* ## FORM DEVICE */}
                          <div className="theme-form-wrapper">
                            <h4 className="text-white mb-3">Device Details</h4>
                            <div className="d-flex mb__20">
                              <label className="col-form-label text-end"></label>
                              <div className="flex-grow-1">
                                <div className="text-end new-tab-text">
                                  {cameraLink()}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex mb__20">
                              <label
                                htmlFor="protocol"
                                className="col-form-label text-end"
                              >
                                Camera Manufacturer
                              </label>
                              <div className="flex-grow-1 position-relative">
                                <select
                                  className="form-control"
                                  name="address_uuid"
                                  disabled
                                  value={isMobotix ? "mobotix" : "axis"} // If isMobotix is true, set to "mobotix", otherwise "axis"
                                >
                                  <option value="axis">Axis</option>
                                  <option value="mobotix">Mobotix</option>
                                </select>
                              </div>
                            </div>
                            {isMobotix && (
                              <div className="d-flex mb__20">
                                <label className="col-form-label text-end"></label>
                                <div className="flex-grow-1">
                                  <button
                                    className="btn btn-primary btn-rounded"
                                    onClick={downloadOpenVPNConfig}
                                    disabled={!isConfigUrlReady}
                                  >
                                    {isConfigUrlReady
                                      ? "Download OpenVPN Config"
                                      : "Preparing the config file..."}
                                  </button>
                                  <button
                                    className="btn btn-secondary btn-rounded ms-2"
                                    onClick={testDeviceConnection}
                                    disabled={isLoading}
                                  >
                                    {isLoading
                                      ? "Loading..."
                                      : "Test Connection"}
                                  </button>
                                </div>
                              </div>
                            )}
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="deviceName"
                                    className="col-form-label text-end"
                                  >
                                    Device Name
                                  </label>
                                  <div className="flex-grow-1 position-relative">
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      value={formData.name}
                                      className="form-control"
                                      id="deviceName"
                                      name="name"
                                      placeholder="Device Name"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="deviceUsername"
                                    className="col-form-label text-end"
                                  >
                                    Username
                                  </label>
                                  <div className="flex-grow-1">
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      value={formData.deviceUsername}
                                      className="form-control"
                                      id="deviceUsername"
                                      placeholder="Username"
                                      name="deviceUsername"
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="dynamicField1"
                                    className="col-form-label text-end"
                                  >
                                    {isMobotix
                                      ? "Mobotix IP/Host"
                                      : "Serial Number"}
                                  </label>
                                  <div className="flex-grow-1 position-relative">
                                    <input
                                      type="text"
                                      onChange={handleChange}
                                      value={
                                        isMobotix
                                          ? formData.onvif_host
                                          : formData.mac
                                      }
                                      className="form-control"
                                      id="dynamicField1"
                                      name={isMobotix ? "onvif_host" : "mac"}
                                      placeholder={
                                        isMobotix
                                          ? "Mobotix IP/Host"
                                          : "Serial Number"
                                      }
                                    />
                                    <div className="form-text">
                                      {isMobotix
                                        ? "Mobotix IP/Host"
                                        : "MAC Address"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <PasswordInput
                                    value={formData.devicePassword}
                                    onChange={handleChange}
                                    sx={{ width: "100%" }} // Custom width if needed
                                  />
                                </div>
                              </div>
                            </div>
                            {!isMobotix && (
                              <div className="row">
                                <div className="col-xl-6">
                                  <div className="d-flex mb__20">
                                    <label
                                      htmlFor="dynamicField2"
                                      className="col-form-label text-end"
                                    >
                                      Owner Authentication Key
                                    </label>
                                    <div className="flex-grow-1 position-relative">
                                      <input
                                        type="text"
                                        onChange={oakInputhandleChange}
                                        value={formData.oak}
                                        className="form-control"
                                        id="dynamicField2"
                                        name="oak"
                                        placeholder="OAK"
                                      />
                                      <div className="form-text">
                                        "Owner Authentication Key - Format:
                                        XXXX-XXXX-XXXX"
                                      </div>
                                      {!isMobotix && oakIsValidMessage && (
                                        <div className="validation-msg">
                                          Please add valid oak
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <hr />
                            <div className="row">
                              <h4 className="text-white mb-3">Settings</h4>
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="address_uuid"
                                    className="col-form-label text-end"
                                  >
                                    Site
                                  </label>
                                  <div className="flex-grow-1">
                                    <Select
                                      className="form-control react-select-container"
                                      classNamePrefix="react-select"
                                      name="address_uuid"
                                      options={addresses}
                                      value={selectedSite}
                                      onChange={(e) => {
                                        setSelectedSite(e);
                                        if (e.value == null) {
                                          siteMapChange();
                                          setSelectedSiteMap(null);
                                        }
                                        getDeviceAddressSitemap(e.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="address_uuid"
                                    className="col-form-label text-end"
                                  ></label>
                                  <div className="flex-grow-1">
                                    {addStrobeHornBtn()}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="sitemap"
                                    className="col-form-label text-end"
                                  >
                                    Sitemap
                                  </label>
                                  <div className="flex-grow-1">
                                    <Select
                                      className="form-control react-select-container"
                                      classNamePrefix="react-select"
                                      name="sitemap_uuid"
                                      options={siteMapList}
                                      value={selectedSiteMap}
                                      onChange={(e) => {
                                        setSelectedSiteMap(e);
                                        siteMapChange(e);
                                        if (e.value) {
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6">
                                <div className="d-flex mb__20">
                                  <label
                                    htmlFor="deviceType"
                                    className="col-form-label text-end"
                                  >
                                    Device Type
                                  </label>
                                  <div className="flex-grow-1">
                                    <select
                                      className="form-control"
                                      name="device_type"
                                      onChange={handleChange}
                                      value={formData.device_type}
                                    >
                                      <option value="">
                                        Select device type
                                      </option>
                                      {deviceTypeList &&
                                        Object.entries(deviceTypeList).map(
                                          (device, i) => {
                                            return (
                                              <option value={device[0]} key={i}>
                                                {device[1]}
                                              </option>
                                            );
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sync-device-checkbox">
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="syncRecording"
                                  name="sync_device_recordings"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      sync_device_recordings: e.target.checked,
                                    });
                                    handleEventCheckboxChange(
                                      e.target.checked,
                                      0
                                    );
                                  }}
                                  checked={formData.sync_device_recordings}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="syncRecording"
                                >
                                  <p>Sync device recordings</p>
                                  <span>
                                    Copy to Cloud if Storage Subscribed
                                  </span>
                                  <button
                                    className="btn btn-primary btn-rounded"
                                    onClick={() => {
                                      setIsShowEventsForSync(true);
                                    }}
                                  >
                                    Configure events for sync
                                  </button>
                                </label>
                              </div>
                            </div>
                            <hr />
                            <div className="d-flex mb__20 align-items-center">
                              <label className="col-form-label text-end">
                                Device Status
                              </label>
                              <div className="flex-grow-1">
                                <label className="switch" id="isActive">
                                  <input
                                    type="checkbox"
                                    id="isActive"
                                    onChange={(e) =>
                                      setFormData({
                                        ...formData,
                                        is_active: e.target.checked,
                                      })
                                    }
                                    checked={formData.is_active}
                                    defaultChecked
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <hr />
                            <fieldset className="d-flex mb__20 group-checkbox align-items-center">
                              <legend className="col-form-label text-end mt-0">
                                Add this Device to a Group
                              </legend>
                              <div className="flex-grow-1">
                                <div className="d-flex flex-wrap group-checkbox-wrap align-items-center">
                                  {formData.group_detail?.map(
                                    (group, index) => (
                                      <div className="form-check" key={index}>
                                        <input
                                          type="radio"
                                          name="group"
                                          id={group.group_uuid}
                                          value={group.group_uuid}
                                          onChange={handleRadioChange}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor={group.group_uuid}
                                        >
                                          {group.name}
                                        </label>
                                      </div>
                                    )
                                  )}
                                  <div className="form-check">
                                    <button
                                      className="add-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#addModal"
                                    >
                                      <Svg name="add-group" />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <hr />
                            {/* Map and Knob Part */}
                            {/* Map and Components Side-by-Side Layout */}
                            {selectedSite?.value !== null && (
                              <Grid container spacing={3}>
                                {/* Left Side: First 3/12 columns - Event Sync Section */}
                                <Grid item xs={12} md={3}>
                                  {isShowEventsForSync && (
                                    <Box sx={{ mb: 4 }}>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ mb: 2 }}
                                      >
                                        {cameraEventsOptions.length > 0
                                          ? "Select events to sync recordings to cloud"
                                          : "No events"}
                                      </Typography>

                                      {cameraEventsOptions &&
                                        cameraEventsOptions.map(
                                          (event, eventIndex) => (
                                            <FormControlLabel
                                              key={eventIndex}
                                              control={
                                                <Checkbox
                                                  id={`event-${eventIndex}`}
                                                  checked={
                                                    eventIndex === 0
                                                      ? selectedEvents?.length ===
                                                        cameraEventsOptions.length -
                                                          1
                                                      : selectedEvents?.includes(
                                                          event
                                                        )
                                                  }
                                                  onChange={(e) =>
                                                    handleEventCheckboxChange(
                                                      e.target.checked,
                                                      eventIndex
                                                    )
                                                  }
                                                  sx={{
                                                    color: "#3bb44a", // Default color
                                                    "&.Mui-checked": {
                                                      color: "#3bb44a", // Color when checked
                                                    },
                                                  }}
                                                />
                                              }
                                              label={event}
                                              sx={{ display: "block", mt: 1 }}
                                            />
                                          )
                                        )}
                                    </Box>
                                  )}
                                </Grid>

                                {/* Middle: Second 3/12 columns - Camera Configuration Section */}
                                <Grid item xs={12} md={3}>
                                  {isCameraConfigShow && (
                                    <Box>
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Grid
                                            container
                                            justifyContent="space-between"
                                          >
                                            <Grid item>
                                              <Typography variant="subtitle2">
                                                Enter Stream name for overlay
                                              </Typography>
                                              <Typography variant="subtitle2">
                                                Set Default Stream
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </Grid>

                                        {/* Camera Types */}
                                        <Grid item xs={12}>
                                          {cameraTypes.map((type) => (
                                            <FormControlLabel
                                              key={type.id}
                                              control={
                                                <Radio
                                                  id={`stream-${type.id}`}
                                                  checked={
                                                    formData.camera_default ===
                                                    type.id
                                                  }
                                                  onChange={() => {
                                                    setFormData({
                                                      ...formData,
                                                      camera_default: type.id,
                                                    });
                                                  }}
                                                  sx={{
                                                    color: "#3bb44a", // Default color
                                                    "&.Mui-checked": {
                                                      color: "#3bb44a", // Color when checked
                                                    },
                                                  }}
                                                />
                                              }
                                              label={type.type}
                                              className="camera-config"
                                              sx={{ display: "block", mt: 1 }}
                                            />
                                          ))}
                                        </Grid>

                                        {/* Overlay Configuration */}
                                        <Grid item xs={12} sx={{ mt: 4 }}>
                                          <Typography
                                            variant="subtitle2"
                                            sx={{ mb: 2 }}
                                          >
                                            Configure Overlay
                                          </Typography>

                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                id="enable-screen-overlay"
                                                checked={
                                                  formData.enable_screen_overlay
                                                }
                                                onChange={(e) => {
                                                  let checked =
                                                    e.target.checked;
                                                  let dateTimeOverlay = false;
                                                  if (!checked) {
                                                    dateTimeOverlay = checked;
                                                  }
                                                  setFormData({
                                                    ...formData,
                                                    enable_screen_overlay:
                                                      checked,
                                                    enable_screen_overlay_date_and_time:
                                                      dateTimeOverlay,
                                                  });
                                                }}
                                                sx={{
                                                  color: "#3bb44a", // Default color
                                                  "&.Mui-checked": {
                                                    color: "#3bb44a", // Color when checked
                                                  },
                                                }}
                                              />
                                            }
                                            label="Enable Screen Overlay"
                                            sx={{ display: "block", mt: 1 }}
                                          />

                                          {formData.enable_screen_overlay && (
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  id="add-date-time"
                                                  checked={
                                                    formData.enable_screen_overlay_date_and_time
                                                  }
                                                  onChange={(e) => {
                                                    setFormData({
                                                      ...formData,
                                                      enable_screen_overlay_date_and_time:
                                                        e.target.checked,
                                                    });
                                                  }}
                                                  sx={{
                                                    color: "#3bb44a", // Default color
                                                    "&.Mui-checked": {
                                                      color: "#3bb44a", // Color when checked
                                                    },
                                                  }}
                                                />
                                              }
                                              label="Add Date and Time"
                                              sx={{
                                                display: "block",
                                                mt: 1,
                                                ml: 4,
                                              }}
                                            />
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}
                                </Grid>

                                {/* Right Side: Last 6/12 columns - Map View and Knob */}
                                <Grid
                                  item
                                  xs={12}
                                  md={6}
                                  ref={stageMobileParentDiv}
                                >
                                  <Stage
                                    width={dimensions.width / 2}
                                    height={dimensions.height / 2}
                                    onMouseDown={checkDeselect}
                                    onTouchStart={checkDeselect}
                                  >
                                    <Layer>
                                      <Image
                                        width={dimensions.width / 2}
                                        height={dimensions.height / 2}
                                        x={0}
                                        y={0}
                                        image={image}
                                        ref={imageRef}
                                      />
                                      <Draw {...{ attrs, setAttrs }} />
                                    </Layer>
                                  </Stage>

                                  {/* Dial Control */}
                                  <Grid
                                    container
                                    spacing={2}
                                    sx={{ mt: 2, mb: 2, px: 1 }}
                                  >
                                    <Grid item xs={12}>
                                      <Typography variant="subtitle2">
                                        Rotate the dial to change the camera
                                        orientation on the map
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{ textAlign: "center", my: 2 }}
                                    >
                                      <Knob
                                        numTicks={125}
                                        degrees={360}
                                        min={1}
                                        max={360}
                                        value={rotation}
                                        onChange={handleRangeChange}
                                        onTouchStart={handleRangeChange}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sx={{ textAlign: "center", my: 2 }}
                                    >
                                      <Button
                                        className="btn btn-primary text-white"
                                        onClick={resetIconPosition}
                                        variant="outlined"
                                        startIcon={<CenterFocusStrongIcon />}
                                      >
                                        Reset Position
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {/* Map and Knob Part */}
                            <hr />
                            <div className="d-flex pt-3">
                              <div className="flex-grow-1 d-flex justify-content-start">
                                <Button
                                  startIcon={<DeleteIcon />}
                                  color="error"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteModal"
                                >
                                  Remove this Device
                                </Button>
                              </div>
                              <div className="flex-grow-1 d-flex justify-content-end">
                                <CustomButton
                                  text="De-register Device"
                                  color="orange"
                                  className="mr-2"
                                  onClick={() => {
                                    deRegisterDevice();
                                  }}
                                />
                                <CustomButton
                                  text="Save Changes"
                                  color="green"
                                  onClick={deviceUpdate}
                                />
                                <button
                                  className="btn btn-primary btn-rounded mt-3 ms-3"
                                  onClick={() => {
                                    setIsCameraConfigShow(!isCameraConfigShow);
                                  }}
                                >
                                  Configure Overlay Options
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* ## FORM DEVICE */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade messageModal"
        id="successModal"
        tabIndex={-1}
        aria-labelledby="successModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-text text-center success">
                <div className="icon-wrap">
                  <Svg name="success-icon" />
                </div>
                <h2>Success!</h2>
                <p>Your changes has been saved successfully</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade add-modal"
        id="addModal"
        tabIndex={-1}
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {isAdmin ? (
              <div className="modal-header">
                <h5 className="modal-title text-center">Create new group</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <Svg name="close-rounded" />
                </button>
              </div>
            ) : null}
            <div className="modal-body">
              <div className="theme-form-wrapper">
                <form>
                  <div className="d-flex mb__20 align-items-center">
                    <label
                      htmlFor="groupName"
                      className="col-form-label text-end"
                    >
                      Group name
                    </label>
                    <div className="flex-grow-1">
                      <input
                        type="text"
                        className="form-control"
                        id="groupName"
                        onChange={(e) => setCreateGroup(e.target.value)}
                        placeholder="Group name"
                      />
                    </div>
                  </div>
                  <div className="button-wrap text-center">
                    <button
                      className="btn btn-primary btn-rounded"
                      type="button"
                      onClick={createHandler}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-delete"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-wrap">
                <h4>Are you sure you want to delete this device? </h4>
                <div className="note">
                  <div className="d-flex heading">
                    <Svg name="delete-modal-icon" />
                    <div className="flex-grow-1 ps-3">Warning</div>
                  </div>
                  <p>All logs for this device will also be deleted.</p>
                </div>
              </div>
              <div className="button-wrap d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-delete-new"
                  data-bs-dismiss="modal"
                  onClick={deleteHandler}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <svg
        style={{ display: "none" }}
        id="svgDeactive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="#e82929"
      >
        <path d="M80-160v-640h640v275l160-160v410L720-435v275H80Z" />
      </svg>
      <svg
        style={{ display: "none" }}
        id="svgActive"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        fill="#3bb44a"
      >
        <path d="M80-160v-640h640v275l160-160v410L720-435v275H80Z" />
      </svg>
    </>
  );
}

function Draw({ attrs, setAttrs }) {
  const [cameraImg, setCameraImg] = useState(new window.Image());
  const cameraImgRef = useRef();
  const TransformRef = useRef();

  useEffect(() => {
    let svgId = attrs.isActive
      ? document.getElementById("svgActive")
      : document.getElementById("svgDeactive");
    const svgData = encodeURIComponent(
      new XMLSerializer().serializeToString(svgId)
    );
    // const cameraImg1 = new window.Image();
    cameraImg.src = "data:image/svg+xml;charset=utf-8," + svgData;
    setCameraImg(cameraImg);
  }, [attrs]);

  const onDragEnd = ({ target }) => {
    setAttrs({
      ...attrs,
      x: target.x(),
      y: target.y(),
      width: target.width(),
      height: target.height(),
    });
  };

  return (
    <Image
      image={cameraImg}
      ref={cameraImgRef}
      {...attrs}
      draggable={true}
      onDragEnd={onDragEnd}
      height={30}
      width={32}
    />
  );
}
