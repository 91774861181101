import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { baseUrl } from "../constants/common";
import moment from "moment-timezone";

const Context = createContext(null);

export const UiContext = ({ children }) => {
  const [noOfColumns, setNoOfColumns] = useState(4);
  const [noOfColumnsGroupCameras, setNoOfColumnsGroupCameras] = useState(4);
  const LIST_VIEW = "LIST_VIEW";
  const [defaultView, setDefaultView] = useState(LIST_VIEW);
  const [refreshCount, refreshData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [authToken, setAuthToken] = useState({ authtoken: { exp: null } });
  const [headerData, setHeadData] = useState({
    title: localStorage.getItem("sn")
      ? localStorage.getItem("sn")
      : "EYEfi Cloud Connect",
    description: localStorage.getItem("sn")
      ? localStorage.getItem("sn")
      : "EYEfi Cloud Connect",
  });

  // Load saved title from localStorage
  useEffect(() => {
    const savedTitle = localStorage.getItem("sn");
    if (savedTitle) {
      setHeadData({ title: savedTitle, description: savedTitle });
    }
  }, []);

  const authHeaders = () => {
    const token = localStorage.getItem("token");
    const verified = localStorage.getItem("x_verified_jwt");
    let d = {};
    if (token) d.Authorization = `Bearer ${token}`;
    if (verified) d["X-Verified-JWT"] = verified;
    return d;
  };

  const apiRequest = async (
    type,
    path,
    body = "",
    headers = {},
    showLoader = false,
    messages = { success: "", error: "", showToast: true },
    params = {}
  ) => {
    try {
      const resHeaders = headers === null ? authHeaders() : headers;
      if (showLoader) {
        setLoading(true);
      }
      const res = await axios({
        method: type,
        url: path.startsWith(baseUrl) ? path : baseUrl + path,
        params: params,
        data: body,
        headers: resHeaders,
      });
      
      if (showLoader) {
        setLoading(false);
      }
      if (messages.success !== "" && messages.showToast) {
        toast.success(messages.success);
      }
      return res;
    } catch (error) {
      if (showLoader) {
        setLoading(false);
      }
      if (messages.error !== "") {
        toast.error(messages.error);
      }
      return error;
    }
  };

  const isAuthTokenExpiring = (expMinutes = 2) => {
    const tokenExpiry = authToken?.authtoken?.exp;
    const tokenExpMinutes = tokenExpiry
      ? moment.unix(tokenExpiry).diff(moment(), "minutes")
      : 0;
    return tokenExpMinutes < expMinutes;
  };

  const getTokens = async () => {
    return await apiRequest("get", "api_auth/1/auth_token", "", null)
      .then((res) => {
        if (res?.response?.status === 401) {
          console.error(res.toJSON());
          return authToken;
        } else if (res.data?.authtoken) {
          // noinspection JSUnresolvedReference
          setAuthToken(res.data);
          return res.data;
        } else {
          console.error(res.toJSON());
          return authToken;
        }
      })
      .catch((error) => {
        console.error(error?.response);
        if (error?.response?.status === 401) {
          console.error("401 Logout");
          return authToken;
        }
        console.error(error.toJSON());
        return authToken;
      });
  };

  const getAuthToken = async (expMinutes = 2) => {
    if (isAuthTokenExpiring(expMinutes)) {
      return getTokens().then((gotToken) => {
        return gotToken?.authtoken.token;
      });
    } else {
      return authToken.authtoken.token;
    }
  };

  const syncAuthToken = (expMinutes = 2) => {
    if (isAuthTokenExpiring(expMinutes)) {
      getTokens().then((_) => {}); //
    }
    return authToken?.authtoken?.token;
  };

  const useDebounce = (callback, delay) => {
    const timerRef = useRef(null);
    
    const debouncedFunction = (...args) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      
      timerRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
    
    return debouncedFunction;
  };

  /* jshint ignore:start */
  return (
    <Context.Provider
      value={{
        noOfColumns,
        setNoOfColumns,
        noOfColumnsGroupCameras,
        setNoOfColumnsGroupCameras,
        defaultView,
        setDefaultView,
        setLoading,
        loading,
        apiRequest,
        refreshCount,
        refreshData,
        searchFilter,
        setSearchFilter,
        sortBy,
        setSortBy,
        headerData,
        setHeadData,
        getAuthToken,
        syncAuthToken,
        authHeaders,
        useDebounce
      }}
    >
      {children}
    </Context.Provider>
  );
  /* jshint ignore:end */
};

export const useUiContext = () => useContext(Context);
