import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import LiveStatusButton from "./LiveStatusButton";
import NavigationIcon from "./NavigationIcon";
import { getIconsToShow } from "../../../../helper/utils";

const LiveStream = ({
  liveUrl,
  isMobotix,
  openFullscreen,
  openMic,
  openRecord,
  openSound,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const currentVideo = videoRef.current;
  
    return () => {
      if (currentVideo) {
        currentVideo.pause(); // Stop playback
        currentVideo.removeAttribute("src"); // Clear src
        currentVideo.load(); // Reset the video element
      }
  
      if (liveUrl.startsWith("blob:")) {
        setTimeout(() => URL.revokeObjectURL(liveUrl), 1000); // Delayed cleanup to prevent premature revocation
      }
    };
  }, [liveUrl]);
  

  const handleIconClick = (stateKey, newState) => {
    console.log(`Icon ${stateKey} is now ${newState ? "on" : "off"}`);
    // Add additional logic here

    const handlerMap = {
      record: openRecord,
      sound: openSound,
      mic: openMic,
      shield: () => console.log("Shield toggled"),
      dim: () => console.log("Dim toggled"),
      horn: () => console.log("Horn toggled"),
      zoom: openFullscreen, // Special case for non-toggleable Zoom
    };

    // Call the appropriate handler, if defined
    if (handlerMap[stateKey]) {
      handlerMap[stateKey](newState);
    }
  };
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#000",
        height: "100vh",
        width: "100%",
      }}
    >
      <LiveStatusButton variant="outlined" color="green" text="Live" />
      <NavigationIcon
        position={{ top: 10, right: 10 }}
        onIconClick={handleIconClick}
        iconsToShow={(() => {
          const icons = getIconsToShow(isMobotix);
          return icons;
        })()}
      />
      <video
        ref={videoRef}
        src={liveUrl}
        controls
        autoPlay
        style={{
          width: "100%",
          height: "auto",
          aspectRatio: "16/9",
          objectFit: "cover",
        }}
      />
    </Box>
  );
};

export default LiveStream;
