import { getAuthHeaders } from "../../../../../helper/utils";
import { toast } from "react-hot-toast";


const getHeaders = () => getAuthHeaders();

export const getTimezoneService = async (apiRequest) => {
    try {
      const res = await apiRequest("get", "api_auth/1/form_choices", {}, getHeaders(), true, {});
      if (res.status === 200 && res.data.timezones) {
        const timezoneArray = Object.entries(res.data.timezones).map(([id, name]) => ({
          id,
          name
        }));
        toast.success("Data Fetched");
        return timezoneArray;
      }
      return null;
    } catch (error) {
      toast.error("Error fetching form choices:", error);
      return null;
    }
  };

  export const getDeviceTypeService = async (apiRequest) => {
    try {
      const res = await apiRequest("get", "api_auth/1/form_choices", {}, getHeaders(), true, {});
      if (res.status === 200 && res.data.device_types) {
        const deviceTypeList = Object.entries(res.data.device_types).map(([id, name]) => ({
          id,
          name
        }));
        toast.success("Data Fetched");
        return deviceTypeList;
      }
      return null;
    } catch (error) {
      toast.error("Error fetching form choices:", error);
      return null;
    }
  };

  export const getAccessLevelService = async (apiRequest, userRole, setAccessLevelList) => {
    try {
      const res = await apiRequest("get", `api_auth/1/form_choices`, {}, getHeaders(), false, {});
      
      if (res) {
        const response = res.data;
        const rolesPriority = response.user_customer_profile_role_admin_priorities;
        const priority = rolesPriority[userRole];
        const mainRolePrefix = userRole.split('_')[0].toLowerCase(); // Convert to lowercase
        
        let filteredData = rolesPriority;
  
        if (mainRolePrefix !== "enterprise") {
          filteredData = Object.fromEntries(
            Object.entries(rolesPriority).filter(([key, value]) => {
              return value <= priority && key.startsWith(`${mainRolePrefix}_`);
            })
          );
        }
  
        if (setAccessLevelList) {
          setAccessLevelList(filteredData);
        }
  
        return filteredData;
      }
      
      return null;
    } catch (error) {
      console.error("Error fetching access level:", error);
      return null;
    }
  };
  

  export const createCustomerService = async (apiRequest, uid, formData) => {

    try {
      const res = await apiRequest(
        "post",
        `api/1/sellers/${uid}/create_customer/`,
        formData,
        getHeaders(),
        true,
        { success: "Created Successfully", error: "Customer not created" }
      );
      
      if (res?.status === 201) {
        return { success: true };
      }
  
      // Handle validation errors
      if (res?.response?.data?.errors) {
        const errorsData = res.response.data.errors;
        
        return {
          success: false,
          errors: {
            abn: errorsData.abn ? errorsData.abn[0] : "",
            timezone: errorsData.timezone ? errorsData.timezone[0] : "",
            contact_email: errorsData.contact_email ? errorsData.contact_email[0] : "",
          },
        };
      }
    } catch (error) {
      return { success: false, errors: {} };
    }
  
    return { success: false, errors: {} };
  };

  export const getCustomerDataService = async (apiRequest, queryID = "customer_uuid", uid) => {

    try {
      
        const res = await apiRequest('get', `api/1/customers/?${queryID}=${uid}`, '', getHeaders(), true);

        if (res && res.data && res.data.results) {
            return res.data.results.map(data => ({
                ...data,
                seller_type: "customer",
            }));
        }
        toast.success("Data Fetched");
        return [];
    } catch (error) {
        toast.error("Error fetching customer data:", error);
        return [];
    }
};

export const getUserRoleAddressService = async (apiRequest, setRoleAddress) => {
  try {
    const res = await apiRequest(
      "get", 
      "api/1/my-user/", 
      {}, 
      getAuthHeaders(), 
      false, 
      {}
    );

    if (res && res.data && res.data.results && res.data.results.length > 0) {
      const addresses = res.data.results[0].customer_profile.role_addresses;
      
      if (setRoleAddress) {
        setRoleAddress(addresses);
      }
      
      return addresses;
    }

    toast.error("No user role addresses found");
    return [];
  } catch (error) {
    toast.error("Error fetching user role addresses");
    console.error("Error in getUserRoleAddressService:", error);
    return [];
  }
};
  
  