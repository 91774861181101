import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DetailsSubmenu from "../../common/DetailsSubmenu";
import HeaderDashboard from "../../common/HeaderDashboard";
import RecordingDetailsSidebar from "../../common/RecordingDetailsSidebar";
import Svg from "../../common/Svg";
import { useLocation } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import FullScreenModal from "../../common/FullScreenModal";
import LiveStream from "./components/LiveStream";
import { baseUrl } from "../../../../constants/common"; // Import baseUrl
import { getAuthHeaders } from "../../../../helper/utils";

export default function LiveView({ route }) {
  const headers = getAuthHeaders();
  const navigate = useNavigate();
  const { device_uuid } = useParams();
  const [device, setDevice] = useState({});
  const location = useLocation();
  const { apiRequest } = useUiContext();
  const [liveUrl, setLiveUrl] = useState("");
  const [isDeletetingDevice, setIsDeletetingDevice] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [streamViewDropdown, setStreamViewDropdown] = useState([]);
  const [activeStream, setActiveStream] = useState("");
  const [allowedDeviceType, setAllowedDeviceType] = useState("");

  const getLiveVideo = async () => {
    const liveUrl = `${baseUrl}api/public/mjpg_img_src/${device_uuid}/`;
    setLiveUrl(liveUrl);
  };

  useEffect(() => {
    if (device_uuid && location) {
      getLiveVideo().then((_) => {});
    }
  }, [device_uuid, location]);

  const getDevice = async () => {
   
    return await apiRequest(
      "get",
      `api/1/devices/${device_uuid}/`,
      {},
      headers,
      true
    ).then((res) => {
      setDevice(res.data);
      setAllowedDeviceType(res.data.allowed_device_type);
      if (res.data.camera_choices) {
        let camera_choices = res.data.camera_choices;
        setStreamViewDropdown(camera_choices);
        setActiveStream(res.data.camera_default);
      }
    });
  };

  const openFullscreen = () => {
    const image = document.getElementById("fullScreenImage");
    if (!image) {
      console.warn("Element with ID 'image' not found.");
      return;
    }

    const requestFullscreen =
      image.requestFullscreen ||
      image.mozRequestFullScreen ||
      image.webkitRequestFullscreen ||
      image.msRequestFullscreen;

    if (requestFullscreen) {
      requestFullscreen.call(image).catch((error) => {
        console.error("Error attempting to enter fullscreen:", error);
      });
    } else {
      console.warn("Fullscreen API is not supported in this browser.");
      setIsModalOpen(true); // Fallback action
    }
  };

  const deleteDevice = async () => {
   
    setIsDeletetingDevice(true);
    await apiRequest(
      "delete",
      `api/1/devices/${device_uuid}`,
      {},
      headers,
      true,
      { success: "Device deleted successfully", error: "", showToast: true }
    )
      .then((res) => {
        navigate("/site-sentry/customer/cameras");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsDeletetingDevice(false);
      });
  };

  const getLiveStream = async (cameraNumber) => {
    try {
      
      return await apiRequest(
        "get",
        `api/1/devices/${device_uuid}/mjpg_img_src/?camera=${cameraNumber}`,
        {},
        headers,
        true
      ).then((res) => {
        if (res.response.data !== "") {
          let url = res.response.data;
          if (liveUrl !== url) window.stop();
          setLiveUrl(url);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    return () => {
      window.stop();
    };
  }, []);

  useEffect(() => {
    if (device_uuid) {
      getDevice(device_uuid);
    }
  }, [device_uuid]);
  
  const isAdmin = localStorage.getItem("isadmin") === "true";
  return (
    <div className="d-flex flex-column site-main-wrapper grid-page">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        {/* listing page */}
        <div className="main-page-height devices-page d-flex">
          <RecordingDetailsSidebar route={route} />
          <div className="flex-grow-1">
            <div className="d-flex flex-column">
              <div className="camera-header d-flex align-items-center details-header bg-dark">
                {device?.status === "ok" ? (
                  <div className="dropdown">
                    <button
                      className="btnn"
                      type="button"
                      id="dropdownSorting"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Svg name="camera-active" />
                    </button>
                  </div>
                ) : (
                  <div className="dropdown">
                    <button
                      className="btnn"
                      type="button"
                      id="dropdownSorting"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Svg name="camera-inactive" />
                    </button>
                  </div>
                )}
                <div className="flex-grow-1 available-heading">
                  <h2>{device?.name}</h2>
                </div>
                <div className="dropdown-wrap ms-3 me-3">
                  <div className="dropdown">
                    <button
                      className="btn btn-primary btn-rounded"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Stream View
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {streamViewDropdown.map((choice, i) => {
                        return (
                          <li
                            key={i}
                            className={
                              activeStream === choice[0]
                                ? "dropdown-item active"
                                : "dropdown-item"
                            }
                            onClick={() => {
                              // setquadView(false);
                              getLiveStream(choice[0]);
                              setActiveStream(choice[0]);
                            }}
                          >
                            {choice[1]}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {isAdmin && (
                  <div className="button-wrap" style={{ marginRight: "10px" }}>
                    <button
                      onClick={() => {
                        navigate(
                          `/${route}/customer/cameras/details/${device.device_uuid}`
                        );
                      }}
                      className="btn btn-notif ms-1 details-btn btn-setting"
                    >
                      <Svg name="setting" />
                    </button>
                  </div>
                )}
                {isAdmin ? (
                  <div className="button-wrap">
                    <button
                      className="btn btn-delete"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                    >
                      <Svg name="delete-icon" />
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="flex-grow-1 details-view">
                <DetailsSubmenu route={route} />
                <div className="live-vew-page">
                  {device?.status === "ok" ? (
                    <LiveStream
                      liveUrl={liveUrl}
                      isMobotix={device.is_onvif}
                      openFullscreen={openFullscreen}
                      openMic={() => console.log("openMic")} // #TODO - DEL
                      openRecord={() => console.log("openRecord")} // #TODO - DEL
                      openSound={() => console.log("openSound")} // #TODO - DEL
                      deviceData={device} // Pass device data
                    />
                  ) : (
                    <div className="card-camera-body no-subscriptions">
                      <div className="alert alert-primary">
                        {device?.device_description}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-delete"
        id="deleteModal"
        tabIndex={-1}
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="text-wrap">
                <h4>Are you sure you want to delete this device? </h4>
                <div className="note">
                  <div className="d-flex heading">
                    <Svg name="delete-modal-icon" />
                    <div className="flex-grow-1 ps-3">Warning</div>
                  </div>
                  <p>All logs for this device will also be deleted.</p>
                </div>
              </div>
              <div className="button-wrap d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-delete-new"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    deleteDevice();
                  }}
                >
                  {isDeletetingDevice ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FullScreenModal
        isOpen={isModalOpen}
        style={{ width: window.innerWidth, height: window.innerHeight }}
      >
        <img
          id="fullScreenImage"
          src={liveUrl}
          alt="Live"
          style={{ width: "100%", height: "auto" }}
        />
        <button
          type="button"
          className="btn-close"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsModalOpen(false);
          }}
          aria-label="Close"
        >
          <Svg name="close-rounded" />
        </button>
      </FullScreenModal>
    </div>
  );
}
