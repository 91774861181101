import { getAuthHeaders } from "../../../../helper/utils";
import { toast } from "react-hot-toast";

const getHeaders = () => getAuthHeaders();

export const getSitesListService = async (apiRequest, address_uuid) => {
  try {
    const res = await apiRequest(
      "get",
      `api/1/sitemaps/?address_uuid=${address_uuid}`,
      "",
      getHeaders()
    );

    if (res?.status === 200 && res?.data?.results) {
      toast.success("Site Data Fetched");
      return res.data.results;
    }
    return [];
  } catch (error) {
    toast.error("Error fetching site maps:", error);
    return [];
  }
};

export const getAddressList = async (apiRequest) => {
  try {

    const res = await apiRequest("get", "api/1/addresses/", "", getHeaders());

    if (res?.data?.results) {
      return res.data.results;
    } else {
      return []; // Fallback to an empty array if no data is returned
    }
  } catch (error) {
    console.error("Error fetching address list:", error);
    toast.error("Error fetching address list");
    return []; // Ensure state is reset even if API fails
  }
};

export const getGroupsList = async (apiRequest) => {
  try {
    const res = await apiRequest("get", "api/1/groups/", "", getHeaders());

    if (res?.data?.results) {
      return res.data.results;
    } else {
      console.warn("No groups data received", res.data);
      return [];
    }
  } catch (error) {
    console.error("Error fetching groups:", error);
    toast.error("Error fetching groups");
    return [];
  }
};

export const testDeviceConnectionService = async (
  apiRequest,
  formData,
  setIsLoading
) => {
  try {
    setIsLoading(true);
    const requestData = {
      ip: formData.onvif_host, // Updated field name
      username: formData.deviceUsername,
      password: formData.devicePassword,
    };

    const res = await apiRequest(
      "post",
      "api/1/devices/test_device_connection/",
      requestData,
      getHeaders()
    );

    if (res.status === 200) {
      toast.success("Connection successful");
      return res.data;
    } else {
      throw new Error("Connection failed");
    }
  } catch (error) {
    console.error("Error testing device connection:", error);
    toast.error("Connection failed");
    return null; // Return null in case of failure
  } finally {
    setIsLoading(false);
  }
};

export const newGroupService = async (apiRequest, createGroup) => {
  try {
    const customerUuid = localStorage.getItem("customeruuid");

    const res = await apiRequest(
      "post",
      "api/1/groups/",
      { customer_uuid: customerUuid, name: createGroup },
      getHeaders()
    );

    if (res.status === 201) {
      toast.success("Group Added Successfully");
      return res.data;
    } else {
      throw new Error("Group not added");
    }
  } catch (error) {
    console.error("Error adding new group:", error);
    toast.error("Group not added");
    return null;
  }
};

export const deviceUpdate = async (apiRequest, device_uuid, formData) => {
  try {
    let updatedFormData = { ...formData };
    if (formData.sync_event_list == null) {
      delete updatedFormData.sync_event_list;
    }

    const res = await apiRequest(
      "patch",
      `api/1/devices/${device_uuid}/`,
      updatedFormData,
      getHeaders()
    );

    if (res.status === 200) {
      toast.success("Camera details have been updated");
      return res.data;
    } else {
      throw new Error("Failed to update camera details");
    }
  } catch (error) {
    console.error("Error updating camera details:", error);
    toast.error("Failed to update camera details");
    return null;
  }
};

export const deleteDevice = async (apiRequest, device_uuid, navigate) => {
  try {
    const res = await apiRequest(
      "delete",
      `api/1/devices/${device_uuid}/`,
      "",
      getHeaders()
    );

    if (res.status === 200) {
      toast.success("Camera has been removed");
      navigate("/site-sentry/asset-management/cameras"); // Redirect after successful deletion
      return true;
    } else {
      throw new Error("Failed to remove camera");
    }
  } catch (error) {
    console.error("Error deleting camera:", error);
    toast.error("Failed to remove camera");
    return false;
  }
};

// Update your cameraService.js file with this new version of getDeviceDetails
export const getDeviceDetailsService = async (apiRequest, id, setters) => {
  const {
    setDeviceDetails,
    setIsMobotix,
    getDeviceAddressSitemap,
    setRotation,
    setCameraTypes,
    setFormData,
    setImage,
    formData,
  } = setters;

  try {
    const res = await apiRequest(
      "get",
      `api/1/devices/${id}`,
      "",
      getHeaders(),
      true
    );

    if (res?.status === 200 && res?.data) {
      const deviceData = res.data;
      
      setDeviceDetails(deviceData);
      setIsMobotix(deviceData.is_onvif);

      if (deviceData.address_uuid) {
        getDeviceAddressSitemap(deviceData.address_uuid, deviceData);
      }

      setRotation(deviceData.sitemap_bearing);

      // Generate camera types dynamically
      const cameraTypes = [];
      const typeNames = ["South", "West", "North", "East", "Combined"];

      deviceData.camera_choices.forEach((choice, choiceIndex) => {
        const streamId = choice[0];
        const type = deviceData.device_type;

        if (type === "quad") {
          cameraTypes.push({
            id: streamId,
            type: `Stream ${streamId} Quad1-${typeNames[choiceIndex]}`,
          });
        } else if (type === "dual") {
          cameraTypes.push({
            id: streamId,
            type: `Stream ${streamId} Dual1`,
          });
        } else if (type === "single") {
          cameraTypes.push({
            id: streamId,
            type: `Single Stream`,
          });
        } else if (type === "max") {
          cameraTypes.push({
            id: streamId,
            type: `Stream Max${streamId}`,
          });
        }
      });

      setCameraTypes(cameraTypes);

      // Update form data with device details
      if (deviceData.sitemap_uuid) {
        setFormData({
          ...formData,
          sitemap_uuid: deviceData.sitemap_uuid,
          address_uuid: deviceData.address_uuid,
          name: deviceData.name,
          mac: deviceData.mac,
          oak: deviceData.oak,
          deviceUsername: deviceData.username,
          devicePassword: deviceData.password,
          is_active: deviceData.is_active,
          group: deviceData.group ? deviceData.group.group_uuid : "",
          cameraManufacture: deviceData.manufacturer || "axis",
          sync_device_recordings: deviceData.sync_device_recordings || false,
          device_type: deviceData.device_type || "",
          sitemap_bearing: deviceData.sitemap_bearing || 0,
          sitemap_x_fraction: deviceData.sitemap_x_fraction || 0.3,
          sitemap_y_fraction: deviceData.sitemap_y_fraction || 0.3,
          onvif_host: deviceData.onvif_host || "",
          is_onvif: deviceData.is_onvif || false,
        });
        const img = new window.Image();
        img.src = deviceData.sitemap ? deviceData.sitemap.image_map : "";
        setImage(img);
      }
      return deviceData;
    } else {
      throw new Error("Failed to fetch device details");
    }
  } catch (error) {
    console.error("Error fetching device details:", error);
    toast.error("Failed to load device details");
    return null;
  }
};

export const handleEventCheckboxChangeService = (checked, index, options, selectedEvents, setters) => {
  const { setSelectedEvents, setFormData, formData } = setters;
  
  // Case 1: "All Events" checkbox (index 0)
  if (index === 0) {
    const allEventsList = checked ? options.slice(1) : [];
    
    setSelectedEvents(allEventsList);
    setFormData({
      ...formData,
      sync_device_recordings: checked,
      sync_all_events: checked,
      sync_event_list: checked ? allEventsList : null,
    });
    return;
  }
  
  // Case 2: Individual event checkboxes
  const eventToToggle = options[index];
  const updatedEvents = checked
    ? [...selectedEvents, eventToToggle]
    : selectedEvents.filter(event => event !== eventToToggle);
    
  const hasAnyEvents = updatedEvents.length > 0;
  const hasAllEvents = updatedEvents.length === options.length - 1;
  
  setSelectedEvents(updatedEvents);
  setFormData({
    ...formData,
    sync_device_recordings: hasAnyEvents,
    sync_all_events: hasAllEvents,
    sync_event_list: hasAnyEvents ? updatedEvents : null,
  });
};