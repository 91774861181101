import React from "react";
import { FormControl, InputLabel, TextField } from "@mui/material";

export function CustomTextField({
  label,
  value,
  onChange,
  name,
  placeholder,
  type = "text",
  disabled = false,
  required = false, // New required prop
  error = false,
  helperText = "",
  isSubmitted
}) {
  const isError = isSubmitted && required && !value.trim(); // Show error only after submit

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
      <InputLabel
        htmlFor={name}
        style={{ marginRight: "8px", color: "#fff", width: "150px" }}
      >
        {label}
      </InputLabel>
      <FormControl fullWidth>
        <TextField
          id={name}
          name={name}
          placeholder={placeholder ? placeholder : name}
          value={value}
          onChange={onChange}
          type={type}
          variant="outlined"
          disabled={disabled}
          error={isError || error} // Apply error state
          helperText={isError ? `${label} is required` : helperText} // Show required message only on submit
          sx={{
            backgroundColor: disabled ? "#444" : "#333",
            color: "#fff",
            "& .MuiOutlinedInput-root": {
              backgroundColor: disabled ? "#444" : "#333",
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: disabled ? "#666" : "green",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "green",
              },
              "&.Mui-disabled": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#666",
                },
              },
            },
          }}
          InputProps={{ style: { color: "#fff" } }}
        />
      </FormControl>
    </div>
  );
}

export default CustomTextField;