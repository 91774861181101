import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useUiContext } from '../../../../contexts/UiContext';
import HeaderDashboard from '../../common/HeaderDashboard';
import SettingNavigation from '../../common/SettingNavigation';
import Svg from '../../common/Svg';
import { formValidation } from '../../../../helper/common';
import AsyncSelect from 'react-select/async';

export default function CreateSubscription({ route }) {
    let uid = localStorage.getItem("customeruuid");
    const { apiRequest } = useUiContext();
    const [uniqueProductCodes, setUniqueProductCodes] = useState([]);
    const [data, setData] = useState([]);
    const [userId, setUserId] = useState([]);
    const [userIdCustomer, setUserIdCustomer] = useState([]);
    const [userData, setUserData] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(0)
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        subscription: "",
        productType: "",
        id: "",
        customer_uuid:"",
        seller_order_reference:"",
        customer_order_reference:""
    });
    let body = {
        "product": {
            "product_code": formData.productType
        },
        "seller": {
            "seller_uuid": formData.id
        },
        "customer": { "customer_uuid": formData.customer_uuid },
        "subscription_count": formData.subscription,
        "seller_order_reference":formData. seller_order_reference,
        "customer_order_reference":formData.customer_order_reference,
    };

    useEffect(() => {
        Promise.all([
            // getProductData(),
            getSellerData(),
            getSummary(),
            getDistributors()]
        ).then(_ => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const {subscription, productType} = formData
        formValidation({subscription,productType}, setDisabled);
    }, [formData]);


    const getSellerData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-Verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        await apiRequest('get', `api_auth/1/form_choices`, {}, headers, false, {})
        .then((res) => {
            if(res){
                let response = res.data;
                setUserId(response?.user_sellers);
                setUserIdCustomer(response?.user_customers)
            }
        })
        .catch((error) => {
            console.log('error: ',error)
        })
    };

    const createSubs = async () => {
        for(var property in body){
            if(property === 'seller' && body['seller']['seller_uuid'] === ''){
                delete body[property]
            }
            if(property === 'customer' && body['customer']['customer_uuid'] === ''){
                delete body[property]
            }
        }
        const res = await apiRequest('post', `api/1/distributors/${uid}/create_subscriptions/`, body, null, true, { success: 'create Subscription succesfully', error: 'SUbscription not created' });
        if (res) {
            setFormData({
                subscription: "",
                productType: "",
                id: "",
                customer_uuid:"",
                seller_order_reference:"",
                customer_order_reference:""
            });
            await getSummary();
        }
    };

    const getSummary = async () => {
        const res = await apiRequest('get', `api/1/distributors/${uid}/get_summary_subscriptions_not_activated/`, "", null, true);
        if (res) {
            setData(res.data.rows);
        }
    };

    const getDistributors = async () => {
        const res = await apiRequest('get', `api/1/distributors/${uid}/`, "", null, true);
        if (res) {
            setUserData(res.data);
        }
    };

    useEffect(() => {
        setPage(page);
        if(page <= pageCount && page !== 1){
            setIsLoading(true)
            fetchProductOptions(page).then((res) => {
                setOptions(options.concat(res));
                setIsLoading(false)
            })
        }
    }, [page, pageCount])

    const handleMenuScrollToBottom = async () => {
        setPage(page + 1);   
    };    

    useEffect(() => {
        fetchProductOptions(page).then((options) => setOptions(options));
    }, []);

    async function fetchProductOptions(page) {
        const response = await apiRequest('get', `api/1/products/?page=${page}`, "", null, false);
        if (response && response.data) {
            setPageCount(response.data.page_count);
        //   let resultArray = response.data.results;
        //   let unique = [
        //     ...new Set(resultArray.map((item) => item.product_code)),
        //   ];
          return response.data.results.map((product) => ({
            label: product.product_code,
            value: product.product_code,
          }));
        }
    }

    /* jshint ignore:start */
    return (
        <>
            <div className='d-flex flex-column site-main-wrapper setting-body'>
                <HeaderDashboard />
                <div className="flex-grow-1 main-wrapper">
                    <div className="main-page-height settings-page d-flex flex-column">
                        <SettingNavigation />
                        <div className="flex-grow-1 setting-page-wrapper">
                            <div className="container">
                                <div className="user-permissions">
                                    <Link to={`/${route}/unassigned-subscriptions-summary`} className="back-btn d-inline-flex align-items-center">
                                        <Svg name="left-arrow" /> Back
                                    </Link>
                                    <div className="create-user">
                                        {userData.name !== undefined &&
                                            <div className="page-heading">
                                                <h3>Create Subscriptions - Distributor: <small>{userData?.name + "(" + userData?.abn + ")"}</small></h3>
                                            </div>}
                                        <div className="create-user-wrapper">
                                            <div className="form-wrapper theme-form-wrapper">
                                                <div className="mb__20">
                                                    <label className='col-form-label'>Distributor</label>
                                                    <input type="text" className="form-control" placeholder='' value={userData?.name + "(" + userData?.abn + ")"} readOnly />

                                                </div>
                                                <div className="mb__20">
                                                    <label className='col-form-label'>Product</label>
                                                    <AsyncSelect
                                                        styles={{padding: '13px'}}
                                                        className="form-control react-select-container"
                                                        classNamePrefix="react-select"
                                                        defaultOptions={options}
                                                        options={options}
                                                        isSearchable={false}
                                                        isLoading={isLoading}
                                                        onMenuScrollToBottom={handleMenuScrollToBottom}
                                                        onChange={(e) => setFormData({ ...formData, productType: e.value })}
                                                    />
                                                </div>
                                                <div className="mb__20">
                                                    <label className='col-form-label'>Subscription count</label>
                                                    <input type="number" className="form-control" placeholder='' min="1" max="100" onChange={(e) =>
                                                        setFormData({ 
                                                            ...formData, 
                                                            subscription: e.target.value > 100 ? 100 : e.target.value 
                                                        })}
                                                        value={formData.subscription} 
                                                    />
                                                    <span className="form-text mt-1">Minimum: 1 Maximum: 100</span>
                                                </div>

                                                <div className="mb__20">
                                                    <label className='col-form-label'>seller</label>
                                                    <select className="form-control"
                                                        onChange={(e) =>
                                                            setFormData({ 
                                                                ...formData, 
                                                                id: e.target.value === '---' ? '' : e.target.value 
                                                            })
                                                        }
                                                        value={formData.id}>
                                                        <option selected>---</option>
                                                        {userId?.map((res, index) => (<> <option value={res.seller_uuid} key={index}>{res.name + "(" + res.abn + ")"}</option>
                                                        </>))}
                                                    </select>
                                                    <span className="form-text mt-1">To assign subscriptions to</span>
                                                </div>

                                                <div className="mb__20">
                                                    <label className='col-form-label'>Seller order reference</label>
                                                    <input type="text" className="form-control" placeholder='' onChange={(e)=>setFormData({...formData,seller_order_reference:e.target.value})}  value={formData.seller_order_reference} />
                                                    <span className="form-text mt-1">purchase order reference from seller</span>
                                                </div>

                                                <div className="mb__20">
                                                    <label className='col-form-label'>customer</label>
                                                    <select className="form-control"
                                                        onChange={(e) =>
                                                            setFormData({ 
                                                                ...formData, 
                                                                customer_uuid: e.target.value === '---' ? '' : e.target.value 
                                                            })
                                                        }
                                                        value={formData.customer_uuid}>
                                                        <option selected>---</option>
                                                        {userIdCustomer?.map((res, index) => (<> <option value={res.customer_uuid} key={index}>{res.name + "(" + res.abn + ")"}</option>
                                                        </>))}
                                                    </select>
                                                    <span className="form-text mt-1">To assign subscriptions to</span>
                                                </div>

                                                <div className="mb__20">
                                                    <label className='col-form-label'>Customer order reference</label>
                                                    <input type="text" className="form-control" placeholder='' onChange={(e)=>setFormData({...formData,customer_order_reference:e.target.value})}  value={formData.customer_order_reference} />
                                                    <span className="form-text mt-1">purchase order reference from customer</span>
                                                </div>

                                                <div className="button-wrap d-flex flex-wrap align-items-center justify-content-between">
                                                    <button className='btn btn-primary btn-rounded me-3' onClick={createSubs} disabled={disabled}>Create Subscriptions</button>
                                                    <div className="alert alert-primary" role="alert">
                                                        Note: Subscriptions become Billable when Assigned to Customer by Resellers/System Integrators

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div className='user-permissions usr-prm devices-storage-wrapper'>
                                    <div className="page-heading">
                                        <h3>Subscriptions Not Ended Summary</h3>
                                    </div>
                                    <div className="devices-storage-list">
                                        <div className="theme-table">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        {data?.map((rows, i) => (
                                                            <tr key={i}>
                                                                {i === 0 && rows?.map((row, index) => (
                                                                    <th key={index}>{row}  </th>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                    <tbody>
                                                        {data?.map((rows, i) => (
                                                            <tr key={i}>
                                                                {i > 0 && rows?.map((row, index) => (
                                                                    <td key={index}>{row}  </td>

                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    /* jshint ignore:end*/
}
