import { getAuthHeaders } from "../../../../helper/utils";
import { toast } from "react-hot-toast";

const headers = getAuthHeaders();

export const toggleUserActiveStatus = async (
  id,
  active,
  route,
  users,
  apiRequest
) => {
  const endpoints =
    {
      customer: "users",
      reseller: "seller-users",
      distributor: "distributor-users",
    }[route] || "users"; // Default to "users" if route is unknown

  try {
    const res = await apiRequest(
      "patch",
      `api/1/${endpoints}/${users[id].username}/`,
      { is_active: !active },
      headers,
      true,
      { success: "Updated Successfully", error: "User Not Updated" }
    );
    return res;
  } catch (error) {
    console.error("Error updating user status:", error);
    return null;
  }
};

export const deleteUserAccount = async (username, endpoint, apiRequest) => {
  const endpoints =
    {
      sellers: "seller-users",
      distributors: "distributor-users",
    }[endpoint] || "users"; // Default to "users" if endpoint is unknown

  try {
    const res = await apiRequest(
      "delete",
      `api/1/${endpoints}/${username}/`,
      {},
      headers,
      true,
      false
    );

    if (res.status === 204) {
      toast.success("User deleted successfully");
    } else if (res.response?.status === 403) {
      toast.error(res.response.data.detail);
    } else {
      toast.error("Some error occurred, try again after some time");
    }
  } catch (error) {
    console.error("Error deleting user:", error);
  }
};

export const resetUserPassword = async (user, apiRequest) => {
  try {
    await apiRequest(
      "post",
      `api_auth/1/password_reset`,
      { email: user.email },
      headers,
      true,
      { error: "Email not sent" }
    ).then((res) => {
      if (res.status === 200) {
        toast.success(res.data);
      }
    });
  } catch (error) {
    console.log("error: ", error);
  }
};

export const sendMail = async (user, apiRequest) => {
  await apiRequest(
    "post",
    `api_auth/1/resend_welcome`,
    { email: user.email },
    headers,
    false
  )
    .then((res) => {
      if (res.status === 200) {
        toast.success(res.data);
      }
    })
    .catch((error) => {
      toast.error("Email not sent");
      console.log("error", error);
    });
};
