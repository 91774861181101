import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderDashboard from "../../../common/HeaderDashboard";
import SettingNavigation from "../../../common/SettingNavigation";
import Svg from "../../../common/Svg";
import uploadImg from "../../../../../assets/images/camera-3.png";
import { useUiContext } from "../../../../../contexts/UiContext";
import { formValidation } from "../../../../../helper/common";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import { toast } from "react-hot-toast";

export default function AddSiteMap() {
  const location = useLocation();
  let navigate = useNavigate();
  const inputRef = useRef(null);
  const { apiRequest } = useUiContext();
  const [addressDetails, setAddressDetails] = useState(null);
  const [addressID, setAddressID] = useState(null);
  const [fileInputValue, setFileInputValue] = useState('')
  const [formData, setFormData] = useState({
    address: "",
    siteName: "",
    name: "",
    images: null,
    default_sitemap: false,
  });
  const [errors, setErrors] = useState({
    name: "",
  });
  const fileInput = useRef();
  const [disabled, setDisabled] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const getAddressData = async (addressID) => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "get",
      `api/1/addresses/${addressID}/`,
      "",
      headers,
      true
    );
    if (res) {
      setAddressDetails(res.data);
      setFormData({
        ...formData,
        siteName: res.data.name,
        address: res.data.address,
        address_uuid: addressID,
      });
    }
  };

  const handleFileInput = (e) => {
    if(e.target.files.length > 0 && e.target.files[0].type !== "application/pdf"){
      setFileInputValue(e.target.files[0].name);
      setFormData({
        ...formData,
        images: e.target.files[0],
      });
    }else{
      setFileInputValue('');
      setFormData({
        ...formData,
        images: null,
      });
    }
  };  

  const handleClick = () => {
    fileInput.current.click();
  }

  const createSiteMap = async () => {
    // here api call
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    await apiRequest("post", `api/1/sitemaps/`, formData, headers, true)
      .then(async (res) => {
        let statusCode = res.status;
        headers["Content-Type"] = "multipart/form-data";
        if (statusCode === 201) {
          let sitemap_uuid = res.data.sitemap_uuid;
          if (formData.images) {
            let dataToSend = {
              image_map: formData.images,
            };
            await apiRequest(
              "post",
              `api/1/sitemaps/${sitemap_uuid}/upload_img/`,
              dataToSend,
              headers,
              true
            ).then((imageUploadRes) => {
              setFormData({
                ...formData,
                siteName: "",
                name: "",
                images: null,
                default_sitemap: false,
              });
              toast.success("Sitemap added Successfully");
              setErrors({ name: "" });
              fileInput.current.value = "";
            });
          }
          navigate(`/site-sentry/customer/sites/${addressID}/site`);
        } else if (res.response.status === 400) {
          let response = res.response.data;
          if (response.name) {
            setErrors({ ...errors, name: res.response.data.name[0] });
            toast.error("Map name already exists");
          }
          if (response.default_sitemap) {
            toast.error(response.default_sitemap[0]);
          }
        }
      })
      .catch((err) => {
        console.log("Create Sitemap Error: ", err);
        toast.error("Sitemap not added");
      });
  };

  function onDocumentLoadSuccess(e) {
    setNumPages(e.numPages);
  }
  useEffect(() => {
    setAddressID(location.search.split("=")[1]);
  }, [location]);

  useEffect(() => {
    if (addressID) getAddressData(addressID);
  }, [addressID]);

  useEffect(() => {
    const { address, siteName, name, images } = formData;
    formValidation({ address, siteName, name, images }, setDisabled);
  }, [formData]);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
      "pdfjs-dist/build/pdf.worker.min.js",
      import.meta.url
    ).toString();
  }, []);

  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height settings-page d-flex flex-column">
          <SettingNavigation />
          <div className="flex-grow-1 setting-page-wrapper">
            <div className="container">
              <div className="user-permissions">
                <Link
                  to={`/customer/sites/edit?address_uuid=${addressID}`}
                  className="back-btn d-inline-flex align-items-center"
                >
                  <Svg name="left-arrow" /> Back
                </Link>
                <div className="create-user">
                  <div className="page-heading">
                    <h3>Add site map</h3>
                  </div>
                  <div className="create-user-wrapper">
                    <div className="form-wrapper theme-form-wrapper">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb__20 position-relative">
                            <input
                              type="text"
                              placeholder="Enter Address"
                              className="form-control"
                              readOnly
                              // defaultValue={formData.address}
                              // onChange={(e) => {
                              //   setFormData({
                              //     ...formData,
                              //     address: e.target.value,
                              //   });
                              // }}
                              name="address"
                              value={formData.address}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb__20 position-relative">
                            <input
                              type="text"
                              placeholder="Site Name"
                              className="form-control"
                              readOnly
                              name="siteName"
                              // defaultValue={formData.siteName}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  siteName: e.target.value,
                                });
                              }}
                              value={formData.siteName}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="mb__20 position-relative">
                            <input
                              type="text"
                              placeholder="Map Name"
                              className="form-control"
                              name="name"
                              defaultValue={formData.name}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                });
                              }}
                              value={formData.name}
                            />
                            {errors.name !== "" && (
                              <div
                                className="validation-msg"
                                onClick={() =>
                                  setErrors({ ...errors, name: "" })
                                }
                              >
                                {errors.name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="upload-wrapper mb-3">
                        <div className="input-group mb-1">
                          <input
                            style={{display: 'none'}}
                            type="file"
                            ref={fileInput}
                            // multiple
                            accept="image/x-png,image/gif,image/jpeg, application/pdf"
                            className="form-control"
                            id="inputGroupFile02"
                            onChange={handleFileInput}
                          />
                          <input 
                            id="uploadFile"
                            type="text"
                            className="form-control file-input"
                            value={fileInputValue}
                            onClick={handleClick}
                            placeholder="No choosen file"
                            readOnly
                            />
                        <label
                            onClick={handleClick}
                            className="input-group-text file-input"
                          >
                            Upload
                        </label>
                        </div>
                        <p className="upload-info">
                          You can upload map in in JPG, GIF, PNG  format
                        </p>
                      </div>
                      <div className="uploaded-list">
                        <div className="row">
                          {formData.images && (
                            <div className="col-lg-4 mb-3">
                              <div className="uploaded-img">
                                <button
                                  className="remove-icon"
                                  onClick={() => {
                                    fileInput.current.value = "";
                                    setFormData({ ...formData, images: null });
                                    setFileInputValue('')
                                  }}
                                >
                                  <Svg name="times-icon-new" />
                                </button>
                                <div className="img-wrap">
                                  {formData.images.type !==
                                  "application/pdf" ? (
                                    <img
                                      src={URL.createObjectURL(formData.images)}
                                      alt=""
                                    />
                                  ) : null 
                                  }
                                  <div className="form-check">
                                    <input
                                      type="radio"
                                      className="form-check-input"
                                      id={`default_image`}
                                      name="defaultImage"
                                      // defaultChecked={imageIndex == 0}
                                      onChange={(e) => {
                                        if (e.target.value === "on") {
                                          setFormData({
                                            ...formData,
                                            default_sitemap: true,
                                          });
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor={`default_image`}
                                    >
                                      Make this as Default
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="button-wrap text-center text-lg-start">
                        <button
                          type="submit"
                          className="btn btn-primary btn-rounded"
                          onClick={() => {
                            createSiteMap();
                          }}
                        >
                          {" "}
                          Save{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
