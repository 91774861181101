// services/userService.js
import { getAuthHeaders } from "../../../../helper/utils";

export const fetchUsers = async (endpoint, loader, apiRequest, userServerParams) => {
  const getHeaders = () => getAuthHeaders();
  
  try {
    const res = await apiRequest(
      "get",
      `api/1/${
        endpoint === "sellers"
          ? "seller-users"
          : endpoint === "distributors"
          ? "distributor-users"
          : "users"
      }/`,
      "",
      getHeaders(),
      loader,
      {},
      userServerParams
    );
    if (res?.data?.results) {
      return { users: res.data.results, totalPages: res.data.page_count };
    }
  } catch (error) {
    console.error("Error fetching users:", error);
  }

  return { users: [], totalPages: 0 }; // Default empty state
};
