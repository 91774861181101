import React from 'react';

const SiteSentry = () => (
  <svg version="1.1" id="Layer_1"  x="0px" y="0px"
	 width="100%" viewBox="0 0 7654 1685" enableBackground="new 0 0 7654 1685">
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M7656.000000,482.000000 
	C7656.000000,883.961670 7656.000000,1285.923340 7656.000000,1688.000000 
	C5349.258301,1688.000000 3042.516357,1688.000000 734.098694,1687.586914 
	C735.120789,1685.445190 737.627625,1683.250122 740.544189,1682.055542 
	C831.423767,1644.835327 921.127319,1605.099976 1007.603394,1558.359985 
	C1064.266724,1527.733765 1118.148560,1493.021362 1167.649292,1451.643677 
	C1209.868530,1416.352783 1249.543579,1378.470703 1285.348389,1336.668701 
	C1332.619385,1281.480225 1371.421509,1220.838989 1401.510376,1154.635376 
	C1411.771484,1132.058472 1420.210693,1109.058594 1420.314941,1083.866943 
	C1420.811768,963.930969 1421.295044,843.994812 1421.606201,724.058228 
	C1421.654541,705.443298 1420.414185,686.826233 1420.400635,668.209717 
	C1420.294189,522.230591 1420.341553,376.251312 1420.343262,230.272095 
	C1420.343262,227.605911 1420.380127,224.939713 1420.377075,222.273544 
	C1420.361572,208.373062 1421.380371,194.375198 1420.091675,180.593307 
	C1417.737427,155.416397 1404.454956,136.911850 1381.291748,126.696014 
	C1368.567383,121.084190 1354.926147,117.568810 1341.746826,112.969032 
	C1236.058228,76.082390 1128.188232,47.565182 1017.814148,28.729836 
	C952.634644,17.606956 887.031799,9.961883 821.048401,5.628043 
	C817.074219,5.367015 813.069519,5.467740 809.116577,5.046854 
	C807.834717,4.910364 805.524597,4.048814 808.000000,2.000000 
	C3090.425049,2.000000 5372.850098,2.000000 7656.000000,2.000000 
	C7656.000000,158.074448 7656.000000,314.150024 7655.068359,471.222229 
	C7618.804688,472.236786 7583.467285,472.595734 7548.145020,472.033203 
	C7539.301758,471.892365 7534.437012,474.793182 7529.790527,482.094360 
	C7484.924316,552.594971 7439.651367,622.836365 7394.589844,693.212830 
	C7378.802734,717.868958 7362.261230,742.109863 7351.009766,769.374329 
	C7349.314941,773.479553 7347.348145,777.472595 7344.972656,782.692200 
	C7342.711914,778.431152 7341.162598,775.528870 7339.628906,772.618530 
	C7331.250000,756.720276 7323.725098,740.298645 7314.355957,725.006958 
	C7264.097656,642.979919 7213.345215,561.255554 7162.907227,479.338257 
	C7159.777344,474.255035 7156.297852,472.086426 7150.147949,472.132782 
	C7112.819336,472.414185 7075.487305,472.265228 7038.156738,472.294281 
	C7036.008789,472.295959 7033.860840,472.598297 7030.816895,472.828461 
	C7032.008301,475.325653 7032.562012,476.835114 7033.391602,478.173767 
	C7047.944824,501.657776 7062.533691,525.119995 7077.111816,548.588379 
	C7143.681152,655.755005 7210.224121,762.937927 7276.890625,870.043762 
	C7280.391113,875.667786 7281.763184,881.331299 7281.754883,887.910522 
	C7281.621094,993.569214 7281.660156,1099.228149 7281.660645,1204.887085 
	C7281.660645,1208.776367 7281.660645,1212.665649 7281.660645,1216.335571 
	C7295.082520,1218.574341 7394.175293,1218.010986 7401.677734,1215.681396 
	C7401.677734,1199.566162 7401.678223,1183.257202 7401.678223,1166.948364 
	C7401.678223,1073.621826 7401.688965,980.295410 7401.650879,886.968933 
	C7401.648926,881.590576 7402.037598,876.644226 7405.150879,871.765686 
	C7448.493652,803.856873 7491.669434,735.841736 7534.855957,667.833313 
	C7565.194336,620.058105 7595.486816,572.253967 7625.795410,524.460205 
	C7633.996094,511.528412 7642.177246,498.584137 7650.376953,485.651917 
	C7652.274902,482.658356 7652.294434,482.670593 7656.000000,482.000000 
M5313.760742,559.191772 
	C5301.670410,547.758789 5289.485840,536.423279 5277.509766,524.871826 
	C5254.740723,502.910553 5232.127930,480.786835 5209.361816,458.822449 
	C5204.204102,453.846649 5198.684082,449.246521 5193.041992,444.216095 
	C5190.806641,455.760895 5191.225586,1209.642090 5193.526367,1217.595093 
	C5230.109375,1217.560547 5266.896973,1217.671997 5304.692383,1217.519287 
	C5304.692383,1045.953247 5304.692383,875.879578 5304.692383,703.557556 
	C5308.037109,707.618225 5309.947754,709.779602 5311.686035,712.071655 
	C5329.004883,734.911316 5347.538574,756.683472 5368.170410,776.623901 
	C5379.663086,787.731445 5391.086914,798.911316 5402.605957,809.991333 
	C5427.080078,833.533691 5451.598633,857.031128 5476.097168,880.548645 
	C5509.480469,912.595520 5542.857910,944.648682 5576.246582,976.690247 
	C5600.985352,1000.430115 5625.745605,1024.146973 5650.481445,1047.889160 
	C5684.105469,1080.162598 5717.757324,1112.407471 5751.319824,1144.744873 
	C5781.532715,1173.854980 5811.608398,1203.106445 5841.827637,1232.209717 
	C5846.423340,1236.635376 5851.467773,1240.595215 5857.055664,1245.419067 
	C5857.055664,986.966614 5857.055664,730.153931 5857.055664,472.246277 
	C5819.705078,472.384888 5782.977539,472.048492 5745.283203,472.498413 
	C5745.283203,643.991150 5745.283203,814.078674 5745.283203,986.121948 
	C5742.325195,983.023193 5740.705078,981.523865 5739.311523,979.837341 
	C5712.169434,946.985229 5683.893555,915.213318 5652.751465,886.017517 
	C5626.038086,860.973206 5599.880859,835.335144 5573.498047,809.938721 
	C5546.392578,783.846619 5519.324219,757.715698 5492.216309,731.625488 
	C5466.070312,706.460754 5439.839355,681.383911 5413.746582,656.163940 
	C5380.704102,624.226196 5347.762695,592.183167 5313.760742,559.191772 
M7020.349121,1152.630981 
	C6997.501465,1122.339844 6974.693848,1092.018188 6951.794922,1061.766113 
	C6928.694824,1031.248169 6905.313965,1000.940796 6882.405273,970.280090 
	C6859.842773,940.082092 6836.568359,910.397339 6813.776855,878.981628 
	C6836.055664,876.144653 6856.122559,871.634338 6875.328125,863.904846 
	C6917.052246,847.112183 6947.770020,818.846497 6965.724121,777.154419 
	C6978.166504,748.259338 6982.233887,717.751282 6983.585938,686.753113 
	C6984.502441,665.754028 6982.357422,644.889832 6977.765625,624.389404 
	C6969.912109,589.323242 6954.952148,557.798340 6930.039551,531.432556 
	C6902.383789,502.163300 6867.563477,487.017395 6828.666016,479.763702 
	C6796.503906,473.766083 6763.877441,473.119659 6731.396484,472.543518 
	C6676.091797,471.562500 6620.757324,472.262695 6565.435059,472.303406 
	C6562.585938,472.305481 6559.736816,472.746857 6556.325684,473.030426 
	C6556.325684,718.195251 6556.323730,962.443787 6556.346191,1206.692383 
	C6556.346680,1209.675049 6556.647461,1212.658813 6556.855957,1215.638672 
	C6556.874512,1215.900391 6557.241211,1216.146973 6557.464355,1216.382812 
	C6557.692383,1216.624390 6557.943359,1216.844238 6558.008301,1216.906006 
	C6595.701172,1216.906006 6633.213867,1216.906006 6671.876953,1216.906006 
	C6671.876953,1105.762207 6671.876953,995.303589 6671.876953,885.155823 
	C6679.469238,883.179810 6684.081543,884.806213 6688.319824,890.560364 
	C6721.285156,935.318237 6754.584473,979.829895 6787.758789,1024.433960 
	C6834.039062,1086.660400 6880.355469,1148.860352 6926.460449,1211.216187 
	C6930.046875,1216.066162 6933.841309,1217.750732 6939.730469,1217.717041 
	C6979.721191,1217.487793 7019.713867,1217.606567 7059.705566,1217.574341 
	C7062.195312,1217.572388 7064.685059,1217.244263 7069.124512,1216.929932 
	C7052.450684,1194.923462 7036.851562,1174.334595 7020.349121,1152.630981 
M4645.654785,472.276337 
	C4643.449707,472.576904 4641.244629,472.877472 4639.205566,473.155396 
	C4637.097656,489.824829 4637.938477,1209.986938 4640.010254,1216.951172 
	C4777.801758,1216.951172 4915.609863,1216.951172 5053.319336,1216.951172 
	C5055.174805,1204.498291 5054.668457,1116.146729 5052.545898,1108.058838 
	C4955.663574,1108.058838 4858.520996,1108.058838 4760.903320,1108.058838 
	C4760.903320,1028.299683 4760.903320,949.511963 4760.903320,869.596985 
	C4794.023438,869.596985 4826.587891,869.596985 4859.152344,869.596985 
	C4891.477051,869.596985 4923.802246,869.602966 4956.126953,869.595398 
	C4988.644043,869.587769 5021.162598,869.630005 5053.736816,869.567322 
	C5053.736816,832.898865 5053.736816,797.720642 5053.736816,761.597534 
	C5039.239746,761.597534 5025.322266,761.597534 5011.404297,761.597534 
	C4997.407715,761.597534 4983.411621,761.597534 4969.415039,761.597534 
	C4955.418945,761.597534 4941.422363,761.597534 4927.426270,761.597534 
	C4913.763184,761.597534 4900.100098,761.597534 4886.437012,761.597534 
	C4872.440430,761.597534 4858.444336,761.597534 4844.447754,761.597534 
	C4830.451660,761.597534 4816.455078,761.627625 4802.458984,761.589050 
	C4788.618652,761.550903 4774.778320,761.443420 4760.930664,761.366333 
	C4760.930664,699.065369 4760.930664,638.224426 4760.930664,576.104980 
	C4858.909668,576.104980 4956.303223,576.104980 5053.742188,576.104980 
	C5053.742188,540.983826 5053.742188,507.147919 5053.742188,472.273499 
	C4917.945312,472.273499 4782.738770,472.273499 4645.654785,472.276337 
M3321.677246,983.000122 
	C3321.677246,945.465149 3321.677246,907.930115 3321.677246,869.416077 
	C3420.172119,869.416077 3517.339844,869.416077 3615.703125,869.416077 
	C3615.586426,833.027832 3615.866943,797.637390 3615.487061,761.254517 
	C3516.894043,761.254517 3419.706055,761.254517 3322.664795,761.254517 
	C3320.644287,746.533508 3321.329590,583.918762 3323.584717,575.954224 
	C3420.448242,575.954224 3517.601318,575.954224 3614.603516,575.954224 
	C3616.803223,560.355225 3616.099121,479.498138 3613.930176,473.006378 
	C3476.013916,473.006378 3338.173340,473.006378 3199.663818,473.006378 
	C3199.663818,477.375153 3199.661865,481.012329 3199.664307,484.649536 
	C3199.839844,728.293396 3200.015137,971.937195 3200.211914,1215.581055 
	C3200.212158,1215.851318 3200.493408,1216.152222 3200.704590,1216.382812 
	C3200.926270,1216.625366 3201.221680,1216.800415 3201.484863,1217.004883 
	C3222.980713,1218.947266 3607.536621,1218.042603 3614.905029,1216.032349 
	C3616.903809,1199.725830 3616.083740,1115.459473 3613.539795,1107.815186 
	C3516.694336,1107.815186 3419.524414,1107.815186 3321.677246,1107.815186 
	C3321.677246,1066.235840 3321.677246,1025.618042 3321.677246,983.000122 
M1974.643555,457.595215 
	C1931.310791,461.374176 1891.291748,474.280579 1856.513306,501.130280 
	C1823.386353,526.704895 1800.179321,559.183899 1788.868408,599.799683 
	C1782.921753,621.153137 1781.354980,642.902100 1781.570190,664.987122 
	C1782.114990,720.882935 1803.094971,766.920837 1846.134888,802.925354 
	C1878.522949,830.019287 1914.709595,851.102661 1951.935791,870.503784 
	C1979.376709,884.805298 2007.508667,897.789246 2034.845093,912.280457 
	C2056.963867,924.005798 2077.463623,938.292480 2094.021484,957.471191 
	C2109.304932,975.174316 2118.239746,995.064026 2117.825195,1019.192688 
	C2116.910400,1072.412598 2083.463135,1112.008667 2032.171997,1122.007812 
	C2010.742676,1126.185181 1989.324341,1125.749756 1967.894043,1122.274170 
	C1917.903687,1114.166992 1882.463013,1087.569092 1863.640625,1039.973145 
	C1861.464111,1034.469116 1859.513184,1028.875732 1857.227539,1022.701965 
	C1824.520996,1037.885254 1792.394287,1052.799438 1759.140503,1068.236694 
	C1764.440552,1083.359863 1768.595215,1097.743896 1774.431519,1111.409668 
	C1794.523682,1158.455444 1828.363770,1192.312134 1874.749146,1213.746460 
	C1911.046021,1230.518677 1949.682007,1236.534180 1989.142456,1237.337524 
	C2007.666016,1237.714722 2026.358154,1236.257690 2044.774658,1234.003052 
	C2076.702148,1230.094971 2107.260010,1221.119995 2135.368652,1204.965576 
	C2182.043945,1178.140381 2214.479492,1139.631958 2231.120117,1088.149536 
	C2240.338135,1059.630981 2242.757324,1030.356445 2242.470703,1000.532166 
	C2241.914307,942.670837 2219.906982,895.464050 2174.858154,858.947693 
	C2149.640381,838.506348 2122.160400,821.631897 2093.348633,806.959656 
	C2065.475830,792.765442 2036.976318,779.801880 2009.100220,765.613831 
	C1982.605225,752.128723 1957.672852,736.235718 1936.974365,714.421814 
	C1919.075317,695.558228 1908.015259,674.239014 1909.553833,647.265686 
	C1911.253052,617.477722 1923.792725,593.782715 1949.120483,578.406433 
	C1983.762573,557.375488 2021.027222,556.117798 2058.638672,569.278931 
	C2083.737549,578.061707 2101.639648,596.492065 2116.963379,617.575562 
	C2119.638672,621.256470 2122.327393,624.927673 2125.222656,628.895203 
	C2157.385254,612.294250 2188.628662,596.167603 2220.247314,579.847412 
	C2211.910889,556.715454 2200.239990,536.451172 2183.712158,519.161255 
	C2159.630859,493.969513 2129.955322,478.166870 2096.936523,468.092438 
	C2074.236816,461.166473 2050.854736,457.767487 2027.309937,456.538544 
	C2010.428711,455.657379 1993.423950,457.141998 1974.643555,457.595215 
M4233.239746,458.013123 
	C4214.666992,460.044067 4196.481445,463.845184 4178.869141,470.144409 
	C4128.113770,488.298248 4088.824463,520.043396 4064.934082,568.873413 
	C4046.105957,607.356384 4042.240479,648.418213 4047.696777,690.541748 
	C4053.421875,734.738831 4073.714355,771.101746 4107.234863,800.238159 
	C4128.170898,818.436035 4151.338867,833.453613 4175.043457,847.704285 
	C4211.617676,869.691956 4250.414062,887.375488 4288.510742,906.421814 
	C4308.822266,916.576538 4327.994629,928.617371 4344.951660,943.820068 
	C4370.238281,966.489441 4385.520508,993.286926 4381.826660,1028.962158 
	C4376.965820,1075.911011 4346.535645,1109.031372 4304.497559,1120.174927 
	C4282.642090,1125.968384 4260.462891,1126.093018 4238.314941,1123.377563 
	C4217.353027,1120.807495 4197.383789,1114.534424 4179.245605,1103.313721 
	C4151.602051,1086.213135 4134.696289,1060.983032 4124.456055,1030.728149 
	C4123.639160,1028.314697 4122.681641,1025.948608 4121.486816,1022.751404 
	C4088.827148,1037.906616 4056.977539,1052.685669 4025.295898,1067.386963 
	C4025.476074,1069.383545 4025.449463,1070.376343 4025.666504,1071.312622 
	C4036.113525,1116.382080 4057.366455,1155.021606 4093.289551,1184.935913 
	C4124.012695,1210.520142 4159.644043,1225.404541 4198.706055,1231.996094 
	C4226.225098,1236.639893 4253.915527,1238.422607 4282.021973,1236.589355 
	C4316.580078,1234.335083 4350.130371,1228.521973 4381.591309,1214.002441 
	C4444.102051,1185.153198 4484.575684,1137.590454 4500.267578,1070.065552 
	C4506.608398,1042.780151 4508.910645,1015.129395 4506.486328,987.006836 
	C4503.621094,953.770752 4494.059082,923.118286 4474.543457,895.913574 
	C4458.007324,872.861389 4436.363281,855.228394 4413.071289,839.497742 
	C4376.525391,814.815857 4336.973145,795.755981 4297.006836,777.427429 
	C4266.653809,763.507629 4237.642578,747.239563 4212.268555,725.219604 
	C4197.509277,712.410950 4185.045898,697.632690 4178.292969,679.035095 
	C4164.240723,640.337402 4180.023438,583.146301 4240.216309,566.179077 
	C4254.456543,562.164917 4268.984863,561.290710 4283.705078,561.547302 
	C4316.880371,562.125427 4344.284180,575.165222 4366.294434,599.644104 
	C4374.468750,608.734863 4381.910156,618.483704 4390.248047,628.615417 
	C4421.596680,612.386414 4453.071289,596.092102 4484.665527,579.736145 
	C4466.414062,529.668030 4432.328125,496.597382 4384.923828,476.605865 
	C4351.695801,462.592712 4316.737793,456.793213 4280.872070,456.379089 
	C4265.596191,456.202698 4250.306152,457.212921 4233.239746,458.013123 
M6146.326660,1040.999878 
	C6146.326660,1099.528931 6146.326660,1158.057983 6146.326660,1217.063477 
	C6187.483398,1217.063477 6227.303223,1217.063477 6268.352051,1217.063477 
	C6268.352051,1003.466431 6268.352051,790.397034 6268.352051,576.091187 
	C6334.657715,576.091187 6399.747559,576.091187 6465.080566,576.091187 
	C6465.080566,541.028931 6465.080566,507.210693 6465.080566,473.015656 
	C6293.096680,473.015656 6121.706055,473.015656 5950.238281,473.015656 
	C5950.238281,507.612976 5950.238281,541.465149 5950.238281,576.346252 
	C6015.612793,576.346252 6080.420410,576.346252 6146.326660,576.346252 
	C6146.326660,731.251831 6146.326660,885.125793 6146.326660,1040.999878 
M2911.676025,631.000122 
	C2911.676025,613.103394 2911.676025,595.206604 2911.676025,576.296265 
	C2977.636230,576.296265 3042.442383,576.296265 3107.592285,576.296265 
	C3107.592285,541.192017 3107.592285,507.130280 3107.592285,472.862793 
	C2935.717285,472.862793 2764.586182,472.862793 2592.304688,472.862793 
	C2592.403564,507.526093 2592.152832,541.597046 2592.491455,576.275452 
	C2606.841797,576.275452 2619.819092,576.275513 2632.796387,576.275513 
	C2645.793945,576.275513 2658.791748,576.275513 2671.789307,576.275513 
	C2685.120361,576.275513 2698.451416,576.275513 2711.782471,576.275513 
	C2724.780273,576.275513 2737.778564,576.185547 2750.775391,576.300964 
	C2763.591064,576.414795 2776.449951,575.792847 2790.049316,576.736938 
	C2790.049316,790.924438 2790.049316,1003.751099 2790.049316,1216.433472 
	C2803.759521,1218.619263 2904.297363,1218.000977 2911.676025,1215.654907 
	C2911.676025,1021.557251 2911.676025,827.278687 2911.676025,631.000122 
M2497.676514,687.000122 
	C2497.676514,615.779724 2497.676514,544.559265 2497.676514,472.252899 
	C2456.375977,472.359955 2416.313721,472.086884 2376.252441,472.450897 
	C2376.252441,721.435303 2376.252441,968.969971 2376.252441,1216.401123 
	C2388.347900,1218.510010 2490.097412,1218.001099 2497.676514,1215.709961 
	C2497.676514,1040.268921 2497.676514,864.634521 2497.676514,687.000122 
z"/>
<path fill="#010000" opacity="1.000000" stroke="none" 
	d="
M698.000000,2.000000 
	C706.713806,2.000000 715.427612,2.000000 724.937866,2.929142 
	C725.734253,29.059937 725.734253,54.261589 725.734253,80.379982 
	C939.200134,83.329025 1144.410889,124.265045 1344.329712,196.793060 
	C1344.329712,201.136169 1344.329712,205.434372 1344.329712,209.732590 
	C1344.329834,364.339020 1344.308716,518.945435 1344.384277,673.551880 
	C1344.388672,682.499573 1345.472900,691.445679 1345.510010,700.394775 
	C1345.540161,707.676025 1344.389038,714.960388 1344.384155,722.243835 
	C1344.305908,839.864624 1344.302612,957.485474 1344.368774,1075.106323 
	C1344.374634,1085.542358 1343.137207,1095.655151 1339.059692,1105.325439 
	C1323.116455,1143.136353 1303.942139,1179.227417 1281.431885,1213.539307 
	C1242.260620,1273.246948 1194.262085,1325.221558 1140.865479,1372.253662 
	C1087.782715,1419.009399 1030.087524,1459.559326 969.369446,1495.757935 
	C900.377014,1536.889404 828.239685,1571.744019 754.019958,1602.357300 
	C744.570007,1606.255249 735.179565,1610.297363 726.000000,1614.172119 
	C726.000000,1639.397827 726.000000,1663.698853 726.000000,1688.000000 
	C717.286194,1688.000000 708.572388,1688.000000 699.031250,1687.067261 
	C698.203857,1662.209473 698.203857,1638.284302 698.203857,1614.157837 
	C694.638000,1612.580566 691.621704,1611.239624 688.599976,1609.911011 
	C652.678162,1594.117554 616.377747,1579.125366 580.915100,1562.360840 
	C515.245605,1531.316284 451.759399,1496.254395 391.708496,1455.205200 
	C325.008911,1409.611328 262.943207,1358.680542 208.824966,1298.356689 
	C158.392578,1242.141357 117.631683,1179.826660 87.496368,1110.537476 
	C82.078560,1098.080566 79.602493,1085.357666 79.606567,1071.817261 
	C79.693420,783.581909 79.670982,495.346619 79.669830,207.111298 
	C79.669815,203.496490 79.669830,199.881668 79.669830,195.750870 
	C179.145050,158.621262 280.504913,130.425201 384.220825,110.748901 
	C487.770020,91.104225 592.353516,81.212738 698.000000,80.077492 
	C698.000000,53.214397 698.000000,27.607199 698.000000,2.000000 
M950.766357,634.254272 
	C987.309570,658.062866 1023.872620,681.841064 1060.391479,705.687012 
	C1117.547363,743.008423 1174.636719,780.431946 1231.879028,817.620239 
	C1236.639160,820.712830 1238.463257,824.019897 1238.448853,829.708191 
	C1238.264526,903.031677 1238.420898,976.356140 1238.163086,1049.679199 
	C1238.140259,1056.155762 1236.877197,1063.075073 1234.350098,1069.004517 
	C1228.747925,1082.149292 1222.489136,1095.078247 1215.590454,1107.594971 
	C1187.705566,1158.188843 1151.551880,1202.210938 1109.206299,1241.420898 
	C1080.069458,1268.400269 1048.936035,1292.749634 1016.261475,1315.193115 
	C953.449036,1358.337524 885.992798,1392.858643 815.980469,1422.588135 
	C814.287659,1423.306885 812.325806,1423.392212 809.672058,1423.942505 
	C809.672058,1419.276001 809.672058,1415.310425 809.672058,1411.344727 
	C809.671997,1258.364258 809.609558,1105.383667 809.856995,952.403625 
	C809.869324,944.791748 807.648804,940.401733 801.222656,936.357971 
	C777.833496,921.640198 754.846252,906.284058 731.677368,891.214905 
	C730.143860,890.217468 728.358887,889.606812 726.342041,888.650940 
	C726.342041,1119.863892 726.342041,1350.248047 726.342041,1581.637329 
	C729.013123,1580.384766 730.808899,1579.674927 732.479126,1578.740723 
	C838.784424,1519.285767 945.108032,1459.870483 1048.114136,1394.745483 
	C1109.810181,1355.738403 1165.710449,1309.940796 1214.195068,1255.236938 
	C1254.224243,1210.073364 1287.099487,1160.336792 1311.386597,1104.979248 
	C1318.586060,1088.569702 1322.411255,1071.673706 1322.386719,1053.723267 
	C1322.267822,967.067871 1321.993408,880.409424 1322.688477,793.759399 
	C1322.797729,780.142395 1318.753052,772.823486 1307.184814,765.388611 
	C1226.231567,713.360779 1145.914307,660.343872 1065.367676,607.682678 
	C982.872314,553.747253 900.392395,499.787537 817.743408,446.088226 
	C811.686707,442.153046 809.493225,437.985046 809.518250,430.840607 
	C809.770996,358.850708 809.665649,286.859558 809.693665,214.868774 
	C809.694885,211.629990 810.008423,208.391342 810.168823,205.300949 
	C828.139343,202.782288 937.182068,213.421158 1000.679260,224.402679 
	C1081.400879,238.363098 1160.406006,259.075897 1238.348145,286.238220 
	C1238.348145,392.746582 1238.348145,498.996826 1238.348145,604.873352 
	C1247.136597,607.368958 1250.482178,615.690430 1257.763184,619.208008 
	C1265.200073,622.800842 1271.855103,628.019470 1278.827271,632.564819 
	C1286.080688,637.293579 1293.292603,642.085876 1300.543701,646.818054 
	C1307.349731,651.259644 1314.183228,655.658997 1322.335205,660.938660 
	C1322.335205,637.422180 1322.335205,615.894470 1322.335205,594.366760 
	C1322.335083,484.714111 1322.046753,375.060089 1322.570557,265.409943 
	C1322.679443,242.606003 1310.035645,220.425461 1284.223511,211.898773 
	C1274.748047,208.768692 1265.214844,205.811829 1255.758423,202.626099 
	C1194.238159,181.900436 1131.916626,163.964569 1068.171265,151.598770 
	C1026.785400,143.570404 985.144897,136.692902 943.432007,130.569763 
	C916.535034,126.621483 889.328430,124.630463 862.206970,122.419930 
	C845.324463,121.043930 828.347595,120.557228 811.403015,120.272507 
	C784.091431,119.813568 756.771484,119.843246 729.454834,119.711624 
	C728.536987,119.707207 727.616760,120.190620 725.668152,120.733406 
	C725.668152,124.525955 725.668091,128.439255 725.668091,132.352554 
	C725.668030,242.671799 725.649353,352.991028 725.707153,463.310242 
	C725.711365,471.254303 723.819946,480.561859 727.264404,486.759918 
	C730.735229,493.005493 739.687195,496.279846 746.369812,500.654083 
	C814.069275,544.968140 881.795837,589.240784 950.766357,634.254272 
M185.821396,288.186279 
	C188.552139,286.622650 191.114700,284.555634 194.041199,283.577942 
	C211.710159,277.675171 229.383148,271.761688 247.200699,266.330444 
	C318.566559,244.576355 391.177124,228.123489 465.080811,218.058289 
	C504.523560,212.686432 544.289917,209.660126 583.933777,205.817963 
	C593.694824,204.871964 603.547852,204.874802 613.739929,204.424591 
	C613.992676,207.402985 614.300720,209.343414 614.301392,211.283951 
	C614.327148,285.617249 614.292358,359.950592 614.400513,434.283691 
	C614.407532,439.121918 612.859070,441.868927 608.593323,444.615784 
	C572.195618,468.053192 536.023987,491.841736 499.801941,515.551636 
	C452.708984,546.377319 405.653534,577.260376 358.543182,608.059448 
	C275.757996,662.181519 192.992950,716.334839 110.043976,770.204834 
	C103.630943,774.369690 101.469780,778.784424 101.493019,786.325745 
	C101.766243,874.991516 101.740143,963.658569 101.608551,1052.324951 
	C101.581917,1070.268921 104.996315,1087.413574 112.249786,1103.704468 
	C124.859756,1132.025757 139.325607,1159.376221 156.405136,1185.294800 
	C183.629852,1226.608887 215.433350,1264.011108 251.415543,1297.993774 
	C284.411591,1329.156128 319.170410,1358.129639 357.438019,1382.499512 
	C399.547974,1409.316040 441.931793,1435.773926 485.067322,1460.892090 
	C553.505737,1500.744385 622.664612,1539.359497 691.539673,1578.461060 
	C693.382812,1579.507446 695.467041,1580.129272 697.793457,1581.096558 
	C697.793457,1350.068481 697.793457,1119.899414 697.793457,887.797302 
	C669.100525,906.565857 641.666016,924.511169 613.826538,942.721436 
	C613.826538,1103.204590 613.826538,1263.351685 613.826538,1424.406250 
	C610.414307,1423.193970 607.882568,1422.496460 605.521057,1421.424072 
	C582.805786,1411.108765 559.906250,1401.168945 537.472046,1390.272217 
	C499.114655,1371.641113 462.004272,1350.729126 426.285553,1327.384644 
	C369.774109,1290.450806 317.278503,1248.769043 272.491821,1197.905884 
	C239.267242,1160.173706 211.667084,1118.781128 191.402328,1072.702515 
	C188.667007,1066.482788 186.658371,1059.387451 186.547867,1052.660889 
	C185.891083,1012.680908 185.821579,972.690430 185.693878,932.703003 
	C185.587418,899.369995 185.889999,866.033936 185.504288,832.705078 
	C185.417892,825.240479 187.442245,820.703796 193.968674,816.527100 
	C233.795502,791.038879 273.315125,765.070129 312.904877,739.212158 
	C380.378693,695.141602 447.831757,651.039246 515.287720,606.941345 
	C574.104858,568.490967 632.883789,529.981689 691.784790,491.660309 
	C696.441528,488.630585 698.471436,485.435669 698.463013,479.686035 
	C698.290771,362.352997 698.338013,245.019669 698.299011,127.686401 
	C698.298157,125.158806 697.797241,122.631371 697.476196,119.599838 
	C694.146851,119.599838 691.513184,119.626312 688.880188,119.595932 
	C633.295471,118.954590 577.761902,119.965271 522.448547,126.003891 
	C493.407959,129.174286 464.323364,132.276932 435.473297,136.784363 
	C382.250793,145.099640 329.327240,155.285187 277.504333,170.134583 
	C231.158875,183.414474 185.088257,197.691071 139.120697,212.236832 
	C115.294266,219.776367 101.133202,240.452133 101.541229,265.275177 
	C102.056137,296.600037 101.668083,327.939789 101.668213,359.273102 
	C101.668617,455.606384 101.663925,551.939697 101.685150,648.272949 
	C101.685974,652.008545 101.933228,655.744141 102.115341,660.881653 
	C110.705681,655.331116 117.658157,650.847168 124.602013,646.349854 
	C131.587799,641.825439 138.571640,637.297974 145.544083,632.753052 
	C152.520554,628.205566 159.163742,623.010376 166.540421,619.252014 
	C173.753464,615.576965 177.385056,607.671875 185.701508,604.683411 
	C185.701508,499.932129 185.701508,394.957916 185.821396,288.186279 
z"/>
<path fill="#EF592A" opacity="1.000000" stroke="none" 
	d="
M697.062683,2.000000 
	C698.000000,27.607199 698.000000,53.214397 698.000000,80.077492 
	C592.353516,81.212738 487.770020,91.104225 384.220825,110.748901 
	C280.504913,130.425201 179.145050,158.621262 79.669830,195.750870 
	C79.669830,199.881668 79.669815,203.496490 79.669830,207.111298 
	C79.670982,495.346619 79.693420,783.581909 79.606567,1071.817261 
	C79.602493,1085.357666 82.078560,1098.080566 87.496368,1110.537476 
	C117.631683,1179.826660 158.392578,1242.141357 208.824966,1298.356689 
	C262.943207,1358.680542 325.008911,1409.611328 391.708496,1455.205200 
	C451.759399,1496.254395 515.245605,1531.316284 580.915100,1562.360840 
	C616.377747,1579.125366 652.678162,1594.117554 688.599976,1609.911011 
	C691.621704,1611.239624 694.638000,1612.580566 698.203857,1614.157837 
	C698.203857,1638.284302 698.203857,1662.209473 698.101929,1687.067261 
	C696.500000,1688.000000 695.000000,1688.000000 693.383057,1687.445801 
	C691.008057,1685.588013 688.864319,1684.007568 686.475281,1683.021240 
	C603.412720,1648.729126 521.624084,1611.692505 442.106171,1569.755981 
	C401.173553,1548.168701 361.210449,1524.920654 322.563477,1499.454590 
	C258.844513,1457.467529 202.207886,1407.246338 151.320007,1350.554810 
	C100.998360,1294.494141 59.314972,1232.542969 27.488804,1164.166748 
	C17.968981,1143.714233 7.651687,1123.426514 5.328839,1100.393799 
	C5.238010,1099.493164 3.154840,1098.793335 2.000006,1098.000000 
	C2.000000,791.291565 2.000000,484.583099 2.698239,177.326263 
	C4.535251,174.496933 6.035641,172.324219 6.756034,169.917984 
	C12.960112,149.195511 26.139179,134.348053 45.603085,125.587296 
	C58.910778,119.597466 72.767082,114.751244 86.574013,109.958031 
	C177.147797,78.514496 269.348328,53.143566 363.569336,35.076683 
	C438.989136,20.614929 514.986267,11.019349 591.557800,5.694803 
	C595.862976,5.395439 600.196289,5.432990 604.483765,4.994129 
	C605.588074,4.881094 608.233093,4.362834 606.000000,2.000000 
	C636.041809,2.000000 666.083557,2.000000 697.062683,2.000000 
z"/>
<path fill="#EF592A" opacity="1.000000" stroke="none" 
	d="
M726.799988,1688.000000 
	C726.000000,1663.698853 726.000000,1639.397827 726.000000,1614.172119 
	C735.179565,1610.297363 744.570007,1606.255249 754.019958,1602.357300 
	C828.239685,1571.744019 900.377014,1536.889404 969.369446,1495.757935 
	C1030.087524,1459.559326 1087.782715,1419.009399 1140.865479,1372.253662 
	C1194.262085,1325.221558 1242.260620,1273.246948 1281.431885,1213.539307 
	C1303.942139,1179.227417 1323.116455,1143.136353 1339.059692,1105.325439 
	C1343.137207,1095.655151 1344.374634,1085.542358 1344.368774,1075.106323 
	C1344.302612,957.485474 1344.305908,839.864624 1344.384155,722.243835 
	C1344.389038,714.960388 1345.540161,707.676025 1345.510010,700.394775 
	C1345.472900,691.445679 1344.388672,682.499573 1344.384277,673.551880 
	C1344.308716,518.945435 1344.329834,364.339020 1344.329712,209.732590 
	C1344.329712,205.434372 1344.329712,201.136169 1344.329712,196.793060 
	C1144.410889,124.265045 939.200134,83.329025 725.734253,80.379982 
	C725.734253,54.261589 725.734253,29.059937 725.867126,2.929142 
	C752.708435,2.000000 779.416931,2.000000 807.062683,2.000000 
	C805.524597,4.048814 807.834717,4.910364 809.116577,5.046854 
	C813.069519,5.467740 817.074219,5.367015 821.048401,5.628043 
	C887.031799,9.961883 952.634644,17.606956 1017.814148,28.729836 
	C1128.188232,47.565182 1236.058228,76.082390 1341.746826,112.969032 
	C1354.926147,117.568810 1368.567383,121.084190 1381.291748,126.696014 
	C1404.454956,136.911850 1417.737427,155.416397 1420.091675,180.593307 
	C1421.380371,194.375198 1420.361572,208.373062 1420.377075,222.273544 
	C1420.380127,224.939713 1420.343262,227.605911 1420.343262,230.272095 
	C1420.341553,376.251312 1420.294189,522.230591 1420.400635,668.209717 
	C1420.414185,686.826233 1421.654541,705.443298 1421.606201,724.058228 
	C1421.295044,843.994812 1420.811768,963.930969 1420.314941,1083.866943 
	C1420.210693,1109.058594 1411.771484,1132.058472 1401.510376,1154.635376 
	C1371.421509,1220.838989 1332.619385,1281.480225 1285.348389,1336.668701 
	C1249.543579,1378.470703 1209.868530,1416.352783 1167.649292,1451.643677 
	C1118.148560,1493.021362 1064.266724,1527.733765 1007.603394,1558.359985 
	C921.127319,1605.099976 831.423767,1644.835327 740.544189,1682.055542 
	C737.627625,1683.250122 735.120789,1685.445190 733.211487,1687.586914 
	C731.866638,1688.000000 729.733337,1688.000000 726.799988,1688.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M2.000006,1098.937256 
	C3.154840,1098.793335 5.238010,1099.493164 5.328839,1100.393799 
	C7.651687,1123.426514 17.968981,1143.714233 27.488804,1164.166748 
	C59.314972,1232.542969 100.998360,1294.494141 151.320007,1350.554810 
	C202.207886,1407.246338 258.844513,1457.467529 322.563477,1499.454590 
	C361.210449,1524.920654 401.173553,1548.168701 442.106171,1569.755981 
	C521.624084,1611.692505 603.412720,1648.729126 686.475281,1683.021240 
	C688.864319,1684.007568 691.008057,1685.588013 692.633057,1687.445801 
	C462.157257,1688.000000 232.314514,1688.000000 2.000000,1688.000000 
	C2.000000,1491.958496 2.000000,1295.916504 2.000006,1098.937256 
z"/>
<path fill="#F0592A" opacity="1.000000" stroke="none" 
	d="
M7656.000000,481.166687 
	C7652.294434,482.670593 7652.274902,482.658356 7650.376953,485.651917 
	C7642.177246,498.584137 7633.996094,511.528412 7625.795410,524.460205 
	C7595.486816,572.253967 7565.194336,620.058105 7534.855957,667.833313 
	C7491.669434,735.841736 7448.493652,803.856873 7405.150879,871.765686 
	C7402.037598,876.644226 7401.648926,881.590576 7401.650879,886.968933 
	C7401.688965,980.295410 7401.678223,1073.621826 7401.678223,1166.948364 
	C7401.678223,1183.257202 7401.677734,1199.566162 7401.677734,1215.681396 
	C7394.175293,1218.010986 7295.082520,1218.574341 7281.660645,1216.335571 
	C7281.660645,1212.665649 7281.660645,1208.776367 7281.660645,1204.887085 
	C7281.660156,1099.228149 7281.621094,993.569214 7281.754883,887.910522 
	C7281.763184,881.331299 7280.391113,875.667786 7276.890625,870.043762 
	C7210.224121,762.937927 7143.681152,655.755005 7077.111816,548.588379 
	C7062.533691,525.119995 7047.944824,501.657776 7033.391602,478.173767 
	C7032.562012,476.835114 7032.008301,475.325653 7030.816895,472.828461 
	C7033.860840,472.598297 7036.008789,472.295959 7038.156738,472.294281 
	C7075.487305,472.265228 7112.819336,472.414185 7150.147949,472.132782 
	C7156.297852,472.086426 7159.777344,474.255035 7162.907227,479.338257 
	C7213.345215,561.255554 7264.097656,642.979919 7314.355957,725.006958 
	C7323.725098,740.298645 7331.250000,756.720276 7339.628906,772.618530 
	C7341.162598,775.528870 7342.711914,778.431152 7344.972656,782.692200 
	C7347.348145,777.472595 7349.314941,773.479553 7351.009766,769.374329 
	C7362.261230,742.109863 7378.802734,717.868958 7394.589844,693.212830 
	C7439.651367,622.836365 7484.924316,552.594971 7529.790527,482.094360 
	C7534.437012,474.793182 7539.301758,471.892365 7548.145020,472.033203 
	C7583.467285,472.595734 7618.804688,472.236786 7655.068359,472.109436 
	C7656.000000,474.777771 7656.000000,477.555573 7656.000000,481.166687 
z"/>
<path fill="#010100" opacity="1.000000" stroke="none" 
	d="
M605.062683,2.000000 
	C608.233093,4.362834 605.588074,4.881094 604.483765,4.994129 
	C600.196289,5.432990 595.862976,5.395439 591.557800,5.694803 
	C514.986267,11.019349 438.989136,20.614929 363.569336,35.076683 
	C269.348328,53.143566 177.147797,78.514496 86.574013,109.958031 
	C72.767082,114.751244 58.910778,119.597466 45.603085,125.587296 
	C26.139179,134.348053 12.960112,149.195511 6.756034,169.917984 
	C6.035641,172.324219 4.535251,174.496933 2.698239,176.388947 
	C2.000000,118.065285 2.000000,60.130573 2.000000,2.000000 
	C202.708420,2.000000 403.416901,2.000000 605.062683,2.000000 
z"/>
<path fill="#F05A2A" opacity="1.000000" stroke="none" 
	d="
M5314.269043,559.688721 
	C5347.762695,592.183167 5380.704102,624.226196 5413.746582,656.163940 
	C5439.839355,681.383911 5466.070312,706.460754 5492.216309,731.625488 
	C5519.324219,757.715698 5546.392578,783.846619 5573.498047,809.938721 
	C5599.880859,835.335144 5626.038086,860.973206 5652.751465,886.017517 
	C5683.893555,915.213318 5712.169434,946.985229 5739.311523,979.837341 
	C5740.705078,981.523865 5742.325195,983.023193 5745.283203,986.121948 
	C5745.283203,814.078674 5745.283203,643.991150 5745.283203,472.498413 
	C5782.977539,472.048492 5819.705078,472.384888 5857.055664,472.246277 
	C5857.055664,730.153931 5857.055664,986.966614 5857.055664,1245.419067 
	C5851.467773,1240.595215 5846.423340,1236.635376 5841.827637,1232.209717 
	C5811.608398,1203.106445 5781.532715,1173.854980 5751.319824,1144.744873 
	C5717.757324,1112.407471 5684.105469,1080.162598 5650.481445,1047.889160 
	C5625.745605,1024.146973 5600.985352,1000.430115 5576.246582,976.690247 
	C5542.857910,944.648682 5509.480469,912.595520 5476.097168,880.548645 
	C5451.598633,857.031128 5427.080078,833.533691 5402.605957,809.991333 
	C5391.086914,798.911316 5379.663086,787.731445 5368.170410,776.623901 
	C5347.538574,756.683472 5329.004883,734.911316 5311.686035,712.071655 
	C5309.947754,709.779602 5308.037109,707.618225 5304.692383,703.557556 
	C5304.692383,875.879578 5304.692383,1045.953247 5304.692383,1217.519287 
	C5266.896973,1217.671997 5230.109375,1217.560547 5193.526367,1217.595093 
	C5191.225586,1209.642090 5190.806641,455.760895 5193.041992,444.216095 
	C5198.684082,449.246521 5204.204102,453.846649 5209.361816,458.822449 
	C5232.127930,480.786835 5254.740723,502.910553 5277.509766,524.871826 
	C5289.485840,536.423279 5301.670410,547.758789 5314.269043,559.688721 
z"/>
<path fill="#F0592A" opacity="1.000000" stroke="none" 
	d="
M7020.800781,1153.188354 
	C7036.851562,1174.334595 7052.450684,1194.923462 7069.124512,1216.929932 
	C7064.685059,1217.244263 7062.195312,1217.572388 7059.705566,1217.574341 
	C7019.713867,1217.606567 6979.721191,1217.487793 6939.730469,1217.717041 
	C6933.841309,1217.750732 6930.046875,1216.066162 6926.460449,1211.216187 
	C6880.355469,1148.860352 6834.039062,1086.660400 6787.758789,1024.433960 
	C6754.584473,979.829895 6721.285156,935.318237 6688.319824,890.560364 
	C6684.081543,884.806213 6679.469238,883.179810 6671.876953,885.155823 
	C6671.876953,995.303589 6671.876953,1105.762207 6671.876953,1216.906006 
	C6633.213867,1216.906006 6595.701172,1216.906006 6558.008301,1216.906006 
	C6557.943359,1216.844238 6557.692383,1216.624390 6557.464355,1216.382812 
	C6557.241211,1216.146973 6556.874512,1215.900391 6556.855957,1215.638672 
	C6556.647461,1212.658813 6556.346680,1209.675049 6556.346191,1206.692383 
	C6556.323730,962.443787 6556.325684,718.195251 6556.325684,473.030426 
	C6559.736816,472.746857 6562.585938,472.305481 6565.435059,472.303406 
	C6620.757324,472.262695 6676.091797,471.562500 6731.396484,472.543518 
	C6763.877441,473.119659 6796.503906,473.766083 6828.666016,479.763702 
	C6867.563477,487.017395 6902.383789,502.163300 6930.039551,531.432556 
	C6954.952148,557.798340 6969.912109,589.323242 6977.765625,624.389404 
	C6982.357422,644.889832 6984.502441,665.754028 6983.585938,686.753113 
	C6982.233887,717.751282 6978.166504,748.259338 6965.724121,777.154419 
	C6947.770020,818.846497 6917.052246,847.112183 6875.328125,863.904846 
	C6856.122559,871.634338 6836.055664,876.144653 6813.776855,878.981628 
	C6836.568359,910.397339 6859.842773,940.082092 6882.405273,970.280090 
	C6905.313965,1000.940796 6928.694824,1031.248169 6951.794922,1061.766113 
	C6974.693848,1092.018188 6997.501465,1122.339844 7020.800781,1153.188354 
M6678.331543,702.999878 
	C6678.331543,731.534363 6678.331543,760.068848 6678.331543,788.958618 
	C6681.548340,789.249512 6683.483398,789.610657 6685.410156,789.569946 
	C6706.355957,789.127136 6727.341797,789.279419 6748.232910,787.947510 
	C6769.115234,786.616150 6789.785645,783.402405 6809.691406,776.223450 
	C6830.572266,768.693054 6844.496094,754.418579 6851.547852,733.688416 
	C6860.678223,706.844360 6860.418457,679.467102 6854.525879,651.999390 
	C6850.430664,632.909973 6842.017090,616.174072 6826.370117,603.946838 
	C6812.143555,592.829590 6795.319824,587.571899 6777.902344,584.002991 
	C6746.411621,577.550415 6714.512207,577.158508 6682.557617,577.722778 
	C6681.348633,577.744141 6680.149902,578.381348 6678.331543,578.910645 
	C6678.331543,619.803345 6678.331543,660.401550 6678.331543,702.999878 
z"/>
<path fill="#F0592A" opacity="1.000000" stroke="none" 
	d="
M4646.593750,472.274902 
	C4782.738770,472.273499 4917.945312,472.273499 5053.742188,472.273499 
	C5053.742188,507.147919 5053.742188,540.983826 5053.742188,576.104980 
	C4956.303223,576.104980 4858.909668,576.104980 4760.930664,576.104980 
	C4760.930664,638.224426 4760.930664,699.065369 4760.930664,761.366333 
	C4774.778320,761.443420 4788.618652,761.550903 4802.458984,761.589050 
	C4816.455078,761.627625 4830.451660,761.597534 4844.447754,761.597534 
	C4858.444336,761.597534 4872.440430,761.597534 4886.437012,761.597534 
	C4900.100098,761.597534 4913.763184,761.597534 4927.426270,761.597534 
	C4941.422363,761.597534 4955.418945,761.597534 4969.415039,761.597534 
	C4983.411621,761.597534 4997.407715,761.597534 5011.404297,761.597534 
	C5025.322266,761.597534 5039.239746,761.597534 5053.736816,761.597534 
	C5053.736816,797.720642 5053.736816,832.898865 5053.736816,869.567322 
	C5021.162598,869.630005 4988.644043,869.587769 4956.126953,869.595398 
	C4923.802246,869.602966 4891.477051,869.596985 4859.152344,869.596985 
	C4826.587891,869.596985 4794.023438,869.596985 4760.903320,869.596985 
	C4760.903320,949.511963 4760.903320,1028.299683 4760.903320,1108.058838 
	C4858.520996,1108.058838 4955.663574,1108.058838 5052.545898,1108.058838 
	C5054.668457,1116.146729 5055.174805,1204.498291 5053.319336,1216.951172 
	C4915.609863,1216.951172 4777.801758,1216.951172 4640.010254,1216.951172 
	C4637.938477,1209.986938 4637.097656,489.824829 4639.205566,473.155396 
	C4641.244629,472.877472 4643.449707,472.576904 4646.593750,472.274902 
z"/>
<path fill="#F0592A" opacity="1.000000" stroke="none" 
	d="
M3321.677246,984.000122 
	C3321.677246,1025.618042 3321.677246,1066.235840 3321.677246,1107.815186 
	C3419.524414,1107.815186 3516.694336,1107.815186 3613.539795,1107.815186 
	C3616.083740,1115.459473 3616.903809,1199.725830 3614.905029,1216.032349 
	C3607.536621,1218.042603 3222.980713,1218.947266 3201.484863,1217.004883 
	C3201.221680,1216.800415 3200.926270,1216.625366 3200.704590,1216.382812 
	C3200.493408,1216.152222 3200.212158,1215.851318 3200.211914,1215.581055 
	C3200.015137,971.937195 3199.839844,728.293396 3199.664307,484.649536 
	C3199.661865,481.012329 3199.663818,477.375153 3199.663818,473.006378 
	C3338.173340,473.006378 3476.013916,473.006378 3613.930176,473.006378 
	C3616.099121,479.498138 3616.803223,560.355225 3614.603516,575.954224 
	C3517.601318,575.954224 3420.448242,575.954224 3323.584717,575.954224 
	C3321.329590,583.918762 3320.644287,746.533508 3322.664795,761.254517 
	C3419.706055,761.254517 3516.894043,761.254517 3615.487061,761.254517 
	C3615.866943,797.637390 3615.586426,833.027832 3615.703125,869.416077 
	C3517.339844,869.416077 3420.172119,869.416077 3321.677246,869.416077 
	C3321.677246,907.930115 3321.677246,945.465149 3321.677246,984.000122 
z"/>
<path fill="#F05A2A" opacity="1.000000" stroke="none" 
	d="
M1975.558960,457.583191 
	C1993.423950,457.141998 2010.428711,455.657379 2027.309937,456.538544 
	C2050.854736,457.767487 2074.236816,461.166473 2096.936523,468.092438 
	C2129.955322,478.166870 2159.630859,493.969513 2183.712158,519.161255 
	C2200.239990,536.451172 2211.910889,556.715454 2220.247314,579.847412 
	C2188.628662,596.167603 2157.385254,612.294250 2125.222656,628.895203 
	C2122.327393,624.927673 2119.638672,621.256470 2116.963379,617.575562 
	C2101.639648,596.492065 2083.737549,578.061707 2058.638672,569.278931 
	C2021.027222,556.117798 1983.762573,557.375488 1949.120483,578.406433 
	C1923.792725,593.782715 1911.253052,617.477722 1909.553833,647.265686 
	C1908.015259,674.239014 1919.075317,695.558228 1936.974365,714.421814 
	C1957.672852,736.235718 1982.605225,752.128723 2009.100220,765.613831 
	C2036.976318,779.801880 2065.475830,792.765442 2093.348633,806.959656 
	C2122.160400,821.631897 2149.640381,838.506348 2174.858154,858.947693 
	C2219.906982,895.464050 2241.914307,942.670837 2242.470703,1000.532166 
	C2242.757324,1030.356445 2240.338135,1059.630981 2231.120117,1088.149536 
	C2214.479492,1139.631958 2182.043945,1178.140381 2135.368652,1204.965576 
	C2107.260010,1221.119995 2076.702148,1230.094971 2044.774658,1234.003052 
	C2026.358154,1236.257690 2007.666016,1237.714722 1989.142456,1237.337524 
	C1949.682007,1236.534180 1911.046021,1230.518677 1874.749146,1213.746460 
	C1828.363770,1192.312134 1794.523682,1158.455444 1774.431519,1111.409668 
	C1768.595215,1097.743896 1764.440552,1083.359863 1759.140503,1068.236694 
	C1792.394287,1052.799438 1824.520996,1037.885254 1857.227539,1022.701965 
	C1859.513184,1028.875732 1861.464111,1034.469116 1863.640625,1039.973145 
	C1882.463013,1087.569092 1917.903687,1114.166992 1967.894043,1122.274170 
	C1989.324341,1125.749756 2010.742676,1126.185181 2032.171997,1122.007812 
	C2083.463135,1112.008667 2116.910400,1072.412598 2117.825195,1019.192688 
	C2118.239746,995.064026 2109.304932,975.174316 2094.021484,957.471191 
	C2077.463623,938.292480 2056.963867,924.005798 2034.845093,912.280457 
	C2007.508667,897.789246 1979.376709,884.805298 1951.935791,870.503784 
	C1914.709595,851.102661 1878.522949,830.019287 1846.134888,802.925354 
	C1803.094971,766.920837 1782.114990,720.882935 1781.570190,664.987122 
	C1781.354980,642.902100 1782.921753,621.153137 1788.868408,599.799683 
	C1800.179321,559.183899 1823.386353,526.704895 1856.513306,501.130280 
	C1891.291748,474.280579 1931.310791,461.374176 1975.558960,457.583191 
z"/>
<path fill="#F05A2A" opacity="1.000000" stroke="none" 
	d="
M4234.130859,457.848511 
	C4250.306152,457.212921 4265.596191,456.202698 4280.872070,456.379089 
	C4316.737793,456.793213 4351.695801,462.592712 4384.923828,476.605865 
	C4432.328125,496.597382 4466.414062,529.668030 4484.665527,579.736145 
	C4453.071289,596.092102 4421.596680,612.386414 4390.248047,628.615417 
	C4381.910156,618.483704 4374.468750,608.734863 4366.294434,599.644104 
	C4344.284180,575.165222 4316.880371,562.125427 4283.705078,561.547302 
	C4268.984863,561.290710 4254.456543,562.164917 4240.216309,566.179077 
	C4180.023438,583.146301 4164.240723,640.337402 4178.292969,679.035095 
	C4185.045898,697.632690 4197.509277,712.410950 4212.268555,725.219604 
	C4237.642578,747.239563 4266.653809,763.507629 4297.006836,777.427429 
	C4336.973145,795.755981 4376.525391,814.815857 4413.071289,839.497742 
	C4436.363281,855.228394 4458.007324,872.861389 4474.543457,895.913574 
	C4494.059082,923.118286 4503.621094,953.770752 4506.486328,987.006836 
	C4508.910645,1015.129395 4506.608398,1042.780151 4500.267578,1070.065552 
	C4484.575684,1137.590454 4444.102051,1185.153198 4381.591309,1214.002441 
	C4350.130371,1228.521973 4316.580078,1234.335083 4282.021973,1236.589355 
	C4253.915527,1238.422607 4226.225098,1236.639893 4198.706055,1231.996094 
	C4159.644043,1225.404541 4124.012695,1210.520142 4093.289551,1184.935913 
	C4057.366455,1155.021606 4036.113525,1116.382080 4025.666504,1071.312622 
	C4025.449463,1070.376343 4025.476074,1069.383545 4025.295898,1067.386963 
	C4056.977539,1052.685669 4088.827148,1037.906616 4121.486816,1022.751404 
	C4122.681641,1025.948608 4123.639160,1028.314697 4124.456055,1030.728149 
	C4134.696289,1060.983032 4151.602051,1086.213135 4179.245605,1103.313721 
	C4197.383789,1114.534424 4217.353027,1120.807495 4238.314941,1123.377563 
	C4260.462891,1126.093018 4282.642090,1125.968384 4304.497559,1120.174927 
	C4346.535645,1109.031372 4376.965820,1075.911011 4381.826660,1028.962158 
	C4385.520508,993.286926 4370.238281,966.489441 4344.951660,943.820068 
	C4327.994629,928.617371 4308.822266,916.576538 4288.510742,906.421814 
	C4250.414062,887.375488 4211.617676,869.691956 4175.043457,847.704285 
	C4151.338867,833.453613 4128.170898,818.436035 4107.234863,800.238159 
	C4073.714355,771.101746 4053.421875,734.738831 4047.696777,690.541748 
	C4042.240479,648.418213 4046.105957,607.356384 4064.934082,568.873413 
	C4088.824463,520.043396 4128.113770,488.298248 4178.869141,470.144409 
	C4196.481445,463.845184 4214.666992,460.044067 4234.130859,457.848511 
z"/>
<path fill="#F05A2A" opacity="1.000000" stroke="none" 
	d="
M6146.326660,1039.999756 
	C6146.326660,885.125793 6146.326660,731.251831 6146.326660,576.346252 
	C6080.420410,576.346252 6015.612793,576.346252 5950.238281,576.346252 
	C5950.238281,541.465149 5950.238281,507.612976 5950.238281,473.015656 
	C6121.706055,473.015656 6293.096680,473.015656 6465.080566,473.015656 
	C6465.080566,507.210693 6465.080566,541.028931 6465.080566,576.091187 
	C6399.747559,576.091187 6334.657715,576.091187 6268.352051,576.091187 
	C6268.352051,790.397034 6268.352051,1003.466431 6268.352051,1217.063477 
	C6227.303223,1217.063477 6187.483398,1217.063477 6146.326660,1217.063477 
	C6146.326660,1158.057983 6146.326660,1099.528931 6146.326660,1039.999756 
z"/>
<path fill="#F05A2A" opacity="1.000000" stroke="none" 
	d="
M2911.676025,632.000122 
	C2911.676025,827.278687 2911.676025,1021.557251 2911.676025,1215.654907 
	C2904.297363,1218.000977 2803.759521,1218.619263 2790.049316,1216.433472 
	C2790.049316,1003.751099 2790.049316,790.924438 2790.049316,576.736938 
	C2776.449951,575.792847 2763.591064,576.414795 2750.775391,576.300964 
	C2737.778564,576.185547 2724.780273,576.275513 2711.782471,576.275513 
	C2698.451416,576.275513 2685.120361,576.275513 2671.789307,576.275513 
	C2658.791748,576.275513 2645.793945,576.275513 2632.796387,576.275513 
	C2619.819092,576.275513 2606.841797,576.275452 2592.491455,576.275452 
	C2592.152832,541.597046 2592.403564,507.526093 2592.304688,472.862793 
	C2764.586182,472.862793 2935.717285,472.862793 3107.592285,472.862793 
	C3107.592285,507.130280 3107.592285,541.192017 3107.592285,576.296265 
	C3042.442383,576.296265 2977.636230,576.296265 2911.676025,576.296265 
	C2911.676025,595.206604 2911.676025,613.103394 2911.676025,632.000122 
z"/>
<path fill="#F05A2A" opacity="1.000000" stroke="none" 
	d="
M2497.676514,688.000122 
	C2497.676514,864.634521 2497.676514,1040.268921 2497.676514,1215.709961 
	C2490.097412,1218.001099 2388.347900,1218.510010 2376.252441,1216.401123 
	C2376.252441,968.969971 2376.252441,721.435303 2376.252441,472.450897 
	C2416.313721,472.086884 2456.375977,472.359955 2497.676514,472.252899 
	C2497.676514,544.559265 2497.676514,615.779724 2497.676514,688.000122 
z"/>
<path fill="#F0592A" opacity="1.000000" stroke="none" 
	d="
M950.140564,633.889648 
	C881.795837,589.240784 814.069275,544.968140 746.369812,500.654083 
	C739.687195,496.279846 730.735229,493.005493 727.264404,486.759918 
	C723.819946,480.561859 725.711365,471.254303 725.707153,463.310242 
	C725.649353,352.991028 725.668030,242.671799 725.668091,132.352554 
	C725.668091,128.439255 725.668152,124.525955 725.668152,120.733406 
	C727.616760,120.190620 728.536987,119.707207 729.454834,119.711624 
	C756.771484,119.843246 784.091431,119.813568 811.403015,120.272507 
	C828.347595,120.557228 845.324463,121.043930 862.206970,122.419930 
	C889.328430,124.630463 916.535034,126.621483 943.432007,130.569763 
	C985.144897,136.692902 1026.785400,143.570404 1068.171265,151.598770 
	C1131.916626,163.964569 1194.238159,181.900436 1255.758423,202.626099 
	C1265.214844,205.811829 1274.748047,208.768692 1284.223511,211.898773 
	C1310.035645,220.425461 1322.679443,242.606003 1322.570557,265.409943 
	C1322.046753,375.060089 1322.335083,484.714111 1322.335205,594.366760 
	C1322.335205,615.894470 1322.335205,637.422180 1322.335205,660.938660 
	C1314.183228,655.658997 1307.349731,651.259644 1300.543701,646.818054 
	C1293.292603,642.085876 1286.080688,637.293579 1278.827271,632.564819 
	C1271.855103,628.019470 1265.200073,622.800842 1257.763184,619.208008 
	C1250.482178,615.690430 1247.136597,607.368958 1238.348145,604.873352 
	C1238.348145,498.996826 1238.348145,392.746582 1238.348145,286.238220 
	C1160.406006,259.075897 1081.400879,238.363098 1000.679260,224.402679 
	C937.182068,213.421158 828.139343,202.782288 810.168823,205.300949 
	C810.008423,208.391342 809.694885,211.629990 809.693665,214.868774 
	C809.665649,286.859558 809.770996,358.850708 809.518250,430.840607 
	C809.493225,437.985046 811.686707,442.153046 817.743408,446.088226 
	C900.392395,499.787537 982.872314,553.747253 1065.367676,607.682678 
	C1145.914307,660.343872 1226.231567,713.360779 1307.184814,765.388611 
	C1318.753052,772.823486 1322.797729,780.142395 1322.688477,793.759399 
	C1321.993408,880.409424 1322.267822,967.067871 1322.386719,1053.723267 
	C1322.411255,1071.673706 1318.586060,1088.569702 1311.386597,1104.979248 
	C1287.099487,1160.336792 1254.224243,1210.073364 1214.195068,1255.236938 
	C1165.710449,1309.940796 1109.810181,1355.738403 1048.114136,1394.745483 
	C945.108032,1459.870483 838.784424,1519.285767 732.479126,1578.740723 
	C730.808899,1579.674927 729.013123,1580.384766 726.342041,1581.637329 
	C726.342041,1350.248047 726.342041,1119.863892 726.342041,888.650940 
	C728.358887,889.606812 730.143860,890.217468 731.677368,891.214905 
	C754.846252,906.284058 777.833496,921.640198 801.222656,936.357971 
	C807.648804,940.401733 809.869324,944.791748 809.856995,952.403625 
	C809.609558,1105.383667 809.671997,1258.364258 809.672058,1411.344727 
	C809.672058,1415.310425 809.672058,1419.276001 809.672058,1423.942505 
	C812.325806,1423.392212 814.287659,1423.306885 815.980469,1422.588135 
	C885.992798,1392.858643 953.449036,1358.337524 1016.261475,1315.193115 
	C1048.936035,1292.749634 1080.069458,1268.400269 1109.206299,1241.420898 
	C1151.551880,1202.210938 1187.705566,1158.188843 1215.590454,1107.594971 
	C1222.489136,1095.078247 1228.747925,1082.149292 1234.350098,1069.004517 
	C1236.877197,1063.075073 1238.140259,1056.155762 1238.163086,1049.679199 
	C1238.420898,976.356140 1238.264526,903.031677 1238.448853,829.708191 
	C1238.463257,824.019897 1236.639160,820.712830 1231.879028,817.620239 
	C1174.636719,780.431946 1117.547363,743.008423 1060.391479,705.687012 
	C1023.872620,681.841064 987.309570,658.062866 950.140564,633.889648 
z"/>
<path fill="#F0592A" opacity="1.000000" stroke="none" 
	d="
M185.761444,289.084961 
	C185.701508,394.957916 185.701508,499.932129 185.701508,604.683411 
	C177.385056,607.671875 173.753464,615.576965 166.540421,619.252014 
	C159.163742,623.010376 152.520554,628.205566 145.544083,632.753052 
	C138.571640,637.297974 131.587799,641.825439 124.602013,646.349854 
	C117.658157,650.847168 110.705681,655.331116 102.115341,660.881653 
	C101.933228,655.744141 101.685974,652.008545 101.685150,648.272949 
	C101.663925,551.939697 101.668617,455.606384 101.668213,359.273102 
	C101.668083,327.939789 102.056137,296.600037 101.541229,265.275177 
	C101.133202,240.452133 115.294266,219.776367 139.120697,212.236832 
	C185.088257,197.691071 231.158875,183.414474 277.504333,170.134583 
	C329.327240,155.285187 382.250793,145.099640 435.473297,136.784363 
	C464.323364,132.276932 493.407959,129.174286 522.448547,126.003891 
	C577.761902,119.965271 633.295471,118.954590 688.880188,119.595932 
	C691.513184,119.626312 694.146851,119.599838 697.476196,119.599838 
	C697.797241,122.631371 698.298157,125.158806 698.299011,127.686401 
	C698.338013,245.019669 698.290771,362.352997 698.463013,479.686035 
	C698.471436,485.435669 696.441528,488.630585 691.784790,491.660309 
	C632.883789,529.981689 574.104858,568.490967 515.287720,606.941345 
	C447.831757,651.039246 380.378693,695.141602 312.904877,739.212158 
	C273.315125,765.070129 233.795502,791.038879 193.968674,816.527100 
	C187.442245,820.703796 185.417892,825.240479 185.504288,832.705078 
	C185.889999,866.033936 185.587418,899.369995 185.693878,932.703003 
	C185.821579,972.690430 185.891083,1012.680908 186.547867,1052.660889 
	C186.658371,1059.387451 188.667007,1066.482788 191.402328,1072.702515 
	C211.667084,1118.781128 239.267242,1160.173706 272.491821,1197.905884 
	C317.278503,1248.769043 369.774109,1290.450806 426.285553,1327.384644 
	C462.004272,1350.729126 499.114655,1371.641113 537.472046,1390.272217 
	C559.906250,1401.168945 582.805786,1411.108765 605.521057,1421.424072 
	C607.882568,1422.496460 610.414307,1423.193970 613.826538,1424.406250 
	C613.826538,1263.351685 613.826538,1103.204590 613.826538,942.721436 
	C641.666016,924.511169 669.100525,906.565857 697.793457,887.797302 
	C697.793457,1119.899414 697.793457,1350.068481 697.793457,1581.096558 
	C695.467041,1580.129272 693.382812,1579.507446 691.539673,1578.461060 
	C622.664612,1539.359497 553.505737,1500.744385 485.067322,1460.892090 
	C441.931793,1435.773926 399.547974,1409.316040 357.438019,1382.499512 
	C319.170410,1358.129639 284.411591,1329.156128 251.415543,1297.993774 
	C215.433350,1264.011108 183.629852,1226.608887 156.405136,1185.294800 
	C139.325607,1159.376221 124.859756,1132.025757 112.249786,1103.704468 
	C104.996315,1087.413574 101.581917,1070.268921 101.608551,1052.324951 
	C101.740143,963.658569 101.766243,874.991516 101.493019,786.325745 
	C101.469780,778.784424 103.630943,774.369690 110.043976,770.204834 
	C192.992950,716.334839 275.757996,662.181519 358.543182,608.059448 
	C405.653534,577.260376 452.708984,546.377319 499.801941,515.551636 
	C536.023987,491.841736 572.195618,468.053192 608.593323,444.615784 
	C612.859070,441.868927 614.407532,439.121918 614.400513,434.283691 
	C614.292358,359.950592 614.327148,285.617249 614.301392,211.283951 
	C614.300720,209.343414 613.992676,207.402985 613.739929,204.424591 
	C603.547852,204.874802 593.694824,204.871964 583.933777,205.817963 
	C544.289917,209.660126 504.523560,212.686432 465.080811,218.058289 
	C391.177124,228.123489 318.566559,244.576355 247.200699,266.330444 
	C229.383148,271.761688 211.710159,277.675171 194.041199,283.577942 
	C191.114700,284.555634 188.552139,286.622650 185.761444,289.084961 
z"/>
<path fill="#020100" opacity="1.000000" stroke="none" 
	d="
M6678.331543,701.999878 
	C6678.331543,660.401550 6678.331543,619.803345 6678.331543,578.910645 
	C6680.149902,578.381348 6681.348633,577.744141 6682.557617,577.722778 
	C6714.512207,577.158508 6746.411621,577.550415 6777.902344,584.002991 
	C6795.319824,587.571899 6812.143555,592.829590 6826.370117,603.946838 
	C6842.017090,616.174072 6850.430664,632.909973 6854.525879,651.999390 
	C6860.418457,679.467102 6860.678223,706.844360 6851.547852,733.688416 
	C6844.496094,754.418579 6830.572266,768.693054 6809.691406,776.223450 
	C6789.785645,783.402405 6769.115234,786.616150 6748.232910,787.947510 
	C6727.341797,789.279419 6706.355957,789.127136 6685.410156,789.569946 
	C6683.483398,789.610657 6681.548340,789.249512 6678.331543,788.958618 
	C6678.331543,760.068848 6678.331543,731.534363 6678.331543,701.999878 
z"/>
</svg>
);

export default SiteSentry;