import React from "react";
import Svg from "../../common/Svg";
import CustomDropdown from "./components/CustomDropDown";
import SearchBar from "./components/SearchBar";
import AddButton from "./components/AddBtn";
import { getLocal, checkUserRoutePermission } from "../../../../helper/common";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import {
  ENTERPRISE_ADMIN,
  MULTI_SITE_ADMIN,
  SINGLE_SITE_ADMIN,
} from "../../../../constants/common";

export default function DisplayControls({
  route,
  activeColumn,
  handleResponsiveColumns,
  defaultView,
  setDefaultView,
  defaultViewApi,
  LIST_VIEW,
  SITE_VIEW,
  onSearch,
  onRefresh,
  addDeviceRoute,
  showListView = true,
  showDropdown = true,
  showSearchBar = true,
  showAddButton = true,
}) {
  let userRole = getLocal("userRole");

  return (
    <div className="display-wrap d-flex flex-wrap align-items-center justify-content-between w-100">
      {/* Left Section: Display Controls */}
      <div className="d-flex flex-grow-1 flex-wrap justify-content-center justify-content-lg-start gap-3">
        <div className="d-flex align-items-center justify-content-center">
          <div className="title mr-2">Display</div>
          <div className="display-inputs d-flex align-items-center gap-2">
            {[1, 2, 3, 4].map((col) => (
              <div
                key={col}
                className={`icon-checkbox ${
                  activeColumn === col ? "active" : ""
                }`}
                onClick={() => handleResponsiveColumns(col)}
              >
                <Svg name={`grid${col}`} />
              </div>
            ))}
          </div>
        </div>

        {showListView && (
          <div className="d-flex align-items-center justify-content-center list-view-site-view custom-list-box">
            <button
              className={
                defaultView === LIST_VIEW ? "active list-btn" : "list-btn"
              }
              onClick={() => {
                setDefaultView(LIST_VIEW);
                defaultViewApi(LIST_VIEW);
              }}
            >
              List View
            </button>
            <button
              className={
                defaultView === SITE_VIEW ? "active list-btn" : "list-btn"
              }
              onClick={() => {
                setDefaultView(SITE_VIEW);
                defaultViewApi(SITE_VIEW);
              }}
            >
              Site View
            </button>
          </div>
        )}

        {showDropdown && (
          <div className="dropdown">
            <CustomDropdown />
          </div>
        )}
      </div>
      {/* Add Refresh Button */}
      {onRefresh && (
        <Tooltip title="Refresh Devices">
          <IconButton
            onClick={onRefresh}
            aria-label="refresh"
            sx={{
              color: "#3bb44a", // Specific green color you requested
              "&:hover": {
                backgroundColor: "rgba(59, 180, 74, 0.1)", // Light green hover effect
              },
            }}
          >
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      {/* Right Section: SearchBar and AddButton */}
      <div className="d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center gap-3">
        {showSearchBar && <SearchBar onSearch={onSearch} />}

        {showAddButton &&
          [ENTERPRISE_ADMIN, MULTI_SITE_ADMIN, SINGLE_SITE_ADMIN].includes(
            userRole
          ) && <AddButton textLabel="Add Device" to={addDeviceRoute} />}
      </div>
    </div>
  );
}
