import React, { useEffect, useState } from "react";
import { useUiContext } from "../../../../contexts/UiContext";
import HeaderDashboard from "../../common/HeaderDashboard";
import SettingNavigation from "../../common/SettingNavigation";
import { getLocal } from "../../../../helper/common";
import {
  DISTRIBUTOR,
  DISTRIBUTOR_ADMIN,
  SELLER,
  SELLER_ADMIN,
} from "../../../../constants/common";
import { useLocation } from "react-router-dom";

export default function DevicesStorage({ route }) {
  let uid = localStorage.getItem("customeruuid");
  const { apiRequest } = useUiContext();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [routeRole, setRouteRole] = useState("");
  const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;
  let role = getLocal("role");
  // const [distributorsData, setDistributorsData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedDistributor, setSelectedDistributor] = useState([]);
  const [formData, setFormData] = useState({
    recordingStartTime: "",
    recordingEndTime: "",
    recordingFetchDescription: "",
  });
  useEffect(() => {
    const routeSiteSentry = location.pathname
      .split("/site-sentry/")[1]
      ?.split("/")[0];
    console.log("Extracted Role:", routeSiteSentry); // ✅ Outputs "customer"
    setRouteRole(routeSiteSentry);
  }, [location.pathname]);

  const endpoint =
    routeRole === "customer"
      ? "customers"
      : routeRole === "reseller"
      ? "sellers"
      : "distributors";
  const getCutomerId = async () => {
    const idHeaders = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "get",
      `api/1/${endpoint}/${uid}/get_summary_device_storage_for/`,
      "",
      idHeaders,
      true
    );
    if (res) {
      setData(res.data.rows);
    }
  };

  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const descName = "recordingFetchDescription";
    let descValue = formData.recordingFetchDescription;
    if (name === "recordingStartTime" || name === "recordingEndTime") {
      const startTime =
        name === "recordingStartTime" ? value : formData.recordingStartTime;
      const endTime =
        name === "recordingEndTime" ? value : formData.recordingEndTime;
      if (!(startTime && endTime && startTime !== endTime)) {
        descValue = "At Any Time";
      } else if (startTime.localeCompare(endTime) === -1) {
        descValue = `Between ${startTime} and ${endTime}`;
      } else {
        descValue = `Between Midnight to ${endTime} and ${startTime} to Midnight`;
      }
    }
    setFormData({ ...formData, [name]: value, [descName]: descValue });
  };

  const getUser = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    const res = await apiRequest(
      "get",
      `api/1/${endpoint}/${uid}/`,
      "",
      headers,
      true,
      { success: "", error: "User details not found" }
    );
    if (res && res.data) {
      setFormData((preData) => ({
        ...preData,
        recordingStartTime: res.data.recording_fetch_start_time,
        recordingEndTime: res.data.recording_fetch_stop_time,
        recordingFetchDescription: res.data.recording_fetch_description,
      }));
      if (role === SELLER_ADMIN) {
        if (res.data.distributors?.length > 0) {
          let options = [];
          res.data.distributors.map((ele) => {
            options.push({
              value: ele.distributor_uuid,
              label: ele.name,
            });
          });
          setSelectedDistributor(options);
        }
      }
    }
  };

  let updateBody = {
    contact_email: formData.contact_email,
    contact_name: formData.name,
    name: formData.name,
    distributors:
      selectedDistributor.length > 0
        ? selectedDistributor.map((distributor, i) => {
            return { distributor_uuid: distributor.value };
          })
        : [],
  };

  const update = async (e) => {
    e.preventDefault();
    console.log("formData", formData);
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };
    const res = await apiRequest(
      "patch",
      `api/1/${endpoint}/${uid}/`,
      updateBody,
      headers,
      true,
      {
        success: "Profile update Successfully",
        error: "Failed to update user details",
      }
    );
    if (res) {
      await getUser();
    }
  };

  useEffect(() => {
    if (!routeRole) return;
    getCutomerId();
    getUser();
  }, [routeRole]);

  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height settings-page d-flex flex-column">
          <SettingNavigation />
          <div className="flex-grow-1 setting-page-wrapper">
            <div className="container">
              <form className="profile-wrapper account-details-form mb-5">
                <div className="row theme-form-wrapper">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {isAdmin
                      ? role !== DISTRIBUTOR &&
                        role !== DISTRIBUTOR_ADMIN &&
                        role !== SELLER_ADMIN &&
                        role !== SELLER && (
                          <div className="col-lg-6">
                            <div className="recording-form">
                              <div className="d-flex mb__20 justify-content-end">
                                <label
                                  htmlFor="recordingStartTime"
                                  className="col-form-label text-end"
                                >
                                  Recording Fetch Start Time
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="recordingStartTime"
                                    name="recordingStartTime"
                                    value={formData.recordingStartTime || ""}
                                    onChange={handleChange}
                                  />
                                  <div className="form-text">
                                    If Start is after Stop fetch between: Start
                                    to Midnight otherwise Start to Stop. If
                                    empty, fetch any time
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mb__20 justify-content-end">
                                <label
                                  htmlFor="recordingEndTime"
                                  className="col-form-label text-end"
                                >
                                  Recording Fetch Stop Time
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="recordingEndTime"
                                    name="recordingEndTime"
                                    value={formData.recordingEndTime || ""}
                                    onChange={handleChange}
                                  />
                                  <div className="form-text">
                                    If Stop is before Start fetch between:
                                    Midnight to Stop otherwise Start to Stop. If
                                    empty, fetch any time
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex mb__20 justify-content-end">
                                <label
                                  htmlFor="recordingFetchDescription"
                                  className="col-form-label text-end"
                                >
                                  Recordings Fetched
                                </label>
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    disabled
                                    className="form-control"
                                    id="recordingFetchDescription"
                                    name="recordingFetchDescription"
                                    value={
                                      formData.recordingFetchDescription || ""
                                    }
                                    onChange={handleChange}
                                  />
                                  <div className="form-text">
                                    Recording Videos will Copy to Cloud during
                                    the saved time(s) if Storage Subscribed.
                                    Note: Recording information will sync at any
                                    time.{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      : null}
                  </div>
                  {isAdmin ? (
                    <div className="col-12">
                      <div className="button-wrap pt-5 text-center">
                        <button
                          className="btn btn-primary btn-rounded"
                          disabled={isDisabled}
                          onClick={update}
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </form>
              <div className="user-permissions usr-prm devices-storage-wrapper">
                <div className="devices-storage-list">
                  <div className="theme-table">
                    <div className="table-responsive">
                      {data?.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              {data[0]?.map((row, index) => (
                                <th key={index}>{row}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {data.slice(1).map((rows, i) => (
                              <tr key={i}>
                                {rows.map((row, index) => (
                                  <td key={index}>{row}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className="no-data text-center py-4">
                          No Data Available
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
