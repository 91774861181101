import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import HeaderDashboard from "../../common/HeaderDashboard";
import ResellerHeader from "../../common/ResellerHeader";
import SettingNavigation from "../../common/SettingNavigation";
import { getLocal, checkUserRoutePermission } from "../../../../helper/common";
import { useLocation } from "react-router-dom";

export default function UnassignedSubscriptionsSummary({ route }) {
  let role = getLocal("role");
  let uid = localStorage.getItem("customeruuid");
  const { apiRequest } = useUiContext();
  const [data, setData] = useState([]);
  const [routeRole, setRouteRole] = useState("");
  const location = useLocation();

  useEffect(() => {
    const routeSiteSentry = location.pathname
      .split("/site-sentry/")[1]
      ?.split("/")[0];
    console.log("Extracted Role:", routeSiteSentry); // ✅ Outputs "customer"
    setRouteRole(routeSiteSentry);
  }, [location.pathname]);

  const endpoint =
    routeRole === "customer"
      ? "customers"
      : routeRole === "reseller"
      ? "sellers"
      : "distributors";

  const getData = async () => {
    try {
      const token = localStorage.getItem("token");
      const verifiedJwt = localStorage.getItem("x_verified_jwt");

      if (!token || !verifiedJwt) {
        console.error("Missing authentication tokens.");
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "X-verified-JWT": verifiedJwt,
      };

      const res = await apiRequest(
        "get",
        `api/1/${endpoint}/${uid}/get_summary_subscriptions_not_activated/`,
        null, // Using `null` instead of an empty string for clarity
        headers,
        true
      );

      if (res?.data?.rows) {
        setData(res.data.rows);
      } else {
        console.warn("No data received from API.");
        setData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!routeRole) return;
    getData();
  }, [routeRole]);

  const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;
  return (
    <>
      <div className="d-flex flex-column site-main-wrapper setting-body">
        <HeaderDashboard />
        <div className="flex-grow-1 main-wrapper">
          <div className="main-page-height settings-page d-flex flex-column">
            <SettingNavigation />
            <div className="flex-grow-1 setting-page-wrapper">
              <div className="container">
                <div className="user-permissions usr-prm devices-storage-wrapper">
                  <div className="devices-storage-list">
                    <div className="theme-table">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            {data?.map((rows, i) => (
                              <tr key={i}>
                                {i === 0 &&
                                  rows.map((row, index) => (
                                    <th key={index}>{row} </th>
                                  ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody>
                            {data?.map((rows, i) => (
                              <tr key={i}>
                                {i > 0 &&
                                  rows.map((row, index) => (
                                    <td key={index}>{row} </td>
                                  ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="button-wrap pt-3 text-center">
                      {isAdmin
                        ? checkUserRoutePermission(
                            role,
                            `/${route}/unassigned-subscriptions-summary/assign`
                          ) && (
                            <Link
                              to={`/${route}/unassigned-subscriptions-summary/assign`}
                              className="btn btn-secondary btn-rounded btn-300 me-3 my-2"
                            >
                              Assign Subscriptions
                            </Link>
                          )
                        : null}
                      {isAdmin
                        ? checkUserRoutePermission(
                            role,
                            `/${route}/unassigned-subscriptions-summary/create`
                          ) && (
                            <Link
                              to={`/${route}/unassigned-subscriptions-summary/create`}
                              className="btn btn-secondary btn-rounded btn-300 my-2"
                            >
                              Create Subscription
                            </Link>
                          )
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
