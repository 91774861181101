import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AddButton from "../customer/cameras/components/AddBtn";
import { siteAssetManagement } from "../site-sentry/common/Utility";
import useUserData from "./services/userService/userActiveService";
import TableUserTop from "./components/User/TableUserTop";
import TableUser from "./components/User/TableUser";
import useUsers from "./services/userService/useUsers";
import { toggleUserActiveStatus, deleteUserAccount, resetUserPassword, sendMail } from "./services/userService/useActiveUser";
import { useUiContext } from "../../contexts/UiContext";


export default function Users() {
  let uid = localStorage.getItem("customeruuid");
  let endpoint = "customers";
  const { apiRequest } = useUiContext();
  const [loader, setLoader] = useState(true);
  const [userServerParams, setUserServerParams] = useState({ search: "", page: 1 });
  const userData = useUserData(endpoint, uid);
  const [headers, ...bodyRows] = userData || [[]];
  const totalUsers = bodyRows.length > 0 ? bodyRows[bodyRows.length - 1].at(-1) : null;
  const { users, totalPages } = useUsers(endpoint, loader, userServerParams);

  useEffect(() => {
    if (users.length > 0 || totalPages >= 0) {
      setLoader(false);
    }
  }, [users, totalPages]);

  const userInactive = async (id, active) => {
    await toggleUserActiveStatus(id, active, endpoint, users, apiRequest)
    setLoader(true)
  }

  const deleteUser = async (username) => {
    await deleteUserAccount(username, endpoint, apiRequest)
    setLoader(true)
  }

  const resetPassword = async (user) => {
    await resetUserPassword(user, apiRequest)
    setLoader(true)
  }

  const sendWelcomeMail = async (user) => {
    await sendMail(user, apiRequest)
    setLoader(true)
  }

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4">Users</Typography>
        <AddButton textLabel="Add User" to={`${siteAssetManagement}/users/user-form`} />
      </Box>
      <div className="my-2">
        <TableUserTop title="User Data" data={{ headers, bodyRows, totalUsers }} />
      </div>
      <div>
        <TableUser 
        title="User Data" 
        users={users}
        totalPages={totalPages} 
        userServerParams={userServerParams} 
        setUserServerParams={setUserServerParams}
        userInactive={userInactive}
        deleteUser={deleteUser}
        resetPassword={resetPassword}
        sendWelcomeMail={sendWelcomeMail}
        />
      </div>
    </div>
  );
}
