import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";

const CustomerSiteListSideBar = ({
  route = "customer",
  setDeviceName = () => {},
  clearSelectedSiteData = () => {},
  setCameraEventsOptions = () => {},
  setSelectedEvents = () => {},
  deviceList = [],
}) => {
  const { device_uuid } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sortBy, setSortBy] = useState("name");
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedCustomers, setExpandedCustomers] = useState([]);
  const [expandedSites, setExpandedSites] = useState([]);
  const open = Boolean(anchorEl);
  

  // Generate hierarchical customer data
  const customerData = Object.values(
    deviceList.reduce((acc, device) => {
      // Group by customer name
      if (!acc[device.customer_name]) {
        acc[device.customer_name] = {
          name: device.customer_name,
          sites: {},
        };
      }

      // Group sites by address name
      if (!acc[device.customer_name].sites[device.address_name]) {
        acc[device.customer_name].sites[device.address_name] = {
          name: device.address_name,
          devices: [],
        };
      }

      // Add device to site
      acc[device.customer_name].sites[device.address_name].devices.push({
        name: device.name,
        device_uuid: device.device_uuid,
        status: device.status,
      });

      return acc;
    }, {})
  ).map((customer) => ({
    ...customer,
    sites: Object.values(customer.sites),
  }));

  // Sort the customers based on sortBy
  const sortedCustomers = [...customerData].sort((a, b) => {
    if (sortBy === "name") {
      return a.name.localeCompare(b.name);
    } else if (sortBy === "-name") {
      return b.name.localeCompare(a.name);
    }
    return 0;
  });

  // Auto-expand accordions to show the selected device
  useEffect(() => {
    if (device_uuid) {
      // Find the device in the customer data
      const selectedDevice = sortedCustomers.reduce((found, customer) => {
        if (found) return found;
        const site = customer.sites.find((site) =>
          site.devices.some((device) => device.device_uuid === device_uuid)
        );

        if (site) {
          // Expand customer and site accordions
          setExpandedCustomers((prev) =>
            prev.includes(customer.name) ? prev : [...prev, customer.name]
          );
          setExpandedSites((prev) =>
            prev.includes(site.name) ? prev : [...prev, site.name]
          );
          return true;
        }
        return found;
      }, null);
    }
  }, [device_uuid, sortedCustomers]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortBy = (sortOption) => {
    setSortBy(sortOption);
    handleClose();
  };

  const handleDeviceClick = (device, siteName) => {
    setCameraEventsOptions(["All Events"]);
    clearSelectedSiteData();
    setSelectedEvents([]);
    setDeviceName(device.name);
  };

  const handleCustomerToggle = (customerName) => {
    setExpandedCustomers((prev) =>
      prev.includes(customerName)
        ? prev.filter((name) => name !== customerName)
        : [...prev, customerName]
    );
  };

  const handleSiteToggle = (siteName) => {
    setExpandedSites((prev) =>
      prev.includes(siteName)
        ? prev.filter((name) => name !== siteName)
        : [...prev, siteName]
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "white",
        height: "100%",
        flexShrink: 0, // Prevents the sidebar from shrinking
      }}
    >
      {/* Header with sorting */}
      {/* <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #333",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", backgroundColor: "#000" }}>
          <Typography variant="body2" sx={{ mr: 1, color: "white" }}>
            Sort by -
          </Typography>
          <Button
            id="sort-button"
            aria-controls={open ? "sort-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon sx={{ color: "white" }} />}
            sx={{
              textTransform: "none",
              p: 0,
              minWidth: "auto",
              color: "white",
            }}
          >
            {sortBy === "name" ? "A To Z" : "Z to A"}
          </Button>
          <Menu
            id="sort-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "sort-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#212121",
                color: "white",
              },
            }}
          >
            <MenuItem
              onClick={() => handleSortBy("name")}
              sx={{ "&:hover": { backgroundColor: "#333" } }}
            >
              A To Z
            </MenuItem>
            <MenuItem
              onClick={() => handleSortBy("-name")}
              sx={{ "&:hover": { backgroundColor: "#333" } }}
            >
              Z to A
            </MenuItem>
          </Menu>
        </Box>
      </Box> */}

      {/* List of customers, sites, and devices */}
      <Box
        sx={{
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#555",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#212121",
          },
        }}
      >
        {sortedCustomers.map((customer) => (
          <Accordion
            key={customer.name}
            disableGutters
            expanded={expandedCustomers.includes(customer.name)}
            onChange={() => handleCustomerToggle(customer.name)}
            sx={{
              backgroundColor: "#1a1a1a",
              color: "white",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
              sx={{
                backgroundColor: "#212121",
                "&.Mui-expanded": {
                  minHeight: "48px",
                },
                "&:hover": {
                  backgroundColor: "#333",
                },
              }}
            >
              <Typography variant="subtitle1" sx={{ color: "white" }}>
                {customer.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0, backgroundColor: "#121212" }}>
              {customer.sites.map((site) => (
                <Accordion
                  key={site.name}
                  disableGutters
                  expanded={expandedSites.includes(site.name)}
                  onChange={() => handleSiteToggle(site.name)}
                  sx={{
                    backgroundColor: "#1a1a1a",
                    color: "white",
                    "&:before": {
                      display: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{ color: "white", fontSize: "small" }}
                      />
                    }
                    sx={{
                      backgroundColor: "#212121",
                      "&.Mui-expanded": {
                        minHeight: "40px",
                      },
                      "&:hover": {
                        backgroundColor: "#333",
                      },
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "white" }}>
                      {site.name}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, backgroundColor: "#121212" }}>
                    <List disablePadding>
                      {site.devices.map((device) => {
                        const isDeviceActive =
                          device.device_uuid === device_uuid;
                        return (
                          <ListItem
                            key={device.device_uuid}
                            component={Link}
                            to={`/site-sentry/${route}/cameras/live-camera/${device.device_uuid}`}
                            sx={{
                              py: 1,
                              px: 2,
                              backgroundColor: isDeviceActive
                                ? "#2a3eb1"
                                : "transparent",
                              "&:hover": {
                                backgroundColor: isDeviceActive
                                  ? "#2a3eb1"
                                  : "#333",
                              },
                              textDecoration: "none",
                              color: "white",
                            }}
                            onClick={() => handleDeviceClick(device, site.name)}
                          >
                            <ListItemIcon sx={{ minWidth: "24px" }}>
                              <FiberManualRecordIcon
                                sx={{
                                  fontSize: 10,
                                  color:
                                    device.status === "ok"
                                      ? "#4caf50"
                                      : "#f44336",
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={device.name}
                              sx={{
                                "& .MuiTypography-root": {
                                  color: isDeviceActive ? "#3bb44a" : "white",
                                  fontSize: "0.875rem",
                                },
                              }}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default CustomerSiteListSideBar;
