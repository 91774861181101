import React, { useState } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";
import { ReactComponent as ShieldActive } from "../components/icon/shieldActive.svg";
import { ReactComponent as ShieldOff } from "../components/icon/shieldOff.svg";
import { ReactComponent as HornOn } from "../components/icon/hornOn.svg";
import { ReactComponent as HornOff } from "../components/icon/hornOff.svg";
import { useUiContext } from "../../../../../contexts/UiContext";
import { toast } from "react-hot-toast";

const NavigationIcon = ({ position, style, onIconClick, iconsToShow, deviceData }) => {
  const { apiRequest, setLoading } = useUiContext();

  // Define the initial state for each toggleable icon
  const [iconStates, setIconStates] = useState({
    record: false,
    sound: false,
    mic: false,
    arm: deviceData.arm_disarm_status === "armed", // Set initial state based on deviceData
    dim: deviceData.strobe_status === "true" || deviceData.strobe_status === true, // Handle both string and boolean
    horn: deviceData.siren_status === "true" || deviceData.siren_status === true, // Handle both string and boolean
  });

  // Define the full list of icons with tooltips
  const allIcons = [
    {
      on: ShieldActive,
      off: ShieldOff,
      stateKey: "arm",
      tooltip: `Device is ${deviceData.arm_disarm_status === "armed" ? "armed" : "disarmed"}`, // Dynamic tooltip
    },
    { on: HornOn, off: HornOff, stateKey: "horn", tooltip: "Horn" },
    { on: FlashlightOnIcon, off: FlashlightOffIcon, stateKey: "dim", tooltip: "Flashlight" },
    { on: MicIcon, off: MicOffIcon, stateKey: "mic", tooltip: "Microphone" },
    {
      on: RadioButtonCheckedIcon,
      off: RadioButtonUncheckedIcon,
      stateKey: "record",
      tooltip: "Record",
    },
    { on: VolumeUpIcon, off: VolumeOffIcon, stateKey: "sound", tooltip: "Sound" },
    { on: ZoomOutMapIcon, off: null, stateKey: "zoom", tooltip: "Zoom" }, // Single icon, no toggle
  ];

  // Filter the icons to display based on `iconsToShow`
  const icons = allIcons.filter((icon) =>
    iconsToShow ? iconsToShow.includes(icon.stateKey) : true
  );

  const handleToggle = async (stateKey, isOn) => {
    if (stateKey) {
      setIconStates((prevState) => ({
        ...prevState,
        [stateKey]: !prevState[stateKey],
      }));

      // Pass state change to parent through callback
      if (onIconClick) {
        onIconClick(stateKey, !isOn, deviceData); // Pass the stateKey, new state, and deviceData
      }

      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
      };

      // Hit the endpoint for arm/disarm status
      if (stateKey === "arm") {
        setLoading(true);
        try {
          const response = await apiRequest(
            "post",
            `api/1/devices/${deviceData.device_uuid}/update_arm_disarm_status/`,
            { status: !isOn ? "armed" : "disarmed" },
            headers,
            true
          );
          if (response.status === 200) {
            toast.success("Arm/Disarm status updated successfully");
          }
        } catch (error) {
          console.error("Failed to update arm/disarm status", error);
          toast.error("Failed to update arm/disarm status");
        } finally {
          setLoading(false);
        }
      }

      // Hit the endpoint for flashlight control
      if (stateKey === "dim") {
        setLoading(true);
        try {
          const response = await apiRequest(
            "post",
            `api/1/devices/${deviceData.device_uuid}/control_lights/`,
            { action: !isOn ? "turn_on" : "turn_off" },
            headers,
            true
          );
          if (response.status === 200) {
            toast.success("Flashlight status updated successfully");
          }
        } catch (error) {
          console.error("Failed to update flashlight status", error);
          toast.error("Failed to update flashlight status");
        } finally {
          setLoading(false);
        }
      }

      // Hit the endpoint for horn control
      if (stateKey === "horn") {
        setLoading(true);
        try {
          const response = await apiRequest(
            "post",
            `api/1/devices/${deviceData.device_uuid}/trigger_sound/`,
            { action: !isOn ? "turn_on" : "turn_off" },
            headers,
            true
          );
          if (response.status === 200) {
            toast.success("Horn status updated successfully");
          }
        } catch (error) {
          console.error("Failed to update horn status", error);
          toast.error("Failed to update horn status");
        } finally {
          setLoading(false);
        }
      }
    } else if (onIconClick) {
      // For non-toggleable icons, just notify the parent
      onIconClick("zoom", true, deviceData);
    }
  };

  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        gap: 1.5,
        ...position, // Allows dynamic positioning
        ...style, // Adds custom styles
      }}
    >
      {icons.map(({ on: IconOn, off: IconOff, stateKey, tooltip }, index) => {
        const isOn = stateKey ? iconStates[stateKey] : false;

        return (
          <IconButton
            key={index}
            onClick={() => handleToggle(stateKey, isOn)}
            sx={{
              color: "#fff",
              backgroundColor: "rgba(0, 0, 0, 0.3)", // Circular background color
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Hover effect
              },
              borderRadius: "50%", // Makes it circular
              width: 40, // Adjust button size
              height: 40, // Adjust button size
            }}
          >
            {/* Conditionally render the "on" or "off" icon */}
            {stateKey && isOn ? (
              typeof IconOn === "function" ? (
                <IconOn sx={{ fontSize: 20 }} />
              ) : (
                <IconOn className="icon-svg" />
              )
            ) : IconOff ? (
              typeof IconOff === "function" ? (
                <IconOff sx={{ fontSize: 20, opacity: 0.5 }} />
              ) : (
                <IconOff
                  className="icon-svg"
                  style={{ opacity: 0.5 }}
                />
              )
            ) : typeof IconOn === "function" ? (
              <IconOn sx={{ fontSize: 20 }} />
            ) : (
              <IconOn className="icon-svg" />
            )}
          </IconButton>
        );
      })}
    </Box>
  );
};

export default NavigationIcon;
