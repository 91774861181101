import { useUiContext } from "../../../../../contexts/UiContext";

export const useAddressList = () => {
  const { apiRequest } = useUiContext();

  const fetchAddressList = async () => {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
      "X-Verified-JWT": localStorage.getItem("x_verified_jwt"),
    };

    try {
      const response = await apiRequest("get", "api/1/addresses/", "", headers, true);
      if (response) {
        const latlogArray = response.data.results.map((result) => [
          result.lat,
          result.lon,
          result.address_uuid,
        ]);
        const siteAddresses = response.data.results.map((result) => result.name);

        return { latlogArray, siteAddresses };
      }
    } catch (error) {
      console.error("Error fetching address list:", error);
      return { latlogArray: [], siteAddresses: [] };
    }
  };

  return { fetchAddressList };
};
