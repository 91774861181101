import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const TableUserTop = ({ title, data, totalUsers }) => {
  if (!data?.headers || !data?.bodyRows) return <p>No data available</p>;

  return (
    <TableContainer component={Paper} className="custom-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={data.headers.length} style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "16px",
                }}
              >
                <Typography variant="subtitle1" style={{ margin: 0 }}>
                  {title || "Data Table"}
                </Typography>
                <Typography variant="body1" style={{ margin: 0 }}>
                  Total: {data.totalUsers}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            {data.headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {data.bodyRows.slice(0, -1).map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableUserTop;
