import React, {useState, useEffect, useCallback, useRef} from "react";
import {MapContainer, TileLayer, Marker, Popup} from 'react-leaflet';
import { tileAttributionHtml, tileUrl } from "../../constants/common";

export default function Leaflet({lat, lng, setFormData, formData, pinDrag, toast}) {
  // Remove reloadMap state as it's causing re-render issues
  
  // Validate latitude and longitude values
  const validateCoordinates = useCallback(() => {
    // Check if coordinates are provided
    if (lat === undefined || lat === "" || lng === undefined || lng === "") {
      toast?.error("Latitude and longitude are required");
      return false;
    }
    
    // Convert to numbers for validation
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);
    
    // Check if values are valid numbers
    if (isNaN(latitude) || isNaN(longitude)) {
      toast?.error("Latitude and longitude must be valid numbers");
      return false;
    }
    
    // Check latitude range (-90 to 90)
    if (latitude < -90 || latitude > 90) {
      toast?.error("Latitude must be between -90 and 90 degrees");
      return false;
    }
    
    // Check longitude range (-180 to 180)
    if (longitude < -180 || longitude > 180) {
      toast?.error("Longitude must be between -180 and 180 degrees");
      return false;
    }
    
    return true;
  }, [lat, lng, toast]);

  // Validate coordinates when lat or lng change
  useEffect(() => {
    validateCoordinates();
  }, [lat, lng, validateCoordinates]);

  function DraggableMarker() {
    const center = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };
    const [draggable, setDraggable] = useState(true);
    const [position, setPosition] = useState(center);
    const markerRef = useRef(null);

    // Update position when lat/lng props change
    useEffect(() => {
      if (lat && lng) {
        setPosition({
          lat: parseFloat(lat),
          lng: parseFloat(lng)
        });
      }
    }, [lat, lng]);

    const eventHandlers = useCallback(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            const latLng = marker.getLatLng();
            setFormData({...formData, lat: latLng.lat, lon: latLng.lng});
            setPosition(latLng);
            if (pinDrag) pinDrag(latLng);
          }
        },
      }),
      [setFormData, formData]
    );
    
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d);
    }, []);

    return (
      <Marker
        draggable={draggable}
        eventHandlers={eventHandlers()}
        position={position}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup>
      </Marker>
    );
  }

  // Don't render the map if coordinates are invalid
  if (!lat || !lng || !validateCoordinates()) {
    return null;
  }

  // Only attempt to render the map when we have valid coordinates
  return (
    <>
      <MapContainer center={[parseFloat(lat), parseFloat(lng)]} zoom={12} scrollWheelZoom={false}>
        <DraggableMarker />
        <TileLayer url={tileUrl} attribution={tileAttributionHtml} />
      </MapContainer>
    </>
  );
}