import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getLocal, checkUserRoutePermission } from "../../../helper/common";

export default function DetailsSubmenu({ route }) {
  let role = getLocal("role");
  const params = useParams();
  return (
    <div className="position-relative">
      <div className="nav-head">
        <ul className="nav nav-tabs header-tabs bg-27">
          {checkUserRoutePermission(
            role,
            `/${route}/customer/cameras/live-camera/:device_uuid`
          ) && (
            <li className="nav-item" role="presentation">
              <NavLink
                to={`/${route}/customer/cameras/live-camera/${params.device_uuid}`}
                className="nav-link"
              >
                Live Camera
              </NavLink>
            </li>
          )}
          {checkUserRoutePermission(
            role,
            `/${route}/customer/cameras/details/:device_uuid`
          ) && (
            <li className="nav-item" role="presentation">
              <NavLink
                to={`/${route}/customer/cameras/details/${params.device_uuid}`}
                className="nav-link"
              >
                Details
              </NavLink>
            </li>
          )}
          {checkUserRoutePermission(
            role,
            `/${route}/customer/cameras/notifications/:device_uuid`
          ) && (
            <li className="nav-item" role="presentation">
              <NavLink
                to={`/${route}/customer/cameras/notifications/${params.device_uuid}`}
                className="nav-link"
              >
                Notification
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
