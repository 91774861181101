import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import GroupSidebar from "../../common/GroupSidebar";
import HeaderDashboard from "../../common/HeaderDashboard";
import Svg from "../../common/Svg";
import { useNavigate } from "react-router-dom";
import { useUiContext } from "../../../contexts/UiContext";
import DisplayControls from "../cameras/DisplayControl";
import { useToken } from "../cameras/services/useToken";
import CameraGrid from "../cameras/components/CameraGrid";
import { baseUrl } from "../../../constants/common";
import { getAuthHeaders } from "../../../helper/utils";

export default function Details({ route }) {
  const headers = getAuthHeaders();
  const { authToken, setAuthToken, getTokens } = useToken();
  const [group, setGroup] = useState(null);
  const [checkedDevice, setCheckedDevice] = useState([]);
  const [enable, setEnable] = useState(true);
  const [createGroup, setCreateGroup] = useState("");
  const [groupUuid, setGroupUuid] = useState("");
  const [defaultView, setDefaultView] = useState("LIST_VIEW");
  const { group_uuid } = useParams();
  const [groupList, setGroupList] = useState({ groups: [], checkedList: {} });
  const {
    apiRequest,
    sortBy,
    noOfColumnsGroupCameras,
    noOfColumns,
    setNoOfColumns,
    setNoOfColumnsGroupCameras,
    searchFilter,
    setSearchFilter,
  } = useUiContext();
  const noForBootstrapClass = 12 / noOfColumnsGroupCameras;
  const navigate = useNavigate();

  // Get group device list
 // Get group device list
const getDevicesInGroup = async () => {
  try {
    // Get token first for all requests
    const token = await getTokens();
    setAuthToken(token);
    
    // Get group data
    let res = await apiRequest(
      "get",
      `api/1/groups/${group_uuid}/`,
      {},
      headers,
      true
    );
    
    let response = res.data;
    if (response?.devices?.length > 0) {
      let devices = response.devices;
      setGroupUuid(response.group_uuid)
      try {
        for (let i = 0; i < devices.length; i++) {
          if (devices[i].is_active) {
            // let res = await apiRequest(
            //   "get",
            //   `api/1/devices/${devices[i].device_uuid}/mjpg_img_src/`,
            //   {},
            //   headers,
            //   true
            // );
            // if (res) {
            //   let response = res.response.data;
            //   devices[i].live_url = response;
            // }
            devices[i].live_url = `${baseUrl}api/public/mjpg_img_src/${devices[i].device_uuid}/`;
          }
        }
        response.devices = devices;
      } catch (error) {
        console.log("error: ", error);
      }
    }
    setAuthToken(token);
    setGroup(response);
  } catch (error) {
    console.error("Error in getDevicesInGroup:", error);
  }
};

  useEffect(() => {
    getDevicesInGroup();
  }, [enable]);

  useEffect(() => {
    const savedGroupUuid = localStorage.getItem("groupUuid");
    if (savedGroupUuid) {
      setGroupUuid(savedGroupUuid);
    }
  }, []);

  // API for Add Device in the group
  const adddevice = async (device_uuid) => {
   
    await apiRequest(
      "post",
      `api/1/groups/${group_uuid}/add_device_to/`,
      { device_uuid: device_uuid },
      headers,
      true,
      { success: "Camera has been added", error: "Failed to add camera" }
    );
    getDevicesInGroup();
    navigate("/customer/groups");
  };

  const handleAddDevice = () => {
    for (let i = 0; i < checkedDevice.length; i++) {
      adddevice(checkedDevice[i]);
    }
    setEnable(true);
  };

  const groupsList = async () => {
   
    let params = {};
    if (groupUuid) params.group_uuid = groupUuid;
    if (searchFilter) params.name__contains = searchFilter;
    if (sortBy) params.name__contains = sortBy;
    return await apiRequest(
      "get",
      "api/1/groups/",
      {},
      headers,
      true,
      { success: "", error: "" },
      params
    )
      .then((res) => {
        let checkedList = {};
        res.data.results.map((el) => {
          checkedList[el.group_uuid] = false;
          return el;
        });
        setGroupList({
          ...groupList,
          groups: res.data.results,
          checkedList: checkedList,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    groupsList().then((_) => {});
  }, [groupUuid, searchFilter, sortBy]);

  // API for remove Device from the group
  const removeGroupDevice = async (device_uuid) => {
    try {
      let res = await apiRequest(
        "post",
        `api/1/groups/${group_uuid}/remove_device_from/`,
        { device_uuid },
        headers,
        true,
        { success: "Camera has been removed", error: "Failed to removed camera" }
      );
      
      if (res) {
        // Use a functional update to avoid stale closures
        setGroup(prevGroup => {
          const updatedDevices = prevGroup.devices.filter(
            device => device.device_uuid !== device_uuid
          );
          return { ...prevGroup, devices: updatedDevices };
        });
      }
    } catch (error) {
      console.error("Error removing device:", error);
    }
  };

  const deleteHandler = (device_uuid) => {
    removeGroupDevice(device_uuid);
  };

  const isAdmin = localStorage.getItem("isadmin") === "true" ? true : false;

  // Create group API
  const createHandler = (e) => {
    e.preventDefault();
    newGroup(createGroup).then((_) => {});
  };

  const newGroup = async (createGroup) => {

    let uid = localStorage.getItem("customeruuid");
    return await apiRequest(
      "post",
      "api/1/groups/",
      { customer_uuid: uid, name: createGroup },
      headers,
      true,
      {
        success: "Group has been created",
        error: "Failed to create group",
        showToast: true,
      }
    ).then((_) => groupsList());
  };

  const handleResponsiveColumns = (columns) => {
    setNoOfColumnsGroupCameras(columns);
    setNoOfColumns(columns);
  };

  const defaultViewApi = (view) => {
    console.log(`Default view set to: ${view}`);
  };

  const typingTimerRef = useRef(null);
  
  const handleSearch = (value) => {
    console.log("Search query:", value);
    clearTimeout(typingTimerRef.current);
    typingTimerRef.current = setTimeout(() => setSearchFilter(value), 1000);
  };
 
  return (
    <>
      <div className="d-flex flex-column site-main-wrapper grid-page">
        <HeaderDashboard route={route} />
        <div className="flex-grow-1 main-wrapper">
          {/* listing page */}

          <div className="main-page-height devices-page d-flex">
            <GroupSidebar
              enable={enable}
              setCheckedDevice={setCheckedDevice}
              handleAddDevice={handleAddDevice}
              setEnable={setEnable}
              route={route}
            />
            <div className="flex-grow-1">
              <div className="d-flex flex-column">
                {isAdmin ? (
                  <div className="camera-header d-flex align-items-center details-header">
                    <div className="flex-grow-1 available-heading ps-0">
                      <h2>
                        Create New Group details
                        <button
                          className="add-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#addModal"
                        >
                          <Svg name="add-gropup-note" />
                        </button>
                      </h2>
                    </div>
                  </div>
                ) : null}
                {/* Display Navigation */}
                <div className="camera-header d-flex align-items-center details-header">
                  <div className="row mb-4">
                    <DisplayControls
                      activeColumn={noOfColumns}
                      handleResponsiveColumns={handleResponsiveColumns}
                      defaultView={defaultView}
                      setDefaultView={setDefaultView}
                      defaultViewApi={defaultViewApi}
                      LIST_VIEW="LIST_VIEW"
                      SITE_VIEW="SITE_VIEW"
                      onSearch={handleSearch}
                      route={route}
                      addDeviceRoute={`/${route}/cameras/add-new-device`}
                      showListView={false}
                      showDropdown={false}
                      showSearchBar={false}
                      showAddButton={false}
                    />
                  </div>
                </div>
                {/* Display Navigation */}
                <div className="flex-grow-1 camera-list-wrapper">
                  <div
                    className="groups-list-wrapper accordion"
                    id="accordionGroups"
                  >
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="accordion-item">
                          <button
                            className="accordion-button no-icon mb-4"
                            type="button"
                          >
                            {group?.name}
                          </button>
                          {/* Camera List Start Here */}
                          <CameraGrid
                            route={route}
                            deviceList={group?.devices || []}
                            columns={noOfColumns}
                            token={authToken}
                            groupUuid={groupUuid || ''}
                            showDeleteButtons={true} // Control visibility
                            onDeleteClick={(id) => deleteHandler(id)} // Handle delete action
                          />
                          {/* Camera List Start Here */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade add-modal"
        id="addModal"
        tabIndex={-1}
        aria-labelledby="addModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {isAdmin ? (
              <div className="modal-header">
                <h5 className="modal-title text-center">Create new group</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <Svg name="close-rounded" />
                </button>
              </div>
            ) : null}
            <div className="modal-body">
              <div className="theme-form-wrapper">
                <form>
                  <div className="d-flex mb__20 align-items-center">
                    <label
                      htmlFor="groupName"
                      className="col-form-label text-end"
                    >
                      Group name
                    </label>
                    <div className="flex-grow-1">
                      <input
                        type="text"
                        onChange={(e) => setCreateGroup(e.target.value)}
                        className="form-control"
                        id="groupName"
                        placeholder="Group name"
                      />
                    </div>
                  </div>
                  <div className="button-wrap text-center">
                    <button
                      className="btn btn-primary btn-rounded"
                      type="button"
                      data-bs-dismiss="modal"
                      onClick={createHandler}
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
