import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableFooter,
  Pagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";

export default function TableCustomerList({data}) {
  const [page, setPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <Typography variant="h6" style={{ padding: "8px 0", color: "white" }}>
        Customer List
      </Typography>
      <GridToolbarQuickFilter
        quickFilterParser={(value) => value.split(",")}
        style={{ width: "300px" }}
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          "& .MuiInputBase-root": { color: "#fff" },
          "& .MuiSvgIcon-root": { color: "#fff" },
          "& .MuiOutlinedInput-root": { borderColor: "#6200c0" },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "#6200c0" },
          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "#6200c0" },
        }}
      />
    </GridToolbarContainer>
  );
  
  const handleEdit = (id) => {
    console.log(`Edit clicked for row with ID: ${id}`);
    // Your edit logic here
  };
  
  const handleDelete = (id) => {
    console.log(`Delete clicked for row with ID: ${id}`);
    // Your delete logic here
  };
  
  const rows = data.map((customer, index) => ({
    id: index + 1,
    name: customer.name,
    entity: customer.seller_type,
    abn: customer.abn,
    email: customer.contact_email,
    timezone: customer.timezone,
  }));
  
  const rowsPerPage = 5;

  const paginatedRows = rows.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const columns = [
    { field: "id", headerName: "ID", flex: 0.1 }, // Small width for the ID column
    { field: "name", headerName: "Name", flex: 0.15 }, // Slightly larger space for "Asset"
    { field: "entity", headerName: "Entity Type", flex: 0.1 }, // Larger space for camera names
    { field: "abn", headerName: "ABN", flex: 0.1 }, // "Client" will also share space
    { field: "email", headerName: "Email", flex: 0.1 }, // "Location" can take up more space
    { field: "timezone", headerName: "Timezone", flex: 0.1 },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   flex: 0.2, // Use flex to allocate space for the action column
    //   renderCell: (params) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-around",
    //         width: "100%",
    //       }}
    //     >
    //       <GridActionsCellItem
    //         icon={<Edit style={{ color: "white" }} />} // Edit icon in white
    //         onClick={() => handleEdit(params.row.id)}
    //         showInMenu
    //       />
    //       <GridActionsCellItem
    //         icon={<Delete style={{ color: "red" }} />} // Trash icon in red
    //         onClick={() => handleDelete(params.row.id)}
    //         showInMenu
    //       />
    //     </div>
    //   ),
    // },
  ];

  return (
    <div>
      <TableContainer component={Paper} className="custom-table">
        <Table>
          {/* DataGrid with Checkbox Selection */}
          <DataGrid
            rows={paginatedRows}
            columns={columns}
            pageSize={rowsPerPage}
            checkboxSelection // Enable checkbox selection
            className="custom-datagrid"
            slots={{ toolbar: CustomToolbar }}
            sx={{
              "& .MuiDataGrid-root": {
                borderColor: "none",
              },
              "& .MuiDataGrid-container--top": {
                backgroundColor: "#000",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#000",
                color: "#fff",
              },
              "& .MuiDataGrid-row--borderBottom": {
                background: "#000 !important",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#fff",
              },
              "& .MuiCheckbox-root": {
                color: "white !important",
              },
            }}
            hideFooter // Hide the default footer
          />
          {/* Custom Pagination */}
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(rows.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    className="custom-pagination"
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
