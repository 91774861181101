import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useUiContext } from '../../../../contexts/UiContext'
import { checkUserRoutePermission, getLocal } from '../../../../helper/common';
import HeaderDashboard from '../../common/HeaderDashboard'
import SettingNavigation from '../../common/SettingNavigation'

export default function ResellersSystemIntegrators({ route }) {
    let role = getLocal("role")
    let uid = localStorage.getItem("customeruuid")
    const { apiRequest } = useUiContext();
    const location = useLocation();
    const [data, setdata] = useState([]);
    const [routeRole, setRouteRole] = useState("");
    const endpoint = (routeRole === 'customer') ? 'sellers' : (routeRole === 'reseller') ? 'distributors' : 'distributors'
    const queryID = (routeRole === 'customer') ? 'customer_uuid' : (routeRole === 'reseller') ? 'seller_uuid' : 'distributor_uuid'
    const setUserData = async () => {
        if (route === 'reseller') {
            const customerData = await getCustomerData();
            const distributorsData = await getDistributorsData();
            setdata([...customerData, ...distributorsData])
        } else {
            getSellersData();
        }
    }
     useEffect(() => {
        const routeSiteSentry = location.pathname
          .split("/site-sentry/")[1]
          ?.split("/")[0];
        console.log("Extracted Role:", routeSiteSentry); // ✅ Outputs "customer"
        setRouteRole(routeSiteSentry);
      }, [location.pathname]);
      
    useEffect(() => {
        if (!routeRole) return;
        setUserData()
    }, [routeRole])

    const getSellersData = async () => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/sellers/?${queryID}=${uid}`, '', headers, true);
        if (res) {
            setdata(res.data.results);
        }

    }
    const getCustomerData = async () => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/customers/?${queryID}=${uid}`, '', headers, true);
        if (res) {
            const customer = [];
            res.data.results.map((data) => {
                data['seller_type'] = "customer";
                customer.push(data)
            });
            return customer;
        }
        return []
    }
    const getDistributorsData = async () => {
        let headers = {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'X-verified-JWT': localStorage.getItem("x_verified_jwt"),
        }
        const res = await apiRequest('get', `api/1/distributors/?${queryID}=${uid}`, '', headers, true);
        if (res) {
            const distributors = [];
            res.data.results.map((data) => {
                data['seller_type'] = "distributors";
                distributors.push(data)
            });
            return distributors;
        }
        return []
    }

    return (
        <div className='d-flex flex-column site-main-wrapper setting-body'>
            <HeaderDashboard />
            <div className="flex-grow-1 main-wrapper">
                <div className="main-page-height settings-page d-flex flex-column">
                    <SettingNavigation />
                    <div className="flex-grow-1 setting-page-wrapper">
                        <div className="container">
                            {data?.map((res, index) =>
                                <div className="d-lg-flex align-items-center" key={index}>
                                    <div className="user-permissions ResellersSystemIntegrators devices-storage-wrapper ps-0 ps-lg-4 flex-grow-1" key={index}>
                                        <div className="devices-storage-list">
                                            <div className="user-prm-list">
                                                <div className="d-flex data-list">
                                                    <div className="title">Entity type</div>
                                                    <div className="data flex-grow-1 text-end ps-2">{res.seller_type ? res.seller_type : route}</div>
                                                </div>
                                                <div className="d-flex data-list">
                                                    <div className="title">Name</div>
                                                    <div className="data flex-grow-1 text-end ps-2">{res.name}</div>
                                                </div>
                                                <div className="d-flex data-list">
                                                    <div className="title">ABN</div>
                                                    <div className="data flex-grow-1 text-end ps-2">{res.abn}</div>
                                                </div>
                                                <div className="d-flex data-list">
                                                    <div className="title">Email</div>
                                                    <div className="data flex-grow-1 text-end ps-2">{res.contact_email}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {checkUserRoutePermission(role, `/${route}/${routeRole}/create-reseller`) && <div className="button-wrap text-center pt-lg-4 pt-3">
                            <Link to={`/${route}/${routeRole}/create-reseller`} className='btn btn-secondary btn-rounded btn-300'>{routeRole === 'distributor' ? 'Create Reseller' : 'Create Customer'}</Link>
                        </div>}

                    </div>
                </div>
            </div>
        </div>
    )
}
