import React from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableFooter,
  Pagination,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import {
  GridActionsCellItem,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";

const rowsPerPage = 5;
export default function TableSites({data, handleDelete}) {
  let navigate = useNavigate();
  const [page, setPage] = React.useState(1);

  const handleEdit = (id) => {
    navigate(`${siteAssetManagement}/sites/site-form/edit/${id}`);
  };
  

  const columns = [
    { field: "siteName", headerName: "Site Name", flex: 0.15 }, // Slightly larger space for "Asset"
    { field: "address", headerName: "Address", flex: 0.2 }, // "Client" will also share space
    { field: "longitude", headerName: "Longitude", type: "text", flex: 0.1 },
    { field: "latitude", headerName: "latitude", type: "text", flex: 0.1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.1, // Use flex to allocate space for the action column
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <GridActionsCellItem
            icon={<Edit style={{ color: "white" }} />} // Edit icon in white
            onClick={() => handleEdit(params.row.id)}
            showInMenu
          />
          <GridActionsCellItem
            icon={<Delete style={{ color: "red" }} />} // Trash icon in red
            onClick={() => handleDelete(params.row.id)}
            showInMenu
          />
        </div>
      ),
    },
  ];

  const rows = data.map((site, index) => ({
    id: site.address_uuid,
    index: index,
    siteName: site.name,
    address: site.address,
    longitude: site.lon,
    latitude: site.lat,
    status: site.devices_count > 0 ? "Active" : "Inactive", // Example logic
  }));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedRows = rows.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "1rem",
      }}
    >
      <Typography variant="h6" style={{ padding: "8px 0", color: "white" }}>
        Location List
      </Typography>
      <GridToolbarQuickFilter
        quickFilterParser={(value) => value.split(",")}
        style={{ width: "300px" }}
        sx={{
          backgroundColor: "#000",
          color: "#fff",
          "& .MuiInputBase-root": { color: "#fff" },
          "& .MuiSvgIcon-root": { color: "#fff" },
          "& .MuiOutlinedInput-root": { borderColor: "#fff" },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
          "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "white" },
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <div>
      <TableContainer component={Paper} className="custom-table">
        <Table>
          {/* DataGrid with Checkbox Selection */}
          <DataGrid
            rows={paginatedRows}
            columns={columns}
            pageSize={rowsPerPage}
            // checkboxSelection // Enable checkbox selection
            className="custom-datagrid"
            slots={{ toolbar: CustomToolbar }}
            sx={{
              "& .MuiDataGrid-container--top": {
                backgroundColor: "#000",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#000",
                color: "#fff",
              },
              "& .MuiDataGrid-row--borderBottom": {
                background: "#000 !important",
              },
              "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
                color: "#fff",
              },
              "& .MuiCheckbox-root": {
                color: "white !important",
              },
            }}
            hideFooter // Hide the default footer
          />
          {/* Custom Pagination */}
          <TableFooter>
            <TableRow>
              <TableCell colSpan={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(rows.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                    className="custom-pagination"
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
