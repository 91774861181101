import React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./component/PrivateRoutes";
import Redirect from "./component/Redirect";
import Loader from "./component/loader";
import "react-tooltip/dist/react-tooltip.css";
import ResetPassword from "./views/authentication/forgot-password/ResetPassword";
import SuccessMessage from "./views/authentication/forgot-password/Success";
import VerifyCode from "./views/authentication/forgot-password/VerifyCode";
import VerifyEmail from "./views/authentication/forgot-password/VerifyEmail";
import Login from "./views/authentication/Login";
import Signup from "./views/authentication/Signup";
import SignupAddOtp from "./views/authentication/SignupAddOtp";
import SignupComplete from "./views/authentication/SignupComplete";
import PrivacyPolicy from "./views/content-pages/PrivacyPolicy";
import Terms from "./views/content-pages/Terms";
import HeaderMetaData from "./views/common/HeaderMetaData";
import Iframe from "./views/iframe";
import HeaderSuperUser from "./views/site-sentry/common/HeaderSuperUser";
import Dashboard from "./views/super-user/Dashboard";
import CamerasSuper from "./views/super-user/Cameras";
import Reports from "./views/super-user/Reports";
import SitesLocation from "./views/super-user/Sites";
import SiteForm from "./views/super-user/components/Site/SiteForm";
import Users from "./views/super-user/Users";
import SuperCustomer from "./views/super-user/SuperCustomer";
import CustomerForm from "./views/super-user/components/Customer/CustomerForm";
import CameraForm from "./views/super-user/components/Camera/CameraForm";
import UserForm from "./views/super-user/components/User/UserForm";
import MapSiteForm from "./views/super-user/components/Site/MapSiteForm";

import { dynamicRoutesPath } from "./views/DynamicRoute";

function App() {

  return (
    <>
      <Loader />
      <Toaster position="top-center" reverseOrder={false} />
      <HeaderMetaData />
      <Routes>
        {/* <Route exact path="/distributor/edit-reseller/:seller_id" element={<ProtectedRoute Component={CreateReseller} />}> </Route> */}
        {/*<Route exact path="/customer/recordings/notes" element={<ProtectedRoute Component={Notes} />}> </Route> */}
        <Route
          path="/site-sentry/asset-management"
          element={<ProtectedRoute Component={HeaderSuperUser} />}
        >
          <Route index element={<ProtectedRoute Component={Dashboard} />} />
          <Route
            path="customer"
            element={<ProtectedRoute Component={SuperCustomer} />}
          />
          <Route
            path="customer/customer-form"
            element={<ProtectedRoute Component={CustomerForm} />}
          />
          <Route
            path="cameras"
            element={<ProtectedRoute Component={CamerasSuper} />}
          />
           <Route
            path="cameras/camera-form"
            element={<ProtectedRoute Component={CameraForm} />}
          />
          <Route
            path="cameras/camera-form/edit/:device_uuid"
            element={<ProtectedRoute Component={CameraForm} />}
          />
          <Route
            path="reports"
            element={<ProtectedRoute Component={Reports} />}
          />
          <Route
            path="sites"
            element={<ProtectedRoute Component={SitesLocation} />}
          />
           <Route
            path="sites/site-form"
            element={<ProtectedRoute Component={SiteForm} />}
          />
          <Route
            path="sites/site-form/edit/:address_uuid"
            element={<ProtectedRoute Component={SiteForm} />}
          />
          <Route
            path="sites/site-map-form/:address_uuid/site/:site_uuid"
            element={<ProtectedRoute Component={MapSiteForm} />}
          />
           <Route
            path="sites/site-map-form/:address_uuid/create-map-site"
            element={<ProtectedRoute Component={MapSiteForm} />}
          />
          <Route path="users" element={<ProtectedRoute Component={Users} />} />
          <Route
            path="users/user-form"
            element={<ProtectedRoute Component={UserForm} />}
          />
          <Route
            path="users/user-form/edit/:username"
            element={<ProtectedRoute Component={UserForm} />}
          />
        </Route>
        <Route exact path="/" element={<Redirect Component={Login} />}>
          {" "}
        </Route>
        <Route exact path="/login" element={<Redirect Component={Login} />}>
          {" "}
        </Route>
        <Route exact path="/iframe" element={<Redirect Component={Iframe} />}>
          {" "}
        </Route>
        <Route exact path="/sign-up" element={<Redirect Component={Signup} />}>
          {" "}
        </Route>
        <Route
          exact
          path="/sign-up-verify"
          element={<Redirect Component={SignupAddOtp} />}
        >
          {" "}
        </Route>
        <Route
          exact
          path="/sign-up-complete"
          element={<Redirect Component={SignupComplete} />}
        >
          {" "}
        </Route>
        <Route
          exact
          path="/verify-email"
          element={<Redirect Component={VerifyEmail} />}
        >
          {" "}
        </Route>
        <Route
          exact
          path="/verify-code"
          element={<Redirect Component={VerifyCode} />}
        >
          {" "}
        </Route>
        <Route
          exact
          path="/reset-password"
          element={<Redirect Component={ResetPassword} />}
        >
          {" "}
        </Route>
        <Route exact path="/success-message" element={<SuccessMessage />}>
          {" "}
        </Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}>
          {" "}
        </Route>
        <Route exact path="/terms" element={<Terms />}>
          {" "}
        </Route>
        {dynamicRoutesPath.map(
          ({ path, component, enterprisePath, enterpriseComponent }) => (
            <>
              <Route
                key={path}
                exact
                path={path}
                element={<ProtectedRoute Component={component} />}
              />
              {enterprisePath && (
                <Route
                  key={enterprisePath}
                  exact
                  path={enterprisePath}
                  element={<ProtectedRoute Component={enterpriseComponent} />}
                />
              )}
            </>
          )
        )}
      </Routes>
    </>
  );
}

export default App;
