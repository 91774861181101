// services/addressService.js
import { getAuthHeaders } from "../../../../helper/utils";
import { toast } from "react-hot-toast";

const getHeaders = () => getAuthHeaders();
const uploadHeaders = {
  ...getHeaders(),
  "Content-Type": "multipart/form-data",
};

export const getSiteList = async (apiRequest) => {
  try {
    const res = await apiRequest("get", "api/1/addresses/", "", getHeaders(), true);
    toast.success("Site Data Fetched");
    return res ? res.data.results : []; // Return data instead of setting state here
  } catch (error) {
    console.error("Error fetching addresses:", error);
    return []; // Return an empty array in case of failure
  }
};

export const deleteAddress = async (addressUUID, apiRequest) => {
    try {
        const res = await apiRequest(
        "delete",
        `api/1/addresses/${addressUUID}/`,
        "",
        getHeaders(),
        true,
        { success: "Deleted Successfully", error: "Address not deleted" }
      );
      return res
    } catch (error) {
      toast.error("Error deleting address:", error);
      return null;
    }
  };

  export const createAddress = async (formData, apiRequest) => {
  
    try {
      const res = await apiRequest(
        "post",
        "api/1/addresses/",
        formData,
        getHeaders(),
        true,
        { success: "Address created successfully", error: "Address not created" }
      );
  
      if (res && res.response && res.response.data) {
        const errorsData = res.response.data;
        return {
          success: false,
          errors: {
            name: errorsData.name ? errorsData.name[0] : "",
            lat: errorsData.lat ? errorsData.lat[0] : "",
            lon: errorsData.lon ? errorsData.lon[0] : "",
          },
        };
      }
      return { success: true };
    } catch (error) {
      console.error("Error creating address:", error);
      return { success: false, errors: {} };
    }
  };

  export const getAddressDetail = async (addressID, apiRequest) => {
    try {
      const res = await apiRequest(
        "get",
        `api/1/addresses/${addressID}/`,
        "",
        getHeaders(),
        true
      );
      return res;
    } catch (error) {
      toast.error("Error fetching address details:", error);
      return { error: true, message: error?.message || "Failed to fetch address details" };
    }
  };
  

  export const updateSiteData = async (addressID, formData, apiRequest) => {
    try {
      const res = await apiRequest(
        "patch",
        `api/1/addresses/${addressID}/`,
        formData,
        getHeaders(),
        true,
        { success: "Updated Successfully", error: "Details Not Updated" }
      );
  
      if (res && res.response && res.response.data) {
        const errorsData = res.response.data;
        return {
          success: false,
          errors: {
            name: errorsData.name?.[0] || "",
            lat: errorsData.lat?.[0] || "",
            lon: errorsData.lon?.[0] || "",
          },
        };
      }
      return { success: true };
    } catch (error) {
      console.error("Error updating address:", error);
      toast.error("Details Not Updated");
      return { success: false, errors: {} };
    }
  };

  // services/siteService.js
export const getSiteData = async (sitemap_uuid, apiRequest) => {
  try {
    const res = await apiRequest("get", `api/1/sitemaps/${sitemap_uuid}/`, {}, getHeaders());
    return res.data; // Return response data
  } catch (error) {
    console.error("Error fetching site data:", error);
    return { error: true, message: error?.message || "Failed to fetch site data" };
  }
};

export const getSiteAndAddressData = async (sitemap_uuid, addressID, apiRequest) => {
  try {
    // Call both APIs in parallel
    const [siteData, addressData] = await Promise.all([
      getSiteData(sitemap_uuid, apiRequest),
      getAddressDetail(addressID, apiRequest)
    ]);
    
    // Handle errors
    if (siteData?.error) {
      throw new Error(siteData.message);
    }
    if (addressData?.error) {
      throw new Error(addressData.message);
    }
    // Return combined data
    return {
      address: addressData.data.address || "",
      siteName: addressData.data.name || "",
      name: siteData.name || "",
      images: siteData.image_map || null,
      default_sitemap: siteData.default_sitemap || false,
    };
  } catch (error) {
    console.error("Error fetching site and address data:", error);
    toast.error(error.message);
    return null;
  }
};


export const deleteSiteMap = async (sitemap_uuid, apiRequest) => {

  try {

    await apiRequest("delete", `api/1/sitemaps/${sitemap_uuid}/`, "", getHeaders(), true);
    return { success: true, message: "Sitemap deleted successfully" };
  } catch (error) {
    toast.error("Error deleting site map:", error);
    return { success: false, message: "Sitemap not deleted" };
  }
};

export const updateSiteMap = async (sitemap_uuid, formData, apiRequest) => { 
  try {
    // Update site map details
    const res = await apiRequest("patch", `api/1/sitemaps/${sitemap_uuid}/`, formData, getHeaders(), true);

    if (res.status === 200) {
      let new_sitemap_uuid = res.data.sitemap_uuid;

      // Upload image if provided
      if (formData.images) {
        const imageData = new FormData();

        // Check if images is an array or a single file
        if (Array.isArray(formData.images)) {
          formData.images.forEach((image) => {
            imageData.append("image_map", image);
          });
        } else {
          imageData.append("image_map", formData.images);
        }

        await apiRequest("post", `api/1/sitemaps/${new_sitemap_uuid}/upload_img/`, imageData, uploadHeaders, true);
      }

      return { success: true, message: "Sitemap updated successfully" };
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      let response = error.response.data;
      let errorMsg = response.name
        ? response.name[0]
        : response.default_sitemap
        ? response.default_sitemap[0]
        : "Update failed";
      return { success: false, message: errorMsg };
    }

    console.error("Update Sitemap Error:", error);
    toast.error("Sitemap not updated");
    return { success: false, message: "Sitemap not updated" };
  }
};

export const createSiteMap = async (formData, apiRequest) => {

  const headers = getHeaders();

  try {
  
   // Create the sitemap
    const res = await apiRequest("post", `api/1/sitemaps/`, formData, getHeaders(), true);
  
    if (res.status === 201) {
      const sitemap_uuid = res.data.sitemap_uuid;

      // Upload image if provided
      if (formData.images) {
        const imageData = new FormData();
        if (Array.isArray(formData.images)) {
          formData.images.forEach((image) => imageData.append("image_map", image));
        } else {
          imageData.append("image_map", formData.images);
        }

        headers["Content-Type"] = "multipart/form-data";
        await apiRequest("post", `api/1/sitemaps/${sitemap_uuid}/upload_img/`, imageData, getHeaders(), true);
      }
      
      return { success: true, sitemap_uuid };
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      return { success: false, error: error.response.data };
    }
    console.error("Create Sitemap Error:", error);
    return { success: false, error: "Sitemap not added" };
  }
};


export const getAddress = async (address, loggedinUserId, apiRequest) => {
  
  try {

    const res = await apiRequest(
      "post",
      `api/1/customers/${loggedinUserId}/geocode_address_text/`,
      { address: address },
      getHeaders(),
      true
    );

    if (res.response && res.response.data.errors) {
      toast.error(res.response.data.errors.address);
      return { success: false, errors: res.response.data.errors };
    }

    if (res.status === 422) {
      return {
        success: false,
        data: {
          address: "",
          lat: "",
          lon: "",
        },
      };
    }

    if (res.data) {
      let locationData = res.data.location;
      return {
        success: true,
        data: {
          address: res.data.address,
          lat: locationData.lat,
          lon: locationData.lon,
        },
      };
    }

    return { success: false, message: "Unexpected error" };
  } catch (error) {
    console.error("Error fetching address:", error);
    toast.error("Failed to fetch address");
    return { success: false, message: error?.message || "Something went wrong" };
  }
};
