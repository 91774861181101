import { getAuthHeaders } from "../../../../../helper/utils";

export const addDeviceService = async (formData, apiRequest, navigate) => {
  const headers = getAuthHeaders();

  try {
    console.log("Request:", {
      method: "post",
      url: "api/1/devices/",
      data: formData,
      headers: headers,
    });

    const response = await apiRequest(
      "post",
      "api/1/devices/",
      formData,
      headers,
      true,
      {
        success: "Device added successfully",
        error: "Failed to add device",
        showToast: true,
      }
    );

    console.log("Response:", response);
    navigate("/site-sentry/customer/cameras");
  } catch (error) {
    console.error("Device creation failed:", error);
  }
};
