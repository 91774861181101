import { useState } from "react";
import { useUiContext } from "../../../../../contexts/UiContext"; // Adjust the path based on your project structure
import { getAuthHeaders } from "../../../../../helper/utils";

export const useGroupList = () => {
  const { apiRequest } = useUiContext();
  const [groups, setGroups] = useState([]);

  const fetchGroups = async () => {
    try {
      const headers = getAuthHeaders();
      const response = await apiRequest("get", "api/1/groups/", "", headers, false);

      if (response.data?.results) {
        setGroups(response.data.results);
      } else {
        console.warn("No group data returned:", response.data);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  return { groups, fetchGroups };
};
