// hooks/useUsers.js
import { useState, useEffect } from "react";
import { useUiContext } from "../../../../contexts/UiContext";
import { fetchUsers } from "./userService";

const useUsers = (endpoint, loader, userServerParams) => {
  const { apiRequest } = useUiContext();
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  
  useEffect(() => {
    const getUsers = async () => {
      const { users, totalPages } = await fetchUsers(endpoint, loader, apiRequest, userServerParams);
      setUsers(users);
      setTotalPages(totalPages);
    };
    getUsers();
  }, [loader, userServerParams]);

  return { users, totalPages };
};

export default useUsers;
