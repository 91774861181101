import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUiContext } from "../../../../contexts/UiContext";
import { Grid, Button, Typography, IconButton } from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import CustomSelect from "../Global/CustomSelect";
import CustomTextField from "../Global/CustomTextField";
import { siteAssetManagement } from "../../../site-sentry/common/Utility";
import {
  getTimezoneService,
  createCustomerService,
} from "./services/customerService";

export default function CustomerForm() {
  const navigate = useNavigate();
  const { apiRequest } = useUiContext();
  let uid = localStorage.getItem("customeruuid");
  const [abnError, setAbnError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState();
  const [errors, setErrors] = useState({
    abn: "",
    timezone: "",
    contact_email: "",
  });
  const [formData, setFormData] = useState({
    name: "",
    abn: "",
    timezone: "",
    sellers: [
      {
        seller_uuid: uid,
      },
    ],
    contact_name: "",
    contact_email: "",
  });

  const fetchFormChoices = async () => {
    const cachedTimezones = localStorage.getItem("timezones");
    if (cachedTimezones && cachedTimezones !== "undefined") {
      try {
        setTimeZoneList(JSON.parse(cachedTimezones));
        return;
      } catch (error) {
        console.error("Failed to parse cached timezones:", error);
      }
    }

    const data = await getTimezoneService(apiRequest);
    if (data) {
      localStorage.setItem("timezones", JSON.stringify(data));
      setTimeZoneList(data);
    }
  };

  useEffect(() => {
    fetchFormChoices();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (
      !formData.name ||
      !formData.abn ||
      !formData.timezone ||
      !formData.contact_email ||
      !formData.contact_name
    ) {
      return;
    }

    const response = await createCustomerService(apiRequest, uid, formData);

    if (response.success) {
      navigate(`${siteAssetManagement}/customer`);
    } else {
      // Update error state to show validation errors
      setErrors(response.errors || {});
    }
    console.log("Error", errors)
    console.log("Submitted Data:", response);
  };

  return (
    <div style={{ padding: "20px", color: "#fff" }}>
      <IconButton onClick={() => navigate(`${siteAssetManagement}/customer`)}>
        <ArrowBackIcon style={{ color: "#3bb44a" }} />
        <Typography color="white" variant="button">
          Back
        </Typography>
      </IconButton>
      <Typography variant="h4" gutterBottom>
        Add Customer
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Left Side: Client and PIC Name */}
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="Name"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              type="text"
              required
              isSubmitted={isSubmitted}
              error={isSubmitted && !formData.name}
              helperText={
                isSubmitted && !formData.name ? "Name is required" : ""
              }
            />
            <CustomTextField
              label="ABN"
              name="abn"
              value={formData.abn}
              onChange={handleChange}
              type="text"
              required
              isSubmitted={isSubmitted}
              error={isSubmitted && (!formData.abn || errors.abn)}
              helperText={
                errors.abn ||
                (isSubmitted && !formData.abn ? "ABN is required" : "")
              }
            />
            <CustomSelect
              options={timeZoneList}
              label="Timezone"
              name="timezone"
              value={formData.timezone}
              onChange={handleChange}
              required
              isSubmitted={isSubmitted}
              error={isSubmitted && (!formData.timezone || errors.timezone)}
              helperText={
                errors.timezone ||
                (isSubmitted && !formData.timezone
                  ? "Timezone is required"
                  : "")
              }
            />
          </Grid>
          {/* Right Side: Email and Location */}
          <Grid item xs={12} sm={6}>
            <CustomTextField
              label="Contact Name"
              name="contact_name"
              placeholder="Billing Contact Name"
              value={formData.contact_name}
              onChange={handleChange}
              required
              type="text"
              error={isSubmitted && !formData.contact_name}
              helperText={
                isSubmitted && !formData.contact_name
                  ? "Billing Contact Name is required"
                  : ""
              }
            />
            <CustomTextField
              label="Contact Email"
              name="contact_email"
              placeholder="Billing Contact Email"
              value={formData.contact_email}
              onChange={handleChange}
              required
              type="email"
              error={
                isSubmitted && (!formData.contact_email || errors.contact_email)
              }
              helperText={
                errors.contact_email ||
                (isSubmitted && !formData.contact_email
                  ? "Billing Contact Email is required"
                  : "")
              }
            />
          </Grid>
        </Grid>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}
        >
          {/* Submit Button */}
          <Button
            variant="contained"
            color="success"
            type="submit"
            className="btn-rounded"
            sx={{ borderRadius: "15px" }}
          >
            Save Customer
          </Button>
        </div>
      </form>
    </div>
  );
}
