import React from "react";
import { FormControlLabel, Switch, Typography } from "@mui/material";

export function CustomSwitch({ label, name, checked, onChange }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        marginTop: "16px",
      }}
    >
      <Typography sx={{ width: "150px", color: "#fff" }}>{label}</Typography>
      <FormControlLabel
        control={
          <Switch
            color="success"
            checked={checked}
            onChange={onChange}
          />
        }
        label=""
      />
    </div>
  );
}

export default CustomSwitch;
