import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import axios from "axios";
import { baseUrl } from "../../../../constants/common";

const CameraStatusDashboard = () => {

  const [cameraStats, setCameraStats] = useState({
    total_cameras: 0,
    online_cameras: 0,
    offline_cameras: 0,
    inactive_cameras: 0
  });

  useEffect(() => {
    const fetchCameraStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${baseUrl}api/1/devices/get_camera_status_counts/`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        setCameraStats(response.data);
      } catch (error) {
        console.error('Error fetching camera stats:', error);
      }
    };

    fetchCameraStats();
  }, []);



  const data = [
    { label: "Total Cameras", value: cameraStats.total_cameras, color: "inherit" },
    { label: "Online", value: cameraStats.online_cameras, color: "green" },
    { label: "Offline", value: cameraStats.offline_cameras, color: "red" },
    { label: "Inactive", value: cameraStats.inactive_cameras, color: "orange" },
  ];

  return (
    <Box padding={4}>
      <Grid container spacing={3}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ backgroundColor: "#1e1e1e", color: "white" }}>
              <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <Box
                      sx={{
                        width: 10,
                        height: 10,
                        borderRadius: "50%",
                        backgroundColor: item.color,
                        marginRight: 1,
                      }}
                    />
                    <Typography variant="h5">{item.label}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center" gap={1}>
                    <InfoIcon />
                  </Box>
                </Box>
                <Typography variant="h3" marginY={1}>{item.value}</Typography>
                <Typography variant="subtitle2">Cameras</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CameraStatusDashboard;
