import React, { useState, useEffect } from "react";
import UserIcon from "../../../../assets/images/user-profile.jpg";
import HeaderDashboard from "../../common/HeaderDashboard";
import SettingNavigation from "../../common/SettingNavigation";
import { useUiContext } from "../../../../contexts/UiContext";
import { Link, useLocation } from "react-router-dom";
import { getLocal, checkUserRoutePermission } from "../../../../helper/common";
import { getAuthHeaders } from "../../../../helper/utils";

export default function Index({ route }) {
  const headers = getAuthHeaders();
  let role = getLocal("role");
  const location = useLocation();
  const { apiRequest } = useUiContext();
  const [routeRole, setRouteRole] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    tz: "",
  });

  useEffect(() => {
    const routeSiteSentry = location.pathname
      .split("/site-sentry/")[1]
      ?.split("/")[0];
    console.log("Extracted Role:", routeSiteSentry); // ✅ Outputs "customer"
    setRouteRole(routeSiteSentry);
  }, [location.pathname]);

  function viewPassword(e) {
    if (passwordType == "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  }

  let name = localStorage.getItem("username");

  const getUserData = async () => {
   
    const res = await apiRequest(
      "get",
      `api/1/my-user/${name}/`,
      "",
      headers,
      true
    );
    if (res) {
      setData(res.data);
    }
  };

  useEffect(() => {
    if (!routeRole) return;
    getUserData();
  }, [routeRole]);

  const updateData = async (e) => {
    e.preventDefault();

    try {
      // Ensure required fields are present
      if (!data?.username) {
        console.error("Username is missing!");
        return;
      }

      // Construct payload with updated fields only
      const payload = {
        ...(data.first_name && { first_name: data.first_name }),
        ...(data.last_name && { last_name: data.last_name }),
      };

      // Check if payload is empty (no updates to send)
      if (Object.keys(payload).length === 0) {
        console.warn("No changes detected.");
        return;
      }

      // API call
      const res = await apiRequest(
        "patch",
        `api/1/my-user/${data.username}/`,
        payload,
        headers,
        true,
        { success: "Updated Successfully", error: "User Not Updated" }
      );
      console.log("API Response:", res);
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
 
  return (
    <div className="d-flex flex-column site-main-wrapper setting-body">
      <HeaderDashboard />
      <div className="flex-grow-1 main-wrapper">
        <div className="main-page-height settings-page d-flex flex-column">
          <SettingNavigation />
          <div className="flex-grow-1 setting-page-wrapper">
            <div className="container">
              <div className="row gx-0 profile-wrapper">
                <div className="col-lg-6 order-2 order-lg-1">
                  <div className="theme-form-wrapper account-form">
                    <form>
                      <div className="d-flex mb__20">
                        <label
                          htmlFor="name"
                          className="col-form-label text-end"
                        >
                          Name
                        </label>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Name"
                            value={data.first_name}
                            onChange={(e) =>
                              setData({
                                ...data,
                                first_name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex mb__20">
                        <label
                          htmlFor="lastName"
                          className="col-form-label text-end"
                        >
                          Last Name
                        </label>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Last Name"
                            value={data.last_name}
                            onChange={(e) =>
                              setData({
                                ...data,
                                last_name: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="d-flex mb__20">
                        <label
                          htmlFor="Email"
                          className="col-form-label text-end"
                        >
                          Timezone
                        </label>
                        <div className="flex-grow-1">
                          <input
                            type="text"
                            className="form-control"
                            value={data.tz}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="d-lg-flex pt-lg-3 text-center">
                        <legend className="col-form-label text-end"></legend>
                        <div className="flex-grow-1">
                          <button
                            className="btn btn-primary btn-rounded"
                            onClick={updateData}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="profile-image-area">
                    <div className="profile-inner">
                      <div className="user-icon">
                        <img src={UserIcon} alt="User Name" />
                        {/* <label htmlFor="inputFile">
                                                    <Svg name="input-file" />
                                                </label> */}
                        <input
                          type="file"
                          id="inputFile"
                          className="invisible d-none"
                        />
                      </div>
                      {checkUserRoutePermission(
                        role,
                        `/${route}/${routeRole}/change-password`
                      ) && (
                        <div className="button-wrap">
                          <Link
                            className="btn btn-primary btn-rounded"
                            to={`/${route}/${routeRole}/change-password`}
                          >
                            Change Password
                          </Link>
                          {/* <button className='btn btn-bordered danger btn-rounded' >Delete Account</button> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
